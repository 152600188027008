import Vue from 'vue';

function createDetailPath(page) {
  const pageDetail = page.split('=');
  const pageUrl = `/${pageDetail[0].split('.')[0]}/`;
  const pageId = pageDetail[1];
  return `${pageUrl}${pageId}`;
}

function getRouteName(routes) {
  return routes.split(', ')[0];
}

export default function availablePages({ to, next, store, nextMiddleware }) {
  Vue.nextTick(() => {
    const paramPages = store.getters['params/getParams'].listParams.availablePages;
    if (to.name === 'fallback' && to.name !== to.meta.fallbackPageName) {
      return next({
        name: to.meta.fallbackPageName
      });
    }
    if (paramPages === undefined) {
      return nextMiddleware();
    }
    if (!paramPages.includes(to.name) && to.name !== getRouteName(paramPages)) {
      if (paramPages.split(', ').length === 1 && paramPages.includes('=')) {
        return next({
          path: createDetailPath(paramPages)
        });
      }
      return next({
        name: getRouteName(paramPages)
      });
    }
    return nextMiddleware();
  });
}
