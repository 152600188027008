<template>
  <div
    class="vms__filters-body-type"
    v-responsive="{
      'media-768': el => el.width < 768
    }"
  >
    <div class="vms__container">
      <simplebar>
        <div v-if="bodyTypesList && bodyTypesList.length" class="vms__filters-body-type_list">
          <div
            v-for="(body, i) of bodyTypesList"
            :key="i"
            class="vms__filters-body-type_list-item"
            v-WaveAnimation.dark="true"
            :data-cy="`filters__icon-${body.name}`"
            :class="{
              'vms__filters-body-type_list-item--active':
                filterSetFilters && filterSetFilters.some(bt => bt === body.key),
              'vms__filters-body-type_list-item--disabled':
                filterSetFiltersAvailable && filterSetFiltersAvailable.length > 0,
              'vms__filters-body-type_list-item--hovered': filterSetFiltersHovered === body.key,
              'vms__filters-body-type_list-item-youngstar':body.name === 'Young Star' || body.name === 'Junge Sterne'
            }"
            @click="setBodyType(body)"
            @mouseover="filterSetFiltersHovered = body.key"
            @mouseleave="filterSetFiltersHovered = null"
          >
            <div
              v-if="body.name === 'Young Star' || body.name === 'Junge Sterne'"
              class="vms__filters-body-type_list-item-p vms__CorporateAConRegular"
              data-cy="filters__icon-YoungStar"
            >
              {{ $t('search.filters.types.BodyTypeFilterYoungstar') }}
            </div>
            <div v-if="body.name !== 'Young Star' && body.name !== 'Junge Sterne'" :class="`${body.cssAlias}`" />
            <div class="vms__filters-body-type_list-item-span vms__OpenSansRegular">
            </div>
          </div>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';
import simplebar from 'simplebar-vue';

export default {
  name: 'BodyTypes',
  mixins,
  components: {
    simplebar
  },
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  data() {
    return {
      filterSetFiltersHovered: null,
      filterSetFilters: [],
      filterSetFiltersAvailable: [],
      timer: null
    };
  },
  created() {
    this.filterSetFilters = this.listParams?.filterSetFilterKeys || [];
    this.filterSetFiltersAvailable = this.listParams?.pluginConfig?.filterSetFilterKeys || [];
  },
  computed: {
    ...mapState('showrooms', {
      filters: 'filters'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    bodyTypesList() {
      if (this.filterSetFiltersAvailable.length > 0) {
        return (
          this.filters.filterSetFilters &&
          this.filters.filterSetFilters.filter(b => this.filterSetFiltersAvailable.includes(b.key))
        );
      }
      return this.filters.filterSetFilters;
    }
  },
  mounted() {
    this.filterSetFilters = this.listParams?.filterSetFilterKeys || [];
  },
  methods: {
    setBodyType(type) {
      if (this.filterSetFiltersAvailable.length < 1) {
        if (this.filterSetFilters.some(bt => bt === type.key)) {
          this.filterSetFilters = this.filterSetFilters.filter(bt => bt !== type.key);
        } else {
          this.filterSetFilters.push(type.key);
        }
        this.$emit('onSelect', this.filterSetFilters);
      }
    },
    reset() {
      this.filterSetFilters = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters-body-type {
  background-color: #f2f2f2;
  padding: 23px 0 0;

  &_list {
    height: 90px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 23px;

    &-item {
      width: 100%;
      min-width: 90px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      overflow: hidden;
      cursor: pointer;
      order: 2;

      &-youngstar {
        order: 1;
        min-width: 120px;
      }

      &-p {
        line-height: 1.43;
        font-size: 1.313rem;
        letter-spacing: -0.04px;
        color: #000;
        margin-bottom: 5px;
        white-space: nowrap;
        pointer-events: none;
      }

      > [class*="vms-icon-"] {
        font-size: 1.75rem;
        color: #062d29;
        opacity: 0.7;
        line-height: 1;
        pointer-events: none;
      }

      &-span {
        line-height: 1.36;
        color: #000;
        opacity: 0.6;
        font-size: 0.688rem;
        font-weight: 600;
        pointer-events: none;
      }

      &--active {
        background-color: #e6eae9;
      }

      &--disabled {
        pointer-events: none;
      }

      &--hovered {
        background-color: #e6eae9;
      }
    }
  }
}

@mixin media-768() {
  &-body-type_list {
    margin: 0 -5px;
  }

  &-body-type_list-item {
    margin: 0 5px;

    &:first-child {
      .vms__filters-body-type_list-item-p {
        font-size: 1rem;
      }

      .vms__filters-body-type_list-item-span {
        font-size: 0.588rem;
      }
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}
</style>
