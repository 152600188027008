<template>
  <div class="vms__ui-pagination-btn" v-if="maxPage > 1">
    <div @click="prev" class="vms__ui-pagination-btn_item vms__ui-pagination-btn--prev">
      <div class="mdi mdi-chevron-left" />
    </div>
    <div class="vms__ui-pagination-btn-p vms__OpenSansRegular">{{ currentPage }}/{{ maxPage }}</div>
    <div @click="next" class="vms__ui-pagination-btn_item vms__ui-pagination-btn--next">
      <div class="mdi mdi-chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIPaginationBtn',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    maxPage: {
      type: Number,
      default: 1
    }
  },
  methods: {
    prev() {
      this.$emit('UIPaginationBtnPrev');
    },
    next() {
      this.$emit('UIPaginationBtnNext');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-pagination-btn {
  display: flex;
  align-items: center;

  &-p {
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
  }

  &_item {
    transition: all 0.2s ease;

    cursor: pointer;
    background-color: transparent;

    .mdi {
      font-size: 1.375rem;
    }

    &:hover {
      transform: scale(1.3);
    }
  }
}
</style>
