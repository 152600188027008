<template>
  <div class="vms__vehicles-form">
    <ValidationObserver v-slot="{ invalid }">
      <div class="vms__vehicles-form_head">
        <div class="vms__vehicles-form_head-h3 vms__OpenSansRegular">
          {{ $t('vehicles.form.name') }}
        </div>
      </div>
      <div v-if="!sended" class="vms__vehicles-form_fields">
        <div class="vms__vehicles-form_fields-list">
          <ValidationProvider name="firstname" rules="required">
            <div slot-scope="{ errors }">
              <UIInput
                v-model="firstname"
                :errorMessage="errors[0]"
                :error="errors && errors.length !== 0"
                :placeholder="$t('vehicles.form.placeholderFirstName')"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider name="lastname" rules="required">
            <div slot-scope="{ errors }">
              <UIInput
                v-model="lastname"
                :errorMessage="errors[0]"
                :error="errors && errors.length !== 0"
                :placeholder="$t('vehicles.form.placeholderLastName')"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider name="phone" rules="required|phoneRule">
            <div slot-scope="{ errors }">
              <UIInput
                v-model="phone"
                :errorMessage="errors[0]"
                :error="errors && errors.length !== 0"
                :placeholder="$t('vehicles.form.placeholderPhone')"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider name="email" rules="required|email">
            <div slot-scope="{ errors }">
              <UIInput
                v-model="email"
                :errorMessage="errors[0]"
                :error="errors && errors.length !== 0"
                typeInput="email"
                :placeholder="$t('vehicles.form.placeholderEmail')"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider name="text" rules="required">
            <div slot-scope="{ errors }">
              <UITextarea
                v-model="text"
                :errorMessage="errors[0]"
                :error="errors && errors.length !== 0"
                :placeholder="$t('vehicles.form.placeholderText')"
              />
            </div>
          </ValidationProvider>
        </div>
        <div class="vms__vehicles-form_fields-checkbox">
          <UICheckbox v-model="isAgree" />
          <div class="vms__vehicles-form_fields-checkbox-p vms__OpenSansRegular">
            {{ $t('vehicles.form.checkboxText') }}
          </div>
        </div>
        <UIButton
          shadow
          @click="sendForm"
          :disabled="[isNotEmpty, invalid, !isAgree].some(s => (s === true ? true : false))"
        >
          <UIIcon v-if="!loading" name="mdi-message-outline" left />
          <UIIcon v-else name="mdi-loading mdi-spin mdi-spin-05" left />
          {{ $t('vehicles.form.btnSend') }}
        </UIButton>
      </div>
      <div v-if="sended" class="vms__vehicles-form_success">
        <div class="mdi mdi-check-circle-outline" />
        <div class="vms__vehicles-form_success-p vms__OpenSansRegular">
          {{ $t('vehicles.form.successText') }}
        </div>
        <UIButton shadow @click="toggleFrom">
          <UIIcon name="mdi-close" left />
          {{ $t('vehicle.modals.request.close') }}
        </UIButton>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import UIButton from '@/components/ui/UIButton';
import UICheckbox from '@/components/ui/UICheckbox';
import UIInput from '@/components/ui/UIInput';
import UIIcon from '@/components/ui/UIIcon';
import UITextarea from '@/components/ui/UITextarea';
import { phone as phoneRule } from '@/helpers/rules';

export default {
  name: 'VehiclesForm',
  mixins,
  directives: { WaveAnimation },
  components: {
    ValidationProvider,
    ValidationObserver,
    UIButton,
    UIIcon,
    UICheckbox,
    UIInput,
    UITextarea
  },
  data() {
    return {
      isAgree: false,
      firstname: null,
      lastname: null,
      phone: null,
      email: null,
      text: null,
      sended: false,
      loading: false,
      phoneRule
    };
  },
  computed: {
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    ...mapState('forms', {
      statusRequestSent: 'statusRequestSent'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    isNotEmpty() {
      return [this.firstname, this.lastname, this.phone, this.email, this.text].some(
        field => !field
      );
    }
  },
  watch: {
    statusRequestSent(newValue) {
      if (newValue === true) {
        this.resetFields();
        const success = {
          show: true,
          text: this.$t('vehicle.modals.request.successAlert'),
          isError: false,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.sended = true;
        this.loading = false;
        this.MixinShowAlert(success);
      }
      if (newValue === false) {
        const error = {
          show: true,
          text: `Error: ${this.$t('vehicle.modals.request.errorAlert')}`,
          isError: true,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.loading = false;
        this.MixinShowAlert(error);
      }
    }
  },
  mounted() {
    extend('required', {
      ...required,
      message: this.$t('errors.requiredField')
    });
    extend('email', {
      ...email,
      message: this.$t('errors.invalidEmail')
    });
    extend('numeric', {
      ...numeric,
      message: this.$t('errors.invalidPhone')
    });
    extend('phoneRule', {
      ...phoneRule,
      message: this.$t('errors.invalidPhone')
    });
  },
  methods: {
    toggleFrom() {
      this.sended = false;
    },
    sendForm() {
      this.loading = true;
      const formData = {
        cultureName: this.listParams.culture,
        showroomUid: this.listParams.showroomUid,
        firstName: this.firstname,
        lastName: this.lastname,
        phone: this.phone,
        email: this.email,
        text: this.text,
        jsonFilters: JSON.stringify(this.listParams)
      };
      this.$store.dispatch('forms/sendVehicleRequestForm', formData);
    },
    resetFields() {
      this.firstname = null;
      this.lastname = null;
      this.phone = null;
      this.email = null;
      this.text = null;
      this.isAgree = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-form {
  padding: 26px 22px;
  background-color: #f2f2f2;

  &_head {
    padding: 50px 20px 0;

    &-h3 {
      color: #000;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }
  }

  &_fields {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > span {
        width: calc(50% - 8px);
        margin-bottom: 20px;

        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .vms__ui-input_wrapper {
        width: 100%;

        /deep/ .vms__ui-input {
          width: 100%;
        }
      }

      .vms__ui-textarea {
        width: 100%;
        min-height: 133px;
      }
    }

    &-checkbox {
      display: flex;
      margin-top: 17px;

      &-p {
        max-width: 526px;
        margin-left: 18px;
        margin-right: 20px;
        color: #000;
        opacity: 0.6;
        line-height: 1.36;
        font-size: 0.688rem;
      }
    }

    .vms__ui-button {
      margin-top: 25px;
      color: #fff;
      background-color: #2d9c90;
    }
  }

  &_success {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .mdi {
      margin-top: 30px;
      font-size: 4rem;
      color: #93d750;
    }

    &-p {
      margin-top: 10px;
      margin-bottom: 40px;
      line-height: 1.5;
      font-size: 1rem;
      color: var(--text-color-black);
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .vms__vehicles-form {
    &_fields {
      flex-direction: column;

      &-list > span {
        width: 100%;
      }

      .vms__ui-input_wrapper {
        width: 100%;
        min-width: 100%;
      }

      .vms__ui-button {
        width: 100%;
      }
    }
  }
}
</style>
