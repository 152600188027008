function nodeScriptIs(node) {
  return node.tagName === 'SCRIPT';
}

function nodeScriptClone(node) {
  const script = document.createElement('script');
  script.text = node.innerHTML;
  let i = -1;
  const attrs = node.attributes;
  let attr = null;
  while (++i < attrs.length) {
    script.setAttribute((attr = attrs[i]).name, attr.value);
  }
  script.async = false;

  return script;
}

export default async function nodeScriptReplace(node) {
  if (nodeScriptIs(node) === true) {
    const node1 = nodeScriptClone(node);
    node.parentNode.replaceChild(node1, node);
    if (node1.src) {
      await new Promise(res => {
        node1.onload = () => {
          setTimeout(() => res(), 100);
        };
      });
    }
  } else {
    let i = -1;
    const children = node.childNodes;
    while (++i < children.length) {
      // eslint-disable-next-line no-await-in-loop
      await nodeScriptReplace(children[i]);
    }
  }

  return node;
}
