import Vue from 'vue';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import vueCustomElement from 'vue-custom-element';
import Vue2TouchEvents from 'vue2-touch-events';
import VueProgressiveImage from 'vue-progressive-image';
import browserDetect from 'vue-browser-detect-plugin';
import i18n from '@/locales';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import configs from '@/configs';

import 'document-register-element/build/document-register-element';

import 'simplebar/dist/simplebar.min.css';

import '@/assets/scss/index.scss';

Vue.config.productionTip = false;
Vue.use(vueCustomElement);
Vue.use(vueCustomElement);
Vue.use(Vue2TouchEvents);
Vue.use(browserDetect);
Vue.use(VueProgressiveImage, {
  cache: false
});

if (configs.SENTRY_DNS && process.env.NODE_ENV === 'never') {
  Sentry.init({
    Vue,
    dsn: configs.SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  });
}

App.i18n = i18n;
App.router = router;
App.store = store;
Vue.customElement('vms-showroom', App);
