import {
  VEHICLES_BEST_DEALS_REQUEST,
  VEHICLES_BEST_DEALS_SUCCESS,
  VEHICLES_BEST_DEALS_ERROR,
  VEHICLES_FILTERED_LIST_REQUEST,
  VEHICLES_FILTERED_LIST_SUCCESS,
  VEHICLES_FILTERED_LIST_ERROR,
  VEHICLES_ITEM_REQUEST,
  VEHICLES_ITEM_SUCCESS,
  VEHICLES_ITEM_ERROR,
  VEHICLES_BY_IDS_REQUEST,
  VEHICLES_BY_IDS_SUCCESS,
  VEHICLES_BY_IDS_ERROR,
  VEHICLES_VIEW_TYPE_SUCCESS,
  VEHICLES_ITEM_CLEAR
} from '@/store/modules/vehicles/types';

export default {
  [VEHICLES_FILTERED_LIST_REQUEST]: state => {
    state.statusFilteredVehiclesLoaded = false;
  },
  [VEHICLES_FILTERED_LIST_SUCCESS]: (state, payload) => {
    state.statusFilteredVehiclesLoaded = true;
    state.filteredList = payload;
  },
  [VEHICLES_FILTERED_LIST_ERROR]: state => {
    state.statusFilteredVehiclesLoaded = false;
  },
  [VEHICLES_BEST_DEALS_REQUEST]: state => {
    state.bestDealsLoaded = false;
  },
  [VEHICLES_BEST_DEALS_SUCCESS]: (state, payload) => {
    state.bestDealsLoaded = true;
    state.bestDealsList = payload;
  },
  [VEHICLES_BEST_DEALS_ERROR]: state => {
    state.bestDealsLoaded = false;
  },
  [VEHICLES_ITEM_REQUEST]: state => {
    state.statusVehicleLoaded = false;
  },
  [VEHICLES_ITEM_SUCCESS]: (state, payload) => {
    state.statusVehicleLoaded = true;
    state.item = payload;
  },
  [VEHICLES_ITEM_ERROR]: state => {
    state.statusVehicleLoaded = false;
  },
  [VEHICLES_ITEM_CLEAR]: state => {
    state.statusVehicleLoaded = false;
    state.item = null;
  },
  [VEHICLES_BY_IDS_REQUEST]: state => {
    state.statusVehiclesByIds = false;
  },
  [VEHICLES_BY_IDS_SUCCESS]: (state, payload) => {
    state.statusVehiclesByIds = true;
    state.vehiclesByIds = payload;
  },
  [VEHICLES_BY_IDS_ERROR]: (state, payload) => {
    state.statusVehiclesByIds = payload;
  },
  [VEHICLES_VIEW_TYPE_SUCCESS]: (state, payload) => {
    state.isGrid = payload;
  }
};
