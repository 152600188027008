<template>
  <div
    class="vms__login"
    v-responsive="{ 'media-480': el => el.width < 480 }"
    :style="{
      width: MixinGetContainerWidth(listParams.width),
      height: MixinGetContainerHeight(listParams.height)
    }"
  >
    <div class="vms__container">
      <div class="vms__login-content">
        <div class="mdi mdi-face vms__BarlowSemiCondensedRegular">
          <div>Sign in</div>
        </div>
        <UIInput
          ref="loginInput"
          v-model="username"
          :placeholder="$t('login.placeholderEmail')"
          :error="notValid"
        />
        <UIInput
          v-model="password"
          typeInput="password"
          :placeholder="$t('login.placeholderPassword')"
          :error="notValid"
        />
        <UIButton shadow @click="onLogin" :disabled="!isValid" background="#2d9c90" color="#fff">
          <UIIcon name="mdi-login" left />
          {{ $t('login.loginBtn') }}
        </UIButton>
        <div v-if="notValid" class="vms__login-content-errors vms__BarlowSemiCondensedRegular">
          <div
            class="vms__login-content-errors-item vms__OpenSansRegular"
          >{{ $t('alerts.authError') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import UIInput from '@/components/ui/UIInput';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';

export default {
  name: 'Login',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: { UIInput, UIButton, UIIcon },
  data() {
    return {
      username: null,
      password: null,
      notValid: false
    };
  },
  computed: {
    ...mapState('auth', {
      status: 'status',
      LoggedIn: 'LoggedIn',
      urlTo: 'urlTo'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    isValid() {
      return this.username && this.password;
    }
  },
  watch: {
    status(newValue) {
      if (newValue === true) {
        this.notValid = false;
        const success = {
          show: true,
          text: this.$t('alerts.authSuccess'),
          isError: false,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.MixinShowAlert(success);
      }
      if (newValue.response && newValue.response.status === 400) {
        this.notValid = true;
      }
    },
    LoggedIn(newValue) {
      if (newValue) {
        if (this.urlTo !== null) {
          window.location.href = `#${this.urlTo}`;
        } else {
          window.location.hash = '';
        }
        window.location.reload();
      }
    },
    showroom(newVal) {
      this.redirectToHome(newVal);
    }
  },
  beforeMount() {
    document.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  mounted() {
    this.$refs.loginInput.$el.children[0].focus();
  },
  methods: {
    onKeydown(e) {
      if (e.keyCode === 13) {
        this.onLogin();
      }
    },
    onLogin() {
      this.$store.dispatch('auth/login', { username: this.username, password: this.password });
    },
    redirectToHome(newVal) {
      if (!newVal || !newVal.customerType) return null;
      if (newVal.customerType === 'b2C') {
        return this.$router.push('/');
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__login {
  min-height: 100vh;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2d9c90;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    box-shadow: 0 3px 10px -2px var(--black-05), 0 2px 10px 0 var(--black-05),
      0 1px 5px 0 rgba(0, 0, 0, 0.05);
    padding: 30px;
    transition: all 0.3s ease;
    background-color: #fff;
    border-radius: 4px;

    > .mdi {
      display: flex;
      align-items: center;
      font-size: 3rem;
      opacity: 0.5;
      margin-bottom: 15px;

      div {
        font-size: 2rem;
        margin-left: 10px;
      }
    }

    &-errors {
      width: 100%;
      margin-top: 15px;
      text-align-last: left;

      &-item {
        position: relative;
        color: #df4949;
        font-size: 0.775rem;
      }
    }

    /deep/ .vms__ui-input_wrapper {
      margin-bottom: 15px;

      input {
        width: 300px;
      }
    }

    .vms__ui-button {
      width: 300px;
    }
  }
}

@mixin media-480() {
  /deep/ .vms__ui-input_wrapper {
    input {
      width: 235px;
    }
  }

  .vms__ui-button {
    width: 235px;
  }
}

.media-480.vms__login {
  @include media-480();
}

@media screen and (max-width: 480px) {
  .vms__login {
    @include media-480();
  }
}
</style>
