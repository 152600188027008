<template>
  <div class="vms__ui-filter-makes" v-if="paramsFromSearchPage">
    <div
      class="vms__ui-filter-makes_item vms__OpenSansRegular"
      v-for="(make, i) of makesFull.filter(m => paramsFromSearchPage.includes(m.code))"
      :key="i"
      :class="{ 'vms__ui-filter-makes_item--disabled': result.includes(make.code) }"
      @click="excludeMake(make.code)"
    >
      <div class="vms__ui-filter-makes_item-p">{{ make.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UIFilterMakes',
  props: {
    makesFull: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      paramsFromSearchPage: null,
      result: []
    };
  },
  created() {
    this.paramsFromSearchPage = this.listParams.makeCodes;
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  methods: {
    excludeMake(make) {
      if (this.result.some(m => m === make)) {
        this.result = this.result.filter(m => m !== make);
      } else {
        this.result.push(make);
      }
      this.$emit(
        'UIFilterMakes',
        this.paramsFromSearchPage.filter(m => !this.result.includes(m))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-filter-makes {
  display: flex;
  align-items: center;

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cacaca;
    padding: 10px 22px;
    margin-right: 12px;
    line-height: 1.38;
    font-size: 0.688rem;
    text-align: center;
    font-weight: 600;
    background-color: #fff;
    cursor: pointer;

    &--disabled {
      opacity: 0.6;
    }

    &:last-child {
      margin-right: 0;
    }

    &-p {
      color: #000;
      opacity: 0.6;
    }
  }
}
</style>
