<template>
  <div
    class="vms__filter-equipment-dot"
    :class="{ 'vms__filter-equipment-dot--checked': checked }"
    @click="toggle"
  >
    <div class="vms__filter-equipment-dot_circle">
      <div
        v-if="checked"
        class="vms__filter-equipment-dot_circle-item"
        :style="{ backgroundColor: colorDot || handleColor(payload && payload.cssAlias) }"
      ></div>
      <div class="vms__filter-equipment-dot_circle-color"></div>
    </div>
    <div class="vms__filter-equipment-dot-p vms__OpenSansRegular">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'UIFilterColors',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    payload: {
      type: Object,
      default: () => {}
    },
    colorDot: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    toggle() {
      this.$emit('UIFilterEquipment', {
        code: this.$props.payload.code,
        name: this.$props.payload.cssAlias,
        count: this.$props.payload.count,
        checked: this.isChecked
      });
    },
    reset() {},
    handleColor(color) {     
      if (color === 'black') {
        return 'var(--accent-white)';
      }
      return 'var(--accent-black)';
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filter-equipment-dot {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_circle {
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 100%;
    position: relative;
    border: 1px solid #cacaca;
    background-color: inherit;
    padding: 0;

    &-color {
      width: 22px;
      height: 22px;
      position: absolute;
      left: 3px;
      top: 3px;
      border-radius: 100%;
      z-index: 1;
    }

    &-item {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      position: absolute;
      left: 8px;
      top: 8px;
      background-color: #000;
      z-index: 2;
    }
  }

  &--checked {
    .vms__filter-equipment-dot_circle {
      border: 1px solid #4f4f4f;
    }

    .vms__filter-equipment-dot-p {
      font-weight: 600;
    }
  }

  &-p {
    font-size: 1rem;
    color: #000;
    margin-left: 20px;
  }
}
</style>
