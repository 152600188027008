<template>
  <div class="vms__dealer-info" data-cy="contact__dealer-info">
    <div class="vms__dealer-info-title vms__BarlowSemiCondensedRegular">
      {{ $t('vehicle.contacts.title') }}
    </div>
    <div class="vms__dealer-info-dealer vms__OpenSansRegular" v-if="info && info.legalCompanyName" data-cy="contact__name">
      {{ info.legalCompanyName }}
    </div>
    <div
      class="vms__dealer-info-tel vms__OpenSansRegular"
      data-cy="contact__phone"
      v-if="info && info.phone"
      @click="MixinOpenLinkThisTab(`tel:${info.phone}`)"
    >
      Tel.: {{ info.phone }}
    </div>
    <div class="vms__dealer-info-fax vms__OpenSansRegular" v-if="info && info.fax" data-cy="contact__fax">
      Fax: {{ info.fax }}
    </div>
    <div class="vms__dealer-address2 vms__OpenSansRegular" v-if="info" data-cy="contact__address">
      <div v-if="info.number || info.street" class="mdi mdi-map-marker" />
      {{ info.street }} {{ info.number }}
      <br v-if="info.street" />
      {{ info.postalCode }} {{ info.city }} {{ info.stateProvince }}
      <br v-if="info.city || info.stateProvince || info.postalCode" />
      {{ countryCodeMappings[info.countryCode] }}
    </div>

    <!-- Link to home page -->
    <UIButton
      v-if="info && info.homepage"
      class="vms__dealer-info-link"
      link
      blank
      :href="info.homepage.replace(/^(?:https?:\/\/)?(?:www\.)?/i, 'https://')"
      data-cy="contact__website"
    >
      <UIIcon left name="mdi-web" size="16px" indentSize="5px" />
      {{ info.homepage.replace(/^(?:https?:\/\/)?(?:www\.)?/i, 'https://') }}
    </UIButton>

    <!-- Link to email -->
    <UIButton
      v-if="!recaptcha || (showEmail && info && info.usedCarsEmail)"
      class="vms__dealer-info-link vms__dealer-info_email-link"
      link
      :href="`mailto:${info && info.usedCarsEmail}`"
    >
      <UIIcon left name="mdi-email-outline" size="16px" indentSize="5px" />
      {{ info && info.usedCarsEmail }}
    </UIButton>
    <UIButton
      v-else
      @click="openModalEmail"
      class="vms__dealer-info-link vms__dealer-info_email-btn"
      color="#0091E2"
      data-cy="btns__show-email"
    >
      <UIIcon left name="mdi-email-outline" size="16px" indentSize="5px" />
      {{ $t('buttons.showEmail') }}
    </UIButton>
  </div>
</template>

<script>
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DealerInfo',
  mixins,
  components: { UIButton, UIIcon },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    recaptcha: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('params', ['showEmail'])
  },
  data() {
    return {
      countryCodeMappings: {
        'AT': 'Austria',
        'DE': 'Germany',
        'UK': 'United Kingdom'
      }
    };
  },
  beforeMount() {
    EventBus.$on('show-email', this.toggleEmail);
  },
  beforeDestroy() {
    EventBus.$off('show-email', this.toggleEmail);
  },
  methods: {
    ...mapMutations('params', ['PARAMS_SHOW_EMAILS']),
    openModalEmail() {
      EventBus.$emit('open-modal-email');
    },
    toggleEmail(e) {
      this.PARAMS_SHOW_EMAILS(e);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__dealer {
  margin-top: 80px;
  background-color: #f2f2f2;
  padding: 28px 0;

  > .vms__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      margin-bottom: 30px;
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    &-dealer,
    &-tel,
    &-fax,
    &-link {
      font-size: 16px;
      line-height: 24px;
      color: #181818;
      text-decoration: none;
    }

    &-tel {
      span {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-link {
      margin-top: 2px;
      cursor: pointer;
      transition: all 0.2s ease;
      text-align: left;
      padding-left: 0;

      &:hover {
        text-decoration: underline;
      }

      .mdi {
        color: #181818;
      }
    }
  }

  &-address2 {
    margin-top: 4px;
    display: flex;

    .mdi {
      margin-top: 3px;
      margin-right: 5px;
      opacity: 0.7;
      font-size: 1.125rem;
    }
  }
}
</style>
