const LocalStorageService = (() => {
  let _service;

  function _installService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    localStorage.setItem('vms__access_token', tokenObj.access_token);
    localStorage.setItem('vms__refresh_token', tokenObj.refresh_token);
  }

  function _getAccessToken() {
    return localStorage.getItem('vms__access_token');
  }

  function _getRefreshToken() {
    return localStorage.getItem('vms__refresh_token');
  }

  function _clearToken() {
    localStorage.removeItem('vms__access_token');
    localStorage.removeItem('vms__refresh_token');
  }

  function _getValueByName(name) {
    return localStorage.getItem(name);
  }

  /**
   * Create list values in localStorage
   * @param {String} keyName - key name in localStorage
   * @param {Object} value - value to add to the localStorage by keyName
   * @returns "item1, item2"
   */
  function _updateListValues(keyName, value) {
    if (typeof value === 'object') {
      let newData = [];
      if (localStorage.getItem(keyName)) {
        newData = JSON.parse(localStorage.getItem(keyName));
      }
      if (newData.some(data => data.id === value.id)) {
        newData = newData.filter(data => data.id !== value.id);
      } else {
        newData.push({ ...value });
      }
      localStorage.setItem(keyName, JSON.stringify(newData));
      return newData;
    }
    if (typeof value === 'string') {
      let newData = [];
      if (localStorage.getItem(keyName)) {
        newData = JSON.parse(localStorage.getItem(keyName));
      }
      if (newData.some(data => data === value)) {
        newData = newData.filter(data => data !== value);
      } else {
        newData.push(value);
      }
      localStorage.setItem(keyName, JSON.stringify(newData));
      return newData;
    }
    if (typeof value === 'number') {
      let newData = [];
      if (localStorage.getItem(keyName)) {
        newData = JSON.parse(localStorage.getItem(keyName));
      }
      if (newData.some(data => data === value)) {
        newData = newData.filter(data => data !== value);
      } else {
        newData.push(value);
      }
      localStorage.setItem(keyName, JSON.stringify(newData));
      return newData;
    }
    return null;
  }

  function _changeListValues(keyName, value) {
    localStorage.setItem(keyName, JSON.stringify(value));
  }

  return {
    installService: _installService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    updateListValues: _updateListValues,
    getValueByName: _getValueByName,
    changeListValues: _changeListValues
  };
})();

export default LocalStorageService;
