<template>
  <div ref="UISelect" class="vms__ui-select">
    <div
      class="vms__ui-select_head"
      :class="{ 'vms__ui-select_head--disable': disable, 'vms__ui-select_head--loaded': !loaded }"
      @click="toggleSelect"
      :data-cy="`filters__${cyName}-dd`"
    >
      <div class="vms__ui-select_head_title vms__OpenSansRegular">{{ label }}</div>
      <div
        class="vms__ui-select_head_desc vms__OpenSansRegular"
        v-for="(option, i) of selectedOptions"
        :key="i"
      >
        {{ option.name || option }}{{ i !== selectedOptions.length - 1 ? ',' : null }}
      </div>
      <div
        v-if="loaded"
        class="mdi mdi-chevron-down"
        :class="{ 'vms__ui-select_head-icon--active': selectOpened }"
      />
      <div v-else class="mdi mdi-loading mdi-spin-05 mdi-24px"></div>
    </div>
    <div
      ref="UISelectOptions"
      class="vms__ui-select_options-wrapper"
      :class="{ 'vms__ui-select_options-wrapper--active': selectOpened }"
    >
      <!-- Search field -->
      <div v-if="searchField" class="vms__ui-select_options-search">
        <input
          v-model="searchRequest"
          class="vms__OpenSansRegular"
          type="text"
          :placeholder="$t('inputs.search')"
        />
        <div @click="searchRequest = null" class="mdi mdi-close" />
      </div>
      <div v-if="options && options.length">
        <!-- Options with categories -->
        <simplebar
          ref="scrollbar"
          v-if="searchField && getOptions && getOptions.length && visible"
          class="vms__ui-select_options"
          :options="scrollbarOptions"
        >
          <div v-for="(options, i) of getOptions" :key="i">
            <div class="vms__ui-select_options-p">- {{ options.key }} -</div>
            <div
              v-for="(option, i) of options.values"
              :key="i"
              :class="{
                'vms__ui-select_options-item--selected': selectedOptions.some(
                  e => e.name === option.name
                )
              }"
              @click="setActiveOption(option)"
              class="vms__ui-select_options-item vms__ui-select_options-item--multiple vms__OpenSansRegular"
            >
              <UIIcon v-if="selectedOptions.some(e => e.name === option.name)" name="mdi-check" />
              <UIIcon v-else name="mdi-minus" />
              {{ option.name }}
            </div>
          </div>
        </simplebar>

        <!-- Simple options -->
        <simplebar
          v-if="!searchField && getOptions && getOptions.length && visible"
          ref="scrollbar"
          class="vms__ui-select_options"
          :options="scrollbarOptions"
        >
          <div
            v-for="(option, i) of getOptions"
            :key="i"
            :class="{
              'vms__ui-select_options-item--selected': selectedOptions.some(
                e => e.name === option.name
              )
            }"
            @click="setActiveOption(option)"
            class="vms__ui-select_options-item vms__OpenSansRegular"
          >
            <UIIcon v-if="selectedOptions.some(e => e.name === option.name)" name="mdi-check" />
            <UIIcon v-else name="mdi-minus" />
            {{ option.name }}
          </div>
        </simplebar>

        <!-- Search result "not found" -->
        <div v-if="getOptions && !getOptions.length" class="vms__ui-select_options--empty">
          {{ $t('ui.notFound') }}
        </div>

        <!-- Footer -->
        <div class="vms__ui-select_footer">
          <UIButton
            shadow
            color="#fff"
            background="#3a3e3c"
            @click="selectOpened = false"
            class="vms__ui-select_finish-btn"
            :data-cy="`btns__finished-${cyName}-dd`"
          >
            {{ $t('ui.finish') }}
          </UIButton>
          <UIButton shadow @click="reset" class="vms__ui-select_reset-btn" :data-cy="`btns__reset-${cyName}-dd`">
            {{ $t('ui.reset') }}
          </UIButton>
        </div>
      </div>

      <!-- Loader -->
      <div v-else class="vms__ui-select_options-loader">
        <div class="mdi mdi-loading mdi-spin-05 mdi-24px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import _ from 'lodash';
import WaveAnimation from '@/directives/WaveAnimation';
import simplebar from 'simplebar-vue';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'UISelect',
  mixins,
  components: { simplebar, UIButton, UIIcon },
  directives: { WaveAnimation },
  props: {
    label: {
      type: String,
      default: 'Label:'
    },
    disable: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: true
    },
    searchField: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    categoryKey: {
      type: String,
      default: 'makeCode'
    },
    visible: {
      type: Boolean,
      default: true
    },
    cyName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timer: null,
      searchRequest: null,
      selectOpened: false,
      activeOption: null,
      selectedOptions: this.options.filter(o => this.selected.includes(o.code||o.key)) || [],
      scrollbarOptions: {
        minScrollbarLength: 20,
        maxScrollbarLength: 50,
        wheelPropagation: false
      }
    };
  },
  mounted() {
    document.addEventListener('click', this.closeSelectOptions);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeSelectOptions);
  },
  computed: {
    getOptions() {
      if (this.searchField) {
        let list = null;
        if (this.searchRequest) {
          list = this.options.filter(o =>
            o.name.toLowerCase().includes(this.searchRequest.toLowerCase())
          );
        }
        return _.chain(list || this.options)
          .groupBy(this.categoryKey)
          .map((values, key) => ({ values, key }))
          .value();
      }
      return this.options;
    }
  },
  methods: {
    toggleSelect() {
      if (!this.disable && this.loaded) {
        if (this.$refs.scrollbar) {
          this.$refs.scrollbar.$el.scrollTop = 0;
        }
        this.selectOpened = !this.selectOpened;
      }
    },
    groupByKey(list, keyGetter) {
      const models = new Map();
      list.forEach(item => {
        const key = keyGetter(item);
        const collection = models.get(key);
        if (!collection) {
          models.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return models;
    },
    closeSelectOptions(e) {
      if (this.selectOpened && !this.$el.contains(e.target) && !this.hasClass(e, 'ps__rail-y')) {
        this.selectOpened = false;
      }
    },
    setActiveOption(option) {
      if (option === null) {
        this.selectedOptions = [];
        this.$emit('UISelect', this.selectedOptions);
        return false;
      }
      if (this.multiple) {
        const selectOptionIndex = this.selectedOptions.findIndex(o => o.name === option.name);
        this.activeOption = option;
        if (this.selectedOptions.length && this.selectedOptions.some(e => e.name === option.name)) {
          this.selectedOptions.splice(selectOptionIndex, 1);
        } else {
          this.selectedOptions.push(option);
        }
        this.$emit('UISelect', this.selectedOptions);
        return true;
      }
      this.toggleSelect();
      this.selectedOptions = [];
      this.selectedOptions.push(option);
      this.$emit('UISelect', this.selectedOptions);
      return true;
    },
    reset() {
      this.setActiveOption(null);
    },
    hasClass(event, className) {
      return event.target.parentNode.className.includes(className);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-select {
  position: relative;
  cursor: pointer;

  &_head {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid #cacaca;
    padding: 5px 0;
    padding-right: 30px;

    &_title,
    &_desc {
      font-size: 1.125rem;
      line-height: 1.33;
      color: #000;
    }

    &_title {
      margin-right: 10px;
    }

    &_desc {
      margin-right: 5px;
      font-size: 16px;
      opacity: 0.7;
    }

    .mdi {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #2d9c90;
      font-size: 30px;
    }

    &-icon--active {
      transform: translateY(-50%) scale(-1) !important;
      transition: all 0.2s ease;
    }

    &--disable {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &--loaded {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  &_options {
    width: 100%;
    max-height: 200px;
    display: flex;
    flex-direction: column;

    &-p {
      padding: 10px 15px;
      font-weight: 700;
    }

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 10px 15px;
      box-sizing: border-box;
      font-size: 0.875rem;
      line-height: 1.33;
      color: var(--text-color--dark);

      &:hover {
        background-color: #f2f2f2;
      }

      &--selected {
        background-color: #f2f2f2;
      }

      &--multiple {
        padding: 8px 25px;
      }

      > div {
        margin-right: 10px;
      }

      .mdi-check {
        font-size: 18px;
      }

      .mdi-minus {
        font-size: 16px;
        opacity: 0.1;
      }
    }

    &-wrapper {
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-out;
      border-radius: 3px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border: 1px solid #cacaca;
      z-index: 20;

      &--active {
        width: 100%;
        height: auto;
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
      }
    }

    &-search {
      width: 100%;
      position: relative;
      padding: 10px 15px;
      box-sizing: border-box;

      input {
        width: 100% !important;
        height: 25px !important;
        border: 1px solid #cacaca !important;
        border-radius: 3px !important;
        font-size: 0.875rem !important;
        padding: 2px 5px !important;
        color: var(--text-color--dark) !important;
        background-color: #fff !important;
        margin: 0 !important;
      }

      .mdi-close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        padding: 4px;
      }
    }

    &-loader {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      box-sizing: border-box;
    }

    /deep/ .simplebar-content-wrapper {
      padding-top: 5px;
    }
  }

  &_footer {
    padding: 10px 15px;
    box-sizing: border-box;
    background-color: #f5f5f5;

    /deep/ .vms__ui-button {
      margin-right: 10px;
    }
  }
}
</style>
