<template>
  <div
    ref="maxHeight"
    class="vms__max-height"
    :class="{ 'vms__max-height--activated': opened }"
    :style="{
      height: opened === false ? startHeight : null,
      maxHeight: opened === false ? `${startHeight}px` : null
    }"
  >
    <slot />
    <div
      v-if="isValidHeight"
      @click="toggle"
      class="vms__ui-max-height-btn"
      :style="{ 'background-color': backgroundButton }"
      :class="{ 'vms__ui-max-height-btn--opened': opened }"
    >
      <div class="mdi mdi-arrow-down" />
      <div class="vms__ui-max-height-btn-span">
        {{ $t('compare.more') }}
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';

export default {
  name: 'MaxHeightFiltersEquipments',
  mixins,
  props: {
    startHeight: {
      type: Number,
      default: 200
    },
    backgroundButton: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      opened: false,
      isValidHeight: false
    };
  },
  updated() {
    // NOTE: revise the logic
    this.isValidHeight =
      this.$slots.default[0].elm &&
      this.$slots.default[0].elm.getBoundingClientRect().height > this.$props.startHeight;
  },
  mounted() {
    // NOTE: revise the logic
    this.isValidHeight =
      this.$slots.default[0].elm &&
      this.$slots.default[0].elm.getBoundingClientRect().height > this.$props.startHeight;
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      if (!this.opened) {
        // this.$refs.maxHeight.scrollIntoView();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__max-height {
  position: relative;
  overflow: hidden;

  &--activated {
    height: auto;
  }
}

.vms__ui-max-height-btn {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 1;
  cursor: pointer;

  &-span {
    font-size: 1rem;
  }

  .mdi {
    font-size: 1.1rem;
    padding: 0 5px;
    margin-top: 3px;
  }

  &--opened {
    position: static;
    margin-top: 10px;

    .mdi {
      transform: scale(-1);
      margin-bottom: 2px;
    }
  }
}
</style>
