import {
  INSTANCE_BY_ID_REQUEST,
  INSTANCE_BY_ID_SUCCESS,
  INSTANCE_BY_ID_ERROR
} from '@/store/modules/instances/types';
import httpClient from '@/axios';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  getInstanceById({ commit }, id) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(INSTANCE_BY_ID_REQUEST);
      httpClient
        .get(`/api/v2/Instances/${id}`, headers)
        .then(resp => {
          if (resp.data && resp.data.baseUrl) {
            httpClient.defaults.baseURL = resp.data.baseUrl;
            commit(INSTANCE_BY_ID_SUCCESS, resp.data);
            resolve(resp);
          } else {
            commit(INSTANCE_BY_ID_ERROR, false);
            resolve(false);
          }
        })
        .catch(err => {
          commit(INSTANCE_BY_ID_ERROR, err);
          reject(err);
        });
    });
  }
};
