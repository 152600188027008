var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
    'media-1200': function (el) { return el.width < 1200; },
    'media-992': function (el) { return el.width < 992; },
    'media-768': function (el) { return el.width < 768; },
    'media-576': function (el) { return el.width < 576; }
  }),expression:"{\n    'media-1200': el => el.width < 1200,\n    'media-992': el => el.width < 992,\n    'media-768': el => el.width < 768,\n    'media-576': el => el.width < 576\n  }"}],staticClass:"vms__container vms__vehicles-detail_equipments_wrapper",class:{
    'vms__autoleasenCalculator-available': _vm.autoleasenCalculator && _vm.autoleasenCalculator !== ''
  }},[_c('div',{staticClass:"vms__vehicles-detail_equipments_content"},[(_vm.equipments && Object.values(_vm.equipments).length)?_c('div',{staticClass:"vms__vehicles-detail_equipments"},[_c('div',{staticClass:"vms__vehicles-detail_equipments_title vms__BarlowSemiCondensedRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicle.services.title'))+" ")]),_c('MaxHeightEquipments',{attrs:{"data-cy":"info__equipments-extras"}},[_c('div',{staticClass:"vms__vehicles-detail_equipments-list"},_vm._l((Object.values(_vm.equipments)),function(equipment,i){return _c('div',{key:i,staticClass:"vms__OpenSansRegular"},[_c('div',{staticClass:"mdi mdi-check"}),_c('div',{staticClass:"vms__vehicles-detail_equipments-list-span"},[_vm._v(_vm._s(equipment))])])}),0)])],1):_vm._e(),(_vm.otherEquipments && _vm.otherEquipments !== '')?_c('div',{staticClass:"vms__vehicles-detail_other-equipments"},[_c('div',{staticClass:"vms__vehicles-detail_other-equipments_title vms__BarlowSemiCondensedRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicle.info.title'))+" ")]),_c('MaxHeightEquipments',[_c('div',{staticClass:"vms__vehicles-detail_other-equipments_p vms__OpenSansRegular",attrs:{"data-cy":"info__additional-info"},domProps:{"innerHTML":_vm._s(_vm.otherEquipments)}})])],1):_vm._e()]),(_vm.autoleasenCalculator && _vm.autoleasenCalculator !== '')?_c('div',{staticClass:"vms__autoleasenCalculator",attrs:{"id":"autoleasen-calc"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }