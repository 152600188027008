import { PARAMS_REQUEST, PARAMS_SUCCESS, PARAMS_ERROR } from '@/store/modules/params/types';

export default {
  async setParams({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(PARAMS_REQUEST);
      if (params) {
        commit(PARAMS_SUCCESS, params);
        resolve(params);
        return;
      }
      commit(PARAMS_ERROR);
      reject(new Error('Parameters not passed'));
    });
  }
};
