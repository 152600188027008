var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
    'media-768': function (el) { return el.width < 768; }
  }),expression:"{\n    'media-768': el => el.width < 768\n  }"}],staticClass:"vms__filters-body-type"},[_c('div',{staticClass:"vms__container"},[_c('simplebar',[(_vm.bodyTypesList && _vm.bodyTypesList.length)?_c('div',{staticClass:"vms__filters-body-type_list"},_vm._l((_vm.bodyTypesList),function(body,i){return _c('div',{directives:[{name:"WaveAnimation",rawName:"v-WaveAnimation.dark",value:(true),expression:"true",modifiers:{"dark":true}}],key:i,staticClass:"vms__filters-body-type_list-item",class:{
            'vms__filters-body-type_list-item--active':
              _vm.filterSetFilters && _vm.filterSetFilters.some(function (bt) { return bt === body.key; }),
            'vms__filters-body-type_list-item--disabled':
              _vm.filterSetFiltersAvailable && _vm.filterSetFiltersAvailable.length > 0,
            'vms__filters-body-type_list-item--hovered': _vm.filterSetFiltersHovered === body.key,
            'vms__filters-body-type_list-item-youngstar':body.name === 'Young Star' || body.name === 'Junge Sterne'
          },attrs:{"data-cy":("filters__icon-" + (body.name))},on:{"click":function($event){return _vm.setBodyType(body)},"mouseover":function($event){_vm.filterSetFiltersHovered = body.key},"mouseleave":function($event){_vm.filterSetFiltersHovered = null}}},[(body.name === 'Young Star' || body.name === 'Junge Sterne')?_c('div',{staticClass:"vms__filters-body-type_list-item-p vms__CorporateAConRegular",attrs:{"data-cy":"filters__icon-YoungStar"}},[_vm._v(" "+_vm._s(_vm.$t('search.filters.types.BodyTypeFilterYoungstar'))+" ")]):_vm._e(),(body.name !== 'Young Star' && body.name !== 'Junge Sterne')?_c('div',{class:("" + (body.cssAlias))}):_vm._e(),_c('div',{staticClass:"vms__filters-body-type_list-item-span vms__OpenSansRegular"})])}),0):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }