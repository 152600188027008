<template>
  <div class="vms__ui-param">
    <div class="vms__ui-param_icon">
      <slot />
    </div>
    <div class="vms__ui-param_info">
      <div class="vms__ui-param_info-p1 vms__OpenSansRegular">{{ label }}</div>
      <div class="vms__ui-param_info-p2 vms__CorporateSDemi" :data-cy="cyValue">
        {{ value }}
        <slot name="value" />
      </div>
      <div class="vms__ui-param_info-p2 vms__CorporateSDemi" v-if="additionalValue">
        {{ additionalValue }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIParam',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    additionalValue: {
      type: String,
      default: ''
    },
    cyValue: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-param {
  display: flex;
  align-items: center;

  &_icon {
    margin-right: 8px;

    .mdi, .vms__ui-icon {
      font-size: 1.875rem;
      color: inherit;
      opacity: 0.7;
    }
  }

  &_info {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-p1 {
      color: inherit;
      font-size: 0.688rem;
      font-weight: 600;
      line-height: 1.36;
      opacity: 0.6;
      margin-bottom: 3px;
    }

    &-p2 {
      font-size: 1.25rem;
      line-height: 1.2;
    }
  }
}
</style>
