export default {
  inserted(el) {
    document.body.appendChild(el);
  },

  unbind(el) {
    if (el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
};
