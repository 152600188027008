<template>
  <div class="vms__vehicle-detail_finance">
    <UIDiscount
      v-if="payload"
      :discount="payload"
    />

    <!-- Price -->
    <div
      v-if="payload && payload"
      class="vms__vehicle-detail_finance_price vms__BarlowLight"
      data-cy="details__vehicle-price"
    >
      € {{ MixinFormatThousands(payload.price) }},—
    </div>
    <!-- END Price -->

    <!-- Warranty -->
    <div
      class="vms__vehicle-detail_finance_warranty vms__OpenSansRegular"
      v-if="payload && payload && payload.warrantyName"
      :title="payload && payload.warrantyDescription"
      data-cy="details__vehicle-guarantee"
    >
      {{$t('vehicle.card.detail.warranty')}}: {{ payload.warrantyName }}
      <span class="mdi mdi-information" data-cy="details__vehicle-guarantee-desc"></span>
    </div>
    <!-- END Warranty -->

    <!-- AutoUncle Rating -->
    <div
      v-if="showroom && showroom.autounclePriceRatingWidgetApiUrl"
      ref="autouncleRating"
      class="au-widget-car-rating"
      data-layout="wider_no_border"
      data-theme="transparent"
      :data-car-url="MixinCreateAutoUncleApiUrl(showroom.autounclePriceRatingWidgetApiUrl, $route.params.id)"
    />
    <!-- END AutoUncle Rating -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import UIDiscount from '@/components/ui/UIDiscount';

export default {
  name: 'Print',
  mixins,
  components: {
    UIDiscount
  },
  props: {
    payload: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('showrooms', {
      showroom: 'showroom'
    })
  },
  mounted() {
    if (window.auWidgetEmbed) {
      window.auWidgetEmbed.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicle-detail_finance {
  display: flex;
  flex-direction: column;

  /deep/ .vms__ui-discount-span {
    font-size: 24px;
  }

  &_price-discount {
    display: block;
    line-height: 1.33;
    font-size: 1.125rem;
    text-decoration: line-through;
    color: #fff;
    opacity: 0.6;
  }

  &_price {
    font-size: 2.688rem;
    font-weight: 300;
    line-height: 1.37;
    color: #fff;
    letter-spacing: -1.52px;
  }

  &_warranty {
    max-width: 280px;
    font-size: 0.688rem;
    font-weight: 600;
    line-height: 1.36;
    opacity: 0.6;
    color: #fff;

    /deep/ ul {
      margin: 5px 0;
      margin-left: 16px;
    }
  }

  .au-widget-car-rating {
    width: 90%;
    margin-top: 10px;
    margin-bottom: -15px;
  }
}
</style>
