<template>
  <div
    class="vms__modal-contact"
    v-responsive="{ 'media-768': el => el.width < 768, 'media-576': el => el.width < 576 }"
  >
    <div class="vms__modal-contact_head">
      <div class="vms__modal-contact_head-title vms__BarlowSemiCondensedRegular">
        {{ $t('vehicle.modals.request.title') }}
      </div>
      <div class="vms__modal-contact_head-desc vms__BarlowSemiCondensedRegular">
        {{ item.makeModel }}
      </div>
    </div>
    <transition name="fade">
      <ValidationObserver v-slot="{ invalid }">
        <div v-if="!sended" class="vms__modal-contact_content">
          <div class="vms__modal-contact_content-fields">
            <ValidationProvider name="firstname" rules="required">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="firstname"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.request.inpPlaceholder1')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="lastname" rules="required">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="lastname"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.request.inpPlaceholder2')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="phone" rules="required|phone">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="phone"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.request.inpPlaceholder3')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="email" rules="required|email">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="email"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  typeInput="email"
                  :placeholder="$t('vehicle.modals.request.inpPlaceholder4')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="subject" rules="required">
              <div slot-scope="{ errors }">
                <UITextarea
                  v-model="subject"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.request.textareaPlaceholder')"
                />
              </div>
            </ValidationProvider>
          </div>
          <div class="vms__modal-contact_content-checkbox">
            <UICheckbox v-model="isAgree" />
            <div
              class="vms__modal-contact_content-checkbox-p vms__OpenSansRegular"
              v-html="$t('vehicle.modals.request.text')"
            ></div>
          </div>
          <vue-recaptcha
            v-if="sitekey"
            :language="cultureSelected.key"
            ref="recaptcha"
            class="vms__modal-contact_captcha"
            :loadRecaptchaScript="true"
            :sitekey="sitekey"
            @verify="verifyRecaptcha"
          />
          <UIButton
            :disabled="[isNotEmpty, invalid, !isAgree, !verified].some(valid => valid === true)"
            @click="sendForm"
            shadow
            background="var(--color--dark)"
            color="var(--text-color--white)"
          >
            <UIIcon v-if="!loading" name="mdi-email" left />
            <UIIcon v-else name="mdi-loading mdi-spin mdi-spin-05" left />
            {{ $t('vehicle.modals.request.btn') }}
          </UIButton>
        </div>
      </ValidationObserver>
    </transition>
    <transition name="fade">
      <div v-if="sended" class="vms__modal-contact_content">
        <div class="vms__modal-contact_content-done">
          <div class="mdi mdi-check-circle-outline" />
          <div class="vms__modal-contact_content-done-p vms__OpenSansRegular">
            {{ $t('modals.successText') }}
          </div>
          <UIButton
            @click="hideModalRequest"
            v-WaveAnimation
            shadow
            background="var(--color--dark)"
            color="var(--text-color--white)"
          >
            <UIIcon name="mdi-close" left />
            {{ $t('vehicle.modals.request.close') }}
          </UIButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import VueRecaptcha from 'vue-recaptcha';
import configs from '@/configs';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import WaveAnimation from '@/directives/WaveAnimation';
import UIButton from '@/components/ui/UIButton';
import UICheckbox from '@/components/ui/UICheckbox';
import UIInput from '@/components/ui/UIInput';
import UITextarea from '@/components/ui/UITextarea';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'ModalRequest',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
    UIButton,
    UICheckbox,
    UIInput,
    UITextarea,
    UIIcon
  },
  data() {
    return {
      sitekey: configs.RECAPTCHA_KEY,
      isAgree: false,
      firstname: null,
      lastname: null,
      phone: null,
      email: null,
      subject: null,
      sended: false,
      loading: false,
      verified: false
    };
  },
  computed: {
    ...mapState('vehicles', {
      item: 'item'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    ...mapState('forms', {
      statusRequestSent: 'statusRequestSent'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    }),
    isNotEmpty() {
      return [this.firstname, this.lastname, this.phone, this.email, this.subject].some(
        field => !field
      );
    }
  },
  mounted() {
    extend('required', {
      ...required,
      message: this.$t('errors.requiredField')
    });
    extend('email', {
      ...email,
      message: this.$t('errors.invalidEmail')
    });
    extend('phone', {
      message: this.$t('errors.invalidPhone'),
      validate(value) {
        const regex = /^[0-9\s- \+]*$/g; //eslint-disable-line
        if (value.match(regex)) {
          return true;
        }
        return false;
      }
    });
  },
  watch: {
    statusRequestSent(newValue) {
      if (newValue) {
        this.resetFields();
        const success = {
          show: true,
          text: this.$t('vehicle.modals.request.successAlert'),
          isError: false,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.sended = true;
        this.loading = false;
        this.MixinShowAlert(success);
      }
      if (typeof newValue === 'string') {
        const error = {
          show: true,
          text: `Error: ${newValue}`,
          isError: true,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.loading = false;
        this.MixinShowAlert(error);
      }
    }
  },
  methods: {
    verifyRecaptcha() {
      this.verified = true;
    },
    hideModalRequest() {
      this.$emit('ModalClose');
    },
    sendForm() {
      if (this.loading === false) {
        this.loading = true;
        const formData = {
          vehicleId: this.item.id,
          culture: this.listParams.culture,
          showroomUid: this.listParams.showroomUid,
          firstName: this.firstname,
          lastName: this.lastname,
          phone: this.phone,
          email: this.email,
          text: this.subject,
          url: window.location.href,
          commissionNumber: this.item.commissionNumber
        };
        this.$store.dispatch('forms/sendRequestForm', formData);
      }
    },
    resetFields() {
      this.firstname = null;
      this.lastname = null;
      this.phone = null;
      this.email = null;
      this.subject = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__modal-contact {
  width: 530px;
  position: relative;

  &_captcha {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &_head {
    padding: 50px 20px 10px;

    &-title {
      color: #000;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }

    &-desc {
      margin-top: 3px;
      font-size: 1.625rem;
      line-height: 1.15;
      letter-spacing: -0.04px;
      color: #000;
    }
  }

  &_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 26px 22px;
    background-color: #fff;

    &-fields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > span {
        width: calc(50% - 8px);
        margin-bottom: 20px;

        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
      }

      &--error {
        color: #df4949;
      }

      .vms__ui-select-border {
        width: calc(50% - 8px);
        height: 42px;
      }

      .vms__ui-input_wrapper {
        width: 100%;

        /deep/ .vms__ui-input {
          width: 100%;
        }
      }

      /deep/ .vms__ui-textarea {
        width: 100%;
        min-height: 133px;
      }
    }

    &-checkbox {
      display: flex;
      margin-top: 17px;
      background-color: #f7f7f7;
      padding: 25px 20px;
      border-radius: 3px;

      &-p {
        margin-left: 18px;
        margin-right: 20px;
        color: #000;
        opacity: 0.6;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-done {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .mdi {
        margin-top: 110px;
        font-size: 4rem;
        color: #93d750;
      }

      &-p {
        margin-top: 10px;
        margin-bottom: 121px;
        line-height: 1.5;
        font-size: 1rem;
        color: #000;
        text-align: center;
      }
    }

    .vms__ui-button {
      width: 100%;
      margin-top: 30px;
    }
  }

  /deep/ .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  /deep/ .fade-enter,
  .fade-leave-to {
    width: 100%;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@mixin media-768() {
  &_content-fields {
    &-p {
      font-size: 0.875rem;
      margin-right: 0;
    }
  }
}

@mixin media-576() {
  width: 100%;

  &_content-fields {
    width: 100%;
    flex-direction: column;

    > span {
      width: 100%;
    }

    .vms__ui-input_wrapper {
      width: 100%;
      min-width: 100%;
    }
  }
}

.media-768 .vms__modal-contact {
  @include media-768();
}

.media-576 .vms__modal-contact {
  @include media-576();
}

@media screen and (max-width: 768px) {
  .vms__modal-contact {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__modal-contact {
    @include media-576();
  }
}
</style>
