<template>
  <div
    class="vms__filters-colors"
    v-if="filters && filters.colors && filters.colors.length"
    v-responsive="{
      'media-768': el => el.width < 768
    }"
  >
    <div class="vms__container">
      <div
        class="vms__filters-colors_sibtitle vms__OpenSansRegular"
      >{{ $t('search.filters.colors.title') }}</div>
      <div class="vms__filters-colors-all" @click="resetAll" data-cy="btns__all-colors">{{ $t('search.filters.colors.all') }}</div>
      <div class="vms__filters-colors_list">
        <UIFilterColorDot
          ref="uiFilterColorDot"
          v-for="(color, i) of filters.colors"
          :key="i"
          :checked="selectedColors && selectedColors.some(c => c === color.code)"
          :payload="color"
          :label="color.name"
          @UIFilterColorDot="createColorParams"
        />
      </div>
      <div class="vms__filters-colors_metallic">
        <UIFilterColorDot
          ref="uiFilterMetallicDot"
          label="Metallic"
          :checked="metallicSelected"
          :payload="metallic"
          @UIFilterColorDot="createMetallicParam"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import UIFilterColorDot from '@/components/ui/UIFilterColorDot';

export default {
  name: 'FilterColors',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: { UIFilterColorDot },
  data() {
    return {
      selectedColors: [],
      metallicSelected: null,
      metallic: {
        cssAlias: 'metallic'
      }
    };
  },
  created() {
    this.selectedColors = this.listParams.colorCodes || [];
    this.metallicSelected = this.listParams.isMetallic || null;
  },
  computed: {
    ...mapState('showrooms', {
      filters: 'filters'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  methods: {
    createColorParams(color) {
      if (this.selectedColors.some(c => c === color.code)) {
        this.selectedColors = this.selectedColors.filter(c => c !== color.code);
      } else {
        this.selectedColors.push(color.code);
      }
      this.$emit('createColorParams', this.selectedColors);
    },
    createMetallicParam(color) {
      if (color.checked) {
        this.$emit('createMetallicParam', true);
      } else {
        this.$emit('createMetallicParam', null);
      }
    },
    resetAll() {
      this.reset();
      this.selectedColors = [];
      this.metallicSelected = null;
      this.$emit('reset');
    },
    reset() {
      this.$refs.uiFilterColorDot.forEach(c => {
        c.reset();
      });
      this.$refs.uiFilterMetallicDot.reset();
      this.selectedColors = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters-colors {
  padding-top: 70px;
  background-color: #f2f2f2;

  &_sibtitle {
    color: #000;
    line-height: 1.33;
    font-size: 1.125rem;
    margin-top: 10px;
  }

  .vms__container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 47px;
  }

  &-all {
    height: 30px;
    margin-top: 25px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 50px;
    padding: 3px 25px;
    border-radius: 15px;
    border: 1px solid #4f4f4f;
    background-color: #fff;
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      opacity: 0.8;
      box-shadow: 0 5px 5px -3px var(--black-07), 0 8px 10px 1px var(--black-07),
        0 3px 14px 2px rgba(0, 0, 0, 0.03);
    }

    &:active {
      background-color: #ccc;
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 50px;
    width: 100%;

    .vms__filter-color-dot {
      width: calc(33% - 20px);
      margin-right: 20px;
      margin-top: 10px;
    }
  }

  &_metallic {
    margin-top: 30px;
    margin-left: 50px;
  }
}

@mixin media-768() {
  &-colors {
    .vms__container {
      flex-wrap: wrap;
    }

    &-all {
      margin-left: 0;
    }

    &_list {
      margin-left: 0;

      .vms__filter-color-dot {
        width: calc(50% - 20px);
      }
    }

    &_metallic {
      margin-left: 0;
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}
</style>
