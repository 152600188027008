<template>
  <div class="vms__modal-preview-img">
    <agile
      v-if="images && images.length"
      :dots="images.length > 1"
      :initialSlide="slideIndex"
      :unagile="images.length < 2"
      ref="cardCarousel"
    >
      <div v-for="(img, i) of images" :key="i" class="vms__modal-preview-img-item">
        <img :src="img.big" />
      </div>
      <template v-if="images.length > 1" slot="prevButton"
        ><div v-WaveAnimation class="mdi mdi-arrow-left"
      /></template>
      <template v-if="images.length > 1" slot="nextButton"
        ><div v-WaveAnimation class="mdi mdi-arrow-right"
      /></template>
    </agile>
    <div v-if="images && !images.length" class="vms__modal-preview-img_img-fallback">
      <div class="mdi mdi-image" />
    </div>
  </div>
</template>

<script>
import { VueAgile } from '@/plugins/vue-agile';
import WaveAnimation from '@/directives/WaveAnimation';

export default {
  name: 'ModalImgPreview',
  components: { agile: VueAgile },
  directives: {
    WaveAnimation
  },
  props: {
    slideIndex: {
      type: Number,
      default: 0
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    document.addEventListener('keydown', this.listenArrowsLeftRight);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.listenArrowsLeftRight);
  },
  methods: {
    prevSlideCard() {
      this.$refs.cardCarousel.goToPrev();
    },
    nextSlideCard() {
      this.$refs.cardCarousel.goToNext();
    },
    listenArrowsLeftRight(e) {
      if (e.keyCode === 37) {
        this.prevSlideCard();
      }
      if (e.keyCode === 39) {
        this.nextSlideCard();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__modal-preview-img {
  width: 100%;
  height: auto;
  display: block;

  &-item {
    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }

  /deep/ .agile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__actions {
      justify-content: center;
      margin: auto 0;
      padding: 0;
    }

    &__nav-button {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 10px;
      position: relative;
      overflow: hidden;

      .mdi {
        font-size: 34px;
        color: #fff;
      }

      &--prev {
        position: absolute;
        left: -65px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--next {
        position: absolute;
        right: -65px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__dots {
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      padding: 3px;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5);
      margin: 0;
    }

    &__dot {
      padding: 0;

      &-btn {
        width: 6px;
        height: 6px;
        display: block;
        background-color: #000;
        opacity: 0.6;
        margin: 0 3px;
        padding: 0;
        border-radius: 100%;
      }

      &--current {
        .agile__dot-btn {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>
