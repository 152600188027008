<template>
  <div
    class="vms__modal-email"
    v-responsive="{ 'media-768': el => el.width < 768, 'media-576': el => el.width < 576 }"
  >
    <div class="vms__modal-email_head">
      <div
        class="vms__modal-email_head-title vms__BarlowSemiCondensedRegular"
      >{{ $t('modals.contact.title') }}</div>
    </div>
    <div class="vms__modal-email_descr vms__OpenSansRegular">{{ $t('modals.contact.desc') }}</div>
    <UIButton
      @click="openModalRequest"
      shadow
      background="var(--color--dark)"
      color="var(--text-color--white)"
    >
      <UIIcon name="mdi-email" left />
      {{ $t('modals.contact.sendEmail') }}
    </UIButton>
    <UIButton
      v-if="showroom && showroom.whatsappNumber"
      background="#4FCE5D"
      color="#fff"
      v-WaveAnimation
      shadow
      link
      blank
      :href="`https://wa.me/${showroom.whatsappNumber}?text=${whatsAppText}`"
    >
      <UIIcon name="mdi-whatsapp" left />
      {{ $t('vehicle.contacts.whatsapp') }}
    </UIButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'ModalContact',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: { UIButton, UIIcon },
  data() {
    return {
      whatsAppText: null
    };
  },
  watch: {
    $route() {
      this.init();
    }
  },
  computed: {
    ...mapState('showrooms', {
      showroom: 'showroom'
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    hideModalContact() {
      this.$emit('ModalClose');
    },
    openModalRequest() {
      this.hideModalContact();
      EventBus.$emit('open-modal-request');
    },
    init() {
      const url = window.location.href;
      this.whatsAppText = encodeURIComponent(this.$t('vehicle.whatsappText', { url }));
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__modal-email {
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
  position: relative;

  &_head {
    padding: 50px 20px 10px;

    &-title {
      color: #000;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }
  }

  &_descr {
    max-width: 310px;
    margin: 20px auto 40px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    text-align: center;
  }

  .vms__ui-button {
    width: 190px;
    margin-bottom: 10px;
  }
}
</style>
