import httpClient from '@/axios';
import EventBus from '@/services/eventBusService';
import authActions from '@/store/modules/auth/actions';

const interceptorsService = () => {
  let isAlreadyFetchingAccessToken = false;
  let subscribers = [];

  function onAccessTokenFetched(accessToken) {
    subscribers = subscribers.filter(callback => callback(accessToken));
  }

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  httpClient.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          authActions
            .refreshToken()
            .then(resp => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(resp.data.access_token);
            })
            .catch(err => err);
        }
        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(httpClient(originalRequest));
          });
        });
        return retryOriginalRequest;
      }
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === 'invalid_grant'
      ) {
        window.location.hash = '/login';
      }
      return Promise.reject(error);
    }
  );

  httpClient.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Network Error') {
        EventBus.$emit('network-error', 604);
      } else if (error.response && error.response.status === 500) {
        EventBus.$emit('network-error', 607);
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error !== 'invalid_grant'
      ) {
        EventBus.$emit('network-error', 605);
      }
      return error;
    }
  );
};

export default interceptorsService;
