import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_ADD_URL_TO_REQUEST,
  AUTH_ADD_URL_TO_SUCCESS,
  AUTH_ADD_URL_TO_ERROR,
  LOGOUT
} from '@/store/modules/auth/types';

export default {
  [AUTH_REQUEST]: state => {
    state.status = false;
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = true;
    state.token = token;
    state.LoggedIn = true;
  },
  [AUTH_ERROR]: (state, payload) => {
    state.status = payload;
    state.LoggedIn = false;
  },
  [AUTH_ADD_URL_TO_REQUEST]: state => {
    state.statusUrlTo = false;
  },
  [AUTH_ADD_URL_TO_SUCCESS]: (state, url) => {
    state.statusUrlTo = true;
    state.urlTo = url;
  },
  [AUTH_ADD_URL_TO_ERROR]: (state, payload) => {
    state.statusUrlTo = payload;
  },
  [LOGOUT]: state => {
    state.status = false;
    state.token = '';
    state.LoggedIn = false;
  }
};
