<template>
  <div :class="`vms__ui-icon ${name.startsWith('vms') ? '' : 'mdi'} ${name}`" :style="indents" />
</template>

<script>
/**
 * A simple icon component
 *
 * ```html
 * <UIIcon name="mdi-account" />
 * ```
 */

export default {
  name: 'UIIcon',
  props: {
    /**
     * Icon className. We use material icons https://cdn.materialdesignicons.com/5.4.55
     */
    name: {
      type: String,
      default: 'mdi-account'
    },
    /**
     * Places the icon on the left, when used inside a button
     */
    left: {
      type: Boolean,
      default: false
    },
    /**
     * Places the icon on the right, when used inside a button
     */
    right: {
      type: Boolean,
      default: false
    },
    /**
     * The size of the indentation of the icon
     */
    indentSize: {
      type: String,
      default: '12px'
    },
    /**
     * Set size for icon
     */
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    indents() {
      const indents = [];
      if (this.left) {
        indents.push(`margin-right: ${this.indentSize}`);
      }
      if (this.right) {
        indents.push(`margin-left: ${this.indentSize}`);
      }
      if (this.size) {
        indents.push(`font-size: ${this.size}`);
      }
      return indents.join('; ');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-icon {
  font-size: 20px;
}
</style>
