<template>
  <div
    @click="toogleMobMenu"
    class="vms__ui-burger"
    :class="{ 'vms__ui-burger--opened': mobMenuOpened }"
    v-responsive="{ 'media-768': el => el.width < 768 }"
  >
    <div class="vms__ui-burger-span"></div>
    <div class="vms__ui-burger-span"></div>
    <div class="vms__ui-burger-span"></div>
    <div class="vms__ui-burger-span"></div>
  </div>
</template>

<script>
import Responsive from '@/directives/Responsive';
import EventBus from '@/services/eventBusService';

export default {
  name: 'UIBurger',
  directives: {
    responsive: Responsive
  },
  data() {
    return {
      mobMenuOpened: false
    };
  },
  beforeMount() {
    EventBus.$on('closeMobMenu', this.close);
  },
  beforeDestroy() {
    EventBus.$off('closeMobMenu', this.close);
  },
  methods: {
    toogleMobMenu() {
      this.mobMenuOpened = !this.mobMenuOpened;
      EventBus.$emit('toogleMobMenu', this.mobMenuOpened);
    },
    close() {
      this.mobMenuOpened = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-burger {
  width: 24px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;

  &::before {
    content: '';
    height: 42px;
    width: 42px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-21%, -28%);
    background-color: #fff;
    border-radius: 100%;
    z-index: -1;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &-span {
    width: 100%;
    height: 3px;
    display: block;
    position: absolute;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    z-index: 1;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  &.vms__ui-burger--opened {
    .vms__ui-burger-span:nth-child(1) {
      top: 0;
      width: 0%;
      left: 50%;
    }

    .vms__ui-burger-span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .vms__ui-burger-span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .vms__ui-burger-span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
}

@mixin media-768() {
  display: block;
}

.media-768 .vms__ui-burger {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__ui-burger {
    @include media-768();
  }
}
</style>
