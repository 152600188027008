import {
  FORMS_REQUEST_REQUEST,
  FORMS_REQUEST_SUCCESS,
  FORMS_REQUEST_ERROR,
  FORMS_PRICE_REQUEST,
  FORMS_PRICE_SUCCESS,
  FORMS_PRICE_ERROR
} from '@/store/modules/forms/types';

export default {
  [FORMS_REQUEST_REQUEST]: state => {
    state.statusRequestSent = null;
  },
  [FORMS_REQUEST_SUCCESS]: (state, payload) => {
    state.statusRequestSent = true;
    state.requestData = payload;
  },
  [FORMS_REQUEST_ERROR]: state => {
    state.statusRequestSent = false;
  },
  [FORMS_PRICE_REQUEST]: state => {
    state.statusPriceSent = null;
  },
  [FORMS_PRICE_SUCCESS]: (state, payload) => {
    state.statusPriceSent = true;
    state.priceData = payload;
  },
  [FORMS_PRICE_ERROR]: state => {
    state.statusPriceSent = false;
  }
};
