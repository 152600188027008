<template>
  <div class="vms__ui-tag">{{ lable }}</div>
</template>

<script>
export default {
  name: 'UITag',
  props: {
    lable: {
      type: String,
      default: 'Tag name'
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-tag {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 20px;
  background-color: #f2f2f2;
  color: #000;
  line-height: normal;
}
</style>
