import { ALERTS_NEW_SUCCESS, ALERTS_CLEAR } from '@/store/modules/alerts/types';

export default {
  [ALERTS_NEW_SUCCESS]: (state, alert) => {
    state.alertsList.push(alert);
  },
  [ALERTS_CLEAR]: (state, dateNow) => {
    state.alertsList = state.alertsList.filter(a => a.dateNow !== dateNow);
  }
};
