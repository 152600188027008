<template>
  <div v-responsive="{
    'media-992': el => el.width < 992,
    'media-576': el => el.width < 576,
    'media-480': el => el.width < 480
  }">

    <PageContainer :loaded="false">
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageContainer from '@/containers/PageContainer';
import Responsive from '@/directives/Responsive';

export default {
  name: 'VehiclesDetailByRef',
  directives: {
    responsive: Responsive
  },
  components: { PageContainer },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      showroomLoaded: 'showroomLoaded'
    })
  },
  watch: {
    showroomLoaded(loaded) {
      if (!loaded) {
        return;
      }
      this.redirectToVehicle();
    }
  },
  async created() {
    if (this.showroomLoaded) {
      this.redirectToVehicle();
    }
  },
  methods: {
    async redirectToVehicle() {
      const id = await this.$store.dispatch(
        'vehicles/getVehicleIdByRef',
        {
          showroomUid: this.listParams.showroomUid,
          culture: this.listParams.culture,
          importedId: this.$route.params.importedId
        }
      );
      this.$router.replace({ name: 'vehicles.detail', params: { id } });
    }
  }
};
</script>
