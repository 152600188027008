import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enUS from '@/locales/enUS';
import de from '@/locales/de';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: { enUS, de },
  silentTranslationWarn: true
});

export default i18n;
