<template>
  <div class="vms__ui-alert" :class="{ 'vms__ui-alert--error': payload.isError }">
    <div class="vms__ui-alert-p vms__OpenSansRegular">{{ payload.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'UIAlert',
  props: {
    payload: {
      type: [Object],
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      lifeTime: 3000,
      showAlert: false
    };
  },
  methods: {
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-alert {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  margin-bottom: 10px;
  border-right: 3px solid #2d9c90;
  position: fixed;
  top: 24px;
  right: 24px;

  @media screen and (max-width: 768px) {
    right: 0;
  }

  &--error {
    border-right: 3px solid #df4949;
  }

  &-p {
    color: var(--text-color--dark);
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
</style>
