import { render, staticRenderFns } from "./VehiclesDetail.vue?vue&type=template&id=4e32333f&scoped=true&"
import script from "./VehiclesDetail.vue?vue&type=script&lang=js&"
export * from "./VehiclesDetail.vue?vue&type=script&lang=js&"
import style0 from "./VehiclesDetail.vue?vue&type=style&index=0&id=4e32333f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e32333f",
  null
  
)

export default component.exports