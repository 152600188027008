<template>
  <div v-responsive="{ 'media-768': el => el.width < 768 }">
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__search">
        <NavigatePanel
          :isKfz="showroom && showroom.includeCars"
          :isMotocycle="showroom && showroom.includeMotorcycles"
          :isVan="false"
          :breadcrumbs="crumbs"
        />
        <Filters :allFields="true" :params="listParams" @scrollToTop="goTopPage" />
        <div class="vms__container" style="padding: 0">
          <VehiclesBestDeals :title="$t('search.filters.bestDealsTitle')" />
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';
import PageContainer from '@/containers/PageContainer';
import VehiclesBestDeals from '@/components/VehiclesBestDeals';
import Filters from '@/components/Filters';
import NavigatePanel from '@/components/NavigatePanel';
import UIBurger from '@/components/ui/UIBurger';

export default {
  name: 'Search',
  mixins,
  components: {
    VehiclesBestDeals,
    Filters,
    NavigatePanel,
    UIBurger,
    PageContainer
  },
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  data() {
    return {
      dataIsReady: false
    };
  },
  created() {
    document.title = this.showroom.instanceName || 'Vms';
  },
  computed: {
    ...mapState('vehicles', {
      statusFilteredVehiclesLoaded: 'statusFilteredVehiclesLoaded',
      filteredList: 'filteredList'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom',
      showroomLoaded: 'showroomLoaded',
      statusFilters: 'statusFilters',
      showroom: 'showroom'
    }),
    dataLoading() {
      return (
        this.showroomLoaded &&
        this.statusShowroom &&
        this.statusFilteredVehiclesLoaded &&
        this.statusFilters
      );
    },
    crumbs() {
      return [{ link: '/search', title: this.$t('breadcrumbs.search') }];
    }
  },
  watch: {
    dataLoading(status) {
      if (status === true) {
        setTimeout(() => {
          this.dataIsReady = true;
        }, 500);
      }
    }
  },
  methods: {
    goTopPage() {
      const offset = window.offsetScrollTop || this.$root.offsetScrollTop || 0;
      window.scrollTo(0, this.MixinGetOffset(this.$root.$el) - offset);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__search {
  height: auto;
  background-color: #fff;

  &_banner {
    min-height: 430px;
    background-image: url('../assets/img/search-banner.jpg');
    background-position: center;
    background-size: cover;

    .vms__container {
      height: 100%;
      min-height: 430px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .vms__ui-discount {
      position: absolute;
      top: 0;
      left: 15px;
    }

    &-info {
      margin-bottom: 55px;

      &_title {
        max-width: 440px;
        margin-left: 9px;

        div {
          line-height: 1.45;
          letter-spacing: -0.22px;
          font-size: 1.625rem;
          color: #fff;
          background-color: #062d29;
          box-shadow: 10px 0 0 var(--accent-dark), -10px 0 0 #062d29;
          padding-bottom: 5px;
        }
      }

      &_price {
        display: inline;
        line-height: 1.45;
        letter-spacing: -0.22px;
        font-size: 2.688rem;
        color: #fff;
        background-color: #062d29;
        box-shadow: 10px 0 0 var(--accent-dark), -10px 0 0 #062d29;
        padding-bottom: 5px;
        margin-left: 9px;
      }

      &_discount {
        display: block;
        line-height: 1.5;
        font-size: 1rem;
        text-decoration: line-through;
        color: #c5c4c4;
        opacity: 0.6;
      }
    }

    .vms__ui-button {
      margin-bottom: 55px;
      background-color: #2d9c90;
      color: #fff;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .vms__filters {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .vms__best-deals {
    margin-top: 60px;
  }
}

@mixin media-768() {
  padding-bottom: 40px;

  .vms__best-deals,
  .vms__filters {
    margin-top: 60px;
  }

  &_banner {
    .vms__container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      .vms__ui-button {
        margin-bottom: 30px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;

      &_title,
      &_price {
        display: inline-block;
        margin-bottom: 6px;
      }

      &_title {
        line-height: 1;
        margin-bottom: 10px;

        div {
          line-height: 1;
        }
      }

      &_discount {
        background-color: #062d29;
        box-shadow: 10px 0 0 var(--accent-dark), -10px 0 0 #062d29;
        margin-left: 9px;
      }
    }
  }
}

.media-768.vms__search {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__search {
    @include media-768();
  }
}
</style>
