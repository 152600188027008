export const loadProbefahrtenbutlerScript = () => {
  const scriptCreated = document.querySelectorAll('[data-modal-class="probefahrt-quicklink"]');
  if (scriptCreated && !scriptCreated.length) {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', 'https://app.probefahrtenbutler.com/js/shop.js');
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('data-name', 'tdx-script');
    scriptElement.setAttribute('data-modal-class', 'probefahrt-quicklink');
    document.body.appendChild(scriptElement);
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('href', 'https://app.probefahrtenbutler.com/css/frame-v1.css');
    document.head.appendChild(linkElement);
  }
};

export const loadReCaptcha = () => {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute(
    'src',
    'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
  );
  scriptElement.setAttribute('defer', true);
  scriptElement.setAttribute('async', true);
  document.body.appendChild(scriptElement);
};

export const loadAutoUncle = (options, stockId) => {
  const scriptElement = document.createElement('script');
  scriptElement.id = 'au-trade-init';
  // List keys https://www.autouncle.se/widgets/trade_in_valuations/demo_page?config_key=dmXE6rThCxNwEAfNEaLWqw#api-reference-parameters
  scriptElement.innerHTML = `
    window.AuEmbed = {
      config: {
        key: '${options.autouncleWidgetConfigKey}', // Your unique configuration key
        noDelayedCtaPage: true, // set to true if you don't want the pop-up to appear automatically
        data: {
          stockId: '${stockId}', // (string) A unique ID that will be assigned to the leads submitted by that particular integration of the module
        },
        customData: {
          // Any data added here will be added to the lead
          leadSource: null, // (string) is used in the exported XML to the CRM system
        },
        onFormOpen: null, // (function) a callback executed when the trade-in module is opened
        onCarDetailsStarted: null, // (function) a callback executed when the customer sees the car details form
        onCarDetailsSent: null, // (function) a callback executed when the customer finishes completing the car details form
        onCustomerDetailsSent: null, // (function) a callback executed when the customer submits the contact details form
        onValuationDone: null, // (function) a callback executed when the customer submits the data about their car, and the price can be valuated
        onValuationFailed: null, // (function) a callback executed when the customer submits the data about their car, and the price cannot be valuated
        onUnavailable: null, // (function) a callback executed when the Trade-in module is unavailable (e.g. deactivated)
      }
    };
    var s=document.createElement('script');
      s.async=true;
      s.defer=true;
      s.src='https://www.autouncle.at/widgets/trade_in_valuations/embed_script.js?config_key='+encodeURIComponent(AuEmbed.config.key);
      s.id='au-trade-in-script';
      document.body.appendChild(s);
  `;
  document.body.appendChild(scriptElement);
};

export const loadAutoUncleRating = options => {
  const scriptRating = document.createElement('script');
  // https://www.autouncle.at/customer_website_scripts/price_rating_doc?uuid=e5047094-7b71-4617-b4f2-5cf69d0a1d6a
  scriptRating.innerHTML = `
    window.auWidgetConfig = {sourceName: '${options.autounclePriceRatingWidgetSourceName}'};
    s=document.createElement('script');
    s.async=true;
    s.src='https://www.autouncle.at/widgets/embed.js?source_name='+encodeURIComponent(auWidgetConfig.sourceName);
    s.setAttribute('data-source-name',auWidgetConfig.sourceName);
    s.id='au-widget-script';
    document.body.appendChild(s);
  `;
  if (options.autounclePriceRatingWidgetEnabled) {
    document.body.appendChild(scriptRating);
  }
};
