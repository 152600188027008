<template>
  <div ref="maxHeight" :class="{ 'vms__max-height': !opened && isValidHeight }">
    <slot />
    <div
      v-if="isValidHeight"
      @click="toggle"
      class="vms__ui-max-height-btn"
      :class="{ 'vms__ui-max-height-btn--opened': opened }"
    >
      <div class="mdi mdi-arrow-down" />
      <div class="vms__ui-max-height-btn-span">
        {{ $t('compare.more') }}
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';

export default {
  name: 'MaxHeightComparesEquipment',
  mixins,
  data() {
    return {
      opened: false,
      isValidHeight: true,
      validHeight: 400
    };
  },
  updated() {
    // NOTE: revise the logic
    this.isValidHeight =
      this.$slots.default[0].elm &&
      this.$slots.default[0].elm.getBoundingClientRect().height > this.validHeight;
  },
  mounted() {
    // NOTE: revise the logic
    this.isValidHeight =
      this.$slots.default[0].elm &&
      this.$slots.default[0].elm.getBoundingClientRect().height > this.validHeight;
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-max-height-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--accent-white) 100%);
  padding: 0;
  padding-top: 140px;
  z-index: 1;

  &-span {
    font-size: 1rem;
  }

  .mdi {
    font-size: 1.1rem;
    padding: 0 5px;
    margin-top: 3px;
  }

  &--opened {
    margin-bottom: -20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      var(--accent-white) 200%
    ) !important;

    .mdi {
      transform: scale(-1);
      margin-bottom: 2px;
    }
  }
}

.vms__max-height {
  height: 200px;
  max-height: 200px;
  overflow: hidden;
  position: relative;
}

@media screen and (min-width: 992px) {
  .vms__max-height {
    height: auto;
    max-height: none;
    overflow: auto;
  }

  .vms__ui-max-height-btn {
    display: none;
  }
}
</style>
