import Vue from 'vue';

export default function startPage({ to, next, store, nextMiddleware }) {
  Vue.nextTick(() => {
    const page = store.getters['params/getParams'].listParams.startPage;
    if (page && !to.path.includes(page) && to.name === 'default') {
      return next({
        name: page
      });
    }
    return nextMiddleware();
  });
}
