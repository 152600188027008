<template>
  <div class="vms__contacts-info">
    <DealerInfo :info="vehicleInfo && vehicleInfo.dealer" />
    <div class="vms__contacts-info-buttons">
      <UIButton
        class="probefahrt-quicklink"
        v-if="vehicleInfo && vehicleInfo.isProbefahrenAvailable"
        v-WaveAnimation
        background="red"
        color="var(--text-color--white)"
        shadow
        :href="`https://app.probefahrtenbutler.com/drive/edentity/EA30D84D/${vehicleInfo.importedId}`"
      >
        <UIIcon name="mdi-calendar" left />
        {{ $t('vehicle.contacts.button3') }}
      </UIButton>
      <UIButton
        class="vms__vehicles-detail_btn-contact"
        v-if="showroom && showroom.showContactFormInDetails"
        v-WaveAnimation
        shadow
        background="var(--color--dark)"
        color="var(--text-color--white)"
        @click="openModalContact"
        data-cy="btns__contact"
      >
        <UIIcon name="mdi-comment-multiple" size="16px" left />
        {{ $t('buttons.contact') }}
      </UIButton>
      <UIButton link :to="`/vehicles/${id}/geo`" class="vms__contacts-info-buttons_geo" data-cy="btns__plan-route">
        <UIIcon name="mdi-flag-outline" left />
        {{ $t('vehicle.contacts.link') }}
      </UIButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EventBus from '@/services/eventBusService';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import DealerInfo from '@/components/DealerInfo';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'ContactsInfo',
  mixins,
  components: { DealerInfo, UIButton, UIIcon },
  directives: {
    WaveAnimation
  },
  props: {
    vehicleInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: 0,
      whatsAppText: null
    };
  },
  created() {
    this.id = this.$router?.history.current.params.id || 0;
  },
  mounted() {
    const url = encodeURI(window.location.href);
    this.whatsAppText = this.$t('vehicle.whatsappText', { url: url.replace('#/', '%23/') });
  },
  computed: {
    ...mapState('showrooms', {
      showroom: 'showroom'
    })
  },
  methods: {
    openModalContact() {
      EventBus.$emit('open-modal-contact');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__contacts-info {
  padding: 30px;
  background-color: #fff;

  &-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;

    .vms__ui-button {
      margin: 5px 10px 5px 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &_geo {
      padding-left: 0;
    }
  }
}
</style>
