<template>
  <div>
    <div v-if="visible && !this.$browserDetect.isIE">
      <div
        v-if="error !== false"
        class="vms__content-wrapper"
        v-responsive="{ 'media-768': el => el.width < 768 }"
        :style="{
          width: MixinGetContainerWidth(listParams.width),
          height: MixinGetContainerHeight(listParams.height)
        }"
      >
        <div class="vms__content-wrapper_icon">
          <div
            v-if="error === 601 || error === 602 || error === 603 || error === 607 || error === 608"
            class="mdi mdi-wrench"
          ></div>
          <div v-if="error === 604" class="mdi mdi-settings mdi-spin"></div>
          <div v-if="error === 605" class="vms__content-wrapper_title"><div>404</div></div>
          <div v-if="error === 606" class="mdi mdi-car"></div>
        </div>
        <!-- Errors texts -->
        <div
          v-if="error === 601"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.showroomUid')"
        ></div>
        <div
          v-if="error === 602"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.instanceId')"
        ></div>
        <div
          v-if="error === 603"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.culture')"
        ></div>
        <div
          v-if="error === 604"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.technical.message')"
        ></div>
        <div
          v-if="error === 605"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.notFound.message')"
        ></div>
        <div
          v-if="error === 606"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.vehiclesEmpty')"
        ></div>
        <div
          v-if="error === 607"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.showroomUidInvalid')"
        ></div>
        <div
          v-if="error === 608"
          class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
          v-html="$t('errorsWrapper.instanceIdInvalid')"
        ></div>
        <!-- END Errors texts -->
        <UIButton @click="reloadPage" shadow v-if="error === 604" background="#2d9c90" color="#fff">
          <UIIcon name="mdi-reload" left />
          {{ $t('errorsWrapper.reloadPage') }}
        </UIButton>
        <UIButton
          @click="goToVehicles"
          shadow
          v-if="error === 605"
          background="#2d9c90"
          color="#fff"
        >
          <UIIcon name="mdi-car" left />
          {{ $t('errorsWrapper.notFound.btn') }}
        </UIButton>
      </div>
      <div v-show="error === false">
        <slot />
      </div>
    </div>

    <!-- If not support browser -->
    <div
      v-if="this.$browserDetect.isIE"
      class="vms__content-wrapper"
      v-responsive="{ 'media-768': el => el.width < 768 }"
      :style="{
        width: MixinGetContainerWidth(listParams.width),
        height: MixinGetContainerHeight(listParams.height)
      }"
    >
      <div class="vms__content-wrapper_icon">
        <div class="mdi mdi-emoticon-sad-outline"></div>
      </div>
      <div
        class="vms__content-wrapper_text vms__BarlowSemiCondensedRegular"
        v-html="$t('common.isNotSupportBrowser')"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'ContentWrapper',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: { UIButton, UIIcon },
  data() {
    return {
      serverError: null,
      visible: true
    };
  },
  beforeMount() {
    EventBus.$on('network-error', this.showError);
  },
  beforeDestroy() {
    EventBus.$off('network-error', this.showError);
  },
  watch: {

  },
  computed: {
    ...mapState('vehicles', {
      list: 'list'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('instances', {
      statusInstance: 'statusInstance'
    }),
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom',
      hasVehicles: 'hasVehicles'
    }),
    error() {
      if (Object.keys(this.listParams).some(p => p === 'showroomUid') === false) {
        return 601;
      }
      if (Object.keys(this.listParams).some(p => p === 'instanceId') === false) {
        return 602;
      }
      if (Object.keys(this.listParams).some(p => p === 'culture') === false) {
        return 603;
      }
      if (!this.statusInstance) {
        return 608;
      }
      if (!this.statusShowroom) {
        return 607;
      }
      if (this.serverError === 604) {
        return 604;
      }
      if (this.serverError === 605) {
        return 605;
      }
      if (this.hasVehicles === false) {
        return 606;
      }
      return false;
    }
  },
  methods: {
    reloadContent() {
      this.visible = false;
      this.$nextTick(() => {
        this.reloadPage();
        this.visible = true;
      });
    },
    reloadPage() {
      this.serverError = null;
      window.location.reload();
    },
    goToVehicles() {
      this.serverError = null;
      this.$router.push('/vehicles');
    },
    showError(errorCode) {
      this.serverError = errorCode;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  background-color: #fff;

  &_icon {
    width: 100%;
    height: 300px;
    position: relative;
    background-color: #2d9c90;

    .mdi {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      background-color: #fff;
      padding: 35px;
      font-size: 9rem;
      border-radius: 100%;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
      color: #062d29;
    }
  }

  &_title {
    width: 100%;
    height: 300px;
    position: relative;
    background-color: #2d9c90;

    > div {
      width: 215px;
      height: 215px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      background-color: #fff;
      padding: 8px;
      font-size: 6rem;
      border-radius: 100%;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
      color: #062d29;
    }
  }

  &_text {
    margin-top: 150px;
    line-height: 1.15;
    font-size: 2rem;
    color: #000;
    padding: 0 15px;
    text-align: center;
  }

  .vms__ui-button {
    margin-top: 20px;
  }
}

@mixin media-768() {
  &_icon {
    height: 200px;

    .mdi {
      font-size: 5rem;
    }
  }

  &_title {
    height: 200px;
    margin-bottom: 50px;
  }

  &_text {
    font-size: 1.5rem;
    margin-top: 100px;
  }
}

.media-768 .vms__content-wrapper {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__content-wrapper {
    @include media-768();
  }
}
</style>
