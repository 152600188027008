import {
  AUTH_ADD_URL_TO_REQUEST,
  AUTH_ADD_URL_TO_SUCCESS,
  AUTH_ADD_URL_TO_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT
} from '@/store/modules/auth/types';
import httpClient from '@/axios';
import configs from '@/configs';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      const data = new FormData();
      data.append('client_id', 'spa');
      data.append('grant_type', 'password');
      data.append('scope', 'VMS.ClientWebAPI offline_access VMS.InstanceWebAPI');
      data.append('username', user.username);
      data.append('password', user.password);
      httpClient
        .post(`${configs.API_URL}/connect/token`, data)
        .then(resp => {
          if (resp.data) {
            localStorageService.setToken({
              access_token: resp.data.access_token,
              refresh_token: resp.data.refresh_token
            });
            httpClient.defaults.headers.common.Authorization = resp.data.access_token;
            commit(AUTH_SUCCESS, resp.data.access_token);
            resolve(resp);
          }
          if (resp.response && resp.response.status === 400) {
            commit(AUTH_ERROR, resp);
          }
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorageService.clearToken();
          reject(err);
        });
    });
  },
  refreshToken() {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('client_id', 'spa');
      data.append('grant_type', 'refresh_token');
      data.append('scope', 'VMS.ClientWebAPI offline_access');
      data.append('refresh_token', localStorageService.getRefreshToken());
      httpClient
        .post(`${configs.API_URL}/connect/token`, data)
        .then(resp => {
          localStorageService.setToken({
            access_token: resp.data.access_token,
            refresh_token: resp.data.refresh_token
          });
          resolve(resp);
        })
        .catch(err => {
          localStorageService.clearToken();
          reject(err);
        });
    });
  },
  addUrlTo({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(AUTH_ADD_URL_TO_REQUEST);
      if (url) {
        commit(AUTH_ADD_URL_TO_SUCCESS, url);
        resolve(url);
      }
      commit(AUTH_ADD_URL_TO_ERROR);
      reject(new Error('Parameters not passed'));
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      commit(LOGOUT);
      localStorageService.clearToken();
      delete httpClient.defaults.headers.common.Authorization;
      resolve();
    });
  }
};
