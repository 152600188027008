/**
 * Helpers methods
 */
const mixin = {
  methods: {
    /**
     * Convert HTML Collection to JS Array
     */
    htmlCollectionToArray(collection) {
      return Array.prototype.slice.call(collection, 0);
    },

    /**
     * Compare elements for breakpoints sorting
     */
    compare(a, b) {
      if (a.breakpoint < b.breakpoint) {
        return this.initialSettings.mobileFirst ? -1 : 1;
      }
      if (a.breakpoint > b.breakpoint) {
        return this.initialSettings.mobileFirst ? 1 : -1;
      }
      return 0;
    },
    /**
     * Set window & container width
     */
    getWidth() {
      // const ratio = window.devicePixelRatio || 1;
      this.widthWindow =
        this.$root.$el.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth; // * ratio;
      this.widthContainer = this.$refs.list.clientWidth;
    }
  }
};

export default mixin;
