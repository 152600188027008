<template>
  <div
    id="app vms__showroom"
    class="vms__app"
    :style="{
      width: MixinGetContainerWidth($props.width),
      height: MixinGetContainerHeight($props.height)
    }"
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768,
      'media-576': el => el.width < 576
    }"
  >
    <transition name="main-fade" mode="out-in">
      <ContentWrapper>
        <router-view />
      </ContentWrapper>
    </transition>
    <Modal ref="ModalEmail">
      <ModalEmail @ModalClose="closeModalEmail" />
    </Modal>
    <div class="vms__app_alerts" v-append-to-body>
      <UIAlerts v-for="(alert, i) in alertsList" :key="i" :payload="alert" />
      <UIAlertsNetwork />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  loadProbefahrtenbutlerScript,
  loadReCaptcha,
  loadAutoUncleRating
} from '@/helpers/connectScripts';
import initProps from '@/initProps';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';
import mixins from '@/mixins';
import OptionsService from '@/services/optionsService';
import InitParamsService from '@/services/initParamsService';
import UIAlerts from '@/components/ui/UIAlerts';
import UIAlertsNetwork from '@/components/ui/UIAlertsNetwork';
import ContentWrapper from '@/containers/ContentWrapper';
import Modal from '@/containers/Modal';
import ModalEmail from '@/components/Modals/ModalEmail';
import AppendToBody from '@/directives/AppendToBody';

export default {
  name: 'App',
  mixins,
  directives: {
    responsive: Responsive,
    appendToBody: AppendToBody
  },
  components: {
    ContentWrapper,
    UIAlerts,
    UIAlertsNetwork,
    ModalEmail,
    Modal
  },
  props: initProps,
  beforeMount() {
    EventBus.$on('open-modal-email', this.showModalEmail);
  },
  beforeDestroy() {
    EventBus.$off('open-modal-email', this.showModalEmail);
  },
  created() {
    loadProbefahrtenbutlerScript();
    loadReCaptcha();
    this.loadData();
  },
  computed: {
    ...mapState('alerts', {
      alertsList: 'alertsList'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  methods: {
    ...mapActions('params', ['setParams']),
    ...mapActions('cultures', ['getCultureTranslations']),
    ...mapActions('instances', ['getInstanceById']),
    ...mapActions('showrooms', ['getShowroomById']),
    ...mapActions('favorites', ['updateFavorites']),
    ...mapActions('compares', ['updateCompares']),
    ...mapActions('options', ['setOptions']),
    loadData() {
      // ----- Order is important ----- //
      this.setInitParams().then(() => {
        this.setLangFromParam();
        this.getCultureTranslations(this.$props.culture).then(() => {
          this.getInstanceById(this.$props.instanceId).then(() => {
            this.getShowroomById(this.listParams).then(resp => {
              loadAutoUncleRating(resp.data);
              this.updateDefaultParams(resp.data);
              if (resp.data && resp.data.instanceName) {
                document.title = resp.data.instanceName;
              }
              this.setCustomStyles(resp.data && resp.data.customCss);
              this.setCustomScripts(resp.data && resp.data.customScripts);
              this.updateFavorites();
              this.updateCompares();
            });
          });
        });
      });
    },
    setInitParams() {
      const initParamsService = InitParamsService.installService();
      const result = initParamsService.createInitParams(this.MixinRemoveFalsy(this.$props));
      return this.setParams(result);
    },
    setCustomStyles(styles) {
      if (styles) {
        const style = document.createElement('style');
        style.type = 'text/css';
        const textNode = document.createTextNode(
          styles.replace(/;(?=[^<>]+:|\s*")*/g, ' !important;')
        );
        style.appendChild(textNode);
        document.body.append(style);
      }
    },
    setCustomScripts(scripts) {
      if (scripts) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        const textNode = document.createTextNode(scripts);
        script.appendChild(textNode);
        document.body.append(script);
      }
    },
    updateDefaultParams(showroomParams) {
      const data = {
        ...showroomParams,
        ...this.listParams
      };
      const optionsService = OptionsService.installService();
      this.setOptions(optionsService.getOptions(this.MixinRemoveFalsy(data)));
    },
    setLangFromParam() {
      sessionStorage.setItem('vms__culture', this.$props.culture);
      this.$i18n.locale = this.$props.culture.replace('-', '');
    },
    showModalEmail() {
      this.$refs.ModalEmail.modalShow();
    },
    closeModalEmail() {
      this.$refs.ModalEmail.modalClose();
    }
  }
};
</script>

<style lang="scss" scoped>
#PageScrollbar {
  z-index: 1;
}

.vms__app {
  min-height: 100vh;
  position: relative;
}

.vms__app_alerts {
  z-index: 999;
}

.vms__app-vehicles--empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  background-color: #fff;
  z-index: 999;
}

@mixin media-768() {
  .vms__app_alerts {
    .vms__ui-alert-network {
      border: 0;
      border-top: 3px solid #df4949;
    }
  }
}

@mixin media-576() {
  .vms__app-vehicles--empty {
    padding: 0 15px;
    text-align: center;
  }
}

.vms__app.media-768 {
  @include media-768();
}

.vms__app.media-576 {
  @include media-576();
}

@media screen and (max-width: 768px) {
  @include media-768();
}

@media screen and (max-width: 576px) {
  @include media-576();
}
</style>
