<template>
  <div class="vms__vehicles-detail_carousel">
    <agile
      v-if="images && images.length"
      :dots="images.length > 1"
      :unagile="images.length <= 1"
      ref="cardCarousel"
    >
      <div
        v-for="(img, i) of images"
        :key="i"
        class="vms__vehicles-detail_carousel-item"
        @click="showModalImgPreview(i)"
      >
        <progressive-img :src="img.small" />
      </div>
      <template v-if="images.length > 1" slot="prevButton"
        ><div class="mdi mdi-arrow-left" data-cy="btns__left-arrow"
      /></template>
      <template v-if="images.length > 1" slot="nextButton"
        ><div class="mdi mdi-arrow-right" data-cy="btns__right-arrow"
      /></template>
    </agile>
    <div v-if="images && !images.length" class="vms__vehicles-detail_carousel_img-fallback">
      <div class="mdi mdi-image" />
    </div>
  </div>
</template>

<script>
import { VueAgile } from '@/plugins/vue-agile';

export default {
  name: 'Carousel',
  components: {
    agile: VueAgile
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    document.addEventListener('keydown', this.listenArrowsLeftRight);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.listenArrowsLeftRight);
  },
  methods: {
    // NOTE: Temporarily disabled this feature, need to change styles
    // event name: OpenImgPreview
    showModalImgPreview(slideIndex) {
      this.$emit('...', slideIndex);
    },
    prevSlideCard() {
      this.$refs.cardCarousel.goToPrev();
    },
    nextSlideCard() {
      this.$refs.cardCarousel.goToNext();
    },
    listenArrowsLeftRight(e) {
      if (e.keyCode === 37) {
        this.prevSlideCard();
      }
      if (e.keyCode === 39) {
        this.nextSlideCard();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__carousel-first-child {
  display: none;
}

.vms__vehicles-detail {
  &_carousel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;

    &-item {
      width: 100%;
      height: auto;

      /deep/ .progressive-image,
      .progressive-image-main {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &_img-fallback {
      width: 100%;
      height: 100%;
      min-height: 300px;
      position: relative;

      .mdi {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #c5c4c4;
        opacity: 0.3;
        font-size: 10rem;
      }
    }

    /deep/ .agile {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      &__actions {
        justify-content: center;
        margin: auto 0;
        padding: 2px 0;
      }

      &__nav-button {
        background-color: transparent;
        cursor: pointer;

        .mdi {
          font-size: 1.375rem;
          color: #fff;
        }
      }

      &__dots {
        margin: 0 40px;
      }

      &__dot {
        padding: 0;

        &-btn {
          width: 6px;
          height: 6px;
          display: block;
          background-color: #fff;
          opacity: 0.6;
          margin: 0 3px;
          padding: 0;
          border-radius: 100%;
        }

        &--current {
          .agile__dot-btn {
            opacity: 0.9;
          }
        }
      }
    }
  }
}

@mixin media-992() {
  &_carousel-item {
    height: auto;
  }

  &_carousel {
    width: 100%;

    /deep/ .agile__actions {
      margin-top: 10px;
    }
  }
}
</style>
