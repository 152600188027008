import _ from 'lodash';

const InitParamsService = (() => {
  let _service;

  function _installService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  const arrayParamsKeys = {
    categories: 'categories',
    categoryGroups: 'categoryGroups',
    makes: 'makeCodes',
    fuels: 'fuelCodes',
    colors: 'colorCodes',
    chassis: 'chassisTypeCodes',
    transmissions: 'gearboxCodes',
    dealers: 'dealerIds',
    bodyTypes: 'bodyTypeFilterCodes',
    vehicleTypes: 'vehicleTypeCodes'
  };

  const stringParamsKeys = {
    priceFrom: 'priceFrom',
    priceTo: 'priceTo',
    mileageFrom: 'mileageFrom',
    mileageTo: 'mileageTo'
  };

  const vehicleTypeV1Map = {
    1: 'Car',
    2: 'Motorcycle',
    13: 'Transporter',
    Car: 'Car',
    Motorcycle: 'Motorcycle',
    Transporter: 'Transporter'
  };

  function _formatInitParams(data, keys = []) {
    Object.keys(data).forEach(key => {
      if (keys.some(k => k === key)) {
        if (String(data[key]).includes(',')) {
          data[key] = data[key].split(',').map(v => v.replace(/\s/g, ''));
        } else {
          data[key] = [data[key]];
        }
        if (key === 'vehicleTypes') {
          data[key] = data[key].filter(x => x in vehicleTypeV1Map).map(x => vehicleTypeV1Map[x]);
        }
      }
    });
    return data;
  }

  function _renameParams(data, keys) {
    const params = {
      ...data
    };

    _.each(params, (value, key) => {
      key = keys[key] || key;
      params[key] = value;
    });
    return params;
  }

  function _createStructure(data, keys) {
    const obj = {};
    const params = {
      ...data
    };
    Object.keys(params).forEach(key => {
      if (keys.some(k => k === key)) {
        obj[key] = params[key];
      }
    });
    Object.keys(params).forEach(key => {
      if (Object.keys(stringParamsKeys).some(k => k === key)) {
        obj[key] = params[key];
      }
    });
    params.pluginConfig = {
      ...obj
    };
    _.each(params, (value, key) => {
      if (Object.values(arrayParamsKeys).includes(key)) {
        delete params[key];
      }
    });
    return params;
  }

  function _removeDuplicate(data) {
    const params = { ...data };

    _.each(params, (value, key) => {
      if (Object.keys(arrayParamsKeys).includes(key)) {
        delete params[key];
      }
    });
    return params;
  }

  function _createInitParams(initParams) {
    const formated = _formatInitParams(initParams, Object.keys(arrayParamsKeys));
    const renamed = _renameParams(formated, arrayParamsKeys);
    const structured = _createStructure(renamed, Object.values(arrayParamsKeys));
    return _removeDuplicate(structured);
  }

  return {
    installService: _installService,
    createInitParams: _createInitParams
  };
})();

export default InitParamsService;
