var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
    'media-1200': function (el) { return el.width < 1200; },
    'media-992': function (el) { return el.width < 992; },
    'media-768': function (el) { return el.width < 768; },
    'media-576': function (el) { return el.width < 576; }
  }),expression:"{\n    'media-1200': el => el.width < 1200,\n    'media-992': el => el.width < 992,\n    'media-768': el => el.width < 768,\n    'media-576': el => el.width < 576\n  }"}],staticClass:"vms__app",style:({
    width: _vm.MixinGetContainerWidth(_vm.$props.width),
    height: _vm.MixinGetContainerHeight(_vm.$props.height)
  }),attrs:{"id":"app vms__showroom"}},[_c('transition',{attrs:{"name":"main-fade","mode":"out-in"}},[_c('ContentWrapper',[_c('router-view')],1)],1),_c('Modal',{ref:"ModalEmail"},[_c('ModalEmail',{on:{"ModalClose":_vm.closeModalEmail}})],1),_c('div',{directives:[{name:"append-to-body",rawName:"v-append-to-body"}],staticClass:"vms__app_alerts"},[_vm._l((_vm.alertsList),function(alert,i){return _c('UIAlerts',{key:i,attrs:{"payload":alert}})}),_c('UIAlertsNetwork')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }