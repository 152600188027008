<template>
  <div
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768,
      'media-576': el => el.width < 576
    }"
  >
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__compare">
        <NavigatePanel
          :breadcrumbs="crumbs"
          :isKfzWhite="true"
          :backspaceLabel="$t('vehicles.navigatePanel.buttonBackspace')"
        />
        <div v-if="ids.length" class="vms__container" v-touch:swipe="swipeHandler">
          <div class="vms__compare_list-head">
            <div class="vms__compare_list-head_title vms__BarlowSemiCondensedRegular">
              {{ $t('compare.list.title') }}
            </div>
            <UIPaginationBtn
              :maxPage="ids.length - maxPerPage + 1"
              :currentPage="this.currentPage + 1"
              @UIPaginationBtnPrev="prevPage"
              @UIPaginationBtnNext="nextPage"
            />
          </div>
          <div v-if="compareVehicles" class="vms__compare_list">
            <div class="vms__compare_list-vehicles">
              <VehiclesCard
                v-for="(item, i) of compareVehicles"
                :key="i"
                :id="item.id"
                :link="`/vehicles/${item.id}`"
                :imgUrl="item && item.thumbUrl"
                :title="item.title"
                :date="item.firstRegistrationDate"
                :km="item.mileage"
                :price="item.price"
                :discount="item"
                :payload="item"
                :cy-name="`results__vehicle-${i+1}`"
              />
            </div>
          </div>
          <div
            v-if="compareVehicles && compareVehicles.length"
            class="vms__compare_characteristics"
            ref="markmale"
          >
            <div class="vms__compare_characteristics_title vms__BarlowSemiCondensedRegular">
              {{ $t('compare.characteristics.title') }}
            </div>
            <MaxHeightEquipments>
              <div class="vms__compare_table">
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item1.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div class="vms__compare_table_row-item_span" v-if="item.primaryEnginePowerKw" :data-cy="`features__vehicle-${i+1}-performance`">
                      {{ item.primaryEnginePowerKw }} {{ $t('compare.table.item1.unit') }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item2.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div class="vms__compare_table_row-item_span" v-if="item.fuelName" :data-cy="`features__vehicle-${i+1}-fuel`">
                      {{ item.fuelName }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item3.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div class="vms__compare_table_row-item_span" v-if="item.cO2Emission" :data-cy="`features__vehicle-${i+1}-co2-emission`">
                      {{ item.cO2Emission }} {{ $t('compare.table.item3.unit') }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item4.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div
                      class="vms__compare_table_row-item_span"
                      v-if="item.fuelConsumptionCombined"
                      :data-cy="`features__vehicle-${i+1}-consumption`"
                    >
                      {{ item.fuelConsumptionCombined }} {{ $t('compare.table.item4.unit') }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item5.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div class="vms__compare_table_row-item_span" v-if="item.gearboxName" :data-cy="`features__vehicle-${i+1}-gearbox`">
                      {{ item.gearboxName }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item6.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div v-if="item.primaryEngineCapacityCcm" class="vms__compare_table_row-item_span" :data-cy="`features__vehicle-${i+1}-cubic-capacity`">
                      {{ item.primaryEngineCapacityCcm }} {{ $t('compare.table.item6.unit') }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item7.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div v-if="item.colorName" class="vms__compare_table_row-item_span" :data-cy="`features__vehicle-${i+1}-color`">
                      {{ item.colorName }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
                <div class="vms__compare_table_row">
                  <div class="vms__compare_table_row-title vms__OpenSansRegular">
                    {{ $t('compare.table.item8.title') }}
                  </div>
                  <div
                    v-for="(item, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_row-item vms__OpenSansRegular"
                  >
                    <div v-if="item.upholsteryName" class="vms__compare_table_row-item_span" :data-cy="`features__vehicle-${i+1}-upholstery`">
                      {{ item.upholsteryName }}
                    </div>
                    <div v-else class="mdi mdi-minus" />
                  </div>
                </div>
              </div>
            </MaxHeightEquipments>
          </div>
          <div v-if="compareVehicles && compareVehicles.length" class="vms__compare_extras">
            <div class="vms__compare_extras_title vms__BarlowSemiCondensedRegular">
              {{ $t('compare.extras.title') }}
            </div>
            <MaxHeightEquipments>
              <div class="vms__compare_table">
                <div class="vms__compare_table_row">
                  <div
                    v-for="(list, i) of compareVehicles"
                    :key="i"
                    class="vms__compare_table_tags"
                    :data-cy="`features__vehicle-${i+1}-equipments-area`"
                  >
                    <UITag v-for="(equipment, j) of list.equipments" :key="j" :lable="equipment" />
                  </div>
                </div>
              </div>
            </MaxHeightEquipments>
          </div>
        </div>
        <div v-else class="vms__container">
          <div class="vms__compare--empty">{{ $t('compare.listEmpty') }}</div>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import UIPaginationBtn from '@/components/ui/UIPaginationBtn';
import VehiclesCard from '@/components/VehiclesCard';
import NavigatePanel from '@/components/NavigatePanel';
import MaxHeightEquipments from '@/containers/MaxHeightEquipments';
import UIBurger from '@/components/ui/UIBurger';
import UITag from '@/components/ui/UITag';
import Responsive from '@/directives/Responsive';
import PageContainer from '@/containers/PageContainer';

export default {
  name: 'Compare',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    VehiclesCard,
    NavigatePanel,
    UIPaginationBtn,
    MaxHeightEquipments,
    UIBurger,
    UITag,
    PageContainer
  },
  data() {
    return {
      ids: [],
      filterParams: null,
      currentPage: 0,
      maxPerPage: 3,
      step: 1,
      dataIsReady: false
    };
  },
  computed: {
    resultPage() {
      return this.currentPage + this.maxPerPage;
    },
    ...mapState('vehicles', {
      statusVehiclesByIds: 'statusVehiclesByIds',
      vehiclesByIds: 'vehiclesByIds'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    compareVehicles() {
      if (this.vehiclesByIds) {
        return (
          this.vehiclesByIds.items &&
          this.vehiclesByIds.items.slice(this.currentPage, this.resultPage)
        );
      }
      return [];
    },
    crumbs() {
      return [
        { link: '/vehicles', title: this.$t('breadcrumbs.vehicles') },
        { link: '/vehicles/compare', title: this.$t('breadcrumbs.compare') }
      ];
    }
  },
  beforeMount() {
    EventBus.$on('lang-changed', this.getVehiclesByIds);
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    EventBus.$off('lang-changed', this.getVehiclesByIds);
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    if (this.compareList && this.compareList.length) {
      this.getVehiclesByIds();
    } else {
      this.dataIsReady = true;
    }
    document.title = this.showroom.instanceName || 'Vms';
    if (window.auWidgetEmbed) {
      window.auWidgetEmbed.init();
    }
  },
  watch: {
    compareList() {
      this.getVehiclesByIds();
    },
    ids(newValue) {
      if (this.currentPage > newValue.length - this.maxPerPage) {
        /**
         * The this.currentPage variable must not have negative values. The minimum number must be 0.
         */
        this.currentPage = Math.max(0, newValue.length - this.maxPerPage);
      }
    },
    statusVehiclesByIds(status) {
      if (status) {
        setTimeout(() => {
          this.dataIsReady = true;
          if (window.auWidgetEmbed) {
            window.auWidgetEmbed.init();
          }
        }, 500);
      }
    }
  },
  methods: {
    getVehiclesByIds() {
      this.ids = this.compareList ? this.compareList : [];
      this.filterParams = {
        ...this.listParams,
        vehicleIds: this.ids
      };
      this.$store.dispatch('vehicles/getVehiclesByIds', this.filterParams);
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage -= this.step;
      }
    },
    nextPage() {
      if (this.currentPage + 1 < this.ids.length - this.maxPerPage + 1) {
        this.currentPage += this.step;
      }
    },
    swipeHandler(direction) {
      if (direction === 'left') {
        this.nextPage();
      } else {
        this.prevPage();
      }
    },
    onResize() {
      const width =
        this.$root.$el.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width > 1200) {
        this.maxPerPage = 3;
        this.currentPage = 0;
      }
      if (width < 1200 && width > 376) {
        this.maxPerPage = 2;
        this.currentPage = 0;
      }
      if (width < 576) {
        this.maxPerPage = 1;
        this.currentPage = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__compare {
  background-color: #fff;

  &_table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    &_tags {
      width: 31%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .vms__ui-tag {
        margin: 0 2px 10px;
      }
    }

    &_row {
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &-title {
        position: absolute;
        left: 0;
        top: 6px;
        font-size: 0.688rem;
        font-weight: 600;
        color: #000;
        line-height: 1.36;
        opacity: 0.6;
      }

      &-item {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        text-align: center;
        border-top: 1px solid #4f4f4f;
        line-height: 1.33;
        letter-spacing: -0.04px;
        font-size: 1.125rem;
        color: #000;

        &_span {
          font-size: inherit;
          margin-left: 5px;
        }
      }
    }
  }

  &_list {
    display: flex;
    flex-direction: column;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      background-color: #fff;
      z-index: 10;

      &_title {
        line-height: 1.15;
        letter-spacing: -0.04px;
        font-size: 1.625rem;
        color: #000;
      }
    }

    &-vehicles {
      display: flex;
      justify-content: space-between;
      margin-top: 42px;
    }
  }

  &_characteristics {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 70px;

    &_title {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }
  }

  &_extras {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    &_title {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    .mdi {
      font-size: 1.875rem;
      color: var(--text-color--dark);
    }

    .mdi-minus {
      color: #c5c4c4;
    }
  }

  &--empty {
    text-align: center;
    margin: 50px 0;
    font-size: 1.625rem;
    line-height: 1.15;
    letter-spacing: -0.04px;
    color: #000;
  }
}

@mixin media-1200() {
  &_list-vehicles {
    margin-bottom: 20px;

    .vms__vehicles_item {
      width: 457px;
      margin-bottom: 15px;
    }
  }

  &_table_row-item,
  &_table_tags {
    width: 49%;
  }
}

@mixin media-992() {
  &_list-vehicles {
    .vms__vehicles_item {
      width: 337px;
    }
  }
}

@mixin media-768() {
  &_characteristics {
    margin-top: 0px;
  }

  &_list-head {
    margin-top: 80px;
  }

  &_extras {
    margin-bottom: 30px;
  }

  &_list-vehicles {
    /deep/ .vms__vehicles_item {
      width: 49%;
      min-width: 49%;

      &-info {
        min-height: 100px;
        padding: 6px 9px;

        &_title {
          margin-top: auto;
          margin-bottom: auto;

          a {
            min-height: auto;
            font-size: 1.1875rem;
            line-height: 1.05;
            letter-spacing: -0.03px;
          }
        }

        &-params {
          flex-direction: column;
          align-items: flex-start;

          &_price {
            font-size: 1.1875rem;
            margin-top: -25px;
          }

          &_km {
            margin: 0;
            margin-top: 4px;
            font-size: 0.625rem;
          }

          &_date {
            font-size: 0.625rem;
          }
        }
      }
    }
  }
}

@mixin media-576() {
  &_list-vehicles {
    /deep/ .vms__vehicles_item {
      width: 100%;
    }
  }

  &_table_tags {
    width: 100%;
  }

  &_characteristics,
  &_extras {
    .vms__compare_table_row-title vms__OpenSansRegular {
      font-size: 0.625rem;
    }

    .vms__compare_table_row-item {
      width: 100%;
      font-size: 1rem;

      .mdi {
        font-size: 1.385rem;
      }
    }
  }

  &_extras {
    margin-top: 35px;
  }

  &_table_row-item {
    width: 48%;
  }
}

.media-1200 .vms__compare {
  @include media-1200();
}

.media-992 .vms__compare {
  @include media-992();
}

.media-768 .vms__compare {
  @include media-768();
}

.media-576 .vms__compare {
  @include media-576();
}

@media screen and (max-width: 1200px) {
  .vms__compare {
    @include media-1200();
  }
}

@media screen and (max-width: 992px) {
  .vms__compare {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__compare {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__compare {
    @include media-576();
  }
}
</style>
