import {
  FAVORITES_ADD_FAVORITE_REQUEST,
  FAVORITES_ADD_FAVORITE_SUCCESS,
  FAVORITES_ADD_FAVORITE_ERROR,
  FAVORITES_UPDATE_FAVORITE_REQUEST,
  FAVORITES_UPDATE_FAVORITE_SUCCESS,
  FAVORITES_UPDATE_FAVORITE_ERROR
} from '@/store/modules/favorites/types';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  toggleFavorite({ commit }, vehicle) {
    commit(FAVORITES_ADD_FAVORITE_REQUEST);
    return new Promise((resolve, reject) => {
      if (vehicle) {
        const favorites = localStorageService.updateListValues('vms__favorite-list', vehicle);
        commit(FAVORITES_ADD_FAVORITE_SUCCESS, favorites);
        resolve(favorites);
      } else {
        commit(FAVORITES_ADD_FAVORITE_ERROR);
        reject(new Error('Parameters not passed'));
      }
    });
  },
  updateFavorites({ commit }) {
    commit(FAVORITES_UPDATE_FAVORITE_REQUEST);
    const favorites = localStorageService.getValueByName('vms__favorite-list');
    try {
      commit(FAVORITES_UPDATE_FAVORITE_SUCCESS, JSON.parse(favorites));
    } catch (error) {
      commit(FAVORITES_UPDATE_FAVORITE_ERROR, error);
    }
  }
};
