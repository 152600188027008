<template>
  <div
    class="vms__filters_price-mileage"
    v-responsive="{
      'media-768': el => el.width < 768
    }"
  >
    <div class="vms__container">
      <UISliderRange
        class="vms__filters_price"
        ref="filtersPriceSlider"
        v-if="MixinCheckValidValues([filters.priceFrom, filters.priceTo])"
        :min="priceMin"
        :max="priceMax"
        :start="[listParams.priceFrom, listParams.priceTo]"
        :interval="listParams.priceInterval ? +listParams.priceInterval : maxPriceInterval"
        :title="$t('vehicles.filters.slider1')"
        startText="€ "
        valueSeparator=",– - "
        endText=",–"
        fromKey="priceFrom"
        toKey="priceTo"
        @onChange="setParamForSlider"
        data-cy="filters__slider-price"
      />
      <UISlider
        class="vms__filters_mileage"
        ref="filtersMileageSlider"
        v-if="MixinCheckValidValues([filters.mileageTo])"
        :min="mileageMin"
        :max="mileageMax"
        :disabled="mileageMin === mileageMax"
        :interval="listParams.mileageInterval ? +listParams.mileageInterval : maxMileageInterval"
        :title="$t('vehicles.filters.slider2')"
        endText=" km"
        fromKey="mileageFrom"
        toKey="mileageTo"
        @onChange="setParamForSlider"
        data-cy="filters__slider-mileage"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import UISliderRange from '@/components/ui/UISliderRange';
import UISlider from '@/components/ui/UISlider';

export default {
  name: 'PriceMileage',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    UISliderRange,
    UISlider
  },
  props: {
    scrollbar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      initParams: _.omitBy(this.$root.$props, _.isNil)
    };
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      filters: 'filters'
    }),
    ...mapState('options', {
      maxMileageInterval: 'maxMileageInterval',
      maxPriceInterval: 'maxPriceInterval'
    }),
    priceMin() {
      if (this.initParams.priceFrom && this.initParams.priceFrom === this.listParams.priceFrom) {
        return +this.initParams.priceFrom;
      }
      if (this.filters.priceFrom && this.filters.priceFrom !== this.filters.priceTo) {
        return +this.filters.priceFrom;
      }
      return 0;
    },
    priceMax() {
      if (this.initParams.priceTo && this.initParams.priceTo === this.listParams.priceTo) {
        return +this.initParams.priceTo;
      }
      if (this.filters.priceTo && this.filters.priceTo !== this.filters.priceFrom) {
        return +this.filters.priceTo;
      }
      return 0;
    },
    mileageMin() {
      if (this.initParams.mileageFrom) {
        return +this.initParams.mileageFrom;
      }
      return 0;
    },
    mileageMax() {
      if (this.initParams.mileageTo && this.initParams.mileageTo === this.listParams.mileageTo) {
        return +this.initParams.mileageTo;
      }
      if (this.filters.mileageTo && this.filters.mileageTo !== this.filters.mileageFrom) {
        return +this.filters.mileageTo;
      }
      return 0;
    }
  },
  methods: {
    setParamForSlider(param) {
      this.$emit('setParamForSlider', param);
    },
    reset() {
      this.$refs.filtersPriceSlider.reset();
      this.$refs.filtersMileageSlider.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters_price-mileage {
  width: 100%;
  background-color: #e9e9e9;
  padding: 35px 0;

  .vms__container {
    display: flex;
    justify-content: space-between;
  }

  .vms__ui-slider {
    width: calc(50% - 42px);

    /deep/ .noUi-connects {
      background-color: rgba(45, 156, 144, 0.3);
    }

    /deep/ .noUi-connect {
      background-color: #2d9c90;
    }
  }

  &-ui-slider-button {
    margin-bottom: 23px;
    padding-bottom: 23px;
  }
}

@mixin media-768() {
  &_price-mileage {
    padding: 15px 0;

    .vms__container {
      display: flex;
      flex-direction: column;

      .vms__ui-slider {
        width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    .vms__filters_price-mileage_price-mob {
      margin-bottom: 23px;
      padding-bottom: 23px;
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}
</style>
