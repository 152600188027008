<template>
  <div>
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__vehicles-detail-geo">
        <NavigatePanel
          :breadcrumbs="crumbs"
          :isKfzWhite="true"
          :backspaceLabel="$t('vehicle.navigatePanel.buttonBackspace')"
        />
        <div class="vms__vehicles-detail-geo_search">
          <div class="vms__container">
            <div class="vms__vehicles-detail-geo_search-head">
              <div
                class="sec-title-h1 vms__vehicles-detail-geo_search-head_title vms__OpenSansRegular"
                data-cy="text__dealer-name"
              >{{ $t('geo.title') }} {{ item && item.dealer && item.dealer.legalCompanyName }}</div>
              <div
                class="vms__vehicles-detail-geo_search-head_desc vms__OpenSansRegular"
              >{{ $t('geo.searchInfo') }}</div>
              <UIButton shadow background="#fff" @click="printPage" data-cy="btns__print">
                <UIIcon left name="mdi-printer" />
                {{ $t('geo.searchPrint') }}
              </UIButton>
            </div>
            <UIInputSearch data-cy="input__search" v-model="searchValue" :placeholder="$t('geo.searchPlaceholder')" />
          </div>
        </div>
        <div class="vms__container">
          <div class="vms__vehicles-detail-geo_map-wrapper">
            <GoogleMap
              id="vehicle-geo-map"
              v-if="dataIsReady"
              searchBoxInputId="ui-input-search"
              :lat="(item && item.dealer && item.dealer.latitude) || 0"
              :lng="(item && item.dealer && item.dealer.longitude) || 0"
              :routeToDealer="true"
            />
            <div
              class="vms__vehicles-detail-geo_map-wrapper_desc vms__OpenSansRegular"
            >Routenberechnung nach {{ item && item.dealer && item.dealer.city }}</div>
          </div>
        </div>
        <div class="vms__vehicles-detail-geo_contact">
          <div class="vms__container">
            <DealerInfo :info="item && item.dealer" />
            <div
              class="vms__vehicles-detail-geo_contact_important-info"
            >{{ $t('geo.importantInfo') }}</div>
          </div>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import NavigatePanel from '@/components/NavigatePanel';
import DealerInfo from '@/components/DealerInfo';
import GoogleMap from '@/components/GoogleMap';
import UIInputSearch from '@/components/ui/UIInputSearch';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import UIBurger from '@/components/ui/UIBurger';
import PageContainer from '@/containers/PageContainer';

export default {
  name: 'VehiclesDetailGeo',
  mixins,
  components: {
    NavigatePanel,
    DealerInfo,
    GoogleMap,
    UIInputSearch,
    UIButton,
    UIIcon,
    UIBurger,
    PageContainer
  },
  directives: { WaveAnimation },
  data() {
    return {
      // showroomUid: 0,
      // id: 0,
      // culture: '',
      postData: {},
      dataIsReady: false,
      searchValue: null
    };
  },
  created() {
    this.updatePostData();
    // this.id = this.$router.history.current.params.id;
    // this.showroomUid = this.listParams.showroomUid;
    // this.culture = this.listParams.culture;
    if (this.showroomLoaded === true) {
      this.getVehicle(this.postData);
    }
  },
  computed: {
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom',
      showroomLoaded: 'showroomLoaded'
    }),
    ...mapState('vehicles', {
      statusVehicleLoaded: 'statusVehicleLoaded',
      item: 'item'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('options', {
      statusOptions: 'statusOptions',
      googleMapsApiKey: 'googleMapsApiKey'
    }),
    crumbs() {
      return [
        { link: '/vehicles', title: this.$t('breadcrumbs.vehicles') },
        {
          link: `/vehicles/${this.$router.history.current.params.id}`,
          title: this.$t('breadcrumbs.view')
        },
        {
          link: `/vehicles/${this.$router.history.current.params.id}/geo`,
          title: this.$t('breadcrumbs.geo')
        }
      ];
    }
  },
  watch: {
    statusVehicleLoaded(status) {
      if (status === true) {
        this.dataIsReady = true;
        if (this.item && this.item.title) {
          document.title = this.item.title;
        }
      }
    },
    showroomLoaded(status) {
      if (status === true) {
        this.updatePostData();
        this.getVehicle(this.postData);
      }
    }
  },
  methods: {
    getVehicle() {
      this.$store.dispatch('vehicles/getVehicle', this.postData);
    },
    updatePostData() {
      this.postData = {
        showroomUid: this.listParams.showroomUid,
        culture: this.listParams.culture,
        id: this.$router.history.current.params.id
      };
    },
    printPage() {
      window.print();
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-detail-geo {
  // height: auto;
  // overflow: hidden;
  background-color: #fff;

  &_search {
    margin-top: 20px;
    width: 100%;
    background-color: #2d9c90;
    padding: 30px 0;

    /deep/ .vms__ui-input-search {
      width: 100%;
    }

    &-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &_title {
        width: 100%;
        color: #fff;
        font-size: 1.645rem;
        line-height: 1.88;
        margin-bottom: 10px;
      }

      &_desc {
        color: #fff;
        font-size: 1rem;
        line-height: 1.88;
      }
    }
  }

  &_map-wrapper {
    width: 100%;
    height: 350px;
    margin-top: 20px;
    margin-bottom: 70px;

    &_desc {
      padding: 6px 10px;
      color: #fff;
      font-size: 1rem;
      line-height: 1.88;
      background-color: #2d9c90;
    }
  }

  &_map {
    width: 100%;
    height: 350px;
  }

  &_contact {
    padding: 30px 0;
    background-color: #f2f2f2;

    &_important-info {
      margin-top: 8px;
    }
  }
}

@media print {
  .vms__navigate-panel-wrap,
  .vms__vehicles-detail-geo_search {
    display: none;
  }
}
</style>
