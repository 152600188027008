<template>
  <div
    class="vms__vehicles-list-wrapper"
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768
    }"
  >
    <div v-if="list.length" class="vms__vehicles-list">
      <VehiclesCard
        v-for="(item, i) of list"
        :key="i"
        :id="item.id"
        :link="`/vehicles/${item.id}`"
        :imgUrl="item && item.thumbUrl"
        :title="item.title"
        :date="item.firstRegistrationDate"
        :km="item.mileage"
        :upholstery="item.upholsteryName"
        :dealer="item.dealer"
        :price="item.price"
        :discount="item"
        :typeGrid="listOptions.ga"
        :payload="item"
        :cy-name="`results__vehicle-${i+1}`"
      />
      <div class="vms__vehicles-list_spare"></div>
    </div>
    <div v-if="paginationBtnIsActive" class="vms__container vms__vehicles-list_wrap-refresh">
      <UIButton
        shadow
        @click="loadMoreVehicles"
        class="vms__vehicles-list_refresh"
        :class="{ 'vms__vehicles-list_refresh--list-view': !listOptions.ga }"
        background="#062d29"
        color="#fff"
        data-cy="btns__show-more"
      >
        <UIIcon v-if="statusFilteredVehiclesLoaded" name="mdi-reload" left />
        <UIIcon v-else name="mdi-loading mdi-spin mdi-spin-05" left />
        {{ $t('vehicles.btnLoadMore') }}
      </UIButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Responsive from '@/directives/Responsive';
import WaveAnimation from '@/directives/WaveAnimation';
import VehiclesCard from '@/components/VehiclesCard';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'VehiclesList',
  components: { UIButton, VehiclesCard, UIIcon },
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    stepPagination: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      paginationBtnIsActive: true
    };
  },
  watch: {
    list(newValue) {
      this.paginationBtnIsActive = newValue.length >= this.listOptions.rpp;
      this.$nextTick(() => {
        if (window.auWidgetEmbed) {
          window.auWidgetEmbed.init();
        }
      });
    }
  },
  mounted() {
    if (window.auWidgetEmbed) {
      window.auWidgetEmbed.init();
    }
    this.paginationBtnIsActive = this.list.length >= this.listOptions.rpp;
  },
  computed: {
    ...mapState('vehicles', {
      statusFilteredVehiclesLoaded: 'statusFilteredVehiclesLoaded'
    }),
    ...mapState('options', {
      listOptions: 'listOptions',
      initOptions: 'initOptions'
    })
  },
  methods: {
    ...mapActions('options', ['updateResultsPerPage']),
    loadMoreVehicles() {
      this.updateResultsPerPage(this.listOptions.rpp + this.$props.stepPagination);
      this.$emit('VehiclesListUpdateCount');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;

  .vms__vehicles_item {
    margin-bottom: 50px;
  }

  .vms__vehicles_item--list {
    margin-bottom: 10px;
  }

  &_spare {
    width: 333px;
  }

  &_wrap-refresh {
    display: flex;
    justify-content: center;
  }

  &_refresh {
    width: 161px;
    margin: 0 auto;
    margin-bottom: 30px;

    &--list-view {
      margin-top: 46px;
    }
  }
}

.vms__ui-button /deep/ i {
  font-size: 1.125rem;
}

@mixin media-1200() {
  margin-bottom: 20px;

  .vms__vehicles_item {
    width: 457px;
    margin-bottom: 15px;
  }
}

@mixin media-992() {
  margin-top: 20px;

  .vms__vehicles_item {
    width: 337px;
  }
}

@mixin media-768() {
  .vms__vehicles_item {
    width: 100%;
  }
}

.media-1200 .vms__vehicles-list {
  @include media-1200();
}

.media-992 .vms__vehicles-list {
  @include media-992();
}

.media-768 .vms__vehicles-list {
  @include media-768();
}

@media screen and (max-width: 1199px) {
  .vms__vehicles-list {
    @include media-1200();
  }
}

@media screen and (max-width: 992px) {
  .vms__vehicles-list {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__vehicles-list {
    @include media-768();
  }
}
</style>
