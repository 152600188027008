var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms__ui-input_wrapper"},[((_vm.showedPassword ? 'text' : _vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"vms__ui-input vms__OpenSansRegular",class:{
      'vms__ui-input--error': _vm.error,
      'vms__ui-input--error-indent': _vm.errorMessage,
      'vms__ui-input--regular': _vm.regular
    },attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}}):((_vm.showedPassword ? 'text' : _vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"vms__ui-input vms__OpenSansRegular",class:{
      'vms__ui-input--error': _vm.error,
      'vms__ui-input--error-indent': _vm.errorMessage,
      'vms__ui-input--regular': _vm.regular
    },attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"change":function($event){_vm.value=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"vms__ui-input vms__OpenSansRegular",class:{
      'vms__ui-input--error': _vm.error,
      'vms__ui-input--error-indent': _vm.errorMessage,
      'vms__ui-input--regular': _vm.regular
    },attrs:{"placeholder":_vm.placeholder,"type":_vm.showedPassword ? 'text' : _vm.type},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}}),(_vm.typeInput === 'password')?_c('div',{staticClass:"vms__ui-input_eye mdi",class:{ 'mdi-eye-outline': _vm.showedPassword, 'mdi-eye-off-outline': !_vm.showedPassword },on:{"click":function($event){_vm.showedPassword = !_vm.showedPassword}}}):_vm._e(),(_vm.error)?_c('div',{staticClass:"vms__ui-input_wrapper-span vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"vms__ui-input-loader"},[_c('div',{staticClass:"mdi mdi-loading mdi-spin-05 mdi-24px"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }