<template>
  <div
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768
    }"
  >
    <div v-if="list.length" class="vms__vehicles-list">
      <VehiclesCard
        v-for="(item, i) of list"
        :key="i"
        :id="item.id"
        :link="`/vehicles/${item.id}`"
        :imgUrl="item && item.thumbUrl"
        :title="item.title"
        :date="item.firstRegistrationDate"
        :km="item.mileage"
        :price="item.price"
        :discount="item"
        :typeGrid="listOptions.ga"
        :payload="item"
        :deactivated="item.deactivated"
        :upholstery="item.upholsteryName"
        :dealer="item.dealer"
        :cy-name="`results__vehicle-${i+1}`"
      />
      <div class="vms__vehicles-list_spare"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Responsive from '@/directives/Responsive';
import WaveAnimation from '@/directives/WaveAnimation';
import VehiclesCard from '@/components/VehiclesCard';

export default {
  name: 'VehiclesList',
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    VehiclesCard
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    vehiclesOnThePage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('vehicles', {
      statusVehiclesByIds: 'statusVehiclesByIds'
    }),
    ...mapState('options', {
      listOptions: 'listOptions'
    })
  },
  methods: {
    loadMoreVehicles() {
      this.$store.dispatch(
        'options/updateResultsPerPage',
        this.listOptions.rpp + this.$props.vehiclesOnThePage
      );
      this.$emit('VehiclesListUpdateCount');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;

  .vms__vehicles_item {
    margin-bottom: 60px;
  }

  .vms__vehicles_item--list {
    margin-bottom: 10px;
  }

  &_spare {
    width: 333px;
  }
}

@mixin media-1200() {
  margin-bottom: 20px;

  .vms__vehicles_item {
    width: 457px;
    margin-bottom: 15px;
  }
}

@mixin media-992() {
  margin-top: 20px;

  .vms__vehicles_item {
    width: 337px;
  }
}

@mixin media-768() {
  .vms__vehicles_item {
    width: 100%;
  }
}

.media-1200 .vms__vehicles-list {
  @include media-1200();
}

.media-992 .vms__vehicles-list {
  @include media-992();
}

.media-768 .vms__vehicles-list {
  @include media-768();
}

@media screen and (max-width: 1200px) {
  .vms__vehicles-list {
    @include media-1200();
  }
}

@media screen and (max-width: 992px) {
  .vms__vehicles-list {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__vehicles-list {
    @include media-768();
  }
}
</style>
