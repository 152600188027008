<template>
  <div class="vms__ui-select-border">
    <div class="vms__ui-select-border_head" @click="toggleSelect">
      <div class="vms__ui-select-border_head-p vms__OpenSansRegular" v-if="activeOption">
        {{ activeOption.name }}
      </div>
      <div v-else class="vms__ui-select-border_head_title vms__OpenSansRegular">
        {{ placeholder }}
      </div>
      <div
        class="mdi mdi-chevron-down"
        :class="{ 'vms__ui-select-border_head-icon--active': selectOpened }"
      />
    </div>
    <div
      class="vms__ui-select-border_options-wrapper"
      :class="{ 'vms__ui-select-border_options-wrapper--active': selectOpened }"
    >
      <div class="vms__ui-select-border_options">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="setActiveOption(option)"
          class="vms__ui-select-border_options-item vms__OpenSansRegular"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UISelect',
  props: {
    placeholder: {
      type: String,
      default: 'Placeholder *'
    },
    options: {
      type: Array,
      default: () => [
        {
          name: 'Option name1',
          value: '0',
          active: true
        },
        {
          name: 'Option name2',
          value: '1',
          active: false
        }
      ]
    }
  },
  data() {
    return {
      selectOpened: false,
      activeOption: null
    };
  },
  created() {
    document.addEventListener('click', this.closeSelectOptions);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeSelectOptions);
  },
  methods: {
    toggleSelect() {
      this.selectOpened = !this.selectOpened;
    },
    closeSelectOptions(e) {
      if (this.selectOpened && !e.target.parentNode.className.includes('vms__ui-select-border')) {
        this.selectOpened = false;
      }
    },
    setActiveOption(option) {
      this.toggleSelect();
      this.activeOption = option;
      this.$emit('input', option);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-select-border {
  width: 100%;
  position: relative;
  border: 1px solid #cacaca;
  background-color: #fff;

  &_head {
    height: inherit;
    display: flex;
    padding: 5px 0;
    padding-right: 30px;
    padding-left: 17px;

    &_title,
    &-p {
      font-size: 1rem;
      line-height: 1.9;
      color: #000;
    }

    &_title {
      margin-right: 10px;
    }

    .mdi {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      color: #2d9c90;
      font-size: 30px;
    }

    &-icon--active {
      transform: translateY(-50%) scale(-1) !important;
      transition: all 0.2s ease !important;
    }
  }

  &_options {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
      cursor: pointer;
      padding: 10px 15px;
      font-size: 0.875rem;
      line-height: 1.33;
      color: var(--text-color--dark);

      &:hover {
        background-color: #f2f2f2;
      }
    }

    &-wrapper {
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-out;
      border-radius: 3px;
      box-shadow: 0 3px 1px -2px var(--black-02), 0 2px 2px 0 var(--black-014),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border: 1px solid #cacaca;
      z-index: 10;

      &--active {
        width: 100%;
        height: auto;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
