import Vue from 'vue';
import Vuex from 'vuex';
import configs from '@/configs';
import authActions from '@/store/modules/auth/actions';
import authMutations from '@/store/modules/auth/mutations';
import authGetters from '@/store/modules/auth/getters';
import instancesActions from '@/store/modules/instances/actions';
import instancesMutations from '@/store/modules/instances/mutations';
import showroomsActions from '@/store/modules/showrooms/actions';
import showroomsMutations from '@/store/modules/showrooms/mutations';
import vehiclesActions from '@/store/modules/vehicles/actions';
import vehiclesMutations from '@/store/modules/vehicles/mutations';
import vehiclesGetters from '@/store/modules/vehicles/getters';
import paramsActions from '@/store/modules/params/actions';
import paramsMutations from '@/store/modules/params/mutations';
import favoritesActions from '@/store/modules/favorites/actions';
import favoritesMutations from '@/store/modules/favorites/mutations';
import comparesActions from '@/store/modules/compares/actions';
import comparesMutations from '@/store/modules/compares/mutations';
import paramsGetters from '@/store/modules/params/getters';
import formsActions from '@/store/modules/forms/actions';
import formsMutations from '@/store/modules/forms/mutations';
import optionsActions from '@/store/modules/options/actions';
import optionsMutations from '@/store/modules/options/mutations';
import alertsActions from '@/store/modules/alerts/actions';
import alertsMutations from '@/store/modules/alerts/mutations';
import culturesActions from '@/store/modules/cultures/actions';
import culturesMutations from '@/store/modules/cultures/mutations';
import LocalStorageService from '@/services/localStorageService';
import DefaultParams from '@/store/defaultParams';
import interceptors from '@/store/interceptors';

Vue.use(Vuex);

const localStorageService = LocalStorageService.installService();
const defaultParams = DefaultParams.installService();
interceptors();

const data = {
  modules: {
    auth: {
      status: '',
      statusUrlTo: false,
      token: localStorageService.getAccessToken() || '',
      LoggedIn: false,
      urlTo: null
    },
    instances: {
      statusInstance: true,
      instance: null
    },
    showrooms: {
      showroomLoaded: false,
      statusShowroom: true,
      statusFilters: null,
      showrooms: [],
      showroom: {},
      transactions: [],
      filters: {},
      hasVehicles: true
    },
    vehicles: {
      statusVehiclesLoaded: null,
      statusFilteredVehiclesLoaded: null,
      bestDealsLoaded: null,
      statusVehicleLoaded: null,
      statusVehiclesByIds: null,
      statusEquipments: '',
      statusEquipmentsTransactions: '',
      statusEquipmentsTransactionsCulture: '',
      statusRemove: '',
      vehiclesByIds: [],
      list: null,
      filteredList: {},
      bestDealsList: [],
      item: {},
      equipments: [],
      equipmentsTransactions: [],
      equipmentsTransactionsCulture: []
    },
    params: {
      statusParams: '',
      statusInitParams: '',
      listParams: {},
      initParams: {},
      showEmail: false
    },
    favorites: {
      statusFavorites: false,
      favoriteList: []
    },
    compares: {
      statusCompares: false,
      compareList: []
    },
    forms: {
      statusRequestSent: true,
      statusPriceSent: true,
      requestData: {},
      priceData: {}
    },
    alerts: {
      alertsList: []
    },
    options: {
      statusOptions: null,
      initOptions: {},
      listOptions: {},
      googleMapsApiKey: defaultParams.defaultGoogleMapsApiKey(),
      showSortList: defaultParams.defaultShowSortList(),
      showCompare: defaultParams.defaultShowCompare(),
      isGrid: defaultParams.defaultIsGrid(),
      mileageTo: defaultParams.defaultmaxMileage(),
      maxMileageInterval: defaultParams.defaultmaxMileageInterval(),
      priceFrom: defaultParams.defaultMinPrice(),
      priceTo: defaultParams.defaultMaxPrice(),
      maxPriceInterval: defaultParams.defaultMaxPriceInterval()
    },
    cultures: {
      statusCulture: null,
      statusTranslations: null,
      cultureSelected: { key: null, name: null }
    }
  }
};

export default new Vuex.Store({
  strict: configs.NODE_ENV !== 'production',
  modules: {
    auth: {
      namespaced: true,
      state: data.modules.auth,
      actions: authActions,
      mutations: authMutations,
      getters: authGetters
    },
    instances: {
      namespaced: true,
      state: data.modules.instances,
      actions: instancesActions,
      mutations: instancesMutations
    },
    showrooms: {
      namespaced: true,
      state: data.modules.showrooms,
      actions: showroomsActions,
      mutations: showroomsMutations
    },
    vehicles: {
      namespaced: true,
      state: data.modules.vehicles,
      actions: vehiclesActions,
      mutations: vehiclesMutations,
      getters: vehiclesGetters
    },
    forms: {
      namespaced: true,
      state: data.modules.forms,
      actions: formsActions,
      mutations: formsMutations
    },
    alerts: {
      namespaced: true,
      state: data.modules.alerts,
      actions: alertsActions,
      mutations: alertsMutations
    },
    params: {
      namespaced: true,
      state: data.modules.params,
      actions: paramsActions,
      mutations: paramsMutations,
      getters: paramsGetters
    },
    favorites: {
      namespaced: true,
      state: data.modules.favorites,
      actions: favoritesActions,
      mutations: favoritesMutations
    },
    compares: {
      namespaced: true,
      state: data.modules.compares,
      actions: comparesActions,
      mutations: comparesMutations
    },
    options: {
      namespaced: true,
      state: data.modules.options,
      actions: optionsActions,
      mutations: optionsMutations
    },
    cultures: {
      namespaced: true,
      state: data.modules.cultures,
      actions: culturesActions,
      mutations: culturesMutations
    }
  }
});
