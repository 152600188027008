<template>
  <div v-if="showAlert" class="vms__ui-alert-network">
    <div class="vms__ui-alert-network-p vms__OpenSansRegular" v-if="status === 604">
      {{ $t('alerts.networkError') }}
    </div>
    <div class="vms__ui-alert-network-p vms__OpenSansRegular" v-if="status === 605">
      {{ $t('alerts.notFoundError') }}
    </div>
    <div class="vms__ui-alert-network-p vms__OpenSansRegular" v-if="status === 607">
      {{ $t('alerts.serverError') }}
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';

export default {
  name: 'UIAlertNetwork',
  mixins,
  data() {
    return {
      showAlert: false,
      status: null
    };
  },
  beforeMount() {
    EventBus.$on('network-error', this.toggleAlert);
  },
  beforeDestroy() {
    EventBus.$off('network-error', this.toggleAlert);
  },
  methods: {
    closeAlert() {
      this.showAlert = false;
    },
    toggleAlert(code) {
      this.showAlert = true;
      this.status = code;
      setTimeout(() => {
        this.showAlert = false;
        this.text = null;
      }, 5000);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-alert-network {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  margin-bottom: 10px;
  border-right: 3px solid #df4949;

  &-p {
    color: var(--text-color--dark);
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
</style>
