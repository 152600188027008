<template>
  <div
    ref="buttons"
    class="vms__float-buttons"
    :class="{
      'vms__float-buttons--visible': buttonsVisible
    }"
  >
    <UIButton
      v-if="item && item.isProbefahrenAvailable"
      class="probefahrt-quicklink"
      background="red"
      color="#fff"
      shadow
      :href="`https://app.probefahrtenbutler.com/drive/edentity/EA30D84D/${item.importedId}`"
    >
      <UIIcon left name="mdi-calendar" />
      <div>
        {{ $t('vehicle.contacts.button3') }}
      </div>
    </UIButton>
    <UIButton
      v-if="showroom && showroom.autouncleWidgetEnabled && showroom.autouncleWidgetConfigKey"
      class="vms__float-buttons_autouncle"
      v-WaveAnimation
      background="#82BA2B"
      color="#fff"
      shadow
      @click="openAutoUnclePopup"
    >
      <UIIcon name="mdi-calculator" left />
      <div>
        {{ $t('buttons.autouncleBtn') }}
      </div>
    </UIButton>
    <UIButton
      v-if="contactBtnVisible"
      class="vms__float-buttons_contact"
      background="var(--color--dark)"
      color="#fff"
      @click="openModalContact"
      v-WaveAnimation
      shadow
      data-cy="popup-btns__contact"
    >
      <UIIcon left name="mdi-comment-multiple" size="16px" />
      <div>
        {{ $t('buttons.contact') }}
      </div>
    </UIButton>
    <UIButton
      v-if="priceBtnVisible"
      @click="$emit('on-price')"
      v-WaveAnimation.dark="true"
      class="vms__float-buttons_price"
      shadow
      data-cy="popup-btns__price-alarm"
    >
      <UIIcon left name="mdi-bell-outline" />
      <div>
        {{ $t('buttons.priceAlarm') }}
      </div>
    </UIButton>
    <FloatButtonsMenu
      @on-price="$emit('on-price')"
      :priceBtnVisible="priceBtnVisible"
      :contactBtnVisible="contactBtnVisible"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import EventBus from '@/services/eventBusService';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import FloatButtonsMenu from '@/components/VehicleDetail/FloatingElements/FloatButtonsMenu';
import WaveAnimation from '@/directives/WaveAnimation';

export default {
  name: 'FloatButtons',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    UIButton,
    UIIcon,
    FloatButtonsMenu
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scrollbarContainer: null,
      buttonsVisible: false,
      contactBtnVisible: false,
      priceBtnVisible: false
    };
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('favorites', {
      favoriteList: 'favoriteList'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom',
      showroomLoaded: 'showroomLoaded'
    }),
    menuItems() {
      return [
        {
          icon: 'mdi-star',
          text: 'Favorite',
          to: '/vehicles/bookmarked',
          visible: this.favoriteslength
        },
        {
          icon: 'mdi-car-multiple',
          text: 'Compare',
          to: '/vehicles/compares',
          visible: this.compareslength
        },
        {
          icon: 'mdi-printer',
          text: 'Print',
          link: false,
          visible: true,
          eventName: 'on-print'
        }
      ];
    },
    favoriteslength() {
      if (this.favoriteList) {
        return this.favoriteList && JSON.stringify(this.favoriteList).split(', ').length;
      }
      return null;
    },
    compareslength() {
      if (this.compareList) {
        return this.compareList && JSON.stringify(this.compareList).split(', ').length;
      }
      return null;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getWidgetArea);
    window.addEventListener('resize', this.contactBtnCondition);
    window.addEventListener('resize', this.priceBtnCondition);
    this.scrollbarContainer = document.getElementById('PageScrollbar');
    this.scrollbarContainer.addEventListener('ps-scroll-y', this.getWidgetArea);
    this.getWidgetArea();
  },
  destroyed() {
    window.removeEventListener('scroll', this.getWidgetArea);
    window.removeEventListener('resize', this.contactBtnCondition);
    window.removeEventListener('resize', this.priceBtnCondition);
    this.scrollbarContainer = document.getElementById('PageScrollbar');
    this.scrollbarContainer.addEventListener('ps-scroll-y', this.getWidgetArea);
  },
  created() {
    if (this.showroomLoaded === true) {
      this.priceBtnCondition();
      this.contactBtnCondition();
    }
  },
  watch: {
    showroomLoaded(loaded) {
      if (loaded === true) {
        this.priceBtnCondition();
        this.contactBtnCondition();
      }
    }
  },
  methods: {
    getWidgetArea() {
      const offsetTop = this.MixinGetOffset(this.$root.$el);
      const windowHeight = window.innerHeight;
      const widgetHeight = this.$root.$el.getBoundingClientRect().height;
      const scrollTop = window.scrollY;
      if (scrollTop > offsetTop + 250 && scrollTop + windowHeight <= offsetTop + widgetHeight) {
        this.buttonsVisible = true;
      } else {
        this.buttonsVisible = false;
      }
    },
    openModalContact() {
      EventBus.$emit('open-modal-contact');
    },
    openAutoUnclePopup() {
      window.AuEmbed.TradeInCalculatorWidgetEmbedder.openTradeInModal();
    },
    contactBtnCondition() {
      if (this.showroom && this.showroom.showContactFormInDetails) {
        const windowHeight = window.innerWidth;
        if (windowHeight < 992) {
          if (this.item && this.item.isProbefahrenAvailable) {
            this.contactBtnVisible = false;
          } else {
            this.contactBtnVisible = true;
          }
        } else {
          this.contactBtnVisible = true;
        }
      }
    },
    priceBtnCondition() {
      if (this.showroom && this.showroom.showPriceAlarmInDetails) {
        const windowHeight = window.innerWidth;
        if (windowHeight < 992) {
          if (
            this.showroom &&
            this.showroom.autouncleFloatingWidgetEnabled &&
            this.showroom.autouncleWidgetConfigKey
          ) {
            this.priceBtnVisible = false;
          } else {
            this.priceBtnVisible = true;
          }
        } else {
          this.priceBtnVisible = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__float-buttons {
  width: auto;
  max-width: 100%;
  position: fixed;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  opacity: 0;

  /deep/ .vms__ui-button {
    margin: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }

  .vms__ui-button--toggler {
    display: none;
  }

  &--visible {
    opacity: 1;
    bottom: 40px;
  }
}
</style>
