<template>
  <div
    class="vms__modal-email"
    v-responsive="{ 'media-768': el => el.width < 768, 'media-576': el => el.width < 576 }"
  >
    <div class="vms__modal-email_head">
      <div class="vms__modal-email_head-title vms__BarlowSemiCondensedRegular">
        {{ $t('modals.email.title') }}
      </div>
    </div>
    <div class="vms__modal-email_descr vms__OpenSansRegular">
      {{ $t('modals.email.desc') }}
    </div>
    <vue-recaptcha
      v-if="sitekey"
      :language="cultureSelected.key"
      ref="recaptcha"
      class="vms__modal-email_captcha"
      :loadRecaptchaScript="true"
      :sitekey="sitekey"
      @verify="verifyRecaptcha"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import configs from '@/configs';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';

export default {
  name: 'ModalEmail',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: { VueRecaptcha },
  data() {
    return {
      sitekey: configs.RECAPTCHA_KEY
    };
  },
  computed: {
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    })
  },
  methods: {
    verifyRecaptcha() {
      this.$emit('ModalClose');
      EventBus.$emit('show-email', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__modal-email {
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &_head {
    padding: 50px 20px 10px;

    &-title {
      color: #000;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }
  }

  &_descr {
    max-width: 300px;
    margin: 20px auto 40px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    text-align: center;
  }

  &_captcha {
    margin: 0 30px 55px;
  }
}
</style>
