import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import configs from '@/configs';
import availablePages from '@/router/middlewares/availablePages';
import startPage from '@/router/middlewares/startPage';
import middlewarePipeline from '@/router/middlewarePipeline';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Vehicles from '@/pages/Vehicles';
import VehiclesBookmarked from '@/pages/VehiclesBookmarked';
import VehiclesDetail from '@/pages/VehiclesDetail';
import VehiclesDetailGeo from '@/pages/VehiclesDetailGeo';
import VehiclesCompare from '@/pages/VehiclesCompare';
import Search from '@/pages/Search';
import TechnicalWork from '@/pages/TechnicalWork';
import NotFound from '@/pages/NotFound';
import VehiclesDetailByRef from '@/pages/VehiclesDetailByRef';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'default',
    component: Home,
    meta: {
      middleware: [startPage, availablePages],
      breadcrumb: 'Home'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      middleware: [startPage, availablePages]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    component: Vehicles,
    meta: {
      middleware: [startPage, availablePages],
      breadcrumb: 'Vehicles'
    }
  },
  {
    path: '/vehicles/bookmarked',
    name: 'vehicles.bookmarked',
    component: VehiclesBookmarked,
    meta: {
      middleware: [startPage, availablePages],
      breadcrumb: 'Bookmarked'
    }
  },
  {
    path: '/vehicles/compares',
    name: 'vehicles.compare',
    component: VehiclesCompare,
    meta: {
      middleware: [startPage, availablePages],
      breadcrumb: 'Compare'
    }
  },
  {
    path: '/vehicles/:id',
    name: 'vehicles.detail',
    component: VehiclesDetail,
    meta: {
      breadcrumb: 'View'
    }
  },
  {
    path: '/vehicles/ref/:importedId',
    name: 'vehicles.detailByRefRedirect',
    component: VehiclesDetailByRef,
    meta: {
      breadcrumb: 'View'
    }
  },
  {
    path: '/vehicles/:id/geo',
    name: 'vehicles.detail.geo',
    component: VehiclesDetailGeo
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      middleware: [startPage, availablePages]
    }
  },
  {
    path: '/technical-work',
    name: 'technical-work',
    component: TechnicalWork,
    meta: {
      middleware: [availablePages]
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      middleware: [availablePages]
    }
  },
  {
    path: '*',
    name: 'fallback',
    meta: {
      fallbackPageName: 'default',
      middleware: [startPage, availablePages]
    }
  }
];

let routeData = null;
const router = new VueRouter({
  mode: 'hash',
  base: configs.BASE_URL,
  routes,
  scrollBehavior(data) {
    setTimeout(() => {
      if (!routeData) {
        routeData = data;
      } else {
        const offset = window.offsetScrollTop || this.app.offsetScrollTop;
        window.scrollTo(0, this.app.MixinGetOffset(this.app.$el) - offset);
      }
    }, 300);
  }
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    routes,
    next,
    store
  };
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
