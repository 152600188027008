import {
  INSTANCE_BY_ID_REQUEST,
  INSTANCE_BY_ID_SUCCESS,
  INSTANCE_BY_ID_ERROR
} from '@/store/modules/instances/types';

export default {
  [INSTANCE_BY_ID_REQUEST]: state => {
    state.statusInstance = true;
  },
  [INSTANCE_BY_ID_SUCCESS]: (state, payload) => {
    state.statusInstance = true;
    state.instance = payload;
  },
  [INSTANCE_BY_ID_ERROR]: state => {
    state.statusInstance = false;
  }
};
