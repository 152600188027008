<template>
  <div
    class="vms__navigate-panel-wrap"
    :class="{
      'vms__navigate-panel-wrap--width-fixed': typeWidth,
      'vms__navigate-panel-wrap--height-fixed': validHeight
    }"
    v-if="mobMenuOpened"
  >
    <transition name="fade">
      <div
        class="vms__navigate-panel vms__container"
        v-responsive="{
          'media-1200': el => el.width < 1200,
          'media-768': el => el.width < 768,
          'media-480': el => el.width < 480
        }"
      >
        <div @click="closeMobMenu" class="vms__navigate-panel_overlay"></div>
        <div
          class="vms__navigate-panel_content"
          :class="{ 'vms__navigate-panel_content--closed': !mobMenuContentOpened }"
          :style="{ top: top }"
        >
          <!-- Left content -->
          <div class="vms__navigate-panel_content--left">
            <UIBreadcrumbs :breadcrumbs="breadcrumbs" />
          </div>

          <!-- Right content -->
          <div class="vms__navigate-panel_content--right">
            <UIPaginationBtn v-if="isPagination" />
            <div class="vms__navigate-panel_links">
              <UIButton
                link
                class="vms__navigate-panel_bookmarked-link"
                to="/vehicles/bookmarked"
                :disabled="favoriteslength === 0 || typeof favoriteslength !== 'number'"
                data-cy="btns__bookmarked"
              >
                <UIIcon name="mdi-star-circle-outline" left />
                {{ $t('navigatePanel.bookmarked') }} ({{ favoriteslength }})
              </UIButton>
              <UIButton
                class="vms__navigate-panel_compares-link"
                :disabled="compareslength === 0 || typeof compareslength !== 'number'"
                link
                to="/vehicles/compares"
                :label="$t('navigatePanel.compare')"
                data-cy="btns__compares"
              >
                <UIIcon name="mdi-car-multiple" left />
                {{ $t('navigatePanel.compare') }} ({{ compareslength }})
              </UIButton>
            </div>
            <LangSwitcher />
            <UIButton
              v-if="showroom && showroom.customerType === 'b2B'"
              class="vms__navigate-panel_logout"
              @click="logout"
            >
              <UIIcon name="mdi-logout" left />
              {{ $t('navigatePanel.logout') }}
            </UIButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import UIPaginationBtn from '@/components/ui/UIPaginationBtn';
import LangSwitcher from '@/components/LangSwitcher';
import UIBreadcrumbs from '@/components/ui/UIBreadcrumbs';
import EventBus from '@/services/eventBusService';

export default {
  name: 'NavigatePanel',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    UIButton,
    UIIcon,
    UIPaginationBtn,
    LangSwitcher,
    UIBreadcrumbs
  },
  props: {
    isBackspace: {
      type: Boolean,
      default: true
    },
    isPagination: {
      type: Boolean,
      default: false
    },
    isKfz: {
      type: Boolean,
      default: false
    },
    isKfzWhite: {
      type: Boolean,
      default: false
    },
    isMotocycle: {
      type: Boolean,
      default: false
    },
    isVan: {
      type: Boolean,
      default: false
    },
    backspaceLabel: {
      type: String,
      default: 'Label'
    },
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mobMenuOpened: true,
      mobMenuContentOpened: true,
      styles: null,
      top: 'auto'
    };
  },
  mounted() {
    this.onResize();
    EventBus.$on('toogleMobMenu', this.toogleMobMenu);
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    EventBus.$off('toogleMobMenu', this.toogleMobMenu);
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState('favorites', {
      favoriteList: 'favoriteList'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    favoriteslength() {
      if (this.favoriteList) {
        return this.favoriteList.length;
      }
      return 0;
    },
    compareslength() {
      if (this.compareList) {
        return this.compareList.length;
      }
      return 0;
    },
    typeWidth() {
      if (this.listParams && this.listParams.width !== 'auto') {
        return true;
      }
      if (
        this.$root.$el.clientWidth !==
        (document.body.clientWidth || document.documentElement.clientWidth)
      ) {
        return true;
      }
      return false;
    },
    validHeight() {
      if (this.listParams?.height?.includes('px') || this.listParams?.height?.includes('vh')) {
        return true;
      }
      return false;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    toogleMobMenu(bool) {
      this.mobMenuOpened = bool;
      setTimeout(() => {
        this.mobMenuContentOpened = bool;
      }, 5);
    },
    closeMobMenu() {
      EventBus.$emit('closeMobMenu');
      this.mobMenuOpened = false;
      this.mobMenuContentOpened = false;
    },
    onResize() {
      if (this.$root.$el.clientWidth <= 768) {
        this.styles = {
          width: this.MixinGetContainerWidth(this.listParams.width),
          height: this.MixinGetContainerHeight(this.listParams.height),
          marginBottom: `-${this.MixinGetContainerHeight(this.listParams.height)}`
        };
        this.closeMobMenu();
      } else {
        this.styles = null;
        this.mobMenuOpened = true;
        this.mobMenuContentOpened = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__navigate-panel {
  &_buttons {
    display: flex;

    &-link {
      display: flex;
      align-items: center;
      line-height: 1.71;
      opacity: 0.7;
      font-size: 1rem;
      color: #000;
      margin-left: 5px;
      margin-right: 10px;

      .mdi {
        font-size: 1.375rem;
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }

  &_overlay {
    display: none;
  }

  &_logout {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #000;
    background-color: transparent;
    order: 2;
    cursor: pointer;
    margin-left: auto;
    padding: 8px 0;

    .mdi {
      font-size: 1.375rem;
      opacity: 0.8;
      margin-right: 8px;
    }
  }

  .vms__ui-lang-switcher {
    margin-left: 10px;
  }

  &_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &--left {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &--right {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .vms__ui-button {
    padding-left: 15px;
    padding-right: 22px;

    svg {
      width: 22px;
      fill: #fff;
    }
  }

  &--button-green {
    background-color: var(--accent-green--dark) !important;
  }

  &_links {
    display: flex;
    align-items: center;
    margin-left: -15px;

    &_link {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.5;
      color: #000;

      &--only-mobile {
        display: none;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
.fade-leave-to {
  width: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin media-1200() {
  &_links {
    .vms__ui-button {
      font-size: 0.875rem;

      .mdi {
        font-size: 1rem;
      }
    }
  }

  &_logout {
    font-size: 0.875rem;

    .mdi {
      margin-right: 4px;
      font-size: 1rem;
    }
  }

  &_content {
    flex-wrap: wrap;
  }
}

@mixin media-768() {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;

  .vms__navigate-panel {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    transition: all 0.2s ease;
    z-index: 99;
    box-shadow: 0 5px 5px -3px var(--black-07), 0 8px 10px 1px var(--black-07),
      0 3px 14px 2px rgba(0, 0, 0, 0.07);
    overflow: hidden;

    &_overlay {
      width: inherit;
      height: inherit;
      position: absolute;
      top: inherit;
      left: inherit;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    &_content {
      width: 240px;
      height: auto;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 15px;
      margin-top: 10px;
      padding: 15px 0;
      transition: all 0.3s ease;
      z-index: 2;
      border-radius: 3px;
      background-color: #fff;

      &--left {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
        margin-bottom: 10px;

        .vms__ui-breadcrumbs {
          width: 100%;
          border-bottom: 1px solid #eee;
          padding-bottom: 10px;
        }
      }

      &--right {
        flex-direction: column;
      }

      &--closed {
        margin-left: -100%;
      }
    }

    &_burger {
      display: block;
    }

    &_links {
      width: 240px;
      margin: 0;
      flex-direction: column;
      align-items: flex-start;

      a {
        width: 100%;
        justify-content: flex-start;
      }

      &_link--only-mobile {
        display: flex;
      }
    }

    .vms__ui-lang-switcher {
      width: 100%;
      margin-left: 0 !important;
      order: 3;
      padding-left: 15px;
      padding-right: 15px;
    }

    &_logout {
      width: 100%;
      margin-left: 0;
      padding-left: 15px;
      margin-top: auto;
      order: 3;
    }

    &_buttons {
      width: 100%;
      flex-direction: column;

      &-link {
        font-size: 0.875rem;

        i {
          font-size: 1rem;
        }
      }

      .vms__ui-button {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    .vms__ui-breadcrumbs {
      flex-wrap: wrap;

      /deep/ a {
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  &--height-full {
    position: absolute;
  }

  &--width-fixed {
    position: absolute;

    .vms__container-modal_container {
      margin-top: 100px;
    }
  }

  &--height-fixed {
    position: sticky;
    margin-bottom: -100vh;

    .vms__container-modal_container {
      margin-top: 0;
    }
  }
}

@mixin media-480() {
  flex-direction: column;
  align-items: flex-start;

  /deep/ .vms__ui-lang-switcher {
    margin-left: -10px;
    margin-top: 0;

    &_current {
      padding: 4px 0;
    }

    &_dropdown {
      margin-left: 10px;
    }
  }

  &_links {
    margin-top: 0;

    &_link {
      font-size: 0.875rem;
    }

    .mdi {
      font-size: 1.125rem;
      margin-right: 8px;
    }
  }
}

.media-1200 .vms__navigate-panel {
  @include media-1200();
}

.media-768 .vms__navigate-panel-wrap {
  @include media-768();
}

.media-480 .vms__navigate-panel {
  @include media-480();
}

@media screen and (max-width: 1200px) {
  .vms__navigate-panel {
    @include media-1200();
  }
}

@media screen and (max-width: 768px) {
  .vms__navigate-panel-wrap {
    @include media-768();
  }
}

@media screen and (max-width: 480px) {
  .vms__navigate-panel {
    @include media-480();
  }
}
</style>
