<template>
  <div
    @click="toggle"
    class="vms__ui-checkbox"
    :class="{ 'vms__ui-checkbox--disabled': disabled }"
    :style="borderStyles"
  >
    <div v-if="isChecked" class="vms__ui-checkbox--checked" :style="pointStyles"></div>
  </div>
</template>

<script>
export default {
  name: 'UICheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    borderSize: {
      type: Number,
      default: 24
    },
    pointSize: {
      type: Number,
      default: 19
    }
  },
  data() {
    return {
      isChecked: this.checked
    };
  },
  computed: {
    borderStyles() {
      return {
        width: `${this.borderSize}px`,
        minWidth: `${this.borderSize}px`,
        height: `${this.borderSize}px`,
        minHeight: `${this.borderSize}px`
      };
    },
    pointStyles() {
      return {
        width: `${this.pointSize}px`,
        height: `${this.pointSize}px`
      };
    }
  },
  watch: {
    checked(c) {
      this.isChecked = c;
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.isChecked = !this.isChecked;
        this.$emit('input', this.isChecked);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-checkbox {
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  position: relative;
  background-color: #fff;
  border: 1px solid #cacaca;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;

  &--checked {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #000;
    border-radius: 2px;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
