<template>
  <div
    class="vms__wrap-contacts-map"
    v-responsive="{
      'media-1024': el => el.width < 1024
    }"
  >
    <div class="vms__container">
      <ContactsInfo :vehicleInfo="vehicleInfo" />
      <GoogleMap
        id="contacts-map"
        v-if="dataIsReady"
        :panToX="panToX"
        :lat="(vehicleInfo && vehicleInfo.dealer && vehicleInfo.dealer.latitude) || 0"
        :lng="(vehicleInfo && vehicleInfo.dealer && vehicleInfo.dealer.longitude) || 0"
        :disableDefaultUI="true"
        :zoom="14"
        class="vms__wrap-contacts-map_gmap"
      />
    </div>
  </div>
</template>

<script>
import Responsive from '@/directives/Responsive';
import ContactsInfo from '@/components/VehicleDetail/ContactsInfo';
import GoogleMap from '@/components/GoogleMap';

export default {
  name: 'WrapContactsMap',
  components: { ContactsInfo, GoogleMap },
  directives: {
    responsive: Responsive
  },
  props: {
    dataIsReady: {
      type: Boolean,
      default: false
    },
    vehicleInfo: {
      type: Object,
      default: () => {}
    },
    scrollbar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      panToX: 0.02
    };
  },
  created() {
    window.addEventListener('resize', this.calcPanToX);
    this.calcPanToX();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcPanToX);
  },
  methods: {
    calcPanToX() {
      if (this.$root.$el.clientWidth > 1200) {
        this.panToX = 0.025;
      } else if (this.$root.$el.clientWidth > 1024 && this.$root.$el.clientWidth < 1200) {
        this.panToX = 0.02;
      } else if (this.$root.$el.clientWidth < 1024) {
        this.panToX = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__wrap-contacts-map {
  position: relative;
  margin-top: 80px;
  background-color: #f2f2f2;
  padding: 28px 0;

  .vms__contacts-info {
    width: 50%;
    position: relative;
    z-index: 2;
  }

  &_gmap {
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }
}

@mixin media-1024() {
  > .vms__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .vms__contacts-info {
    width: 100%;
  }

  .vms__wrap-contacts-map_gmap {
    height: 350px;
    position: relative !important;
  }
}

.media-1024.vms__wrap-contacts-map {
  @include media-1024();
}

@media screen and (max-width: 1024px) {
  .vms__wrap-contacts-map {
    @include media-1024();
  }
}
</style>
