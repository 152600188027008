import {
  SHOWROOMS_REQUEST,
  SHOWROOMS_SUCCESS,
  SHOWROOMS_ERROR,
  SHOWROOMS_FILTERS_INIT_REQUEST,
  SHOWROOMS_FILTERS_INIT_SUCCESS,
  SHOWROOMS_FILTERS_INIT_ERROR,
  SHOWROOMS_FILTERS_UPDATE_SUCCESS,
  SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS,
  SHOWROOMS_SHOW_SORT_LIST_SUCCESS,
  SHOWROOMS_SHOW_COMPARE_SUCCESS
} from '@/store/modules/showrooms/types';

export default {
  [SHOWROOMS_REQUEST]: state => {
    state.statusShowroom = true;
  },
  [SHOWROOMS_SUCCESS]: (state, payload) => {
    state.showroomLoaded = true;
    state.statusShowroom = true;
    state.showroom = payload;
  },
  [SHOWROOMS_ERROR]: state => {
    state.showroomLoaded = true;
    state.statusShowroom = false;
  },
  [SHOWROOMS_FILTERS_INIT_REQUEST]: state => {
    state.statusFilters = false;
  },
  [SHOWROOMS_FILTERS_INIT_SUCCESS]: (state, payload) => {
    state.statusFilters = true;
    state.filters = payload;
    state.hasVehicles = payload.hasVehicles;
  },
  [SHOWROOMS_FILTERS_INIT_ERROR]: state => {
    state.statusFilters = false;
  },
  [SHOWROOMS_FILTERS_UPDATE_SUCCESS]: (state, payload) => {
    state.filters = payload;
  },
  [SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS]: (state, payload) => {
    state.googleMapsApiKey = payload;
  },
  [SHOWROOMS_SHOW_SORT_LIST_SUCCESS]: (state, payload) => {
    state.showSortList = payload;
  },
  [SHOWROOMS_SHOW_COMPARE_SUCCESS]: (state, payload) => {
    state.showShowCompare = payload;
  }
};
