<template>
  <div
    class="vms__modal-price"
    v-responsive="{ 'media-768': el => el.width < 768, 'media-576': el => el.width < 576 }"
  >
    <div class="vms__modal-price_head">
      <div class="vms__modal-price_head-title vms__OpenSansRegular">
        {{ $t('vehicle.modals.price.title') }}
      </div>
      <div class="vms__modal-price_head-desc vms__BarlowSemiCondensedRegular">
        {{ item.makeModel }}
      </div>
    </div>
    <transition v-if="!sended" name="fade">
      <ValidationObserver v-slot="{ invalid }">
        <div class="vms__modal-price_content">
          <div class="vms__modal-price_content-fields">
            <ValidationProvider name="firstname" rules="required">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="firstname"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.price.inpPlaceholder1')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="lastname" rules="required">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="lastname"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.price.inpPlaceholder2')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="phone" rules="required|phone">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="phone"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.price.inpPlaceholder3')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider name="email" rules="required|email">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="email"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.price.inpPlaceholder4')"
                />
              </div>
            </ValidationProvider>
            <div
              class="vms__modal-price_content-fields-p vms__OpenSansRegular"
              v-html="$t('vehicle.modals.price.text1')"
            ></div>
            <ValidationProvider name="priceLimit" rules="required|price">
              <div slot-scope="{ errors }">
                <UIInput
                  v-model="priceLimit"
                  :errorMessage="errors[0]"
                  :error="errors && errors.length !== 0"
                  :placeholder="$t('vehicle.modals.price.inpPlaceholder5')"
                />
              </div>
            </ValidationProvider>
            <UISelectBorder
              v-model="selectValue"
              :options="durations"
              :placeholder="$t('vehicle.modals.price.selectPlaceholder')"
            />
          </div>
          <div class="vms__modal-price_content-checkbox">
            <UICheckbox v-model="isAgree" />
            <div
              class="vms__modal-price_content-checkbox-p vms__OpenSansRegular"
              v-html="$t('vehicle.modals.price.text2')"
            ></div>
          </div>
          <vue-recaptcha
            v-if="sitekey"
            :language="cultureSelected.key"
            ref="recaptcha"
            class="vms__modal-price_captcha"
            :loadRecaptchaScript="true"
            :sitekey="sitekey"
            @verify="verifyRecaptcha"
          />
          <UIButton
            shadow
            @click="sendForm"
            :disabled="
              [isNotEmpty, invalid, !isAgree, !selectValue, !verified].some(s =>
                s === true ? true : false
              )
            "
            color="#fff"
            background="#3b3e3c"
          >
            <UIIcon v-if="!loading" name="mdi-message-outline" left />
            <UIIcon v-else name="mdi-loading mdi-spin mdi-spin-05" left />
            {{ $t('vehicle.modals.price.btn') }}
          </UIButton>
        </div>
      </ValidationObserver>
    </transition>
    <transition name="fade">
      <div v-if="sended" class="vms__modal-price_content">
        <div class="vms__modal-price_content-done">
          <div class="mdi mdi-check-circle-outline" />
          <div class="vms__modal-price_content-done-p vms__OpenSansRegular">
            {{ $t('modals.successText') }}
          </div>
          <UIButton shadow @click="hideModalPrice" color="#fff" background="#3b3e3c">
            <UIIcon name="mdi-close" left />
            {{ $t('vehicle.modals.price.close') }}
          </UIButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';
import VueRecaptcha from 'vue-recaptcha';
import configs from '@/configs';
import Responsive from '@/directives/Responsive';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import UISelectBorder from '@/components/ui/UISelectBorder';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import UICheckbox from '@/components/ui/UICheckbox';
import UIInput from '@/components/ui/UIInput';

export default {
  name: 'ModalPrice',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
    UISelectBorder,
    UIButton,
    UIIcon,
    UICheckbox,
    UIInput
  },
  data() {
    return {
      sitekey: configs.RECAPTCHA_KEY,
      firstname: null,
      lastname: null,
      phone: null,
      email: null,
      priceLimit: null,
      selectValue: null,
      isAgree: false,
      sended: false,
      loading: false,
      verified: false
    };
  },
  computed: {
    ...mapState('vehicles', {
      item: 'item'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    ...mapState('forms', {
      statusPriceSent: 'statusPriceSent'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    }),
    durations() {
      return [
        { name: this.$t('modals.price.selectDuration.option1'), value: 7 },
        { name: this.$t('modals.price.selectDuration.option2'), value: 14 },
        { name: this.$t('modals.price.selectDuration.option3'), value: 21 },
        { name: this.$t('modals.price.selectDuration.option4'), value: 31 },
        { name: this.$t('modals.price.selectDuration.option5'), value: 62 }
      ];
    },
    isNotEmpty() {
      return [this.firstname, this.lastname, this.phone, this.email, this.priceLimit].some(
        field => !field
      );
    }
  },
  watch: {
    statusPriceSent(newValue) {
      if (newValue) {
        this.resetFields();
        const success = {
          show: true,
          text: this.$t('vehicle.modals.price.successAlert'),
          isError: false,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.sended = true;
        this.loading = false;
        this.MixinShowAlert(success);
      }
      if (typeof newValue === 'string') {
        const error = {
          show: true,
          text: `Error: ${newValue}`,
          isError: true,
          lifeTime: 3000,
          dateNow: Date.now()
        };
        this.loading = false;
        this.MixinShowAlert(error);
      }
    }
  },
  mounted() {
    extend('required', {
      ...required,
      message: this.$t('errors.requiredField')
    });
    extend('email', {
      ...email,
      message: this.$t('errors.invalidEmail')
    });
    extend('phone', {
      message: this.$t('errors.invalidPhone'),
      validate(value) {
        const regex = /^[0-9\s- \+]*$/g; //eslint-disable-line
        if (value.match(regex)) {
          return true;
        }
        return false;
      }
    });
    extend('price', {
      ...numeric,
      message: this.$t('errors.invalidPrice')
    });
  },
  methods: {
    verifyRecaptcha() {
      this.verified = true;
    },
    hideModalPrice() {
      this.$emit('ModalClose');
    },
    sendForm() {
      this.loading = true;
      const formData = {
        vehicleId: this.item.id,
        culture: this.listParams.culture,
        showroomUid: this.listParams.showroomUid,
        firstName: this.firstname,
        lastName: this.lastname,
        phone: this.phone,
        email: this.email,
        priceLimit: this.priceLimit,
        durationDays: this.selectValue.value
      };
      this.$store.dispatch('forms/sendPriceForm', formData);
    },
    resetFields() {
      this.firstname = null;
      this.lastname = null;
      this.phone = null;
      this.email = null;
      this.priceLimit = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__modal-price {
  max-width: 530px;
  position: relative;

  &_captcha {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &_head {
    padding: 50px 20px 10px;

    &-title {
      color: #000;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }

    &-desc {
      margin-top: 3px;
      font-size: 1.625rem;
      line-height: 1.15;
      letter-spacing: -0.04px;
      color: #000;
    }
  }

  &_content {
    padding: 26px 22px;

    &-fields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > span {
        width: calc(50% - 8px);
        margin-bottom: 20px;

        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
      }

      &-p {
        margin-bottom: 20px;
      }

      .vms__ui-select-border {
        width: calc(50% - 8px);
        height: 42px;
      }

      .vms__ui-input_wrapper {
        width: 100%;

        /deep/ .vms__ui-input {
          width: 100%;
        }
      }

      .vms__ui-textarea {
        width: 100%;
        min-height: 133px;
      }
    }

    &-done {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .mdi {
        margin-top: 110px;
        font-size: 4rem;
        color: #93d750;
      }

      &-p {
        margin-top: 10px;
        margin-bottom: 121px;
        line-height: 1.5;
        font-size: 1rem;
        color: var(--text-color-black);
        text-align: center;
      }
    }

    &-checkbox {
      display: flex;
      margin-top: 17px;
      background-color: #f7f7f7;
      padding: 25px 20px;
      border-radius: 3px;

      &-p {
        margin-left: 18px;
        margin-right: 20px;
        color: #000;
        opacity: 0.6;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .vms__ui-button {
      width: 100%;
      margin-top: 15px;
    }
  }

  /deep/ .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  /deep/ .fade-enter,
  .fade-leave-to {
    width: 100%;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@mixin media-768() {
  &_head {
    &-title {
      font-size: 0.938rem;
    }

    &-desc {
      font-size: 1.188rem;
    }
  }

  &_content-fields {
    &-p {
      font-size: 0.875rem;
      margin-right: 0;
    }
  }
}

@mixin media-576() {
  width: 100%;

  &_content-fields {
    flex-direction: column;
    margin-bottom: 17px;

    > span {
      width: 100%;
    }

    .vms__ui-input_wrapper,
    .vms__ui-select-border {
      width: 100%;
      min-width: 100%;
    }
  }
}

.media-768 .vms__modal-price {
  @include media-768();
}

.media-576 .vms__modal-price {
  @include media-576();
}

@media screen and (max-width: 768px) {
  .vms__modal-price {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__modal-price {
    @include media-576();
  }
}
</style>
