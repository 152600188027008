<template>
  <div class="vms__control-buttons" v-responsive="{
    'media-768': el => el.width < 768
  }">
    <div v-if="favoriteActive" @click="toggleFavorite" @mouseover="onFocusedFavorite"
      class="vms__control-buttons_item vms__control-buttons_bookmarked" v-WaveAnimation.dark="true" :class="{
        'vms__control-buttons_item--active': isFavorite,
        'vms__control-buttons_item--focus': isFocusedFavorite
      }" data-cy="btns__favorite">
      <div class="mdi mdi-star-circle-outline" />
      <div class="vms__control-buttons_item-p">{{ $t('detailPanel.favoriteLink') }}</div>
    </div>
    <div v-if="compareActive" @click="toggleCompare" @mouseover="onFocusedCompare" v-WaveAnimation.dark="true"
      class="vms__control-buttons_item vms__control-buttons_compare" :class="{
        'vms__control-buttons_item--active': isCompare,
        'vms__control-buttons_item--focus': isFocusedCompare
      }" data-cy="btns__compare">
      <div class="mdi mdi-car-multiple" />
      <div class="vms__control-buttons_item-p">{{ $t('detailPanel.compareLink') }}</div>
    </div>
    <div v-if="shareActive" v-WaveAnimation.dark="true" class="vms__control-buttons_item">
      <div class="mdi mdi-share-variant" />
      <div class="vms__control-buttons_item-p">{{ $t('detailPanel.shareLink') }}</div>
      <div class="vms__control-buttons_item-links">
        <div class="vms__control-buttons_item-links-whatsapp"><i class="mdi mdi-whatsapp" /></div>
        <div class="vms__control-buttons_item-links-facebook">
          <i class="mdi mdi-facebook-box" />
        </div>
        <div class="vms__control-buttons_item-links-mail"><i class="mdi mdi-email-outline" /></div>
      </div>
    </div>
    <div v-if="printActive" v-WaveAnimation.dark="true" class="vms__control-buttons_item vms__control-buttons_print"
      @click="print" data-cy="btns__print">
      <div class="mdi mdi-printer" />
      <div class="vms__control-buttons_item-p">{{ $t('detailPanel.printLink') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import printHtmlBlock from 'print-html-block';
import Responsive from '@/directives/Responsive';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';

export default {
  name: 'ControlButtons',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  props: {
    favoriteActive: {
      type: Boolean,
      default: true
    },
    compareActive: {
      type: Boolean,
      default: true
    },
    shareActive: {
      type: Boolean,
      default: true
    },
    printActive: {
      type: Boolean,
      default: true
    },
    payload: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      isFocusedFavorite: false,
      isFocusedCompare: false
    };
  },
  computed: {
    ...mapState('favorites', {
      favoriteList: 'favoriteList'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    isFavorite() {
      const id = this.payload && this.payload.id;
      if (!id) return false;
      if (this.favoriteList && this.favoriteList.length) {
        return this.favoriteList.some(f => f.id === id);
      }
      return false;
    },
    isCompare() {
      const id = this.payload && this.payload.id;
      if (!id) return false;
      if (this.compareList && this.compareList.length) {
        return this.compareList.some(_id => _id === id);
      }
      return false;
    }
  },
  methods: {
    print() {
      const selector = '.vms__print';
      const options = {
        importStyle: true
      };
      printHtmlBlock(selector, options);
    },
    toggleFavorite() {
      this.$store.dispatch('favorites/toggleFavorite', this.payload);
    },
    toggleCompare() {
      this.$store.dispatch('compares/toggleCompare', this.payload);
    },
    onFocusedFavorite() {
      let timerFavorite = 0;
      window.clearTimeout(timerFavorite);
      if (!this.isFocusedFavorit) {
        this.isFocusedFavorite = true;
        timerFavorite = setTimeout(() => {
          this.isFocusedFavorite = false;
        }, 1000);
      }
    },
    onFocusedCompare() {
      let timerCompare = 0;
      window.clearTimeout(timerCompare);
      if (!this.isFocusedCompare) {
        this.isFocusedCompare = true;
        timerCompare = setTimeout(() => {
          this.isFocusedCompare = false;
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__control-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &_item {
    width: auto;
    height: 44px;
    max-height: 44px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    padding: 10px 11px;
    background-color: #fff;
    cursor: pointer;
    color: #000;
    line-height: 1.33;
    font-size: 1.125rem;
    z-index: 10;
    text-decoration: none;
    overflow: hidden;
    border-radius: 3px;

    .mdi {
      font-size: 1.375rem;
      transition: all 0.2s ease;
      pointer-events: none;
    }

    &-p {
      width: 0;
      overflow: hidden;
      transition: all 0.2s ease;
      pointer-events: none;
    }

    &-links {
      width: 0;
      display: flex;
      position: absolute;
      right: 100%;
      top: 0;
      transition: all 0.2s ease;
      overflow: hidden;
      z-index: -1;
      margin-right: 1px;

      >div {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 1px;
        transition: all 0.2s ease;

        &:hover {
          width: 48px;
          height: 48px;
          margin-top: -2px;

          .mdi {
            font-size: 1.575rem;
          }
        }
      }

      &-whatsapp {
        background-color: #20c15c;
      }

      &-facebook {
        background-color: #3b5998;
      }

      &-mail {
        background-color: #2d9c90;
      }
    }

    &--active {
      background-color: #2d9c90;
      color: #fff;
    }

    &--focus {
      .vms__control-buttons_item-p {
        width: auto;
        padding-left: 11px;
      }

      .vms__control-buttons_item-links {
        width: auto;
        overflow: initial;
      }
    }
  }
}

@mixin media-768() {
  &_item {
    max-height: 39px;
    padding: 8px;
    height: auto;
  }

  &_print {
    display: none;
  }
}

.media-768 .vms__control-buttons {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__control-buttons {
    @include media-768();
  }
}
</style>
