var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agile",class:{
    'agile--auto-play': _vm.settings.autoplay,
    'agile--disabled': _vm.settings.unagile,
    'agile--fade': _vm.settings.fade && !_vm.settings.unagile,
    'agile--rtl': _vm.settings.rtl
  }},[_c('div',{ref:"list",staticClass:"agile__list"},[_c('div',{ref:"track",staticClass:"agile__track",style:({
        transform: ("translate(" + (_vm.translateX + _vm.marginX) + "px)"),
        transition: ("transform " + (_vm.settings.timing) + " " + _vm.transitionDelay + "ms")
      }),on:{"mouseover":function($event){return _vm.handleMouseOver('track')},"mouseout":function($event){return _vm.handleMouseOut('track')}}},[(_vm.clonedSlides)?_c('div',{ref:"slidesClonedBefore",staticClass:"agile__slides agile__slides--cloned"},[_vm._t("default")],2):_vm._e(),_c('div',{ref:"slides",staticClass:"agile__slides agile__slides--regular"},[_vm._t("default")],2),(_vm.clonedSlides)?_c('div',{ref:"slidesClonedAfter",staticClass:"agile__slides agile__slides--cloned"},[_vm._t("default")],2):_vm._e()])]),(!_vm.settings.unagile && (_vm.settings.navButtons || _vm.settings.dots))?_c('div',{staticClass:"agile__actions"},[(_vm.settings.navButtons && !_vm.settings.unagile)?_c('div',{ref:"prevButton",staticClass:"agile__nav-button agile__nav-button--prev",attrs:{"disabled":!_vm.canGoToPrev},on:{"click":function($event){_vm.goToPrev(), _vm.restartAutoPlay()}}},[_vm._t("prevButton",[_vm._v("←")])],2):_vm._e(),(_vm.settings.dots && !_vm.settings.unagile)?_c('div',{ref:"dots",staticClass:"agile__dots"},_vm._l((_vm.slidesCount),function(n){return _c('div',{key:n,staticClass:"agile__dot",class:{ 'agile__dot--current': n - 1 === _vm.currentSlide },on:{"mouseover":function($event){return _vm.handleMouseOver('dot')},"mouseout":function($event){return _vm.handleMouseOut('dot')}}},[_c('div',{staticClass:"agile__dot-btn",on:{"click":function($event){_vm.goTo(n - 1), _vm.restartAutoPlay()}}},[_vm._v(_vm._s(n))])])}),0):_vm._e(),(_vm.settings.navButtons && !_vm.settings.unagile)?_c('div',{ref:"nextButton",staticClass:"agile__nav-button agile__nav-button--next",attrs:{"disabled":!_vm.canGoToNext},on:{"click":function($event){_vm.goToNext(), _vm.restartAutoPlay()}}},[_vm._t("nextButton",[_vm._v("→")])],2):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }