<template>
  <div class="vms__ui-textarea-wrapper">
    <textarea
      class="vms__ui-textarea vms__OpenSansRegular"
      :class="{ 'vms__ui-textarea--error': error, 'vms__ui-textarea--error-indent': errorMessage }"
      :placeholder="placeholder"
      v-model="value"
    ></textarea>
    <div v-if="error" class="vms__ui-textarea-wrapper-span vms__OpenSansRegular">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UITextarea',
  props: {
    placeholder: {
      type: String,
      default: 'Placeholder *'
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: null
    };
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-textarea-wrapper {
  position: relative;

  &-span {
    position: absolute;
    bottom: -2px;
    left: 0;
    font-size: 0.675rem;
    color: #df4949;
  }

  .vms__ui-textarea {
    width: 100%;
    padding: 8px 17px !important;
    background-color: #fff !important;
    color: #000 !important;
    line-height: 1.5 !important;
    font-size: 1rem !important;
    border-radius: 3px !important;
    border: 1px solid #e2e2e2 !important;
    resize: vertical;

    &::placeholder {
      font-size: 1rem !important;
      font-family: OpenSansRegular, serif !important;
      color: #8e8e8e !important;
    }

    &--error {
      border-color: #df4949 !important;

      &-indent {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
