var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms__vehicles-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vms__vehicles-form_head"},[_c('div',{staticClass:"vms__vehicles-form_head-h3 vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.form.name'))+" ")])]),(!_vm.sended)?_c('div',{staticClass:"vms__vehicles-form_fields"},[_c('div',{staticClass:"vms__vehicles-form_fields-list"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('UIInput',{attrs:{"errorMessage":errors[0],"error":errors && errors.length !== 0,"placeholder":_vm.$t('vehicles.form.placeholderFirstName')},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('UIInput',{attrs:{"errorMessage":errors[0],"error":errors && errors.length !== 0,"placeholder":_vm.$t('vehicles.form.placeholderLastName')},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|phoneRule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('UIInput',{attrs:{"errorMessage":errors[0],"error":errors && errors.length !== 0,"placeholder":_vm.$t('vehicles.form.placeholderPhone')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('UIInput',{attrs:{"errorMessage":errors[0],"error":errors && errors.length !== 0,"typeInput":"email","placeholder":_vm.$t('vehicles.form.placeholderEmail')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('UITextarea',{attrs:{"errorMessage":errors[0],"error":errors && errors.length !== 0,"placeholder":_vm.$t('vehicles.form.placeholderText')},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)}}],null,true)})],1),_c('div',{staticClass:"vms__vehicles-form_fields-checkbox"},[_c('UICheckbox',{model:{value:(_vm.isAgree),callback:function ($$v) {_vm.isAgree=$$v},expression:"isAgree"}}),_c('div',{staticClass:"vms__vehicles-form_fields-checkbox-p vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.form.checkboxText'))+" ")])],1),_c('UIButton',{attrs:{"shadow":"","disabled":[_vm.isNotEmpty, invalid, !_vm.isAgree].some(function (s) { return (s === true ? true : false); })},on:{"click":_vm.sendForm}},[(!_vm.loading)?_c('UIIcon',{attrs:{"name":"mdi-message-outline","left":""}}):_c('UIIcon',{attrs:{"name":"mdi-loading mdi-spin mdi-spin-05","left":""}}),_vm._v(" "+_vm._s(_vm.$t('vehicles.form.btnSend'))+" ")],1)],1):_vm._e(),(_vm.sended)?_c('div',{staticClass:"vms__vehicles-form_success"},[_c('div',{staticClass:"mdi mdi-check-circle-outline"}),_c('div',{staticClass:"vms__vehicles-form_success-p vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.form.successText'))+" ")]),_c('UIButton',{attrs:{"shadow":""},on:{"click":_vm.toggleFrom}},[_c('UIIcon',{attrs:{"name":"mdi-close","left":""}}),_vm._v(" "+_vm._s(_vm.$t('vehicle.modals.request.close'))+" ")],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }