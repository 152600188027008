import { ALERTS_NEW_SUCCESS, ALERTS_CLEAR } from '@/store/modules/alerts/types';

export default {
  showAlerts({ commit }, payload) {
    commit(ALERTS_NEW_SUCCESS, payload);
    setTimeout(() => {
      commit(ALERTS_CLEAR, payload.dateNow);
    }, payload.lifeTime);
  }
};
