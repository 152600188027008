<template>
  <div class="vms__ui-slider" :class="{ 'vms__ui-slider--disabled': disabled }">
    <div class="vms__ui-slider_info">
      <div class="vms__ui-slider_info_title vms__OpenSansRegular">{{ title }}</div>
      <div class="vms__ui-slider_info-p3 vms__OpenSansRegular">
        <div v-if="value">
          {{ resultValue }}
        </div>
      </div>
    </div>
    <div ref="vms__slider" :disabled="disabled"></div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider';
import mixins from '@/mixins';

export default {
  name: 'UISliderRange',
  mixins,
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    startText: {
      type: String,
      default: ''
    },
    endText: {
      type: String,
      default: ''
    },
    valueSeparator: {
      type: String,
      default: ' - '
    },
    fromKey: {
      type: String,
      default: 'from'
    },
    toKey: {
      type: String,
      default: 'to'
    },
    start: {
      type: Array,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    interval: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      oldValue: null,
      value: null,
      slider: null,
      sliderOptions: {
        start: 0
      }
    };
  },
  computed: {
    resultValue() {
      const v1 = this.MixinFormatThousands(this.value[0], this.format);
      const v2 = this.MixinFormatThousands(this.value[1], this.format);
      return `${this.startText}${v1}${this.valueSeparator}${v2}${this.endText}`;
    }
  },
  mounted() {
    this.initSlider();
    this.oldValue = this.value;
  },
  methods: {
    initSlider() {
      this.slider = this.$refs.vms__slider;
      noUiSlider.create(this.slider, {
        start: this.setUpPosition(),
        connect: true,
        step: this.$props.interval,
        format: {
          from: value => value,
          to: value => Math.round(value)
        },
        range: {
          min: this.$props.min,
          max: this.$props.max || 1
        }
      });
      this.slider.noUiSlider.on('update', newValue => {
        this.value = newValue;
      });
      this.slider.noUiSlider.on('set', () => {
        this.changeValue();
      });
    },
    changeValue() {
      if (!this.$props.disabled && this.value !== this.oldValue) {
        if (this.value[0] !== this.oldValue[0]) {
          const key = this.fromKey;
          const result = {};
          result[key] = this.value[0];
          this.$emit('onChange', result);
        } else if (this.value[1] !== this.oldValue[1]) {
          const key = this.toKey;
          const result = {};
          result[key] = this.value[1];
          this.$emit('onChange', result);
        }
        this.oldValue = this.value;
      }
    },
    setUpPosition() {
      return [this.$props.start[0] || this.$props.min, this.$props.start[1] || this.$props.max];
    },
    reset() {
      this.slider.noUiSlider.set([0, this.$props.max]);
    }
  }
};
</script>

<style lang="scss">
@import '~nouislider/distribute/nouislider.min.css';

.vms__ui-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--disabled {
    opacity: 0.5;

    * {
      cursor: not-allowed !important;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &_title,
    &-p1,
    &-p2,
    &-p3,
    &-p4 {
      margin-right: 10px;
      line-height: 1.33;
      font-size: 1.125rem;
      color: inherit;
    }

    &-p2 {
      display: flex;

      > div {
        margin-left: 6px;
      }
    }
  }

  .noUi-horizontal {
    height: 26px;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
  }

  .noUi-base {
    padding-top: 12px;
  }

  .noUi-connects {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .noUi-connect {
    background-color: #fff;
  }

  .noUi-handle {
    width: 20px;
    height: 20px;
    top: 2px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    background-color: #2d9c90;
    box-shadow: none;
    border: 2px solid #fff;

    &::before {
      content: '';
      display: none;
    }

    &::after {
      content: '';
      display: none;
    }
  }
}
</style>
