import {
  VEHICLES_FILTERED_LIST_REQUEST,
  VEHICLES_FILTERED_LIST_SUCCESS,
  VEHICLES_FILTERED_LIST_ERROR,
  VEHICLES_BEST_DEALS_REQUEST,
  VEHICLES_BEST_DEALS_SUCCESS,
  VEHICLES_BEST_DEALS_ERROR,
  VEHICLES_BY_IDS_REQUEST,
  VEHICLES_BY_IDS_SUCCESS,
  VEHICLES_BY_IDS_ERROR,
  VEHICLES_ITEM_REQUEST,
  VEHICLES_ITEM_SUCCESS,
  VEHICLES_ITEM_ERROR,
  VEHICLES_VIEW_TYPE_SUCCESS
} from '@/store/modules/vehicles/types';
import helpers from '@/helpers';
import httpClient from '@/axios';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  getVehiclesByFilters({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    const data = {
      ...params,
      showroomUid: params.showroomUid,
      culture
    };

    return new Promise((resolve, reject) => {
      commit(VEHICLES_FILTERED_LIST_REQUEST);
      httpClient
        .post(
          `/api/v2/ShowroomV2/public/${params.showroomUid}/vehicles/filtered?culture=${culture}`,
          data,
          headers
        )
        .then(resp => {
          commit(VEHICLES_FILTERED_LIST_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit(VEHICLES_FILTERED_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  getVehicle({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    const data = {
      showroomUid: params.showroomUid,
      culture
    };
    return new Promise((resolve, reject) => {
      commit(VEHICLES_ITEM_REQUEST);
      httpClient
        .get(
          `/api/v2/ShowroomV2/public/${data.showroomUid}/vehicles/${params.id}?culture=${culture}`,
          data,
          headers
        )
        .then(resp => {
          commit(VEHICLES_ITEM_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit(VEHICLES_ITEM_ERROR, err);
          reject(err);
        });
    });
  },
  getVehiclesByIds({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(VEHICLES_BY_IDS_REQUEST);
      const data = {
        showroomUid: params.showroomUid,
        culture,
        vehicleIds: params.vehicleIds,
        pageSize: params.PageSize
      };
      httpClient
        .post(
          `/api/v2/ShowroomV2/public/${data.showroomUid}/vehicles/byIds?culture=${culture}`,
          data,
          headers
        )
        .then(resp => {
          commit(VEHICLES_BY_IDS_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit(VEHICLES_BY_IDS_ERROR, err);
          reject(err);
        });
    });
  },
  getVehicleIdByRef(_, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      httpClient
        .get(
          `/api/v2/ShowroomV2/public/${params.showroomUid}/vehicles/byRef/${params.importedId}?culture=${culture}`,
          headers
        )
        .then(resp => {
          resolve(resp.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getBestDeals({ commit, getters: { bestDealsLoaded } }, params) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    const data = {
      ...params,
      showroomUid: params.showroomUid,
      pluginConfig: params.pluginConfig
    };
    if(bestDealsLoaded){
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      commit(VEHICLES_BEST_DEALS_REQUEST);
      httpClient
        .post(
          `/api/v2/ShowroomV2/public/${
            params.showroomUid
          }/vehicles/best-deals?${helpers.generateGetParams(params)}`,
          data,
          headers
        )
        .then(resp => {
          commit(VEHICLES_BEST_DEALS_SUCCESS, resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit(VEHICLES_BEST_DEALS_ERROR, err);
          reject(err);
        });
    });
  },
  setVehiclesViewType({ commit }, isGrid) {
    commit(VEHICLES_VIEW_TYPE_SUCCESS, isGrid);
  }
};
