<template>
  <div class="vms__ui-input-search_wrapper">
    <div v-if="isError" class="vms__ui-input-search_wrapper-span vms__OpenSansRegular">
      {{ $t('inputs.requiredError') }}
    </div>
    <input
      id="ui-input-search"
      class="vms__ui-input-search vms__OpenSansRegular"
      :class="{ 'vms__ui-input-search--error': isError }"
      :type="typeInput"
      :placeholder="placeholder"
      v-model="value"
      @change="onChange"
    />
    <div class="mdi mdi-magnify vms__ui-input-search-magnify" />
  </div>
</template>

<script>
import mixins from '@/mixins';

export default {
  name: 'UIInputSearch',
  mixins,
  props: {
    placeholder: {
      type: String,
      default: 'Placeholder *'
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isError() {
      return this.$props.error;
    }
  },
  data() {
    return {
      value: null
    };
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    }
  },
  methods: {
    onChange(e) {
      setTimeout(() => {
        this.$emit('input', e.target.value);
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-input-search_wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;

  &-span {
    position: absolute;
    top: -17px;
    left: 0;
    font-size: 0.675rem;
    color: #df4949;
  }

  .vms__ui-input-search {
    padding: 8px 17px !important;
    background-color: #fff !important;
    color: #000 !important;
    line-height: 1.5 !important;
    font-size: 1rem !important;
    border: 1px solid #cacaca !important;

    &::placeholder {
      font-size: 1rem !important;
      font-family: OpenSansRegular, serif !important;
      color: #9e9e9e !important;
    }

    &--error {
      border-color: #df4949 !important;
    }

    &-magnify {
      position: absolute;
      top: 11px;
      right: 17px;
      font-size: 1.345rem;
      color: #9e9e9e;
    }
  }
}
</style>
