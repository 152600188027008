var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mobMenuOpened)?_c('div',{staticClass:"vms__navigate-panel-wrap",class:{
    'vms__navigate-panel-wrap--width-fixed': _vm.typeWidth,
    'vms__navigate-panel-wrap--height-fixed': _vm.validHeight
  }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
        'media-1200': function (el) { return el.width < 1200; },
        'media-768': function (el) { return el.width < 768; },
        'media-480': function (el) { return el.width < 480; }
      }),expression:"{\n        'media-1200': el => el.width < 1200,\n        'media-768': el => el.width < 768,\n        'media-480': el => el.width < 480\n      }"}],staticClass:"vms__navigate-panel vms__container"},[_c('div',{staticClass:"vms__navigate-panel_overlay",on:{"click":_vm.closeMobMenu}}),_c('div',{staticClass:"vms__navigate-panel_content",class:{ 'vms__navigate-panel_content--closed': !_vm.mobMenuContentOpened },style:({ top: _vm.top })},[_c('div',{staticClass:"vms__navigate-panel_content--left"},[_c('UIBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_c('div',{staticClass:"vms__navigate-panel_content--right"},[(_vm.isPagination)?_c('UIPaginationBtn'):_vm._e(),_c('div',{staticClass:"vms__navigate-panel_links"},[_c('UIButton',{staticClass:"vms__navigate-panel_bookmarked-link",attrs:{"link":"","to":"/vehicles/bookmarked","disabled":_vm.favoriteslength === 0 || typeof _vm.favoriteslength !== 'number',"data-cy":"btns__bookmarked"}},[_c('UIIcon',{attrs:{"name":"mdi-star-circle-outline","left":""}}),_vm._v(" "+_vm._s(_vm.$t('navigatePanel.bookmarked'))+" ("+_vm._s(_vm.favoriteslength)+") ")],1),_c('UIButton',{staticClass:"vms__navigate-panel_compares-link",attrs:{"disabled":_vm.compareslength === 0 || typeof _vm.compareslength !== 'number',"link":"","to":"/vehicles/compares","label":_vm.$t('navigatePanel.compare'),"data-cy":"btns__compares"}},[_c('UIIcon',{attrs:{"name":"mdi-car-multiple","left":""}}),_vm._v(" "+_vm._s(_vm.$t('navigatePanel.compare'))+" ("+_vm._s(_vm.compareslength)+") ")],1)],1),_c('LangSwitcher'),(_vm.showroom && _vm.showroom.customerType === 'b2B')?_c('UIButton',{staticClass:"vms__navigate-panel_logout",on:{"click":_vm.logout}},[_c('UIIcon',{attrs:{"name":"mdi-logout","left":""}}),_vm._v(" "+_vm._s(_vm.$t('navigatePanel.logout'))+" ")],1):_vm._e()],1)])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }