import dayjs from 'dayjs';
import 'dayjs/locale/de';

const mixins = {
  methods: {
    MixinGetContainerHeight(height) {
      if (height) {
        if (height === 'full') {
          const scrollbar = document.getElementById('PageScrollbar');
          if (scrollbar) {
            scrollbar.style.height = '100%';
            return '100%';
          }
          return 'auto';
        }
        return height;
      }
      return 'auto';
    },
    MixinGetContainerWidth(width) {
      if (width) {
        return width;
      }
      return '100%';
    },
    MixinRemoveFalsy(obj) {
      const newObj = {};
      Object.keys(obj).forEach(prop => {
        if (obj[prop] !== '' && obj[prop] !== null) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },
    MixinCheckValidValues(array) {
      return array.some(value => value !== null && value !== undefined);
    },
    MixinFormatThousands(value, format = true) {
      if (format) {
        return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      }
      return value;
    },
    MixinFormatThousandsPrice(price, culture) {
      try {
        return Number(price).toLocaleString(culture.key);
      } catch {
        return Number(price);
      }
    },
    MixinShowAlert(data) {
      this.$store.dispatch('alerts/showAlerts', { ...data });
    },
    MixinCreateSelectData(data) {
      return (
        data &&
        data.map(v => ({
          name: v,
          option: v
        }))
      );
    },
    MixinGetOffset(element, horizontal = false) {
      // NOTE: https://medium.com/@alexcambose/js-offsettop-property-is-not-great-and-here-is-why-b79842ef7582
      if (!element) return 0;
      return (
        this.MixinGetOffset(element.offsetParent, horizontal) +
        (horizontal ? element.offsetLeft : element.offsetTop)
      );
    },
    MixinOpenLinkNewTab(link) {
      window.open(link);
    },
    MixinOpenLinkThisTab(link) {
      window.location.href = link;
    },
    MixinFormatDate(date, format) {
      if (!date) return date;
      if (!dayjs) return date;
      const result = dayjs(date);
      if (result.isValid()) {
        const culture = sessionStorage.getItem('vms__culture').split('-')[0];
        if (culture) {
          return result.locale(culture).format(format);
        }
        return result.format(format);
      }
      return this.$i18n.t('common.dateNew');
    },
    MixinCreateAutoUncleApiUrl(url, uid) {
      if (!url) return null;
      if (!uid) return null;
      return `${url.replace(/\/$/g, '')}/#/vehicles/${uid}`;
    }
  }
};

export default [mixins];
