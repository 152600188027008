import {
  COMPARES_UPDATE_REQUEST,
  COMPARES_UPDATE_SUCCESS,
  COMPARES_UPDATE_ERROR,
  COMPARES_ADD_REQUEST,
  COMPARES_ADD_SUCCESS,
  COMPARES_ADD_ERROR
} from '@/store/modules/compares/types';
import LocalStorageService from '@/services/localStorageService';
import i18n from '@/locales';

const localStorageService = LocalStorageService.installService();

export default {
  toggleCompare({ commit, dispatch, state: { compareList } }, vehicle) {
    if (compareList && compareList.length >= 3 && compareList.every(x => x !== vehicle.id)) {
      const error = {
        show: true,
        text: `Error: ${i18n.t('alerts.compareMaxItemsError')}`,
        isError: true,
        lifeTime: 3000,
        dateNow: Date.now()
      };
      dispatch('alerts/showAlerts', error, { root: true });
      return Promise.reject();
    }

    commit(COMPARES_ADD_REQUEST);
    return new Promise((resolve, reject) => {
      if (vehicle) {
        const compare = localStorageService.updateListValues('vms__compares-list', vehicle.id);
        commit(COMPARES_ADD_SUCCESS, compare);
        resolve(compare);
      } else {
        commit(COMPARES_ADD_ERROR);
        reject(new Error('Parameters not passed'));
      }
    });
  },
  updateCompares({ commit }) {
    commit(COMPARES_UPDATE_REQUEST);
    const compares = localStorageService.getValueByName('vms__compares-list');
    try {
      commit(COMPARES_UPDATE_SUCCESS, JSON.parse(compares));
    } catch (error) {
      commit(COMPARES_UPDATE_ERROR, error);
    }
  }
};
