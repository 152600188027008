<template>
  <div v-responsive="{ 'media-768': el => el.width < 768, 'media-576': el => el.width < 576 }">
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__vehicles-bookmarked">
        <NavigatePanel
          :breadcrumbs="crumbs"
          :isKfzWhite="true"
          :backspaceLabel="$t('vehicles.navigatePanel.buttonBackspace')"
        />
        <div class="vms__container">
          <div
            v-if="
              showroom &&
              showroom.showListSwitch &&
              showroom.showGallerySwitch &&
              resultVehicles &&
              resultVehicles.length
            "
            class="vms__vehicles-bookmarked_head"
          >
            <div class="sec-title-h1 vms__vehicles-bookmarked_head-title" data-cy="info__vehicles-amount">
              {{ $t('favorites.listTitle') }} {{ resultVehicles && resultVehicles.length }}
            </div>
            <UICardSwitcher class="ml-4" :isGrid="listOptions.ga" @on-change="changeTypeCards" />
          </div>
          <VehiclesListBookmarked
            v-if="resultVehicles"
            :list="resultVehicles"
            :vehiclesOnThePage="initOptions.rpp"
          />
          <div
            v-if="!resultVehicles || !resultVehicles.length"
            class="vms__vehicles-bookmarked--empty"
          >
            {{ $t('favorites.listEmpty') }}
          </div>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import PageContainer from '@/containers/PageContainer';
import VehiclesListBookmarked from '@/components/VehiclesListBookmarked';
import NavigatePanel from '@/components/NavigatePanel';
import UIBurger from '@/components/ui/UIBurger';
import UICardSwitcher from '@/components/ui/UICardSwitcher';
import Responsive from '@/directives/Responsive';

export default {
  name: 'VehiclesBookmarked',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    PageContainer,
    VehiclesListBookmarked,
    NavigatePanel,
    UIBurger,
    UICardSwitcher
  },
  data() {
    return {
      filterParams: null,
      dataIsReady: false,
      resultVehicles: []
    };
  },
  beforeMount() {
    EventBus.$on('lang-changed', this.getVehiclesByIds);
  },
  beforeDestroy() {
    EventBus.$off('lang-changed', this.getVehiclesByIds);
  },
  computed: {
    ...mapState('vehicles', {
      statusVehiclesByIds: 'statusVehiclesByIds',
      vehiclesByIds: 'vehiclesByIds'
    }),
    ...mapState('favorites', {
      statusFavorites: 'statusFavorites',
      favoriteList: 'favoriteList'
    }),
    ...mapState('showrooms', {
      filters: 'filters',
      showroom: 'showroom'
    }),
    ...mapState('options', {
      statusOptions: 'statusOptions',
      listOptions: 'listOptions',
      initOptions: 'initOptions'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    crumbs() {
      return [
        { link: '/vehicles', title: this.$t('breadcrumbs.vehicles') },
        { link: '/vehicles/bookmarked', title: this.$t('breadcrumbs.favorite') }
      ];
    }
  },
  created() {
    this.$store.dispatch('options/updateResultsPerPage', this.listOptions.rpp);
    if (this.statusFavorites) {
      this.updateFavorites();
      this.getVehiclesByIds();
    }
    document.title = this.showroom.instanceName || 'Vms';

    if (window.auWidgetEmbed) {
      window.auWidgetEmbed.init();
    }
  },
  watch: {
    statusFavorites(status) {
      if (status) {
        this.getVehiclesByIds();
      }
    },
    statusVehiclesByIds(loaded) {
      if (loaded) {
        this.formatResultVehicles();
        setTimeout(() => {
          this.dataIsReady = true;
          if (window.auWidgetEmbed) {
            window.auWidgetEmbed.init();
          }
        }, 500);
      }
    },
    favoriteList(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.resultVehicles = newVal;
        this.formatResultVehicles();
      }
    }
  },
  methods: {
    ...mapActions('favorites', ['updateFavorites']),
    changeTypeCards(isGrid) {
      if (isGrid) {
        this.$store.dispatch('options/updateVehicleCardType', true);
      } else {
        this.$store.dispatch('options/updateVehicleCardType', false);
      }
    },
    getVehiclesByIds() {
      this.filterParams = {
        ...this.listParams,
        PageSize: 1000,
        vehicleIds: this.favoriteList ? this.favoriteList.map(vehicle => vehicle.id) : []
      };
      this.$store.dispatch('vehicles/getVehiclesByIds', this.filterParams);
    },
    formatResultVehicles() {
      this.resultVehicles = this.favoriteList.map(f => {
        if (
          this.vehiclesByIds &&
          this.vehiclesByIds.items &&
          this.vehiclesByIds.items.some(i => i.id === f.id)
        ) {
          return {
            ...this.vehiclesByIds.items.find(i => i.id === f.id),
            deactivated: false
          };
        }
        return {
          ...f,
          deactivated: true
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-bookmarked {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  background-color: #fff;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &--empty {
    text-align: center;
    margin: 50px 0;
    font-size: 1.625rem;
    line-height: 1.15;
    letter-spacing: -0.04px;
    color: #000;
  }
}

/deep/ .vms__vehicles_item--deactivated .vms__control-buttons_compare {
  display: none;
}

@mixin media-768() {
  &_head {
    margin-top: 60px;

    &-title {
      font-size: 1.375rem;
    }
  }

  /deep/ .vms__vehicles-list {
    padding-top: 40px;
  }
}

@mixin media-576() {
  /deep/ .vms__ui-card-switcher {
    display: none;
  }
}

.media-768 .vms__vehicles-bookmarked {
  @include media-768();
}

.media-576 .vms__vehicles-bookmarked {
  @include media-576();
}

@media screen and (max-width: 768px) {
  .vms__vehicles-bookmarked {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__vehicles-bookmarked {
    @include media-576();
  }
}
</style>
