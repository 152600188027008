// import i18n from '@/locales/index';

export const phone = {
  getMessage() {
    return 'test'; // i18n.t('errors.invalidPhone');
  },
  validate(value) {
    const MOBILEREG = /^\+{0,1}[0-9]{5,}$/;
    return MOBILEREG.test(value);
  }
};

export default {
  phone
};
