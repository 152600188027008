<template>
  <div
    class="vms__container vms__vehicles-detail_equipments_wrapper"
    :class="{
      'vms__autoleasenCalculator-available': autoleasenCalculator && autoleasenCalculator !== ''
    }"
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768,
      'media-576': el => el.width < 576
    }"
  >
    <div class="vms__vehicles-detail_equipments_content" >
      <div class="vms__vehicles-detail_equipments" v-if="equipments && Object.values(equipments).length">
        <div class="vms__vehicles-detail_equipments_title vms__BarlowSemiCondensedRegular">
          {{ $t('vehicle.services.title') }}
        </div>
        <MaxHeightEquipments data-cy="info__equipments-extras">
          <div class="vms__vehicles-detail_equipments-list">
            <div
              class="vms__OpenSansRegular"
              v-for="(equipment, i) of Object.values(equipments)"
              :key="i"
            >
              <div class="mdi mdi-check" />
              <div class="vms__vehicles-detail_equipments-list-span">{{ equipment }}</div>
            </div>
          </div>
        </MaxHeightEquipments>
      </div>

      <div
        class="vms__vehicles-detail_other-equipments"
        v-if="otherEquipments && otherEquipments !== ''"
      >
        <div class="vms__vehicles-detail_other-equipments_title vms__BarlowSemiCondensedRegular">
          {{ $t('vehicle.info.title') }}
        </div>
        <MaxHeightEquipments>
          <div
            class="vms__vehicles-detail_other-equipments_p vms__OpenSansRegular"
            v-html="otherEquipments"
            data-cy="info__additional-info"
          ></div>
        </MaxHeightEquipments>
      </div>
    </div>

    <div
      class="vms__autoleasenCalculator"
      id="autoleasen-calc"
      v-if="autoleasenCalculator && autoleasenCalculator !== ''"
    ></div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import MaxHeightEquipments from '@/containers/MaxHeightEquipments';
import nodeScriptReplace from '@/helpers/thirdPartyHtmlHelper';

export default {
  name: 'Equipments',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    MaxHeightEquipments
  },
  watch: {
    autoleasenCalculator() {
      if (this.autoleasenCalculator) {
        this.$nextTick(async () => {
          const autoleasenWrapper = document.getElementById('autoleasen-calc');
          autoleasenWrapper.innerHTML = this.autoleasenCalculator;
          await nodeScriptReplace(autoleasenWrapper);
        });
      }
    }
  },
  props: {
    equipments: {
      type: Object,
      default: () => {}
    },
    otherEquipments: {
      type: String,
      default: null
    },
    autoleasenCalculator: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-detail {
  &_equipments {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #cacaca;
    padding-left: 20px;
    margin-top: 70px;

    &_wrapper {
      display: flex;

      &.vms__autoleasenCalculator-available {
        .vms__vehicles-detail_equipments-list > div {
          width: 48%;
        }
      }
    }
    &_content {
      width: 100%;
    }

    &_title {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;

      > div {
        width: 32%;
        display: flex;
        align-items: flex-start;
        font-size: 1rem;
        line-height: 1.88;
        color: #000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .mdi {
          font-size: 1.188rem;
          margin-right: 12px;
          margin-top: 6px;
        }
      }

      &-span {
        white-space: normal;
      }
    }
  }

  &_other-equipments {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #cacaca;
    padding-left: 20px;
    margin-top: 77px;

    &_title {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    &_p {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1.88;
      color: #000;
    }
  }

  .vms__autoleasenCalculator {
    width: 30%;
    min-width: 30%;
    margin-top: 70px;

    .container {
      width: 100%;

      .offer {
        padding-left: 10px;

        h3 {
          margin-left: 5px;
        }

        .offer-definition {
          width: auto;
          margin: 10px;
        }

        .side-note-wrapper {
          padding-left: 0;
        }
      }
    }
  }
}

@mixin media-1200() {
  &_equipments_wrapper {
    flex-direction: column;

    .vms__autoleasenCalculator {
      width: 100%;
      margin-top: 60px;
    }
  }
}

@mixin media-992() {
  &_equipments-list {
    > div {
      width: 48%;
    }
  }
}

@mixin media-768() {
  &_equipments-list {
    flex-direction: column;

    > div {
      width: 100%;
      font-size: 0.875rem;
    }
  }
}

@mixin media-576() {
  &_equipments {
    margin-top: 40px;
  }
}

.media-1200 .vms__vehicles-detail {
  @include media-992();
}

.media-992 .vms__vehicles-detail {
  @include media-992();
}

.media-768 .vms__vehicles-detail {
  @include media-768();
}

.media-576 .vms__vehicles-detail {
  @include media-576();
}

@media screen and (max-width: 1200px) {
  .vms__vehicles-detail {
    @include media-1200();
  }
}

@media screen and (max-width: 992px) {
  .vms__vehicles-detail {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__vehicles-detail {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__vehicles-detail {
    @include media-576();
  }
}

@media print {
  .vms__vehicles-detail {
    &_equipments {
      padding-left: 0;
      border: 0;
      margin-top: 40px;
    }
  }
}
</style>
