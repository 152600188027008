import {
  CULTURE_CHANGE_REQUEST,
  CULTURE_CHANGE_SUCCESS,
  CULTURE_CHANGE_ERROR,
  CULTURE_TRANSLATIONS_REQUEST,
  CULTURE_TRANSLATIONS_SUCCESS,
  CULTURE_TRANSLATIONS_ERROR
} from '@/store/modules/cultures/types';
import i18n from '@/locales';
import configs from '@/configs';
import httpClient from '@/axios';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  changeCulture({ commit }, lang) {
    return new Promise((resolve, reject) => {
      commit(CULTURE_CHANGE_REQUEST);
      if (lang) {
        commit(CULTURE_CHANGE_SUCCESS, lang);
        resolve(lang);
      } else {
        commit(CULTURE_CHANGE_ERROR);
        reject(new Error('Parameters not passed'));
      }
    });
  },
  getCultureTranslations({ commit }, culture) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(CULTURE_TRANSLATIONS_REQUEST);
      const url = `${configs.API_URL}/api/v1/CultureSections/json?name=${configs.CULTURE_SECTION}&culture=${culture}`;
      httpClient
        .get(url, headers)
        .then(resp => {
          commit(CULTURE_TRANSLATIONS_SUCCESS);
          if (configs.NODE_ENV !== 'development') {
            i18n.setLocaleMessage(culture.replace('-', ''), resp.data);
          }
          resolve(resp.data);
        })
        .catch(err => {
          commit(CULTURE_TRANSLATIONS_ERROR);
          reject(err);
        });
    });
  }
};
