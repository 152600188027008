import {
  COMPARES_ADD_REQUEST,
  COMPARES_ADD_SUCCESS,
  COMPARES_ADD_ERROR,
  COMPARES_UPDATE_REQUEST,
  COMPARES_UPDATE_SUCCESS,
  COMPARES_UPDATE_ERROR
} from '@/store/modules/compares/types';

export default {
  [COMPARES_ADD_REQUEST]: state => {
    state.statusCompares = false;
  },
  [COMPARES_ADD_SUCCESS]: (state, payload) => {
    state.statusCompares = true;
    state.compareList = payload;
  },
  [COMPARES_ADD_ERROR]: (state, payload) => {
    state.statusCompares = payload;
  },
  [COMPARES_UPDATE_REQUEST]: state => {
    state.statusCompares = false;
  },
  [COMPARES_UPDATE_SUCCESS]: (state, payload) => {
    state.statusCompares = true;
    state.compareList = payload;
  },
  [COMPARES_UPDATE_ERROR]: (state, payload) => {
    state.statusCompares = payload;
  }
};
