const defaultParams = (() => {
  let _service;

  function _installService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function convertToBoolean(param) {
    if (param === 'true' || param === true) {
      return true;
    }
    return false;
  }

  function _defaultResultPerPage() {
    if (process.env.VUE_APP_RESULT_PER_PAGE !== undefined) {
      return process.env.VUE_APP_RESULT_PER_PAGE;
    }
    return 10;
  }

  function _defaultGoogleMapsApiKey() {
    if (process.env.VUE_APP_GOOGLE_MAPS_API_KEY !== undefined) {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    }
    return null;
  }

  function _defaultIsGrid() {
    if (process.env.VUE_APP_SHOW_GALLERY_SWITCH === 'true') {
      return true;
    }
    if (
      process.env.VUE_APP_SHOW_GALLERY_SWITCH === 'false' &&
      process.env.VUE_APP_SHOW_LIST_SWITCH === 'true'
    ) {
      return false;
    }
    return true;
  }

  function _defaultShowSortList() {
    if (process.env.VUE_APP_SHOW_SORT_LIST !== undefined) {
      return convertToBoolean(process.env.VUE_APP_SHOW_SORT_LIST);
    }
    return true;
  }

  function _defaultShowCompare() {
    if (process.env.VUE_APP_SHOW_COMPARE !== undefined) {
      return convertToBoolean(process.env.VUE_APP_SHOW_COMPARE);
    }
    return true;
  }

  function _defaultmaxMileage() {
    if (process.env.VUE_APP_MAX_MILAGE !== undefined) {
      return process.env.VUE_APP_MAX_MILAGE;
    }
    return 0;
  }

  function _defaultmaxMileageInterval() {
    if (process.env.VUE_APP_MAX_MILAGE_INTERVAL !== undefined) {
      return Number(process.env.VUE_APP_MAX_MILAGE_INTERVAL);
    }
    return 1;
  }

  function _defaultMinPrice() {
    if (process.env.VUE_APP_MIN_PRICE !== undefined) {
      return process.env.VUE_APP_MIN_PRICE;
    }
    return 0;
  }

  function _defaultMaxPrice() {
    if (process.env.VUE_APP_MAX_PRICE !== undefined) {
      return process.env.VUE_APP_MAX_PRICE;
    }
    return 0;
  }

  function _defaultMaxPriceInterval() {
    if (process.env.VUE_APP_MAX_PRICE_INTERVAL !== undefined) {
      return Number(process.env.VUE_APP_MAX_PRICE_INTERVAL);
    }
    return 1;
  }

  return {
    installService: _installService,
    defaultGoogleMapsApiKey: _defaultGoogleMapsApiKey,
    defaultResultPerPage: _defaultResultPerPage,
    defaultIsGrid: _defaultIsGrid,
    defaultShowSortList: _defaultShowSortList,
    defaultShowCompare: _defaultShowCompare,
    defaultmaxMileage: _defaultmaxMileage,
    defaultmaxMileageInterval: _defaultmaxMileageInterval,
    defaultMinPrice: _defaultMinPrice,
    defaultMaxPrice: _defaultMaxPrice,
    defaultMaxPriceInterval: _defaultMaxPriceInterval
  };
})();

export default defaultParams;
