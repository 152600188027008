import {
  CULTURE_CHANGE_REQUEST,
  CULTURE_CHANGE_SUCCESS,
  CULTURE_CHANGE_ERROR,
  CULTURE_TRANSLATIONS_REQUEST,
  CULTURE_TRANSLATIONS_SUCCESS,
  CULTURE_TRANSLATIONS_ERROR
} from '@/store/modules/cultures/types';

export default {
  [CULTURE_CHANGE_REQUEST]: state => {
    state.statusCulture = false;
  },
  [CULTURE_CHANGE_SUCCESS]: (state, lang) => {
    state.statusCulture = true;
    state.cultureSelected = lang;
  },
  [CULTURE_CHANGE_ERROR]: (state, payload) => {
    state.statusCulture = payload;
  },
  [CULTURE_TRANSLATIONS_REQUEST]: state => {
    state.statusTranslations = false;
  },
  [CULTURE_TRANSLATIONS_SUCCESS]: state => {
    state.statusTranslations = true;
  },
  [CULTURE_TRANSLATIONS_ERROR]: (state, payload) => {
    state.statusTranslations = payload;
  }
};
