<template>
  <div class="vms__filters" v-responsive="{
    'media-768': el => el.width < 768,
    'media-576': el => el.width < 576
  }">
    <div class="vms__container vms__filters-subtitle vms__BarlowSemiCondensedRegular"
      data-cy="filters__total_vehicles_amount">
      {{
          $t('search.filters.title', { carsCount: (filteredList && filteredList.totalCount) || 0 })
      }}
    </div>
    <BodyTypes @onSelect="setBodyType" @disableScroll="disableScroll" @enableScroll="enableScroll"
      ref="filterBodyTypes" />
    <PriceMileage ref="PriceMileage" :scrollbar="$props.scrollbar" @setParamForSlider="setParamForSlider" />
    <div class="vms__filters-selects">
      <div class="vms__container">
        <UISelect ref="filtersMakes" :multiple="true" @UISelect="selectMakes" :options="filters.makes"
          :selected="listParams.makeCodes" :label="$t('search.filters.selectLabel1')" :disable="
            (filters && filters.makes === null) ||
            (filters.makes && filters.makes.length === 0)
          " cy-name="brand" />
        <UISelect ref="filtersModels" :searchField="true" :multiple="true" @UISelect="selectModels"
          :options="filteredModels" :selected="listParams.modelCodes" categoryKey="makeName"
          :label="$t('search.filters.selectLabel2')" :loaded="updatedMakes" :disable="
            (filters && filteredModels === null) || (filteredModels && filteredModels.length === 0)
          " cy-name="model" />
        <UISliderRange ref="filtersFirstRegistr" :format="false"
          v-if="MixinCheckValidValues([filters.firstRegistrationYearsFull])" :min="firstRegisterMin"
          :max="firstRegisterMax" :start="[firstRegisterFrom, firstRegisterTo]" :interval="1"
          :title="$t('search.filters.selectLabel3')" fromKey="FirstRegistrationYearFrom" toKey="FirstRegistrationYearTo"
          :disabled="firstRegisterMin === firstRegisterMax - 1" @onChange="setParamForSlider"
          data-cy="filters__slider-initial-registration" />
        <UISelect ref="filtersFuels" @UISelect="selectFuels" :options="filters.fuels" :selected="listParams.fuelCodes"
          :multiple="true" :label="$t('search.filters.selectLabel9')" :disable="
            (filters && filters.fuels === null) ||
            (filters && filters.fuels && filters.fuels.length === 0)
          " cy-name="fuels" />
        <UISelect ref="filtersDrives" @UISelect="selectDrives" :options="filters.drives"
          :selected="listParams.driveCodes" :multiple="true" :label="$t('search.filters.selectLabelDrives')" :disable="
            (filters && filters.drives === null) ||
            (filters && filters.drives && filters.drives.length === 0)
          " cy-name="drives" />
        <UISelect ref="filtersGearboxes" @UISelect="selectGearboxes" :options="filters.gearboxes"
          :selected="listParams.gearboxCodes" :multiple="true" :label="$t('search.filters.selectLabel6')" :disable="
            (filters && filters.gearboxes === null) ||
            (filters && filters.gearboxes && filters.gearboxes.length === 0)
          " cy-name="gearboxes" />

      </div>
    </div>
    <div v-show="additionalField" class="vms_search-additional-fields">
      <div class="vms__filters-selects">
        <div class="vms__container">
          <UISelect :multiple="true" ref="filtersChasisTypes" @UISelect="selectChassisTypes"
            :options="filters.chassisTypes" :selected="listParams.chassisTypeCodes"
            :label="$t('search.filters.selectLabel8')" :visible="additionalField" :disable="
              (filters && filters.chassisTypes === null) ||
              (filters && filters.chassisTypes && filters.chassisTypes.length === 0)
            " cy-name="chassis-types" />
          <UIInput class="vms__free-text-filter" ref="filtersFreeText" :regular="true" @input="updateId"
            :isLoading="!statusFilters" :placeholder="$t('search.filters.selectLabel13')" data-cy="filters__input-id" />
          <UISelect v-if="filters && filters.dealerFilterAvailable" ref="filtersDealers" show-checkboxes
            @UISelect="selectDealers" :options="filters.dealers" :selected="listParams.dealerIds" :multiple="true"
            :label="$t('search.filters.selectLabel12')" :disable="
              (filters && filters.dealers === null) ||
              (filters && filters.dealers && filters.dealers.length === 0)
            " cy-name="dealers" />
        </div>
      </div>

      <Co2Consumption ref="Co2Consumption" :scrollbar="$props.scrollbar" @setParamForSlider="setParamForSlider" />
      <Colors ref="FilterColors" @createColorParams="createColorParams" @createMetallicParam="createMetallicParam"
        @reset="resetColors" />
      <div class="vms__filters_wrapp-line">
        <div class="vms__container">
          <div class="vms__filters_line"></div>
        </div>
      </div>
      <Equipments ref="FilterEquipments" :selected="listParams.equipmentCodes"
        @createEquipmentParams="createEquipmentParams" />
    </div>
    <div class="vms__filters-btns">
      <div class="vms__container">
        <UIButton shadow @click="toggleAdditionalField" class="vms__filters-btns_aditional-filters"
          data-cy="btns__more-filters">
          <UIIcon name="mdi-filter" left />
          {{ additionalField ? $t('search.filters.btns.btn1') : $t('search.filters.btns.btn2') }}
        </UIButton>
        <div class="vms__filters-reset" data-cy="btns__reset-filters" @click="resetFilters" v-WaveAnimation.dark="true">
          <div class="vms__filters-reset-span vms__OpenSansRegular">{{ $t('search.filters.reset') }}</div>
          <div class="mdi mdi-window-close" />
        </div>
        <UIButton shadow :disabled="filteredList && filteredList.totalCount === 0" @click="runSearch" link :to="
          filteredList && filteredList.totalCount === 1
            ? `/vehicles/${filteredList.items[0].id}`
            : '/vehicles'
        " background="#2d9c90" color="#fff" class="vms__filters-btns_find" data-cy="btns__show-results">
          <UIIcon name="mdi-flag-checkered" left />
          {{ $t('search.filters.btns.btn3', { results: filteredList && filteredList.totalCount }) }}
        </UIButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';
import mixins from '@/mixins';
import WaveAnimation from '@/directives/WaveAnimation';
import PriceMileage from '@/components/Filters/PriceMileage';
import BodyTypes from '@/components/Filters/BodyTypes';
import Co2Consumption from '@/components/Filters/Co2Consumption';
import Colors from '@/components/Filters/Colors';
import Equipments from '@/components/Filters/Equipments';
import UISliderRange from '@/components/ui/UISliderRange';
import UISelect from '@/components/ui/UISelect';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import UIInput from '@/components/ui/UIInput';
import debounce from '@/helpers/debounce';

export default {
  name: 'Search',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    UIInput,
    UISliderRange,
    UISelect,
    PriceMileage,
    BodyTypes,
    Co2Consumption,
    Colors,
    Equipments,
    UIButton,
    UIIcon
  },
  props: {
    params: {
      params: {
        type: Object,
        default: () => { }
      }
    },
    allFields: {
      type: Boolean,
      default: false
    },
    scrollbar: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      additionalField: this.$props.allFields,
      filterParams: {},
      initParams: _.omitBy(this.$root.$props, _.isNil),
      updatedMakes: true,
      isResetEvent: false,
      timer: null,
      updateId: debounce(id => {
        this.filterParams.freeText = id;
        this.createParams();
      }, 700)
    };
  },
  beforeMount() {
    EventBus.$on('lang-changed', this.createParams);
  },
  beforeDestroy() {
    EventBus.$off('lang-changed', this.createParams);
  },
  created() {
    if (this.statusOptions === true) {
      this.filterParams = {
        ...this.listParams
      };
      this.createParams();
    }
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('vehicles', {
      filteredList: 'filteredList'
    }),
    ...mapState('showrooms', {
      statusFilters: 'statusFilters',
      filters: 'filters'
    }),
    ...mapState('options', {
      statusOptions: 'statusOptions'
    }),
    firstRegisterMin() {
      if (this.initParams.firstRegistrationYearFrom) {
        return +this.initParams.firstRegistrationYearFrom;
      }
      if (
        this.filters &&
        this.filters.firstRegistrationYearsFull &&
        this.filters.firstRegistrationYearsFull.length
      ) {
        const years = this.filters.firstRegistrationYearsFull.map(y => y.name);
        return Math.min.apply(null, years);
      }
      return new Date().getFullYear() - 30;
    },
    firstRegisterMax() {
      if (this.listParams.firstRegistrationYearTo) {
        return +this.listParams.firstRegistrationYearTo;
      }
      if (
        this.filters &&
        this.filters.firstRegistrationYearsFull &&
        this.filters.firstRegistrationYearsFull.length
      ) {
        const years = this.filters.firstRegistrationYearsFull.map(y => y.name);
        if (this.filters.firstRegistrationYearsFull.length < 2) {
          return years[0] + 1;
        }
        return +Math.max.apply(null, years);
      }
      return new Date().getFullYear();
    },
    firstRegisterFrom() {
      if (
        this.initParams.firstRegistrationYearFrom &&
        this.initParams.firstRegistrationYearFrom === this.listParams.FirstRegistrationYearFrom
      ) {
        return +this.initParams.firstRegistrationYearFrom;
      }
      if (
        this.listParams.FirstRegistrationYearFrom &&
        this.listParams.FirstRegistrationYearFrom !== this.listParams.FirstRegistrationYearTo
      ) {
        return +this.listParams.FirstRegistrationYearFrom;
      }
      return 0;
    },
    firstRegisterTo() {
      if (
        this.initParams.firstRegistrationYearTo &&
        this.initParams.firstRegistrationYearTo === this.listParams.FirstRegistrationYearTo
      ) {
        return +this.initParams.firstRegistrationYearTo;
      }
      if (
        this.listParams.FirstRegistrationYearTo &&
        this.listParams.FirstRegistrationYearTo !== this.listParams.FirstRegistrationYearFrom
      ) {
        return +this.listParams.FirstRegistrationYearTo;
      }
      return 0;
    },
    filteredModels() {
      if (this.filterParams.makeCodes && this.filterParams.makeCodes.length) {
        return this.filters.models.filter(model =>
          this.filterParams.makeCodes.includes(model.makeCode)
        );
      }
      return this.filters.models;
    }
  },
  watch: {
    statusOptions(status) {
      if (status === true) {
        this.filterParams = {
          ...this.listParams
        };
        this.createParams();
      }
    },
    statusFilters(status) {
      if (status === true) {
        this.updatedMakes = true;
      }
    }
  },
  methods: {
    getVehiclesByFilters(params) {
      this.$store.dispatch('vehicles/getVehiclesByFilters', params);
    },
    toggleAdditionalField() {
      if (this.additionalField === true) {
        this.$emit('scrollToTop');
      }
      this.additionalField = !this.additionalField;
    },
    getShowroomsFilters(params) {
      this.$store.dispatch('showrooms/getShowroomsFilters', params);
    },
    createParams() {
      this.getShowroomsFilters(this.filterParams);
      this.getVehiclesByFilters(this.filterParams);
      this.runSearch();
    },
    selectMakes(make) {
      this.updatedMakes = false;
      if (this.isResetEvent === false) {
        this.filterParams.makeCodes = make.map(o => o.code);
        this.createParams();
      }
    },
    selectModels(model) {
      if (this.isResetEvent === false) {
        this.filterParams.modelCodes = model.map(o => o.key);
        this.createParams();
      }
    },
    selectFuels(fuel) {
      if (this.isResetEvent === false) {
        this.filterParams.fuelCodes = fuel.map(o => o.code);
        this.createParams();
      }
    },
    selectDrives(drive) {
      if (this.isResetEvent === false) {
        this.filterParams.driveCodes = drive.map(o => o.code);
        this.createParams();
      }
    },
    selectDealers(dealer) {
      if (this.isResetEvent === false) {
        this.filterParams.dealerIds = dealer.map(o => o.key);
        this.createParams();
      }
    },
    selectChassisTypes(body) {
      if (this.isResetEvent === false) {
        this.filterParams.chassisTypeCodes = body.map(o => o.code);
        this.createParams();
      }
    },
    selectGearboxes(gearboxes) {
      if (this.isResetEvent === false) {
        this.filterParams.gearboxCodes = gearboxes.map(o => o.code);
        this.createParams();
      }
    },
    createColorParams(colors) {
      if (this.isResetEvent === false) {
        this.filterParams.colorCodes = colors;
        this.createParams();
      }
    },
    createMetallicParam(selected) {
      if (this.isResetEvent === false) {
        this.filterParams.isMetallic = selected;
        this.createParams();
      }
    },
    resetColors() {
      this.filterParams.isMetallic = null;
      this.filterParams.colorCodes = [];
      this.createParams();
    },
    createEquipmentParams(equipments) {
      if (this.isResetEvent === false) {
        this.filterParams.equipmentCodes = equipments;
        this.createParams();
      }
    },
    setBodyType(types) {
      if (this.isResetEvent === false) {
        this.filterParams.filterSetFilterKeys = types;
        this.createParams();
      }
    },
    setParamForSlider(param) {
      if (this.isResetEvent === false) {
        const paramName = Object.keys(param)[0];
        const paramValue = param[Object.keys(param)[0]];
        this.filterParams[paramName] = paramValue;
        this.createParams();
      }
    },
    enableScroll() {
      this.$props.scrollbar.create();
    },
    disableScroll() {
      this.$props.scrollbar.destroy();
    },
    runSearch() {
      this.$store.dispatch('params/setParams', this.filterParams);
    },
    resetFilters() {
      const currentCulture = sessionStorage.getItem('vms__culture');
      const initP = _.omitBy(this.$root.$props, _.isNil);
      initP.culture = currentCulture;
      this.isResetEvent = true;
      this.filterParams = { ...initP, pluginConfig: this.filterParams.pluginConfig };

      this.$store.dispatch('params/setParams', this.filterParams);
      this.$refs.filtersMakes.reset();
      this.$refs.filtersModels.reset();
      this.$refs.filtersFuels.reset();
      this.$refs.filtersDrives.reset();
      this.$refs.filtersGearboxes.reset();
      this.$refs.filtersChasisTypes.reset();
      this.$refs.filtersFirstRegistr.reset();
      this.$refs.Co2Consumption.reset();
      this.$refs.PriceMileage.reset();
      this.$refs.FilterColors.reset();
      this.$refs.FilterEquipments.reset();
      if (this.$refs.filtersDealers) {
        this.$refs.filtersDealers.reset();
      }
      this.$refs.filtersFreeText.reset();
      this.$refs.filterBodyTypes.reset();
      this.getShowroomsFilters(this.filterParams);
      this.getVehiclesByFilters(this.filterParams);
      this.isResetEvent = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters {
  .vms__free-text-filter {
    width: calc(50% - 42px);

    /deep/ input {
      width: 100%;
    }
  }

  .vms_search-additional-fields {
    .vms__filters-selects {
      padding: 0;
    }
  }

  &-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    line-height: 1.15;
    letter-spacing: -0.04px;
    font-size: 1.625rem;
    color: #000;
  }

  &-reset {
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-left: auto;
    padding: 0 5px;
    cursor: pointer;
    overflow: hidden;

    &-span {
      line-height: 1.36;
      color: #000;
      opacity: 0.6;
      font-size: 0.688rem;
      font-weight: 600;
      pointer-events: none;
    }

    &:hover {
      text-decoration: underline;
    }

    .mdi {
      margin-left: 4px;
      pointer-events: none;
    }
  }

  &_wrapp-line {
    background-color: #f2f2f2;
  }

  &_line {
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  &-selects_line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 30px 0 60px;
  }

  &-selects {
    width: 100%;
    padding-top: 40px;
    background-color: #f2f2f2;

    .vms__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .vms__ui-select {
      width: calc(50% - 42px);
      margin-bottom: 40px;
    }

    .vms__ui-slider {
      width: calc(50% - 42px);
      margin-bottom: 40px;

      /deep/ .vms__ui-slider_info {
        margin-bottom: -2px;
      }

      /deep/ .noUi-connects {
        background-color: rgba(45, 156, 144, 0.3);
      }

      /deep/ .noUi-connect {
        background-color: #2d9c90;
      }
    }
  }

  &-btns {
    background-color: #f2f2f2;
    padding: 40px 0;

    .vms__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &_all-filters {
      background-color: #fff;
    }

    &_find {
      background-color: #2d9c90;
      color: #fff;
      margin-left: 20px;
    }
  }
}

.vms__ui-slider-button {
  display: none;
}

@mixin media-768() {
  &-subtitle {
    font-size: 1.375rem;
  }

  &-selects {
    .vms__container {
      display: flex;
      flex-direction: column;

      .vms__ui-select,
      .vms__ui-slider,
      .vms__free-text-filter {
        width: 100%;
      }

      .vms__ui-slider {
        margin-bottom: 25px;
      }

      /deep/ .vms__ui-slider-button {
        .vms__ui-slider-button_subtitle {
          color: #000;
          font-weight: normal;
        }

        .vms__ui-slider-button_controls-button {
          background-color: #2d9c90;
        }

        .vms__ui-slider-button_controls-start,
        .vms__ui-slider-button_controls-current,
        .vms__ui-slider-button_controls-end {
          color: #000;
          font-weight: 100;
        }
      }
    }
  }
}

@mixin media-576() {
  &-selects {
    padding-bottom: 0;
  }

  &-btns {
    &_find {
      width: 100%;
      margin: 0;
      margin-top: 15px;
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

.media-576 .vms__filters {
  @include media-576();
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__filters {
    @include media-576();
  }
}
</style>
