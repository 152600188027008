<template>
  <div
    class="vms__vehicles_item"
    :class="{
      'vms__vehicles_item--list': !typeGrid && validWindowWidth,
      'vms__vehicles_item--deactivated': deactivated
    }"
    v-responsive="{
      'media-1200': el => el.width < 1200,
      'media-1024': el => el.width < 1024,
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768
    }"
    :data-id="payload && payload.id"
  >
    <div class="vms__vehicles_item-img">
      <UILink :link="link" :disabled="deactivated" :data-cy="`${cyName}`">
        <img v-if="imgUrl && typeof imgUrl === 'string' && !deactivated" :src="imgUrl" />
        <!-- <progressive-background v-if="imgUrl && typeof imgUrl === 'string'" :src="imgUrl" /> -->
        <div v-else class="vms__vehicles_item-img-fallback">
          <div v-if="deactivated" class="mdi mdi-emoticon-sad-outline" />
          <div v-else class="mdi mdi-image" />
        </div>

        <div v-if="deactivated" class="vms__vehicles_item--deactivated-msg vms__OpenSansRegular">
          {{ $t('common.unavailable') }}
        </div>
      </UILink>
      <div class="vms__vehicles_item_controls">
        <ControlButtons
          :class="{ 'vms__control-buttons--show': validFavoriteButton }"
          :shareActive="false"
          :printActive="false"
          :compareActive="false"
          :payload="payload"
          :data-cy="`${cyName}__favourite-btn`"
        />
        <ControlButtons
          :class="{ 'vms__control-buttons--show': validCompareButton }"
          :shareActive="false"
          :printActive="false"
          :favoriteActive="false"
          :payload="payload"
          :data-cy="`${cyName}__compare-btn`"
        />
      </div>
    </div>
    <div class="vms__vehicles_item-info">
      <div class="vms__vehicles_item-info-params">
        <!-- KM -->
        <div class="vms__vehicles_item-info-params_km vms__OpenSansRegular" :data-cy="`${cyName}__mileage`">
          <div class="mdi mdi-highway" />
          {{ MixinFormatThousands(km) }} km
        </div>
        <!-- END KM -->

        <!-- Date -->
        <div class="vms__vehicles_item-info-params_date vms__OpenSansRegular" :data-cy="`${cyName}__first-registration-date`">
          <div class="mdi mdi-progress-clock" />
          {{ MixinFormatDate(date, 'MMMM YYYY') || $t('vehicle.new') }}
        </div>
        <!-- End Date -->

        <!-- Upholstery -->
        <div
          v-if="showroom && showroom.showUpholsteryInList && !typeGrid && validWindowWidth"
          class="vms__vehicles_item-info-params_upholstery vms__OpenSansRegular"
          :data-cy="`${cyName}__upholstery`"
        >
          <div class="mdi mdi-seat-recline-extra" />
          {{ upholstery }}
        </div>
        <!-- END Upholstery -->

        <!-- Location -->
        <div
          v-if="
            showroom &&
            showroom.showLocationInList &&
            validDealerLocation &&
            !typeGrid &&
            validWindowWidth
          "
          class="vms__vehicles_item-info-location vms__OpenSansRegular"
          :data-cy="`${cyName}__location`"
        >
          <div class="mdi mdi-map-marker" />
          {{ dealer.number }} {{ dealer.street }} {{ dealer.city }}
        </div>
        <!-- END Location -->

        <!-- Title -->
        <div class="vms__vehicles_item-info_title" :data-cy="`${cyName}__vehicle-name`">
          <UILink :link="link" :disabled="deactivated">{{ title }}</UILink>
        </div>
        <!-- END Title -->
      </div>

      <div class="vms__vehicles_item-info-price">
        <!-- AutoUncle Rating -->
        <div
          v-show="typeGrid"
          class="au-widget-car-rating"
          data-theme="transparent"
          data-layout="tall_smaller"
          :data-car-url="MixinCreateAutoUncleApiUrl(showroom.autounclePriceRatingWidgetApiUrl, id)"
        ></div>
        <div
          v-show="!typeGrid"
          class="au-widget-car-rating"
          data-theme="transparent"
          data-layout="wider_no_border"
          :data-car-url="MixinCreateAutoUncleApiUrl(showroom.autounclePriceRatingWidgetApiUrl, id)"
        ></div>
        <!-- END AutoUncle Rating -->

        <div class="vms__vehicles_item-info-price_data">
          <UIDiscount v-if="discount" :discount="discount" />
          <div class="vms__vehicles_item-info-price_data-main" :data-cy="`${cyName}__price`">
            € {{ MixinFormatThousands(price) }},–
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import ControlButtons from '@/components/ControlButtons';
import UIDiscount from '@/components/ui/UIDiscount';
import UILink from '@/components/ui/UILink';

export default {
  name: 'VehiclesCard',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: { UIDiscount, ControlButtons, UILink },
  props: {
    id: {
      type: Number,
      default: 0
    },
    deactivated: {
      type: Boolean,
      default: false
    },
    typeGrid: {
      type: Boolean,
      default: true
    },
    imgUrl: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: null
    },
    upholstery: {
      type: String,
      default: null
    },
    dealer: {
      type: Object,
      default: () => {}
    },
    km: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    },
    discount: {
      type: Object,
      default: () => {}
    },
    payload: {
      type: Object,
      default: () => {}
    },
    cyName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      windowWidth: 0
    };
  },
  computed: {
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    }),
    ...mapState('favorites', {
      favoriteList: 'favoriteList'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    validFavoriteButton() {
      if (this.favoriteList && this.favoriteList.length) {
        return this.favoriteList.some(f => f.id === this.payload.id);
      }
      return false;
    },
    validCompareButton() {
      if (this.compareList && this.compareList.length) {
        return this.compareList.some(id => id === this.payload.id);
      }
      return false;
    },
    validWindowWidth() {
      if (this.windowWidth > 576) {
        return true;
      }
      return false;
    },
    validDealerLocation() {
      if (!this.dealer) return false;
      if (this.dealer.number || this.dealer.street || this.dealer.city) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth =
        this.$root.$el.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles_item {
  min-width: 333px;
  max-width: 333px;
  height: auto;
  display: flex;
  flex-direction: column;

  &-img {
    width: 100%;
    height: auto;
    min-height: 250px;
    position: relative;
    background-color: #f2f2f2;
    overflow: hidden;

    .vms__ui-link {
      height: 100%;
      display: flex;
    }

    &-fallback {
      width: 100%;
      height: 223px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      margin: auto;
    }

    .mdi {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #c5c4c4;
      opacity: 0.3;
      font-size: 10rem;
    }

    .vms__control-buttons {
      transition: all 0.2s ease;
      margin-right: -100%;

      &--show {
        margin-right: 0;
      }
    }
  }

  &_controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    right: 0;
  }

  &:hover {
    .vms__control-buttons {
      margin-right: 0;
    }
  }

  &-info {
    width: 100%;
    height: auto;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: 16px 10px;
    box-sizing: border-box;
    background-color: #062d29;

    &_title {
      width: 100%;
      display: flex;
      margin-top: 5px;
    }

    .vms__ui-link {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 26px;
      line-height: 30px;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    &-params {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .mdi {
        margin-right: 5px;
        font-size: 14px;
        opacity: 0.6;
      }

      &_date,
      &_km,
      &_upholstery {
        display: flex;
        margin-right: 14px;
        font-size: 11px;
        font-weight: 600;
        line-height: 15px;
        align-items: center;
        color: #fff;
        opacity: 0.7;
      }
    }

    &-price {
      width: 100%;
      display: flex;
      align-items: flex-end;
      margin-top: auto;
      padding-top: 12px;

      &_data {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-left: auto;

        &-main {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 1.625rem;
          line-height: 30px;
          color: #fff;
          white-space: nowrap;
        }
      }
    }

    &-location {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 600;
      line-height: 15px;
      color: #000;
      opacity: 0.7;
      order: 3;
      margin-top: -5px;

      .mdi {
        font-size: 14px;
        opacity: 0.6;
        margin-right: 5px;
      }
    }
  }

  &--list {
    width: 100% !important;
    max-width: 100% !important;
    flex-direction: row;

    .vms__vehicles_item--deactivated-msg {
      max-width: 90%;
      font-size: 12px;
      line-height: normal;
      bottom: 10px;
    }

    .vms__vehicles_item-img-fallback {
      .mdi-emoticon-sad-outline {
        font-size: 150px !important;
        left: -38px !important;
        top: -45px !important;
      }
    }

    .vms__control-buttons {
      top: 16px;
    }

    .vms__vehicles_item-img {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
      height: auto;
      min-height: auto;

      &-fallback {
        height: 120px;
      }

      /deep/ .progressive-background-image {
        height: 120px !important;
      }

      .mdi {
        font-size: 3rem;
      }
    }

    .vms__vehicles_item-info {
      background-color: #fff;
      padding: 15px 40px;
      padding-right: 30px;
      flex-direction: row;

      &:hover {
        background-color: #e6eae9;
      }

      .vms__ui-link {
        font-size: 1.625rem;
        line-height: 1.15;
        letter-spacing: -0.04px;
        color: #000;
      }

      &_title {
        width: 100%;
        margin-top: 0;
        min-height: none;
        order: 0;
        margin-bottom: 10px;
      }

      &-params {
        width: 100%;

        &_date,
        &_km,
        &_upholstery,
        > .mdi {
          color: #000;
          opacity: 0.6;
        }

        &_date {
          order: 1;
        }

        &_km {
          order: 2;
        }

        &_upholstery {
          order: 3;
        }
      }

      &-price {
        width: auto;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0;
        margin: auto 0;

        &_data {
          &-main {
            display: flex;
            align-items: center;
            font-size: 1.625rem;
            line-height: 1.15;
            letter-spacing: -0.04px;
            color: #000;
            order: 0;
            padding-bottom: 10px;
          }
        }

        .au-widget-car-rating {
          order: 1;
        }
      }

      /deep/ .vms__ui-discount-span {
        font-size: 18px;
      }
    }
  }

  &--deactivated {
    .vms__vehicles_item-info {
      opacity: 0.3;
    }

    .vms__ui-link img {
      opacity: 0.5;
    }

    .vms__vehicles_item-img-fallback {
      position: relative;

      .mdi-emoticon-sad-outline {
        font-size: 288px;
        position: absolute;
        left: -70px;
        top: -90px;
        transform: none;
      }
    }

    &-msg {
      width: 100%;
      max-width: 250px;
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translateX(-50%);
      font-size: 18px;
      color: #3b3e3c;
      text-align: center;
      line-height: 24px;
      font-weight: 600;
    }

    /deep/ .vms__ui-link:hover {
      text-decoration: none;
    }
  }
}

@mixin media-1200() {
  max-width: 50%;
}

@mixin media-1024() {
  margin-right: 0 !important;
}

@mixin media-992() {
  .vms__vehicles_item-info_title {
    .vms__ui-link {
      font-size: 20px;
    }
  }

  .vms__vehicles_item-info-price_data-main {
    font-size: 22px;
  }
}

@mixin media-768() {
  max-width: 100%;
}

@mixin media-576-768() {
  .vms__vehicles_item-img {
    height: auto;
  }

  .vms__vehicles_item-info_title {
    display: flex;
    flex-direction: column;

    .vms__ui-link {
      height: auto;
      min-height: auto;
      margin-bottom: 6px;
    }

    .vms__vehicles_item-info-params_price {
      margin: 0;
    }
  }
}

@mixin media-380() {
  min-width: auto;
}

.media-1200 .vms__vehicles_item {
  @include media-1200();
}

.media-992 .vms__vehicles_item {
  @include media-992();
}

.media-1024 .vms__control-buttons {
  @include media-1024();
}

.media-768 .vms__vehicles_item {
  @include media-768();
}

.media-576-768.vms__vehicles_item--list {
  @include media-576-768();
}

.media-380.vms__control-buttons {
  @include media-380();
}

@media screen and (max-width: 1200px) {
  .vms__vehicles_item {
    @include media-1200();
  }
}

@media screen and (max-width: 1024px) {
  .vms__control-buttons {
    @include media-1024();
  }
}

@media screen and (max-width: 992px) {
  .vms__vehicles_item {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__vehicles_item {
    @include media-768();
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .vms__vehicles_item--list {
    @include media-576-768();
  }
}

@media screen and (max-width: 380px) {
  .vms__vehicles_item {
    @include media-380();
  }
}
</style>
