<template>
  <transition name="main-fade" mode="out-in">
    <div
      v-if="isVisible"
      v-responsive="{ 'media-768': el => el.width < 768 }"
      class="vms__app vms__container-modal"
      v-append-to-body
    >
      <transition name="made">
        <div v-if="isOverlay" class="vms__container-modal_overlay" @click="modalClose"></div>
      </transition>
      <transition name="fade">
        <div
          v-if="contentLoaded"
          :style="{
            'max-width': maxWidth,
            width: maxWidth
          }"
          class="vms__container-modal_container"
        >
          <div class="vms__container-modal_close" @click="modalClose">
            <UIIcon name="mdi-close" size="22px" />
          </div>
          <simplebar class="vms__container-modal_content" ref="content">
            <slot />
          </simplebar>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import simplebar from 'simplebar-vue';
import Responsive from '@/directives/Responsive';
import UIIcon from '@/components/ui/UIIcon';
import AppendToBody from '@/directives/AppendToBody';

export default {
  name: 'Modal',
  mixins,
  directives: {
    responsive: Responsive,
    appendToBody: AppendToBody
  },
  components: { UIIcon, simplebar },
  props: {
    maxWidth: {
      type: String,
      default: '530px'
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false,
      contentLoaded: false,
      isOverlay: false
    };
  },
  beforeMount() {
    document.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    typeWidth() {
      if (this.listParams && this.listParams.width !== 'auto') {
        return true;
      }
      if (
        this.$root.$el.clientWidth !==
        (document.body.clientWidth || document.documentElement.clientWidth)
      ) {
        return true;
      }
      return false;
    },
    validHeight() {
      if (
        (this.listParams && this.listParams.height.includes('px')) ||
        this.listParams.height.includes('vh')
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    modalClose() {
      setTimeout(() => {
        this.isVisible = false;
      }, 200);
      setTimeout(() => {
        this.contentLoaded = false;
      }, 50);
      setTimeout(() => {
        this.isOverlay = false;
        this.$emit('on-close');
      }, 100);
    },
    modalShow() {
      this.isVisible = true;
      setTimeout(() => {
        this.contentLoaded = true;
      }, 50);
      setTimeout(() => {
        this.isOverlay = true;
        this.$emit('on-show');
      }, 100);
    },
    onKeydown(e) {
      if (e.keyCode === 27) {
        this.modalClose();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__container-modal {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 15px;
  overflow: hidden;
  box-sizing: border-box;

  &_overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  &_close {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    color: #8e8e8e;
    z-index: 11;
    cursor: pointer;

    &:hover {
      .vms__container-modal_close-span {
        text-decoration: underline;
      }
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 12;
    margin: auto;
    transition: top 0.4s ease;
    overflow: hidden;
    border-radius: 3px;
  }

  &_content {
    max-height: 95vh;
    background-color: #fff;
    border-radius: 3px;
  }

  /deep/ .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s;
  }

  /deep/ .fade-enter,
  .fade-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }

  /deep/ .made-enter-active,
  .made-leave-active {
    transition: all 0.3s;
  }

  /deep/ .made-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

@mixin media-768() {
  margin-right: auto;
}

.media-768 .vms__container-modal {
  .vms__container-modal_close {
    @include media-768();
  }
}

@media screen and (max-width: 768px) {
  .media-768 .vms__container-modal {
    .vms__container-modal_close {
      @include media-768();
    }
  }
}
</style>
