import {
  FORMS_REQUEST_REQUEST,
  FORMS_REQUEST_SUCCESS,
  FORMS_REQUEST_ERROR,
  FORMS_PRICE_REQUEST,
  FORMS_PRICE_SUCCESS,
  FORMS_PRICE_ERROR
} from '@/store/modules/forms/types';
import httpClient from '@/axios';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  sendRequestForm({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(FORMS_REQUEST_REQUEST);
      const data = {
        ...params
      };
      httpClient
        .post(
          `api/v2/ShowroomV2/${data.showroomUid}/vehicles/${data.vehicleId}/contact-forms?culture=${culture}`,
          data,
          headers
        )
        .then(resp => {
          if (resp && resp.status === 204) {
            commit(FORMS_REQUEST_SUCCESS, resp);
            resolve(resp);
          } else {
            commit(FORMS_REQUEST_ERROR);
            reject(resp);
          }
        })
        .catch(err => {
          commit(FORMS_REQUEST_ERROR);
          reject(err);
        });
    });
  },
  sendVehicleRequestForm({ commit }, params) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(FORMS_REQUEST_REQUEST);
      const data = {
        ...params
      };
      httpClient
        .post(`api/v2/ShowroomV2/${data.showroomUid}/vehicle-requests`, data, headers)
        .then(resp => {
          if (resp && resp.status === 204) {
            commit(FORMS_REQUEST_SUCCESS, resp);
            resolve(resp);
          } else {
            commit(FORMS_REQUEST_ERROR);
            reject(resp);
          }
        })
        .catch(err => {
          commit(FORMS_REQUEST_ERROR);
          reject(err);
        });
    });
  },
  sendPriceForm({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(FORMS_PRICE_REQUEST);
      const data = {
        ...params
      };
      httpClient
        .post(
          `api/v2/ShowroomV2/${data.showroomUid}/vehicles/${data.vehicleId}/price-alerts?culture=${culture}`,
          data,
          headers
        )
        .then(resp => {
          if (resp && resp.status === 204) {
            commit(FORMS_PRICE_SUCCESS, resp);
            resolve(resp);
          } else {
            commit(FORMS_PRICE_ERROR);
            reject(resp);
          }
        })
        .catch(err => {
          commit(FORMS_PRICE_ERROR);
          reject(err);
        });
    });
  }
};
