<template>
  <div class="vms__ui-filter-colors" v-if="paramsFromSearchPage">
    <div
      class="vms__ui-filter-colors_item"
      v-for="(color, i) of colorsFull.filter(m => paramsFromSearchPage.includes(m.code))"
      :key="i"
      :class="{ 'vms__ui-filter-colors_item--disabled': result.some(c => color.code === c) }"
      @click="excludeColor(color.code)"
    >
      <div :style="{ backgroundColor: color.cssAlias }"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UIFilterMakes',
  props: {
    colorsFull: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      paramsFromSearchPage: null,
      result: []
    };
  },
  created() {
    this.paramsFromSearchPage = this.listParams.colorCodes;
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  methods: {
    excludeColor(color) {
      if (this.result.some(m => m === color)) {
        this.result = this.result.filter(m => m !== color);
      } else {
        this.result.push(color);
      }
      this.$emit(
        'UIFilterColors',
        this.paramsFromSearchPage.filter(c => !this.result.includes(c))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-filter-colors {
  display: flex;
  align-items: center;
  padding: 9px 22px;
  border: 1px solid #cacaca;

  &_item {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    border: 1px solid #4f4f4f;
    background-color: #fff;
    padding: 0;
    margin-right: 8px;
    cursor: pointer;
    transition: all 0.3 ease;

    &:hover {
      transform: scale(1.1);
    }

    &:last-child {
      margin-right: 0;
    }

    &--disabled {
      opacity: 0.5;
    }

    > div {
      width: 13px;
      height: 13px;
      border-radius: 100%;
    }
  }
}
</style>
