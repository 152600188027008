<template>
  <div
    class="vms__filters_co2-consumption"
    v-if="(filters && filters.co2To) || filters.fuelConsumptionTo"
    v-responsive="{
      'media-768': el => el.width < 768
    }"
  >
    <div class="vms__container">
      <UISlider
        v-if="MixinCheckValidValues([filters.co2To])"
        ref="filtersCo2"
        :title="$t('search.filters.slider3')"
        :startText="$t('vehicles.filters.from')"
        endText=" g/km"
        :interval="1"
        fromKey="co2From"
        toKey="co2To"
        :min="0"
        :max="filters.co2To"
        @onChange="setParamForSlider"
        data-cy="filters__slider-co2-emission"
      />
      <UISlider
        v-if="MixinCheckValidValues([filters.fuelConsumptionTo])"
        ref="filtersConsumption"
        :title="$t('search.filters.slider4')"
        :startText="$t('vehicles.filters.from')"
        endText=" l"
        :interval="1"
        fromKey="fuelConsumptionFrom"
        toKey="fuelConsumptionTo"
        :min="0"
        :max="Math.round(filters.fuelConsumptionTo)"
        @onChange="setParamForSlider"
        data-cy="filters__slider-consumption"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import UISlider from '@/components/ui/UISlider';

export default {
  name: 'Co2Consumption',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    UISlider
  },
  props: {
    scrollbar: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('showrooms', {
      filters: 'filters'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  methods: {
    setParamForSlider(param) {
      this.$emit('setParamForSlider', param);
    },
    reset() {
      this.$refs.filtersCo2.reset();
      this.$refs.filtersConsumption.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters_co2-consumption {
  width: 100%;
  background-color: #c8e0a9;
  padding: 35px 0;

  .vms__container {
    display: flex;
    justify-content: space-between;
  }

  .vms__ui-slider {
    width: calc(50% - 42px);
    color: #000;
  }

  &-ui-slider-button {
    margin-bottom: 23px;
    padding-bottom: 23px;
    border-bottom: 1px solid #fff;
  }
}

@mixin media-768() {
  &_co2-consumption {
    padding: 15px 0;

    .vms__container {
      display: flex;
      flex-direction: column;

      .vms__ui-slider {
        width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    .vms__filters_co2-consumption_price-mob {
      margin-bottom: 23px;
      padding-bottom: 23px;
      border-bottom: 1px solid #fff;
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}
</style>
