<template>
  <div class="vms__vehicles-detail_features">
    <div class="vms__vehicles-detail_features-prev" @click="prevSlideFeatures" data-cy="ribbon__chevron-left">
      <div class="mdi mdi-chevron-left" />
    </div>
    <agile
      v-if="features && features.length"
      ref="featuresCarousel"
      :dots="false"
      :navButtons="false"
      :options="featuresOptions"
      :unagile="features.length < 2"
    >
      <div v-for="(feature, i) of features" :key="i" class="vms__vehicles-detail_features_slide">
        <UIParam
          v-if="feature.value !== null"
          :title="
            feature.icon === 'mdi-seat-recline-extra'
              ? payload && payload.upholsteryColorCode
              : null
          "
          :class="{ 'vms__ui-param--green': feature.icon === 'mdi mdi-leaf' }"
          :label="feature.label"
          :value="dynamicValue(feature)"
          :cy-value="feature.cyValue"
        >
          <UIIcon :name="feature.icon" />
          <div
            v-if="payload && payload.colorCssAlias && feature.icon === 'mdi-format-color-fill'"
            :class="{ 'vms__vehicle-color': payload.colorCssAlias }"
            :style="{ 'background-color': payload.colorCssAlias }"
            slot="value"
          />
          <div
            v-if="
              payload &&
              payload.upholsteryColorCssAlias &&
              feature.icon === 'mdi-seat-recline-extra'
            "
            :class="{ 'vms__vehicle-color': payload.upholsteryColorCssAlias }"
            :style="{ 'background-color': payload.upholsteryColorCssAlias }"
            slot="value"
          />
        </UIParam>
      </div>
    </agile>
    <div class="vms__vehicles-detail_features-next" @click="nextSlideFeatures" data-cy="ribbon__chevron-right">
      <div class="mdi mdi-chevron-right" />
    </div>
  </div>
</template>

<script>
import { VueAgile } from '@/plugins/vue-agile';
import UIParam from '@/components/ui/UIParam';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'Features',
  components: {
    agile: VueAgile,
    UIParam,
    UIIcon
  },
  props: {
    features: {
      type: Array,
      default: () => []
    },
    payload: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    colors() {
      if (this.payload) {
        let result;
        const { colorName, manufacturerColorName, isMetallicColor } = this.payload;
        if (manufacturerColorName && manufacturerColorName !== '') {
          result = `${manufacturerColorName ? `${manufacturerColorName},` : ''} ${
            isMetallicColor ? 'Metallic' : ''
          }`;
        } else {
          result = `${colorName ? `${colorName},` : ''} ${isMetallicColor ? 'Metallic' : ''}`;
        }
        return result.trim().replace(/,\s*$/, ''); // remove last comma
      }
      return '';
    },
    uphostery() {
      if (this.payload) {
        let result;
        const { upholsteryName, manufacturerUpholsteryName, upholsteryColorName } = this.payload;
        if (upholsteryName && upholsteryName !== '') {
          result = `${upholsteryName ? `${upholsteryName},` : ''} ${upholsteryColorName || ''}`;
        } else {
          result = `${manufacturerUpholsteryName ? `${manufacturerUpholsteryName},` : ''} ${
            upholsteryColorName || ''
          }`;
        }
        return result.trim().replace(/,\s*$/, ''); // remove last comma
      }
      return '';
    },
    featuresOptions() {
      if (this.features.length) {
        return {
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: this.features.filter(f => f.value !== null).length
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow:
                  this.features.filter(f => f.value !== null).length >= 4
                    ? 4
                    : this.features.filter(f => f.value !== null).length
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow:
                  this.features.filter(f => f.value !== null).length >= 3
                    ? 3
                    : this.features.filter(f => f.value !== null).length
              }
            },
            {
              breakpoint: 375,
              settings: {
                slidesToShow:
                  this.features.filter(f => f.value !== null).length >= 2
                    ? 2
                    : this.features.filter(f => f.value !== null).length
              }
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow:
                  this.features.filter(f => f.value !== null).length >= 1
                    ? 1
                    : this.features.filter(f => f.value !== null).length
              }
            }
          ]
        };
      }
      return {};
    }
  },
  methods: {
    dynamicValue(feature) {
      if (!feature) return null;
      if (feature.icon === 'mdi-format-color-fill') {
        return this.colors;
      }
      if (feature.icon === 'mdi-seat-recline-extra') {
        return this.uphostery;
      }
      return `${feature.value}`;
    },
    prevSlideFeatures() {
      this.$refs.featuresCarousel.goToPrev();
    },
    nextSlideFeatures() {
      this.$refs.featuresCarousel.goToNext();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-detail {
  &_features {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;
    position: relative;

    .agile {
      width: 100%;
      margin-left: -40px;
    }

    &-prev {
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #f2f2f2;
      z-index: 2;
      cursor: pointer;

      &:hover {
        background-color: #ececec;
      }

      .mdi {
        font-size: 1.875rem;
        margin-left: 10px;
      }
    }

    &-next {
      width: 75px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: -75px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--accent-grey) 60%);
      z-index: 2;
      cursor: pointer;

      &:hover {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ececec 60%);
      }

      .mdi {
        font-size: 1.875rem;
        margin-right: 10px;
      }
    }

    .slick-slider {
      width: calc(100% - 75px);
      z-index: 1;
    }

    .vms__ui-param {
      min-width: 173px;
      height: 95px;
      justify-content: center;
      color: #000;
      background-color: #f2f2f2;

      &--blue {
        background-color: rgba(0, 173, 239, 0.22);
      }

      &--green {
        background-color: #c8e0a9;
      }
    }

    .vms__vehicle-color {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 100%;
      margin-left: 6px;
      background-color: transparent;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
    }

    .vms__vehicle-upholstery-color {
      width: 16px;
      height: 16px;
      border: 3px solid;
      display: inline-block;
      border-radius: 100%;
      margin-left: 6px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
    }
  }
}

@media print {
  .vms__vehicles-detail_features {
    display: none;
  }
}
</style>
