import {
  SHOWROOMS_REQUEST,
  SHOWROOMS_SUCCESS,
  SHOWROOMS_ERROR,
  SHOWROOMS_FILTERS_INIT_REQUEST,
  SHOWROOMS_FILTERS_INIT_SUCCESS,
  SHOWROOMS_FILTERS_INIT_ERROR,
  SHOWROOMS_FILTERS_UPDATE_SUCCESS,
  SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS,
  SHOWROOMS_SHOW_SORT_LIST_SUCCESS,
  SHOWROOMS_SHOW_COMPARE_SUCCESS
} from '@/store/modules/showrooms/types';
import httpClient from '@/axios';
import LocalStorageService from '@/services/localStorageService';

const localStorageService = LocalStorageService.installService();

export default {
  getShowroomById({ commit }, params) {
    const culture = sessionStorage.getItem('vms__culture');
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(SHOWROOMS_REQUEST);
      httpClient
        .get(`/api/v2/ShowroomV2/public/${params.showroomUid}?culture=${culture}`, headers)
        .then(resp => {
          if (resp.data) {
            commit(SHOWROOMS_SUCCESS, resp.data);
            resolve(resp);
          } else {
            commit(SHOWROOMS_ERROR, false);
          }
        })
        .catch(err => {
          commit(SHOWROOMS_ERROR, err);
          reject(err);
        });
    });
  },
  getShowroomsFilters({ commit }, params) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
    return new Promise((resolve, reject) => {
      commit(SHOWROOMS_FILTERS_INIT_REQUEST);
      const culture = sessionStorage.getItem('vms__culture');
      const data = {
        ...params,
        showroomUid: params.showroomUid,
        culture: (params.culture && params.culture.key) || params.culture
      };
      httpClient
        .post(
          `/api/v2/ShowroomV2/public/${data.showroomUid}/AvailableFilters?culture=${culture}`,
          data,
          headers
        )
        .then(async resp => {
          if (resp && resp.data) {
            const result = resp.data.colors.map(obj => {
              const o = Object.assign({}, obj);
              o.checked = false;
              return o;
            });
            resp.data.colors = result;
            commit(SHOWROOMS_FILTERS_INIT_SUCCESS, resp.data);
            resolve(resp);
          }
        })
        .catch(err => {
          commit(SHOWROOMS_FILTERS_INIT_ERROR, err);
          reject(err);
        });
    });
  },
  updateShowroomsFilters({ commit }, filters) {
    commit(SHOWROOMS_FILTERS_UPDATE_SUCCESS, filters);
  },
  updateGoogleMapsApiKey({ commit }, key) {
    commit(SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS, key);
  },
  updateShowSortList({ commit }, bool) {
    commit(SHOWROOMS_SHOW_SORT_LIST_SUCCESS, bool);
  },
  updateShowCompare({ commit }, bool) {
    commit(SHOWROOMS_SHOW_COMPARE_SUCCESS, bool);
  }
};
