<template>
  <div
    class="vms__filters-equipment"
    v-if="filters && filters.equipments"
    v-responsive="{
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768,
      'media-576': el => el.width < 576
    }"
  >
    <div class="vms__container">
      <div class="vms__filters-equipment_subtitle vms__OpenSansRegular">
        {{ $t('search.filters.equipment.title') }}
      </div>
      <div class="vms__filters-equipment-wrapp">
        <MaxHeightFiltersEquipments :startHeight="startHeight">
          <div class="vms__filters-equipment_list">
            <UIFilterEquipment
              ref="UIFilterEquipment"
              v-for="(equipment, i) of filters.equipments"
              :key="i"
              :checked="selectedEquipments && selectedEquipments.some(e => e === equipment.code)"
              :payload="equipment"
              :label="equipment.name || '-'"
              @UIFilterEquipment="createEquipmentParams"
            />
          </div>
        </MaxHeightFiltersEquipments>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import UIFilterEquipment from '@/components/ui/UIFilterEquipment';
import MaxHeightFiltersEquipments from '@/containers/MaxHeightFiltersEquipments';

export default {
  name: 'Equipments',
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    UIFilterEquipment,
    MaxHeightFiltersEquipments
  },
  data() {
    return {
      selectedEquipments: [],
      startHeight: 445
    };
  },
  computed: {
    ...mapState('showrooms', {
      filters: 'filters'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  mounted() {
    this.selectedEquipments = [...this.$props.selected];
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.selectedEquipments = this.listParams?.equipmentCodes || [];
  },
  methods: {
    onResize() {
      this.windowWidth =
        this.$root.$el.clientWidth ||
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (this.windowWidth < 576) {
        this.startHeight = 155;
      } else {
        this.startHeight = 445;
      }
    },
    createEquipmentParams(equipment) {
      if (this.selectedEquipments.some(e => e === equipment.code)) {
        this.selectedEquipments = this.selectedEquipments.filter(e => e !== equipment.code);
      } else {
        this.selectedEquipments.push(equipment.code);
      }
      this.$emit('createEquipmentParams', this.selectedEquipments);
    },
    reset() {
      this.selectedEquipments = [];
      this.$refs.UIFilterEquipment.forEach(e => {
        e.reset();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__filters-equipment {
  padding-top: 62px;
  padding-bottom: 60px;
  background-color: #f2f2f2;

  &_subtitle {
    color: #000;
    line-height: 1.33;
    font-size: 1.125rem;
    margin-bottom: 37px;
  }

  &-wrapp {
    display: flex;
    justify-content: space-between;
    padding-left: 74px;
    padding-right: 60px;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .vms__filter-equipment-dot {
      width: 30%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      background-color: #f2f2f2;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@mixin media-992 {
  &-equipment-wrapp {
    padding: 0;
  }
}

@mixin media-768() {
  &-equipment-wrapp {
    flex-wrap: wrap;
  }

  &-equipment_list {
    width: 100%;
    margin-top: 6px;

    .vms__filter-equipment-dot {
      width: 48%;
    }
  }
}

@mixin media-576() {
  &-equipment {
    padding-bottom: 30px;

    &_list {
      width: 100%;

      .vms__filter-equipment-dot {
        width: 100%;
      }
    }
  }
}

.media-768 .vms__filters {
  @include media-768();
}

.media-992 .vms__filters {
  @include media-992();
}

.media-576 .vms__filters {
  @include media-576();
}

@media screen and (max-width: 992px) {
  .vms__filters {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__filters {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__filters {
    @include media-576();
  }
}
</style>
