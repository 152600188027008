export default {
  common: {
    isNotSupportBrowser:
      'Your browser is out-of-date!<br>Update your browser to view this website correctly.',
    dateNew: 'New',
    unavailable: 'Sorry, this vehicle are no longer available.'
  },
  buttons: {
    contact: 'Contact',
    showEmail: 'Show E-Mail',
    priceAlarm: 'Price alarm',
    favorite: 'Favorite',
    compare: 'Compare',
    print: 'Print',
    autouncleBtn: 'Trade-in price calculator',
    autouncleModalBtn: 'Calculate now!'
  },
  modals: {
    close: 'Close',
    successText: 'Your request has been successfully submitted.',
    contact: {
      title: 'Contact',
      desc: 'Select an option with which you would like to contact the dealer.',
      sendEmail: 'Send Email'
    },
    email: {
      title: 'Show email',
      desc: 'The email will be displayed if we are sure you are not a robot.'
    },
    price: {
      selectDuration: {
        option1: '1 week',
        option2: '2 weeks',
        option3: '3 weeks',
        option4: '4 weeks',
        option5: '2 months'
      }
    },
    autouncle: {
      title: 'Trade-in price?'
    }
  },
  alerts: {
    networkError: 'Network error. Please reload the page',
    serverError: 'Server error. Wrong data was sent',
    notFoundError: 'Server error. Data not found',
    authError: 'Wrong username or password',
    authErrorLogin: 'Wrong username',
    authErrorPassword: 'Wrong password',
    authSuccess: 'Authorization was successful',
    compareMaxItemsError: 'Up to 3 vehicles are allowed to be compared. Please remove some already added to be able to compare the new ones.'
  },
  navigatePanel: {
    bookmarked: 'Bookmarked',
    compare: 'Compares',
    motocycle: 'Motorcycle',
    van: 'Transporter',
    kfz: 'KFZ',
    logout: 'Log out'
  },
  breadcrumbs: {
    home: 'Home',
    search: 'Search',
    vehicles: 'Search result',
    view: 'Vehicle',
    geo: 'Geo',
    compare: 'Compare',
    favorite: 'Bookmarked'
  },
  login: {
    loginBtn: 'Login',
    placeholderEmail: 'E-mail',
    placeholderPassword: 'Password'
  },
  home: {
    title: 'Off into the spring with the new used',
    filters: {
      title: 'Found {carsCount} vehicles',
      reset: 'Reset filter',
      slider1: 'Purchase price:',
      slider2: 'Kilometer:',
      selectLabel1: 'Brand:',
      selectLabel2: 'Model:',
      selectLabel3: 'Initial registration:',
      selectLabel4: 'Performance:',
      btns: {
        btn1: 'more filters',
        btn2: 'Show {results} results'
      },
      bestDealsTitle: 'Recommendation Vehicles'
    }
  },
  search: {
    banner: {
      btn: 'to the offer'
    },
    filters: {
      title: 'Found {carsCount} vehicles',
      reset: 'Reset filter',
      types: {
        BodyTypeFilterYoungstar: 'Young stars',
        BodyTypeFilterCompact: 'Compact',
        BodyTypeFilterCoupe: 'Coupe',
        BodyTypeFilterCabrio: 'Cabrio',
        BodyTypeFilterKombi: 'Combi',
        BodyTypeFilterSuv: 'SUV',
        BodyTypeFilterTransporter: 'Transporter'
      },
      slider1: 'Purchase price:',
      slider2: 'Kilometer:',
      slider3: 'CO2 Emissions:',
      slider4: 'Consumption:',
      selectLabel1: 'Brand:',
      selectLabel2: 'Model:',
      selectLabel3: 'Initial registration:',
      selectLabel4: 'Performance:',
      selectLabel5: 'Fuel:',
      selectLabel6: 'Gearboxes:',
      selectLabel7: 'Type of drive:',
      selectLabel8: 'Body Types:',
      selectLabel9: 'Fuels:',
      selectLabel10: 'Transmissions:',
      selectLabel11: 'Colors:',
      selectLabel12: 'Dealers:',
      selectLabel13: 'ID:',
      selectLabelDrives: 'Drives:',
      btns: {
        btn1: 'less filters',
        btn2: 'more filters',
        btn3: 'Show {results} results'
      },
      bestDealsTitle: 'Recommendation Vehicles',
      colors: {
        title: 'Colors:',
        all: 'all'
      },
      equipment: {
        title: 'Equipments:',
        item1: 'Navigation system',
        item2: 'Speakerphone',
        item3: 'Multifunction steering wheel',
        item4: 'Cruise control',
        item5: 'Air conditioning',
        item6: 'Towing device',
        item7: 'Automatic climate control',
        item8: 'Seat heating',
        item9: 'Xenon',
        item10: 'Bend lighting',
        item11: 'Lane Keeping Assistant',
        item12: 'Four wheel drive',
        item13: 'Parking aid',
        item14: 'Distance control'
      }
    },
    resultTitle: 'Search result'
  },
  vehicles: {
    navigatePanel: {
      buttonBackspace: 'Back'
    },
    listTitle: 'Vehicles found',
    btnLoadMore: 'Show more',
    filters: {
      showFilters: 'Show filters',
      slider1: 'Purchase price:',
      slider2: 'Kilometer:',
      all: 'all',
      from: 'from',
      to: 'to',
      selectLabel: 'Sort:',
      selectSort: {
        PriceAscending: 'Price Ascending',
        PriceDescending: 'Price Descending',
        MileageAscending: 'Milage Ascending',
        MileageDescending: 'Milage Descending',
        FirstRegistrationAscending: 'First registration Ascending',
        FirstRegistrationDescending: 'First registration Descending'
      }
    },
    form: {
      title: 'No vehicles found',
      desc:
        'Your search did not find any vehicles. If you are looking for a special vehicle you can also contact us directly and make a search request. As soon as we receive a corresponding vehicle search we will inform you.',
      name: 'Search query',
      placeholderFirstName: 'First name *',
      placeholderLastName: 'Last name *',
      placeholderPhone: 'Phone *',
      placeholderEmail: 'Email *',
      placeholderText: 'Message *',
      checkboxText:
        'By submitting the form you agree that your personal data will be processed by the operator of this form (Fa.Edentity Software Solutions GmbH) as well as the respective dealer for the purpose of answering the vehicle request.',
      btnSend: 'Send request',
      successText: 'Your request has been successfully submitted.'
    }
  },
  vehicle: {
    new: 'New',
    navigatePanel: {
      buttonBackspace: 'Search results'
    },
    offerNumber: 'Offer number:',
    card: {
      detail: {
        item1: 'Mileage',
        item2: 'Initial registration',
        item3: 'Performance',
        item4: 'Fuel',
        warranty: 'Warranty'
      },
      button1: 'Request',
      button2: 'Price Alarm'
    },
    services: {
      title: 'Equipments / Extras'
    },
    info: {
      title: 'Additional information',
      instead: 'instead of'
    },
    autouncleSection: {
      title: 'Eintauschpreis-Rechner',
      desc: 'Erhalten Sie direkt eine kostenfreie Preisbewertung für Ihren Gebrauchten.',
      btn: 'Jetzt berechnen!'
    },
    contacts: {
      title: 'Please contact us',
      button1: 'Request',
      button2: 'Call',
      button3: 'Test drive now!',
      whatsapp: 'WhatsApp',
      link: 'Plan route'
    },
    nextCar: 'Next vehicle',
    prevCar: 'Previous vehicle',
    bestDealsTitle: 'Recommendation Vehicles',
    featuresLabels: {
      slide1: 'CO2 Emission *',
      slide2: 'Consumption *',
      slide3: 'Gearboxes',
      slide4: 'Cubic capacity',
      slide5: 'Color',
      slide6: 'Upholstery',
      slideDrive: 'Drive'
    },
    map: 'Map',
    photos: 'Photos',
    modals: {
      request: {
        title: 'Contact us',
        inpPlaceholder1: 'First name *',
        inpPlaceholder2: 'Last name *',
        inpPlaceholder3: 'Phone *',
        inpPlaceholder4: 'Email *',
        textareaPlaceholder: 'Message *',
        text:
          'By submitting the form you agree that your personal data will be processed by the operator of this form <br> (Fa. Edentity Software Solutions GmbH) as well as the respective dealer for the purpose of answering the vehicle request.',
        btn: 'Send request',
        close: 'Close',
        successAlert: 'Form successfully sent',
        errorAlert: 'Incorrect data'
      },
      price: {
        title: 'Price Alarm',
        inpPlaceholder1: 'First name *',
        inpPlaceholder2: 'Last name *',
        inpPlaceholder3: 'Phone *',
        inpPlaceholder4: 'Email *',
        inpPlaceholder5: 'Desired price in € *',
        selectPlaceholder: 'Duration *',
        text1:
          'Enter your desired price (e.g. € 25.000) and a duration for which we may inform you about the price development.',
        text2:
          'By submitting the form you agree that your personal data will be processed by the operator of this form <br> (Fa. Edentity Software Solutions GmbH) as well as the respective dealer for the purpose of answering the vehicle request.',
        btn: 'Price Alarm start',
        close: 'Close',
        successAlert: 'Form successfully sent'
      }
    },
    whatsappText: 'Hello, I want more information about this vehicle {url}'
  },
  favorites: {
    listTitle: 'Number of favorite cars',
    listEmpty: 'The list is empty'
  },
  compare: {
    head: {
      btn1: 'Vehicle Search',
      btn2: 'Bookmarked ({count})',
      btn3: 'Compares ({count})'
    },
    list: {
      title: 'My comparison list'
    },
    table: {
      item1: {
        title: 'Performance',
        unit: 'PS'
      },
      item2: {
        title: 'Fuel',
        unit: ''
      },
      item3: {
        title: 'CO2 Emission',
        unit: 'g/km'
      },
      item4: {
        title: 'Consumption *',
        unit: 'l'
      },
      item5: {
        title: 'Gearboxes',
        unit: ''
      },
      item6: {
        title: 'Cubic capacity',
        unit: 'ccm'
      },
      item7: {
        title: 'Color',
        unit: ''
      },
      item8: {
        title: 'Upholstery',
        unit: ''
      },
      item9: {
        title: 'ABS',
        unit: 'abs'
      },
      item10: {
        title: 'Automatic air conditioning',
        unit: 'kla'
      },
      item11: {
        title: 'Light and vision package',
        unit: '_LSP'
      },
      item12: {
        title: 'Night Package',
        unit: '_NIGHT'
      },
      item13: {
        title: 'Seating Comfort Package',
        unit1: '_SKP',
        unit2: 'SitzkomfPak'
      },
      item14: {
        title: 'Seating Comfort Package',
        unit: 'mfl'
      },
      item15: {
        title: 'Rear view camera',
        unit: 'RÜCKFAHRKAM'
      },
      item16: {
        title: 'Active Lane Keeping Assistant',
        unit: 'SAA'
      },
      item17: {
        title: 'Bluetooth',
        unit: 'BT'
      },
      item18: {
        title: 'Cruise control',
        unit: 'tem'
      },
      item19: {
        title: 'Light metal rims 19"',
        unit: 'alu'
      },
      item20: {
        title: 'Rain sensor',
        unit: 'rese'
      }
    },
    characteristics: {
      title: 'Features'
    },
    extras: {
      title: 'Equipments / Extras'
    },
    listEmpty: 'The list is empty',
    more: 'More'
  },
  bookmarked: {
    btnLoadMore: 'Show more'
  },
  detailPanel: {
    compareLink: 'Compare',
    favoriteLink: 'Favorite',
    shareLink: 'Share',
    printLink: 'Print'
  },
  inputs: {
    search: 'Search',
    requiredError: 'Mandatory field'
  },
  geo: {
    title: 'Route: ',
    searchInfo: 'Enter your address to create a map to the dealer.',
    searchPrint: 'Print',
    searchPlaceholder: 'Search',
    importantInfo: '* INKL. ALL TAXES AND DUTIES'
  },
  technical: {
    title: 'Sorry, we do some technical work on the website',
    btn: 'Go to the homepage'
  },
  notFound: {
    text: 'Data not found',
    btn: 'Go to Vehicles page'
  },
  errorsWrapper: {
    btn: 'Go to home page',
    instanceId: 'Key <div class="vms__red">instance-id="*"</div> is required',
    instanceIdInvalid: 'Id for parameter <div class="vms__red">instance-id="*"</div> is not valid',
    showroomUid: 'Key <div class="vms__red">showroom-uid="***"</div> is required',
    showroomUidInvalid:
      'Uid for parameter <div class="vms__red">showroom-uid="***"</div> is not valid',
    culture: 'Key <div class="vms__red">culture="en-US"</div> is required',
    vehiclesEmpty: 'Sorry, we have no vehicles.',
    technical: {
      message: 'Sorry, we do some technical work on the website',
      btn: 'Go to the homepage'
    },
    notFound: {
      message: 'Data not found',
      btn: 'Go to Vehicles page'
    },
    reloadPage: 'Reload page'
  },
  ui: {
    uiPreloader: 'Loading',
    reset: 'Reset',
    finish: 'Finished',
    notFound: 'Not found'
  },
  errors: {
    requiredField: 'This field is required',
    invalidEmail: 'E-mail is not valid',
    invalidPhone: 'Phone is not valid',
    invalidPrice: 'Price is not valid'
  },
  print: {
    qrLinkText: 'For more vehicle information scan this QR-Code or open this following URL:',
    qrGoogle: {
      title: 'Open in Google Maps',
      step1: 'Open Camera',
      step2: 'Scan QR-Code',
      step3: 'Open Map'
    }
  }
};
