export const SHOWROOMS_SUCCESS = 'SHOWROOMS_SUCCESS';
export const SHOWROOMS_REQUEST = 'SHOWROOMS_REQUEST';
export const SHOWROOMS_ERROR = 'SHOWROOMS_ERROR';
export const SHOWROOMS_FILTERS_INIT_SUCCESS = 'SHOWROOMS_FILTERS_INIT_SUCCESS';
export const SHOWROOMS_FILTERS_INIT_REQUEST = 'SHOWROOMS_FILTERS_INIT_REQUEST';
export const SHOWROOMS_FILTERS_INIT_ERROR = 'SHOWROOMS_FILTERS_INIT_ERROR';
export const SHOWROOMS_FILTERS_UPDATE_SUCCESS = 'SHOWROOMS_FILTERS_UPDATE_SUCCESS';
export const SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS = 'SHOWROOMS_GOOGLE_MAPS_API_KEY_SUCCESS';
export const SHOWROOMS_SHOW_SORT_LIST_SUCCESS = 'SHOWROOMS_SHOW_SORT_LIST_SUCCESS';
export const SHOWROOMS_SHOW_COMPARE_SUCCESS = 'SHOWROOMS_SHOW_COMPARE_SUCCESS';
