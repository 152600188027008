<template>
  <div
    v-if="currentLang && showroom && showroom.cultures && showroom.cultures.length > 1 || initOptions.techLanguage"
    class="vms__ui-lang-switcher"
    ref="LangSwitcher"
    v-responsive="{ 'media-1200': el => el.width < 1200 }"
  >
    <div
      class="vms__ui-lang-switcher_current"
      :class="{
        'vms__ui-lang-switcher_current--disable': showroom && showroom.cultures.length < 2
      }"
    >
      <div class="mdi mdi-earth" />
      <div class="vms__ui-lang-switcher_current-p vms__OpenSansRegular">{{ currentLang }}</div>
    </div>
    <div
      v-if="showroom && showroom.cultures.length > 1 || initOptions.techLanguage"
      ref="UILangSwitcherOptions"
      class="vms__ui-lang-switcher_dropdown"
    >
      <div
        v-for="(culture, index) in showroom && showroom.cultures && showroom.cultures"
        :key="index"
        class="vms__ui-lang-switcher_dropdown-item"
        @click="setActiveCulture(culture)"
      >
        <div class="vms__ui-lang-switcher_dropdown-item-p vms__OpenSansRegular">
          {{ culture && culture.name }}
        </div>
      </div>
      <div
        v-if="initOptions.techLanguage"
        class="vms__ui-lang-switcher_dropdown-item"
        @click="setTechCulture()"
      >
        <div class="vms__ui-lang-switcher_dropdown-item-p vms__OpenSansRegular">
          Technical
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';

export default {
  name: 'LangSwitcher',
  directives: {
    responsive: Responsive
  },
  data() {
    return {
      scrollbarContainer: null,
      opened: false
    };
  },
  computed: {
    ...mapState('showrooms', {
      showroom: 'showroom'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    }),
    ...mapState('options', ['initOptions']),
    currentLang() {
      const currentCulture = sessionStorage.getItem('vms__culture');
      const result = this.showroom?.cultures?.find(c => c.key === currentCulture) || null;
      if (result) {
        try {
          if (this.showroom.cultures.length < 2) {
            sessionStorage.setItem('vms__culture', this.showroom.cultures[0].key);
            return this.showroom.cultures[0].name;
          }
          const selectedLang = this.showroom.cultures.find(
            lang => lang.key === this.cultureSelected.key
          );
          sessionStorage.setItem('vms__culture', selectedLang.key);
          return selectedLang.name;
        } catch {
          return result.name;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions('cultures', ['getCultureTranslations', 'changeCulture']),
    ...mapActions('params', ['setParams']),
    setActiveCulture(culture) {
      if (this.cultureSelected.key !== culture.key) {
        const params = {
          ...this.listParams
        };
        params.culture = culture;
        this.getCultureTranslations(culture.key).then(() => {
          this.$i18n.locale = culture.key.replace('-', '');
          this.changeCulture(culture.key);
          this.setParams(params);
          sessionStorage.setItem('vms__culture', culture.key);
          EventBus.$emit('lang-changed');
        });
      }
    },
    setTechCulture() {
      this.$i18n.locale = 'tech-culture';
      this.$i18n.fallbackLocale = 'tech-culture';
    },
    showOptions() {
      this.opened = true;
      this.$nextTick(() => {
        this.optionsTopPosition();
      });
    },
    hideOptions() {
      this.opened = true;
    },
    optionsTopPosition() {
      const parentOptions = this.$refs.LangSwitcher;
      const options = this.$refs.UILangSwitcherOptions;
      const parentOptionsOffsetTop =
        parentOptions.offsetTop + options.getBoundingClientRect().height;
      const windowOffsetTop =
        this.scrollbarContainer &&
        this.scrollbarContainer.offsetHeight + this.scrollbarContainer.scrollTop;
      const trigger = parentOptionsOffsetTop > windowOffsetTop;
      if (trigger) {
        options.style.top = `${windowOffsetTop - parentOptionsOffsetTop}px`;
      } else {
        options.style.top = `${parentOptions && parentOptions.getBoundingClientRect().height}px`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-lang-switcher {
  min-width: 110px;
  padding: 8px 0;
  position: relative;
  text-align: left;
  cursor: pointer;

  &:hover .vms__ui-lang-switcher_dropdown {
    display: block;
  }

  &_current {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;

    .mdi {
      font-size: 1.375rem;
      opacity: 0.8;
    }

    &-p {
      margin-left: 8px;
      font-size: 1rem;
      line-height: 1.5;
      color: #000;
    }

    &--disable {
      cursor: not-allowed;
    }
  }

  &_dropdown {
    min-width: 100%;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    display: none;
    background: #f2f2f2;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;

    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 10px;

      &:hover {
        background-color: #e6eae9;
      }

      &-img {
        width: 15px;
        height: 15px;
      }

      &-p {
        margin-left: 8px;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #000;
      }

      &--active {
        background-color: #f2f2f2;
        filter: brightness(90%);
      }
    }
  }
}

@mixin media-1200() {
  order: 2;
  padding: 10px 0;

  &_current {
    .mdi {
      font-size: 1rem;
    }

    > div {
      font-size: 0.875rem;
      line-height: 1;
    }
  }
}

.media-1200 .vms__ui-lang-switcher {
  @include media-1200();
}

@media screen and (max-width: 1200px) {
  .vms__ui-lang-switcher {
    @include media-1200();
  }
}
</style>
