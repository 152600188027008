<template>
  <div
    class="vms__ui-link"
    :class="{ 'vms__ui-link--disabled': disabled }"
    @click="goTo"
    :title="disabled ? null : link"
    :data-link="link || null"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UILink',
  props: {
    link: {
      type: String,
      default: '/'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goTo() {
      if (this.disabled) return false;
      if (this.$route.path === this.link) return false;
      this.$router.push(this.link);
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-link {
  cursor: pointer;

  &--disabled {
    cursor: default;
  }
}
</style>
