<template>
  <div class="vms__preloader">
    <div class="vms__preloader-span vms__OpenSansRegular">
      {{ $t('ui.uiPreloader') || 'Loading' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIPreloader'
};
</script>

<style lang="scss" scoped>
.vms__preloader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #fff;

  &-span {
    font-size: 1.325rem;
    font-weight: bold;
    animation: load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px #000;
    color: var(--text-color--dark);
  }
}

@keyframes load {
  0% {
    opacity: 0;
    filter: blur(5px);
    letter-spacing: 3px;
  }
}
</style>
