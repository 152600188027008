<template>
  <div v-if="discount && discount.originalPrice && discount.novaPercent && discount.purchasePrice" class="vms__ui-discount">
    <div class="vms__ui-discount-span vms__BarlowSemiCondensedRegular">
      € {{ MixinFormatThousands(discount.purchasePrice.toFixed(0)) }},–
    </div>
    <div class="vms__ui-discount-percent vms__OpenSansRegular">-{{ discount.novaPercent }}%</div>
  </div>
</template>

<script>
import mixins from '@/mixins';

export default {
  name: 'UIDiscount',
  mixins,
  props: {
    discount: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-discount {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-span {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #8e8e8e;
    letter-spacing: -0.215px;
    white-space: nowrap;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 12px;
      background-color: #8e8e8e;
    }
  }

  &-percent {
    background: #df4949;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    line-height: 15px;
    padding: 1px 5px;
    margin-left: 5px;
  }
}
</style>
