<template>
  <div :id="id" class="vms__google-map"></div>
</template>

<script>
import { mapState } from 'vuex';
import configs from '@/configs';
import gmapsInit from '@/services/gmapsService';

export default {
  name: 'GoogleMap',
  props: {
    id: {
      type: String,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
    zoom: {
      type: Number,
      default: 0
    },
    /**
     * Move map center to right
     */
    panToX: {
      type: Number,
      default: 0
    },
    /**
     * if set this parameter, the center will move to the left
     */
    panToXReverse: {
      type: Boolean,
      default: false
    },
    /**
     * Move map center to bottom
     */
    panToY: {
      type: Number,
      default: 0
    },
    /**
     * if set this parameter, the center will move to the top
     */
    panToYReverse: {
      type: Boolean,
      default: false
    },
    /**
     * Draws the path from point A to point B (From your position to the dealer)
     */
    routeToDealer: {
      type: Boolean,
      default: false
    },
    searchBoxInputId: {
      type: String,
      default: null
    },
    disableDefaultUI: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('options', {
      googleMapsApiKey: 'googleMapsApiKey'
    }),
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom'
    })
  },
  watch: {
    panToX() {
      this.initGMap();
    },
    panToY() {
      this.initGMap();
    }
  },
  created() {
    this.initGMap();
  },
  methods: {
    async initGMap() {
      const google = await gmapsInit(this.googleMapsApiKey);
      const markerPos = { lat: Number(this.lat), lng: Number(this.lng) };
      // move map to right
      const panToCenter = {
        lat: this.calcPanToY(Number(this.lat)),
        lng: this.calcPanToX(Number(this.lng))
      };

      const map = new google.maps.Map(document.getElementById(this.id), {
        center: panToCenter,
        zoom: this.zoom !== 0 ? this.zoom : Number(configs.MAP_ZOOM),
        disableDefaultUI: this.disableDefaultUI
      });

      const directionsDisplay = new google.maps.DirectionsRenderer();
      directionsDisplay.setMap(map);
      const directionsService = new google.maps.DirectionsService();
      new google.maps.Marker({ position: markerPos, map });
      if (this.routeToDealer === true) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const start = new google.maps.LatLng({
              lat: Number(position.coords.latitude),
              lng: Number(position.coords.longitude)
            });
            const end = new google.maps.LatLng({ lat: Number(this.lat), lng: this.lng });
            const request = {
              origin: start,
              destination: end,
              travelMode: google.maps.TravelMode.DRIVING
            };
            directionsService.route(request, (response, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
              }
            });
          },
          () => {
            console.log('The Locator was denied. :(');
          }
        );
      }
      if (this.searchBoxInputId && typeof this.searchBoxInputId === 'string') {
        // Create the search box and link it to the UI element.
        const input = document.getElementById(this.searchBoxInputId);
        const searchBox = new google.maps.places.SearchBox(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();
          if (places.length === 0) {
            return;
          }
          // For each place, get the icon, name and location.
          const bounds = new google.maps.LatLngBounds();
          places.forEach(place => {
            if (!place.geometry) {
              console.log('Returned place contains no geometry');
              return;
            }
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
          const start = map.getCenter();
          const end = new google.maps.LatLng({ lat: Number(this.lat), lng: this.lng });
          const request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
          };
          directionsService.route(request, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
            }
          });
        });
      }
    },
    calcPanToY(lat) {
      if (this.panToYReverse) {
        return lat + this.panToY;
      }
      return lat - this.panToY;
    },
    calcPanToX(lng) {
      if (this.panToXReverse) {
        return lng + this.panToX;
      }
      return lng - this.panToX;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__google-map {
  width: 100%;
  height: 350px;
}
</style>
