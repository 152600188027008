export default {
  common: {
    isNotSupportBrowser:
      'Ihr Browser ist veraltet!<br>Aktualisieren Sie Ihren Browser, um diese Website korrekt anzuzeigen.',
    dateNew: 'Neu',
    unavailable: 'Dieses Fahrzeug ist leider nicht mehr verfügbar.'
  },
  buttons: {
    contact: 'Kontakt',
    showEmail: 'E-Mail anzeigen',
    priceAlarm: 'Preis-Alarm',
    favorite: 'Favoriten',
    compare: 'Vergleichen',
    print: 'Druck',
    autouncleBtn: 'Eintauschpreis-Rechner',
    autouncleModalBtn: 'Jetzt berechnen!'
  },
  modals: {
    close: 'Schliessen',
    successText: 'Ihre Anfrage wurde erfolgreich übermittelt.',
    contact: {
      title: 'Kontakt',
      desc: 'Wählen Sie eine Option, mit der Sie den Händler kontaktieren möchten.',
      sendEmail: 'E-Mail senden'
    },
    email: {
      title: 'E-Mail anzeigen',
      desc: 'Die E-Mail Adresse wird angezeigt, wenn wir sicher sind das Sie kein Roboter sind.'
    },
    price: {
      selectDuration: {
        option1: '1 Woche',
        option2: '2 Wochen',
        option3: '3 Wochen',
        option4: '4 Wochen',
        option5: '2 Monate'
      }
    },
    autouncle: {
      title: 'Eintauschpreis?'
    }
  },
  alerts: {
    networkError: 'Netzwerkfehler. Bitte laden Sie die Seite neu',
    serverError: 'Serverfehler. Falsche Daten wurden gesendet',
    notFoundError: 'Serverfehler. Daten nicht gefunden',
    authError: 'Falscher Benutzername oder Passwort',
    authErrorLogin: 'Falscher Benutzername',
    authErrorPassword: 'Falsches Passwort',
    authSuccess: 'Autorisierung war erfolgreich',
    compareMaxItemsError: 'Es dürfen bis zu 3 Fahrzeuge verglichen werden. Bitte entfernen Sie einige bereits hinzugefügte, um die neuen vergleichen zu können.'
  },
  navigatePanel: {
    bookmarked: 'Merkliste',
    compare: 'Vergleichsliste',
    motocycle: 'Motorrad',
    van: 'Transporter',
    kfz: 'KFZ',
    logout: 'Abmelden'
  },
  breadcrumbs: {
    home: 'Startseite',
    search: 'Suche',
    vehicles: 'Suchergebnis',
    view: 'Fahrzeug',
    geo: 'Geo',
    compare: 'Vergleichsliste',
    favorite: 'Merkliste'
  },
  login: {
    loginBtn: 'Login',
    placeholderEmail: 'E-mail',
    placeholderPassword: 'Passwort'
  },
  home: {
    title: 'Ab in den Frühling mit den neuen Gebrauchten',
    filters: {
      title: 'Finden Sie aus {carsCount} Fahrzeugen das Richtige',
      reset: 'Filter zurücksetzen',
      slider1: 'Kaufpreis:',
      slider2: 'Kilometer:',
      selectLabel1: 'Marke:',
      selectLabel2: 'Modell:',
      selectLabel3: 'Erstzulassung:',
      selectLabel4: 'Leistung:',
      selectLabel5: 'Dealer:',
      btns: {
        btn1: 'mehr Filter',
        btn2: '{results} Treffer anzeigen'
      },
      bestDealsTitle: 'Unsere besten Angebote'
    }
  },
  search: {
    banner: {
      btn: 'zum Angebot'
    },
    filters: {
      title: 'Finden Sie aus {carsCount} Fahrzeugen das Richtige',
      reset: 'Filter zurücksetzen',
      types: {
        BodyTypeFilterYoungstar: 'Junge Sterne',
        BodyTypeFilterCompact: 'Kompaktwagen',
        BodyTypeFilterCoupe: 'Coupé',
        BodyTypeFilterCabrio: 'Cabrio',
        BodyTypeFilterKombi: 'Kombi',
        BodyTypeFilterSuv: 'SUV',
        BodyTypeFilterTransporter: 'Transporter'
      },
      slider1: 'Kaufpreis:',
      slider2: 'Kilometer:',
      slider3: 'CO2 Emissionen:',
      slider4: 'Verbrauch:',
      selectLabel1: 'Marke:',
      selectLabel2: 'Modell:',
      selectLabel3: 'Erstzulassung:',
      selectLabel4: 'Leistung:',
      selectLabel5: 'Treibstoff:',
      selectLabel6: 'Getriebe:',
      selectLabel7: 'Antriebsart:',
      selectLabel8: 'Karosserietyp:',
      selectLabel9: 'Treibstoff:',
      selectLabel10: 'Transmissions:',
      selectLabel11: 'Colors:',
      selectLabel12: 'Händler:',
      selectLabel13: 'Kennung:',
      selectLabelDrives: 'Antrieb:',
      btns: {
        btn1: 'weniger Filter',
        btn2: 'mehr Filter',
        btn3: '{results} Treffer anzeigen'
      },
      bestDealsTitle: 'Unsere besten Angebote',
      colors: {
        title: 'Farbe:',
        all: 'alle'
      },
      equipment: {
        title: 'Ausstattung:',
        item1: 'Navigationssystem',
        item2: 'Freisprecheinrichtung',
        item3: 'Multifunktionslenkrad',
        item4: 'Tempomat',
        item5: 'Klimaanlage',
        item6: 'Anhängevorrichtung',
        item7: 'Klimaautomatik',
        item8: 'Sitzheizung',
        item9: 'Xenon',
        item10: 'Kurvenlicht',
        item11: 'Spurhalteassistent',
        item12: 'Allrad',
        item13: 'Parkhilfe',
        item14: 'Abstandsregelung'
      }
    },
    resultTitle: 'Suchergebnis'
  },
  vehicles: {
    navigatePanel: {
      buttonBackspace: 'Zurück'
    },
    listTitle: 'Fahrzeuge gefunden',
    btnLoadMore: 'Mehr laden',
    filters: {
      showFilters: 'Filter anzeigen',
      slider1: 'Kaufpreis:',
      slider2: 'Kilometer:',
      all: 'alle',
      from: 'von',
      to: 'bis',
      selectLabel: 'Sortieren:',
      selectSort: {
        PriceAscending: 'Preis Aufsteigend',
        PriceDescending: 'Preis Absteigend',
        MileageAscending: 'Milage Aufsteigend',
        MileageDescending: 'Milage Absteigend',
        FirstRegistrationAscending: 'Erstzulassung Aufsteigend',
        FirstRegistrationDescending: 'Erstzulassung Absteigend'
      }
    },
    form: {
      title: 'Keine Fahrzeuge gefunden',
      desc:
        'Ihre Suche hat leider keine Fahrzeuge gefunden. Falls sie ein spezielles Fahrzeug suchen können sie uns auch direkt kontaktieren und eine Suchanfrage stellen. Sobald ein entsprechendes Fahrzeuch bei uns eingeht informieren wir sie gerne.',
      name: 'Suchanfrage',
      placeholderFirstName: 'Vorname *',
      placeholderLastName: 'Nachname *',
      placeholderPhone: 'Telefon *',
      placeholderEmail: 'Email *',
      placeholderText: 'Nachricht *',
      checkboxText:
        ' Mit Abschicken des Formulars erklären Sie sich damit einverstanden, dass Ihre personenbezogenen Daten zum Zweck der Beantwortung der Fahrzeuganfrage durch den Betreiber dieses Formular (Fa.Edentity Software Solutions GmbH) sowie des jeweiligen Händlers verarbeitet werden.',
      btnSend: 'Anfrage abschicken',
      successText: 'Ihre Anfrage wurde erfolgreich übermittelt.'
    }
  },
  vehicle: {
    new: 'Neu',
    navigatePanel: {
      buttonBackspace: 'Suchergebnisse'
    },
    offerNumber: 'Angebotsnummer:',
    card: {
      detail: {
        item1: 'Kilometerstand',
        item2: 'Erstzulassung',
        item3: 'Leistung',
        item4: 'Treibstoff',
        warranty: 'Garantie'
      },
      button1: 'Anfragen',
      button2: 'Preisalarm'
    },
    services: {
      title: 'Ausstattungen'
    },
    info: {
      title: 'Zusatzinformationen',
      instead: 'statt'
    },
    autouncleSection: {
      title: 'Eintauschpreis-Rechner',
      desc: 'Erhalten Sie direkt eine kostenfreie Preisbewertung für Ihren Gebrauchten.',
      btn: 'Jetzt berechnen!'
    },
    contacts: {
      title: 'Kontaktieren Sie uns',
      button1: 'Anfragen',
      button2: 'Anrufen',
      button3: 'Jetzt Probefahren!',
      whatsapp: 'WhatsApp',
      link: 'Route planen'
    },
    nextCar: 'Nächstes Fahrzeug',
    prevCar: 'Vorheriges Fahrzeug',
    bestDealsTitle: 'Weitere Empfehlungen',
    featuresLabels: {
      slide1: 'CO2 Emission*',
      slide2: 'Verbrauch*',
      slide3: 'Getriebe',
      slide4: 'Hubraum',
      slide5: 'Farbe',
      slide6: 'Polsterung',
      slideDrive: 'Antrieb'
    },
    map: 'Karte',
    photos: 'Fotos',
    modals: {
      request: {
        title: 'Contact',
        inpPlaceholder1: 'Vorname *',
        inpPlaceholder2: 'Nachname *',
        inpPlaceholder3: 'Telefon *',
        inpPlaceholder4: 'Email *',
        textareaPlaceholder: 'Nachricht *',
        text:
          'Mit Abschicken des Formulars erklären Sie sich damit einverstanden, dass Ihre personenbezogenen Daten zum Zweck der Beantwortung der Fahrzeuganfrage durch den Betreiber dieses Formular <br> (Fa. Edentity Software Solutions GmbH) sowie des jeweiligen Händlers verarbeitet werden.',
        btn: 'Anfrage abschicken',
        close: 'Schließen',
        successAlert: 'Formular erfolgreich gesendet',
        errorAlert: 'Falsche Daten'
      },
      price: {
        title: 'Preisalarm',
        inpPlaceholder1: 'Vorname *',
        inpPlaceholder2: 'Nachname *',
        inpPlaceholder3: 'Telefon *',
        inpPlaceholder4: 'Email *',
        inpPlaceholder5: 'Wunschpreis in € *',
        selectPlaceholder: 'Dauer *',
        text1:
          'Geben Sie Ihren Wunschpreis (z.B. € 25.000) und eine Dauer ein, für die wir Sie über die Preisentwicklung informieren dürfen.',
        text2:
          'Mit Abschicken des Formulars erklären Sie sich damit einverstanden, dass Ihre personenbezogenen Daten zum Zweck der Beantwortung der Fahrzeuganfrage durch den Betreiber dieses Formular <br> (Fa. Edentity Software Solutions GmbH) sowie des jeweiligen Händlers verarbeitet werden.',
        btn: 'Preisalarm starten',
        close: 'Schließen',
        successAlert: 'Formular erfolgreich gesendet'
      }
    },
    whatsappText: 'Hallo, ich möchte weitere Informationen zu diesem Fahrzeug {url}'
  },
  favorites: {
    listTitle: 'Anzahl der Lieblingsautos',
    listEmpty: 'Die Liste ist leer'
  },
  compare: {
    head: {
      btn1: 'Fahrzeug Suche',
      btn2: 'Merkliste ({count})',
      btn3: 'Vergleichsliste ({count})'
    },
    list: {
      title: 'Meine Vergleichsliste'
    },
    table: {
      item1: {
        title: 'Leistung',
        unit: 'PS'
      },
      item2: {
        title: 'Treibstoff',
        unit: ''
      },
      item3: {
        title: 'CO2 Emission',
        unit: 'g/km'
      },
      item4: {
        title: 'Verbrauch *',
        unit: 'l'
      },
      item5: {
        title: 'Getriebe',
        unit: ''
      },
      item6: {
        title: 'Hubraum',
        unit: 'ccm'
      },
      item7: {
        title: 'Farbe',
        unit: ''
      },
      item8: {
        title: 'Polsterung',
        unit: ''
      },
      item9: {
        title: 'ABS',
        unit: 'abs'
      },
      item10: {
        title: 'Klimatisierungsautomatik',
        unit: 'kla'
      },
      item11: {
        title: 'Licht- und Sicht-Paket',
        unit: '_LSP'
      },
      item12: {
        title: 'Night Paket',
        unit: '_NIGHT'
      },
      item13: {
        title: 'Sitzkomfort-Paket',
        unit1: '_SKP',
        unit2: 'SitzkomfPak'
      },
      item14: {
        title: 'Multifunktionslenkrad',
        unit: 'mfl'
      },
      item15: {
        title: 'Rückfahrkamera',
        unit: 'RÜCKFAHRKAM'
      },
      item16: {
        title: 'Aktiver Spurhalte-Assistent',
        unit: 'SAA'
      },
      item17: {
        title: 'Bluetooth',
        unit: 'BT'
      },
      item18: {
        title: 'Tempomat',
        unit: 'tem'
      },
      item19: {
        title: 'Leichmetallfelgen 19"',
        unit: 'alu'
      },
      item20: {
        title: 'Regensensor',
        unit: 'rese'
      }
    },
    characteristics: {
      title: 'Merkmale'
    },
    extras: {
      title: 'Ausstattung / Extras'
    },
    listEmpty: 'Die Liste ist leer',
    more: 'Mehr'
  },
  bookmarked: {
    btnLoadMore: 'Mehr laden'
  },
  detailPanel: {
    compareLink: 'Vergleichen',
    favoriteLink: 'Favoriten',
    shareLink: 'Teilen',
    printLink: 'Druck'
  },
  inputs: {
    search: 'Suche',
    requiredError: 'Pflichtfeld'
  },
  geo: {
    title: 'Route: ',
    searchInfo: 'Geben Sie Ihre Adresse ein, um einen Anfahrtsplan zum Händler zu erstellen.',
    searchPrint: 'Drucken',
    searchPlaceholder: 'Suche',
    importantInfo: '* INKL. ALLER STEUERN UND ABGABEN'
  },
  technical: {
    title: 'Entschuldigung, wir machen einige technische arbeiten auf der Website',
    btn: 'Zur Startseite gehen'
  },
  notFound: {
    text: 'Daten nicht gefunden',
    btn: 'Zur Seite Fahrzeuge'
  },
  errorsWrapper: {
    btn: 'Zur Startseite gehen',
    instanceId: 'Schlüssel <div class="vms__red">instance-id="*"</div> ist erforderlich',
    instanceIdInvalid:
      'Id für Parameter <div class="vms__red">instance-id="*"</div> ist nicht gültig',
    showroomUid: 'Schlüssel <div class="vms__red">showroom-uid="***"</div> ist erforderlich',
    showroomUidInvalid:
      'Uid für Parameter <div class="vms__red">showroom-uid="***"</div> ist nicht gültig',
    culture: 'Schlüssel <div class="vms__red">culture="en-US"</div> ist erforderlich',
    vehiclesEmpty: 'Sorry, wir haben keine Fahrzeuge.',
    technical: {
      message: 'Sorry, wir machen einige technische arbeiten auf der Website',
      btn: 'Zur Startseite gehen'
    },
    notFound: {
      message: 'Daten nicht gefunden',
      btn: 'Zur Seite Fahrzeuge'
    },
    reloadPage: 'Seite neu laden'
  },
  ui: {
    uiPreloader: 'Laden',
    reset: 'Zurücksetzen',
    finish: 'Finisched',
    notFound: 'Nicht gefunden'
  },
  errors: {
    requiredField: 'Dieses Feld ist erforderlich',
    invalidEmail: 'E-Mail ist nicht gültig',
    invalidPhone: 'Telefon ist nicht gültig',
    invalidPrice: 'Preis ist nicht gültig'
  },
  print: {
    qrLinkText:
      'Für weitere Fahrzeuginformationen scannen Sie diesen QR-Code oder öffnen Sie die folgende URL:',
    qrGoogle: {
      title: 'In Google Maps öffnen',
      step1: 'Offene Kamera',
      step2: 'QR-Code scannen',
      step3: 'Karte öffnen'
    }
  }
};
