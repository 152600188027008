<template>
  <div
    v-responsive="{
      'media-992': el => el.width < 992,
      'media-768': el => el.width < 768,
      'media-576': el => el.width < 576,
      'media-480': el => el.width < 480
    }"
  >
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__vehicles">
        <NavigatePanel
          :breadcrumbs="crumbs"
          :isKfzWhite="true"
          :backspaceLabel="$t('vehicles.navigatePanel.buttonBackspace')"
        />
        <div class="vms__vehicles-filters">
          <PriceMileage @setParamForSlider="setParamForSlider" />
          <div
            class="vms__container"
            v-if="
              (listParams && listParams.makeCodes) ||
              (listParams.colorCodes && listParams.colorCodes.length)
            "
          >
            <div class="vms__vehicles-filters_btns">
              <div class="vms__vehicles-filters_btns-title vms__OpenSansRegular">
                {{ $t('vehicles.filters.showFilters') }}
              </div>
              <div class="vms__vehicles-filters_btns-makes">
                <UIFilterMakes :makesFull="filters.makes" @UIFilterMakes="setMakes" />
              </div>
              <UIFilterColors :colorsFull="filters.colors" @UIFilterColors="setColors" />
            </div>
          </div>
        </div>
        <div v-if="filteredList && filteredList.totalCount !== 0" class="vms__container">
          <div class="vms__vehicles_list-head">
            <div class="sec-title-h1" data-cy="text__vehicles-amount">
              {{ filteredList && filteredList.totalCount }} {{ $t('vehicles.listTitle') }}
            </div>
            <UISelect
              class="vms__vehicles-sort"
              v-if="showSortList"
              :options="selectSort"
              :selected="
                listParams && listParams.orderByColumns && listParams.orderByColumns[0].key
                  ? Array(listParams.orderByColumns[0].key)
                  : []
              "
              :label="$t('vehicles.filters.selectLabel')"
              @UISelect="setSortFilter"
              cy-name="sort"
            />
            <UICardSwitcher
              v-if="showroom && showroom.showListSwitch && showroom.showGallerySwitch"
              class="ml-4"
              :isGrid="listOptions.ga"
              @on-change="changeTypeCards"
            />
          </div>
          <VehiclesList
            v-if="filteredList && filteredList.items"
            :stepPagination="initOptions && initOptions.rpp"
            :list="filteredList && filteredList.items"
            @VehiclesListUpdateCount="updateVehiclesCount"
          />
        </div>
        <div v-else class="vms__container">
          <div class="vms__vehicles-empty-title vms__BarlowSemiCondensedRegular">
            {{ $t('vehicles.form.title') }}
          </div>
          <div class="vms__vehicles-empty-desc vms__OpenSansRegular">
            {{ $t('vehicles.form.desc') }}
          </div>
          <VehiclesForm />
          <VehiclesBestDeals :title="$t('vehicle.bestDealsTitle')" />
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import Responsive from '@/directives/Responsive';
import PageContainer from '@/containers/PageContainer';
import VehiclesList from '@/components/VehiclesList';
import VehiclesForm from '@/components/VehiclesForm';
import VehiclesBestDeals from '@/components/VehiclesBestDeals';
import NavigatePanel from '@/components/NavigatePanel';
import PriceMileage from '@/components/Filters/PriceMileage';
import UISelect from '@/components/ui/UISelect';
import UIFilterMakes from '@/components/ui/UIFilterMakes';
import UIFilterColors from '@/components/ui/UIFilterColors';
import UIBurger from '@/components/ui/UIBurger';
import UICardSwitcher from '@/components/ui/UICardSwitcher';

export default {
  name: 'Vehicles',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    PageContainer,
    VehiclesList,
    VehiclesForm,
    VehiclesBestDeals,
    NavigatePanel,
    PriceMileage,
    UISelect,
    UIFilterMakes,
    UIFilterColors,
    UIBurger,
    UICardSwitcher
  },
  data() {
    return {
      dataIsReady: false,
      filterParams: {}
    };
  },
  beforeMount() {
    EventBus.$on('lang-changed', this.getPageData);
  },
  beforeDestroy() {
    EventBus.$off('lang-changed', this.getPageData);
  },
  created() {
    if (this.showroom) {
      this.$store.dispatch(
        'options/updateResultsPerPage',
        this.listOptions && this.listOptions.rpp
      );
      if (this.statusOptions === true) {
        this.filterParams = {
          ...this.listParams,
          PageSize: this.listOptions && this.listOptions.rpp
        };
        this.getVehiclesByFilters(this.filterParams);
        this.getShowroomAvailableFilters(this.filterParams);
      }
    }
    document.title = this.showroom.instanceName || 'Vms';
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('vehicles', {
      statusFilteredVehiclesLoaded: 'statusFilteredVehiclesLoaded',
      filteredList: 'filteredList'
    }),
    ...mapState('showrooms', {
      showroomLoaded: 'showroomLoaded',
      statusShowroom: 'statusShowroom',
      statusFilters: 'statusFilters',
      filters: 'filters',
      showroom: 'showroom'
    }),
    ...mapState('options', {
      statusOptions: 'statusOptions',
      initOptions: 'initOptions',
      listOptions: 'listOptions',
      showSortList: 'showSortList',
      mileageTo: 'mileageTo',
      priceFrom: 'priceFrom',
      priceTo: 'priceTo'
    }),
    selectSort() {
      return (
        this.showroom &&
        this.showroom.vehicleSortingFields &&
        this.showroom.vehicleSortingFields.map(v => ({
          key: v.key,
          name: this.$t(`vehicles.filters.selectSort.${v.key}`),
          option: v.key,
          payload: v
        }))
      );
    },
    crumbs() {
      return [
        { link: '/search', title: this.$t('breadcrumbs.search') },
        { link: '/vehicles', title: this.$t('breadcrumbs.vehicles') }
      ];
    },
    dataLoading() {
      return (
        this.showroomLoaded &&
        this.statusShowroom &&
        this.statusFilteredVehiclesLoaded &&
        this.statusFilters
      );
    }
  },
  watch: {
    statusOptions(status) {
      if (status === true) {
        this.filterParams = {
          ...this.listParams,
          PageSize: this.listOptions && this.listOptions.rpp
        };
        this.getVehiclesByFilters(this.filterParams);
        this.getShowroomAvailableFilters(this.filterParams);
      }
    },
    list(newValue) {
      if (newValue) {
        this.$store.dispatch('options/updateMaxMileage', newValue.mileageTo);
        this.$store.dispatch('options/updateMinPrice', newValue.priceFrom);
        this.$store.dispatch('options/updateMaxPrice', newValue.priceTo);
      }
    },
    dataLoading(status) {
      if (status === true) {
        this.$nextTick(() => {
          this.dataIsReady = true;
        });
      }
    }
  },
  methods: {
    getPageData() {
      this.$store.dispatch('params/setParams', this.filterParams);
      this.$store.dispatch('vehicles/getVehiclesByFilters', this.filterParams);
    },
    getVehiclesByFilters(params) {
      this.$store.dispatch('params/setParams', params);
      this.$store.dispatch('vehicles/getVehiclesByFilters', params);
    },
    setParamForSlider(param) {
      const paramName = Object.keys(param)[0];
      const paramValue = param[Object.keys(param)[0]];
      this.filterParams[paramName] = paramValue;
      this.getVehiclesByFilters(this.filterParams);
    },
    setMakes(makeCodes) {
      this.filterParams = {
        ...this.filterParams,
        makeCodes
      };
      this.getVehiclesByFilters(this.filterParams);
    },
    getShowroomAvailableFilters(params) {
      this.$store.dispatch('showrooms/getShowroomsFilters', params);
    },
    updateVehiclesCount() {
      this.filterParams = {
        ...this.filterParams,
        PageSize: this.listOptions && this.listOptions.rpp
      };
      this.getVehiclesByFilters(this.filterParams);
    },
    changeTypeCards(isGrid) {
      if (isGrid) {
        this.$store.dispatch('options/updateVehicleCardType', true);
      } else {
        this.$store.dispatch('options/updateVehicleCardType', false);
      }
    },
    setColors(colorCodes) {
      this.filterParams = {
        ...this.filterParams,
        colorCodes
      };
      this.getVehiclesByFilters(this.filterParams);
    },
    setSortFilter(value) {
      let orderByColumns = null;
      if (!value.length) {
        delete this.filterParams.orderByColumns;
      } else {
        orderByColumns = [
          {
            key: value[0].payload.key,
            fieldName: value[0].payload.fieldName,
            isDescendingDirection: value[0].payload.isDescendingDirection
          }
        ];
      }
      this.filterParams = {
        ...this.filterParams,
        orderByColumns
      };
      this.getVehiclesByFilters(this.filterParams);
    },
    loadAll() {
      this.filterParams = {
        ...this.listParams,
        PageSize: this.filteredList && this.filteredList.totalCount
      };
      this.getVehiclesByFilters(this.filterParams);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles {
  background-color: #fff;

  &-filters {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 60px;

    &_btns {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      &-title {
        margin-right: 42px;
        font-size: 1rem;
        line-height: 1.5;
        color: #000;
      }

      &-makes {
        margin-right: 12px;
      }
    }
  }

  &_list-head {
    display: flex;
    justify-content: flex-start;

    .vms__ui-select {
      min-width: 334px;
      margin-left: auto;
    }
  }

  /deep/ .vms__navigate-panel_buttons {
    .vms__ui-button {
      background-color: transparent;
    }
  }

  &-empty-title {
    line-height: 1.15;
    letter-spacing: -0.04px;
    font-size: 1.625rem;
    color: #000;
    margin-bottom: 34px;
  }

  &-empty-desc {
    color: #000;
    opacity: 0.8;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 600;
  }

  .vms__vehicles-form {
    margin: 60px 0;
  }
}

@mixin media-992() {
  &_list-head {
    flex-wrap: wrap;

    .vms__ui-select {
      width: 100%;
      order: 2;
      margin-top: 15px;
    }
  }

  /deep/ .vms__ui-card-switcher {
    order: 1;
    margin-left: auto !important;
  }
}

@mixin media-768() {
  &-filters {
    margin-top: 60px;

    .vms__container {
      display: flex;
      flex-direction: column;
      padding: 0;

      .vms__ui-slider {
        width: 100%;

        &:first-child {
          margin-bottom: 25px;
        }
      }
    }
  }

  /deep/ &_item {
    &-img {
      height: auto;
    }
  }
}

@mixin media-576() {
  &_item {
    &-img {
      height: 210px;

      img {
        height: 210px;
      }
    }
  }

  /deep/ .vms__ui-card-switcher {
    display: none;
  }
}

@mixin media-480() {
  /deep/ &_item {
    &-img {
      height: 210px;

      /deep/ .progressive-background-image {
        height: 210px;
      }
    }
  }

  &_list-head .vms__ui-select {
    min-width: 100%;
  }
}

.media-992 .vms__vehicles {
  @include media-992();
}

.media-768 .vms__vehicles {
  @include media-768();
}

.media-576 .vms__vehicles {
  @include media-576();
}

.media-480 .vms__vehicles {
  @include media-480();
}

@media screen and (max-width: 992px) {
  .vms__vehicles {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__vehicles {
    @include media-768();
  }
}

@media screen and (max-width: 576px) {
  .vms__vehicles {
    @include media-576();
  }
}

@media screen and (max-width: 480px) {
  .vms__vehicles {
    @include media-480();
  }
}
</style>
