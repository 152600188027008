<template>
  <div
    v-responsive="{
      'media-768': el => el.width < 768,
      'media-480': el => el.width < 480
    }"
  >
    <UIBurger />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__home">
        <NavigatePanel
          :isKfz="showroom && showroom.includeCars"
          :isMotocycle="showroom && showroom.includeMotorcycles"
          :isVan="false"
        />
        <Filters :params="listParams" @scrollToTop="goTopPage" />
        <div class="vms__container">
          <VehiclesBestDeals :title="$t('home.filters.bestDealsTitle')" />
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import PageContainer from '@/containers/PageContainer';
import Responsive from '@/directives/Responsive';
import VehiclesBestDeals from '@/components/VehiclesBestDeals';
import Filters from '@/components/Filters';
import NavigatePanel from '@/components/NavigatePanel';
import UIBurger from '@/components/ui/UIBurger';

export default {
  name: 'Search',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: {
    PageContainer,
    VehiclesBestDeals,
    Filters,
    NavigatePanel,
    UIBurger
  },
  data() {
    return {
      dataIsReady: false
    };
  },
  created() {
    document.title = this.showroom.instanceName || 'Vms';
  },
  computed: {
    ...mapState('vehicles', {
      statusFilteredVehiclesLoaded: 'statusFilteredVehiclesLoaded'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom',
      showroomLoaded: 'showroomLoaded',
      statusFilters: 'statusFilters',
      showroom: 'showroom'
    }),
    dataLoading() {
      return (
        this.showroomLoaded &&
        this.statusShowroom &&
        this.statusFilteredVehiclesLoaded &&
        this.statusFilters
      );
    }
  },
  watch: {
    dataLoading(status) {
      if (status === true) {
        setTimeout(() => {
          this.dataIsReady = true;
        }, 500);
      }
    }
  },
  methods: {
    goTopPage() {
      const offset = window.offsetScrollTop || this.$root.offsetScrollTop || 0;
      window.scrollTo(0, this.MixinGetOffset(this.$root.$el) - offset);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__home {
  height: auto;
  overflow: hidden;
  background-color: #fff;

  &_banner {
    min-height: 430px;
    background-image: url('../assets/img/home-banner.jpg');
    background-position: center;
    background-size: cover;

    .vms__container {
      height: 100%;
      min-height: 430px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    h1 {
      max-width: 440px;
      margin-bottom: 78px;

      span {
        line-height: 1.45;
        letter-spacing: -0.22px;
        font-size: 2.688rem;
        color: #fff;
        background-color: #062d29;
        box-shadow: 10px 0 0 var(--accent-dark), -10px 0 0 #062d29;
        padding-bottom: 5px;
      }
    }
  }

  .vms__filters {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  &_filters {
    margin-top: 54px;

    h2 {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    &-subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-reset {
      display: flex;
      align-items: center;
      background-color: transparent;

      span {
        line-height: 1.36;
        color: #000;
        opacity: 0.6;
        font-size: 0.688rem;
        font-weight: 600;
      }

      i {
        margin-left: 4px;
      }
    }

    &-body-type {
      margin-top: 24px;
      background-color: #f2f2f2;
      padding: 23px 0;

      &_list {
        height: 74px;
        display: flex;
        justify-content: flex-start;

        &-item {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          p {
            line-height: 1.43;
            font-size: 1.313rem;
            letter-spacing: -0.04px;
            color: #000;
            margin-bottom: 5px;
          }

          i {
            font-size: 1.75rem;
            color: #062d29;
            opacity: 0.7;
          }

          span {
            line-height: 1.36;
            color: #000;
            opacity: 0.6;
            font-size: 0.688rem;
            font-weight: 600;
          }

          &:hover {
            background-color: #e6eae9;
          }
        }
      }
    }

    &-sliders {
      width: 100%;
      background-color: #2d9c90;
      padding: 35px 0;

      .vms__container {
        display: flex;
        justify-content: space-between;
      }

      .vms__ui-slider {
        width: calc(50% - 42px);
        color: #fff;
      }
    }

    &-selects {
      width: 100%;
      padding-top: 40px;
      background-color: #f2f2f2;

      .vms__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .vms__ui-select {
        width: calc(50% - 42px);
        margin-bottom: 40px;
      }
    }

    &-btns {
      background-color: #f2f2f2;
      padding: 40px 0;

      .vms__container {
        display: flex;
        justify-content: space-between;
      }

      button {
        &:first-child {
          background-color: #fff;
        }

        &:last-child {
          background-color: #2d9c90;
          color: #fff;
        }
      }
    }
  }

  .vms__best-deals {
    margin-top: 60px;
  }
}

@mixin media-768() {
  .vms__best-deals,
  .vms__filters {
    margin-top: 60px;
  }
}

@mixin media-480() {
  &_banner {
    min-height: 230px;

    .vms__container {
      min-height: 230px;
    }

    h1 {
      margin-bottom: 30px;
      line-height: 1.1;

      span {
        font-size: 1.875rem;
        line-height: 0;
      }
    }
  }
}

.media-768.vms__home {
  @include media-768();
}

.media-480.vms__home {
  @include media-480();
}

@media screen and (max-width: 768px) {
  .vms__home {
    @include media-768();
  }
}

@media screen and (max-width: 480px) {
  .vms__home {
    @include media-480();
  }
}
</style>
