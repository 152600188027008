<template>
  <div ref="detailPage" v-responsive="{
    'media-992': el => el.width < 992,
    'media-576': el => el.width < 576,
    'media-480': el => el.width < 480
  }">
    <UIBurger />
    <Print :payload="item" />
    <PageContainer :loaded="dataIsReady">
      <div class="vms__vehicles-detail">
        <NavigatePanel :breadcrumbs="crumbs" :isKfzWhite="true"
          :backspaceLabel="$t('vehicle.navigatePanel.buttonBackspace')" />
        <div class="vms__container" ref="vehicleName">
          <div class="vms__vehicles-detail-head_title vms__BarlowLight" data-cy="details__vehicle-name" v-if="item && item.title">{{ item.title }}</div>
          <div class="vms__vehicles-detail-head_desc vms__OpenSansRegular" data-cy="details__vehicle-id" v-if="item && item.importedId">{{
              $t('vehicle.offerNumber')
          }} {{ item.importedId }} {{ item.commissionNumber ? ', ' + item.commissionNumber :
    ''
}}</div>
        </div>
        <Carousel v-if="dataIsReady" :images="item && item.images" @OpenImgPreview="showModalImgPreview"
          class="vms__vehicles-detail_mobile-carousel" />
        <div class="vms__vehicles-detail_card">
          <div class="vms__container">
            <Carousel v-if="dataIsReady" :images="item && item.images" @OpenImgPreview="showModalImgPreview"
              class="vms__vehicles-detail_card-carousel" />
            <div class="vms__vehicles-detail_card-info">
              <!-- Finance -->
              <Finance v-if="dataIsReady" :payload="item" />
              <!-- End Finance -->
              <div class="vms__vehicles-detail_card-info_detail">
                <UIParam v-if="item && item.mileage" :label="$t('vehicle.card.detail.item1')"
                  :value="`${MixinFormatThousands(item.mileage)} km`" cy-value="details__vehicle-mileage">
                  <div class="mdi mdi-highway" />
                </UIParam>
                <UIParam v-if="item && item.firstRegistrationDate" :label="$t('vehicle.card.detail.item2')"
                  :value="`${MixinFormatDate(item.firstRegistrationDate, 'MMMM YYYY')}`" cy-value="details__vehicle-initial-reg">
                  <div class="mdi mdi-progress-clock" />
                </UIParam>
                <UIParam v-if="item && item.primaryEnginePowerKw" :label="$t('vehicle.card.detail.item3')"
                  :value="`${item.primaryEnginePowerKw} KW`"
                  cy-value="details__vehicle-perfomance"
                  :additional-value="showroom && showroom.showPSPower && `${item.primaryEnginePowerPs} PS`">
                  <div class="mdi mdi-speedometer" />
                </UIParam>
                <UIParam v-if="item && item.fuelName" :label="$t('vehicle.card.detail.item4')"
                  :value="`${item.fuelName}`" cy-value="details__vehicle-fuel">
                  <div class="mdi mdi-gas-station" />
                </UIParam>
              </div>
              <div class="vms__vehicles-detail_card-info_buttons">
                <UIButton v-if="showroom && showroom.showPriceAlarmInDetails" shadow
                  class="vms__vehicles-detail_card-info_buttons_price" @click="showModalPrice" background="#fff"
                  color="#000" data-cy="btns__price-alarm">
                  <UIIcon name="mdi-bell-outline" left />
                  {{ $t('vehicle.card.button2') }}
                </UIButton>
              </div>
            </div>
          </div>
          <ControlButtons :compareActive="showCompare" :shareActive="false" :printActive="true" :payload="item" />
        </div>
        <Features v-if="dataIsReady && item" :features="features" :payload="item" />
        <Equipments :equipments="item && item.equipments" :otherEquipments="item && item.otherEquipments"
          :autoleasenCalculator="autoleasenCalculator" />

        <div v-if="showroom && showroom.autouncleWidgetEnabled && showroom.autouncleWidgetConfigKey"
          class="vms__container">
          <AutoUncleSection />
        </div>

        <!-- Contacts & Map -->
        <WrapContactsMap v-if="item" :vehicleInfo="item" :dataIsReady="dataIsReady" />
        <!-- END -->

        <div class="vms__container vms__vehicles-detail_next-prev">
          <UIButton shadow v-if="validatePrevButton" class="vms__vehicles-detail_prev-car" @click="goPrevVehicle"
            background="#3b3e3c" color="#fff" data-cy="btns__prev-vehicle">
            <UIIcon name="mdi-arrow-left" left />
            {{ $t('vehicle.prevCar') }}
          </UIButton>
          <UIButton shadow v-if="validateNextButton" class="vms__vehicles-detail_next-car" @click="goNextVehicle"
            background="#3b3e3c" color="#fff" data-cy="btns__next-vehicle">
            {{ $t('vehicle.nextCar') }}
            <UIIcon name="mdi-arrow-right" right />
          </UIButton>
        </div>
        <div class="vms__container">
          <VehiclesBestDeals :title="$t('vehicle.bestDealsTitle')" />
        </div>
        <Modal ref="ModalRequest">
          <ModalRequest @ModalClose="closeModalRequest" />
        </Modal>
        <Modal ref="ModalPrice">
          <ModalPrice @ModalClose="closeModalPrice" />
        </Modal>
        <Modal ref="ModalContact">
          <ModalContact @ModalClose="closeModalContact" />
        </Modal>
        <Modal maxWidth="70%" ref="ModalImgPreview">
          <ModalImgPreview :images="item && item.images" :slideIndex="slideIndex" />
        </Modal>
      </div>
    </PageContainer>
    <FloatButtons :item="item" @on-price="showModalPrice" />
    <transition name="fade">
      <AutoUncleFloatingTooltip ref="autouncleFloatingWidget" @on-close="hideFloatingAutoUncleWidget" v-if="
        autouncleModalVisibleDelay &&
        autouncleModalVisible &&
        autouncleTooltip !== 'false' &&
        showroom &&
        showroom.autouncleFloatingWidgetEnabled &&
        showroom.autouncleWidgetConfigKey
      " />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { loadAutoUncle } from '@/helpers/connectScripts';
import WaveAnimation from '@/directives/WaveAnimation';
import Responsive from '@/directives/Responsive';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import VehiclesBestDeals from '@/components/VehiclesBestDeals';
import NavigatePanel from '@/components/NavigatePanel';
import ControlButtons from '@/components/ControlButtons';
import ModalRequest from '@/components/Modals/ModalRequest';
import ModalPrice from '@/components/Modals/ModalPrice';
import ModalImgPreview from '@/components/Modals/ModalImgPreview';
import ModalContact from '@/components/Modals/ModalContact';
import Modal from '@/containers/Modal';
import UIParam from '@/components/ui/UIParam';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import UIBurger from '@/components/ui/UIBurger';
import Carousel from '@/components/VehicleDetail/Carousel';
import Equipments from '@/components/VehicleDetail/Equipments';
import Features from '@/components/VehicleDetail/Features';
import FloatButtons from '@/components/VehicleDetail/FloatingElements/FloatButtons';
import WrapContactsMap from '@/components/VehicleDetail/WrapContactsMap';
import PageContainer from '@/containers/PageContainer';
import Print from '@/components/VehicleDetail/Print';
import Finance from '@/components/VehicleDetail/Finance';
import AutoUncleSection from '@/components/VehicleDetail/AutoUncleSection';
import AutoUncleFloatingTooltip from '@/components/VehicleDetail/FloatingElements/AutoUncleFloatingTooltip';

export default {
  name: 'VehiclesDetail',
  mixins,
  directives: {
    WaveAnimation,
    responsive: Responsive
  },
  components: {
    VehiclesBestDeals,
    NavigatePanel,
    ControlButtons,
    ModalRequest,
    ModalPrice,
    ModalImgPreview,
    Modal,
    ModalContact,
    UIParam,
    UIButton,
    UIIcon,
    PageContainer,
    UIBurger,
    Carousel,
    Equipments,
    Features,
    FloatButtons,
    WrapContactsMap,
    Print,
    Finance,
    AutoUncleSection,
    AutoUncleFloatingTooltip
  },
  data() {
    return {
      showroomUid: 0,
      id: 0,
      vehicleIndex: 0,
      culture: '',
      postData: {},
      dataIsReady: false,
      slideIndex: 0,
      autouncleModalVisible: true,
      autouncleModalVisibleDelay: false,
      autouncleTooltip: localStorage.getItem('vms__autouncle-tooltip')
    };
  },
  beforeMount() {
    EventBus.$on('open-modal-request', this.showModalRequest);
    EventBus.$on('open-modal-contact', this.showModalContact);
    EventBus.$on('lang-changed', this.getPageData);
    if (window.AuEmbed && window.AuEmbed.tradeInDelayedCta) {
      window.AuEmbed.tradeInDelayedCta.activate();
    }
  },
  beforeDestroy() {
    this.$store.commit('vehicles/VEHICLES_ITEM_CLEAR');
    EventBus.$off('open-modal-request', this.showModalRequest);
    EventBus.$off('open-modal-contact', this.showModalContact);
    EventBus.$off('lang-changed', this.getPageData);
    if (window.AuEmbed && window.AuEmbed.tradeInDelayedCta) {
      window.AuEmbed.tradeInDelayedCta.remove();
      document.getElementById('au-trade-init').remove();
      document.getElementById('au-trade-in-script').remove();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.changePosition);
    this.scrollbarContainer = document.getElementById('PageScrollbar');
    this.scrollbarContainer.addEventListener('ps-scroll-y', this.changePosition);
    this.changePosition();
  },
  destroyed() {
    window.removeEventListener('scroll', this.changePosition);
    this.scrollbarContainer = document.getElementById('PageScrollbar');
    this.scrollbarContainer.addEventListener('ps-scroll-y', this.changePosition);
  },
  created() {
    this.showroomUid = this.listParams.showroomUid;
    this.culture = this.listParams.culture;
    this.postData = {
      showroomUid: this.showroomUid,
      culture: this.culture,
      id: this.$route.params.id
    };
    if (this.showroomLoaded === true) {
      this.getPageData();
      this.autouncleModalSetVisibleDelay();
      if (this.showroom.autouncleFloatingWidgetEnabled || this.showroom.autouncleWidgetEnabled) {
        loadAutoUncle(this.showroom, this.$route.params.id);
      }
    }
  },
  computed: {
    autoleasenCalculator() {
      if (this.item?.autoleasenCalculatorSrc) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = this.item.autoleasenCalculatorSrc;
        return textArea.value;
      }
      return null;
    },
    ...mapState('showrooms', {
      statusShowroom: 'statusShowroom',
      showroomLoaded: 'showroomLoaded',
      showroom: 'showroom'
    }),
    ...mapState('vehicles', {
      statusVehicleLoaded: 'statusVehicleLoaded',
      statusFilteredVehiclesLoaded: 'statusFilteredVehiclesLoaded',
      item: 'item',
      list: 'list',
      filteredList: 'filteredList'
    }),
    ...mapState('cultures', {
      cultureSelected: 'cultureSelected'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('options', {
      googleMapsApiKey: 'googleMapsApiKey',
      showCompare: 'showCompare'
    }),
    ...mapState('options', {
      listOptions: 'listOptions',
      initOptions: 'initOptions'
    }),
    features() {
      if (this.item) {
        return [
          {
            label: this.$t('vehicle.featuresLabels.slide1'),
            value: this.item.cO2Emission ? `${this.item.cO2Emission} g/km` : null,
            icon: 'mdi-leaf',
            cyValue: 'ribbon__co2emission'
          },
          {
            label: this.$t('vehicle.featuresLabels.slideDrive'),
            value: this.item.driveName || null,
            icon: 'vms-icon-drive',
            cyValue: 'ribbon__drive'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide2'),
            value: this.item.fuelConsumptionCombined || null,
            icon: 'mdi-water',
            cyValue: 'ribbon__consumption'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide3'),
            value: this.item.gearboxName || null,
            icon: 'mdi-source-branch',
            cyValue: 'ribbon__gearboxes'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide4'),
            value: this.item.primaryEngineCapacityCcm ? `${this.item.primaryEngineCapacityCcm} ccm` : null,
            icon: 'mdi-piston',
            cyValue: 'ribbon__cubic-capacity'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide5'),
            value: this.item.colorName || null,
            icon: 'mdi-format-color-fill',
            cyValue: 'ribbon__color'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide6'),
            value: this.item.upholsteryName || null,
            icon: 'mdi-seat-recline-extra',
            cyValue: 'ribbon__upholstery'
          }
        ].filter(f => f.value !== null);
      }
      return [];
    },
    crumbs() {
      return [
        { link: '/search', title: this.$t('breadcrumbs.search') },
        { link: '/vehicles', title: this.$t('breadcrumbs.vehicles') },
        { link: `/vehicles/${this.$route.params.id}`, title: this.$t('breadcrumbs.view') }
      ];
    },
    validateNextButton() {
      return (
        (this.filteredList && this.filteredList.items && this.filteredList.totalCount) >
        this.vehicleIndex + 1
      );
    },
    validatePrevButton() {
      return this.vehicleIndex > 0;
    },
    dataLoading() {
      return this.statusShowroom && this.statusFilteredVehiclesLoaded && this.statusVehicleLoaded;
    }
  },
  watch: {
    $route(to) {
      /* eslint object-shorthand: "error" */
      this.dataIsReady = false;
      this.postData = {
        showroomUid: this.listParams.showroomUid,
        culture: this.listParams.culture,
        id: to.params.id
      };
      this.getPageData();
    },
    showroomLoaded(loaded) {
      if (loaded === true) {
        this.getPageData();
        if (this.showroom.autouncleFloatingWidgetEnabled || this.showroom.autouncleWidgetEnabled) {
          loadAutoUncle(this.showroom, this.$route.params.id);
        }
      }
    },
    statusVehicleLoaded(status) {
      if (status === true && this.item) {
        document.title = this.item.title;
      }
    },
    statusFilteredVehiclesLoaded(status) {
      if (status === true) {
        const items = this.filteredList && this.filteredList.items;
        if (items && items.length) {
          const index = items.findIndex(e => e.id === Number.parseInt(this.$route.params.id, 10));
          if (index !== -1) {
            this.vehicleIndex = index;
          }
        }
      }
    },
    dataLoading(status) {
      if (status === true) {
        setTimeout(() => {
          this.dataIsReady = true;
          this.autouncleModalSetVisibleDelay();
        }, 500);
      }
    }
  },
  methods: {
    hideFloatingAutoUncleWidget() {
      this.autouncleTooltip = 'false';
      localStorage.setItem('vms__autouncle-tooltip', false);
    },
    changePosition() {
      const offsetTop = this.MixinGetOffset(this.$root.$el);
      const windowHeight = window.innerHeight;
      const widgetHeight = this.$root.$el.getBoundingClientRect().height;
      const scrollTop = window.scrollY;
      if (
        scrollTop > offsetTop - windowHeight / 1.4 &&
        scrollTop + windowHeight <= offsetTop + widgetHeight
      ) {
        this.autouncleModalVisible = true;
      } else {
        this.autouncleModalVisible = false;
      }
    },
    autouncleModalSetVisibleDelay() {
      const observer = new IntersectionObserver(
        (entries, obs) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setTimeout(() => {
                this.autouncleModalVisibleDelay = true;
              }, 4000);
              obs.unobserve(this.$root.$el);
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(this.$root.$el);
    },
    async goNextVehicle() {
      if (this.filteredList.items.length <= this.vehicleIndex + 1) {
        await this.loadMoreVehicles();
      }
      if (this.filteredList.items.length > this.vehicleIndex + 1) {
        const nextId = this.filteredList.items[this.vehicleIndex + 1].id;
        this.postData.id = nextId;
        this.$router.push(`/vehicles/${nextId}`);
      }
    },
    goPrevVehicle() {
      if (this.vehicleIndex > 0) {
        const prevId = this.filteredList.items[this.vehicleIndex - 1].id;
        this.postData.id = prevId;
        this.$router.push(`/vehicles/${prevId}`);
      }
    },
    async loadMoreVehicles() {
      this.$store.dispatch(
        'options/updateResultsPerPage',
        this.listOptions.rpp + this.initOptions.rpp
      );
      const filterParams = {
        ...this.listParams,
        PageSize: this.listOptions && this.listOptions.rpp
      };
      await this.$store.dispatch('vehicles/getVehiclesByFilters', filterParams);
    },
    getPageData() {
      this.postData = {
        showroomUid: this.listParams.showroomUid,
        culture: this.listParams.culture,
        id: this.$route.params.id
      };
      this.$store.dispatch('vehicles/getVehicle', this.postData);
      const filterParams = {
        ...this.listParams,
        PageSize: this.listOptions && this.listOptions.rpp
      };
      this.$store.dispatch('vehicles/getVehiclesByFilters', filterParams);
    },
    nextSlideCard() {
      this.$refs.cardCarousel.goToNext();
    },
    closeModalRequest() {
      this.$refs.ModalRequest.modalClose();
    },
    showModalRequest() {
      this.$refs.ModalRequest.modalShow();
    },
    showModalContact() {
      if (this.showroom && this.showroom.whatsappNumber) {
        this.$refs.ModalContact.modalShow();
      } else {
        this.showModalRequest();
      }
    },
    closeModalPrice() {
      this.$refs.ModalPrice.modalClose();
    },
    closeModalContact() {
      this.$refs.ModalContact.modalClose();
    },
    showModalPrice() {
      this.$refs.ModalPrice.modalShow();
    },
    showModalImgPreview(index) {
      this.slideIndex = index;
      this.$refs.ModalImgPreview.modalShow();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__vehicles-detail {
  padding-bottom: 70px;

  /deep/ .vms__autouncle_section {
    margin-top: 50px;
  }

  .vms__equipments-container {
    display: flex;

    .vms__equipments {
      width: 75%;
    }

    .vms__calculator {
      width: 25%;
    }
  }

  height: auto;
  overflow: hidden;
  background-color: #fff;

  &-head_title {
    margin-top: 20px;
    font-size: 2.688rem;
    font-weight: 300;
    line-height: 1.37;
    color: #000;
    letter-spacing: -1.52px;
  }

  &-head_desc {
    line-height: 1.36;
    font-size: 0.688rem;
    font-weight: 600;
    color: var(--text-color-black);
    opacity: 0.6;
  }

  &_mobile-carousel {
    width: 100%;
    height: auto;
    display: none;
  }

  &_next-prev {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  &_prev-car,
  &_next-car {
    width: 250px;
    margin: 40px 15px 0;
  }

  &_card {
    position: relative;
    background-color: #062d29;
    margin-top: 22px;

    >.vms__container {
      min-height: 496px;
      display: flex;
      position: relative;
    }

    &-info {
      max-width: 40%;
      min-width: 40%;
      position: relative;
      padding: 75px 42px 44px;
      box-sizing: border-box;

      /deep/ .vms__vehicle-detail_finance {
        padding-bottom: 20px;
      }

      &_detail {
        display: flex;
        flex-wrap: wrap;
        margin: 30px 0 35px;

        .vms__ui-param {
          width: 50%;
          margin-bottom: 13px;
          color: #fff;
        }
      }

      &_buttons {
        display: flex;

        &_contact {
          margin-right: 12px;
          background-color: #2d9c90;
          color: #fff;

          i {
            font-size: 1.375rem;
            color: #fff;
          }
        }

        &_price {
          margin-right: 12px;
        }
      }
    }

    .vms__control-buttons {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &_desc {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #cacaca;
    padding-left: 20px;
    margin-top: 77px;

    &_title {
      line-height: 1.15;
      letter-spacing: -0.04px;
      font-size: 1.625rem;
      color: #000;
    }

    &_p {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1.88;
      color: #000;
    }
  }
}

@mixin media-992() {
  &_card {
    .vms__container {
      flex-direction: column;
    }

    &-carousel {
      width: 100%;

      /deep/ .agile__actions {
        margin-top: 10px;
      }
    }

    &-info {
      width: 100%;
      max-width: none;
      padding: 30px 0;
      padding-top: 15px;

      &_detail {
        justify-content: space-between;
        margin-top: 50px;

        .vms__ui-param {
          width: auto;
          margin: 10px;
        }
      }

      &_discount {
        top: -53px;
        left: 0;
      }
    }
  }

  &_contacts {
    &-info {
      width: 100%;
      margin-bottom: 30px;
    }

    &-buttons {
      align-items: flex-start;

      >div {
        margin-bottom: 15px;
      }
    }
  }
}

@mixin media-576() {
  &-head_title {
    margin-top: 60px;
  }

  &_mobile-carousel {
    display: block;
    margin-top: 10px;
    background-color: #062d29;

    /deep/ .agile__nav-button--prev {
      padding-left: 10px;
    }

    /deep/ .agile__nav-button--next {
      padding-right: 10px;
    }
  }

  .equipments-container {
    display: flex;
    flex-direction: column;

    .equipments {
      width: 100%;
    }

    .vms__calculator {
      width: 100%;
    }
  }

  &_card {
    margin-top: 0;

    .vms__container {
      min-height: auto;
    }

    &-carousel {
      display: none;
    }

    &-info {
      min-height: auto;

      .vms__ui-param {
        width: 50%;
        margin: 10px 0;
      }

      &_discount {
        position: relative;
        top: -15px !important;
      }
    }
  }

  &_contacts {
    margin-top: 40px;
  }

  &_next-prev {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  &_next-car,
  &_prev-car {
    margin-top: 15px;
  }
}

@mixin media-480() {
  >.vms__container {
    .vms__vehicles-detail-head_desc {
      font-size: 0.625rem;
    }
  }

  &-head_title {
    font-size: 1.875rem;
  }

  &_card-info {
    &_title {
      font-size: 1.875rem;
    }

    &_price {
      margin-top: 6px;
      font-size: 0.625rem;

      &-discount {
        font-size: 0.938rem;
      }
    }

    &_discount {
      top: -45px;
    }
  }

  &_contacts {
    .vms__container {
      flex-direction: column;
    }

    &-gmap {
      width: 100%;
      border-radius: 4px;
    }
  }
}

.media-992 .vms__vehicles-detail {
  @include media-992();
}

.media-576 .vms__vehicles-detail {
  @include media-576();
}

.media-480 .vms__vehicles-detail {
  @include media-480();
}

@media screen and (max-width: 992px) {
  .vms__vehicles-detail {
    @include media-992();
  }
}

@media screen and (max-width: 576px) {
  .vms__vehicles-detail {
    @include media-576();
  }
}

@media screen and (max-width: 480px) {
  .vms__vehicles-detail {
    @include media-480();
  }
}

@media print {
  .vms__container {
    max-width: none;
  }

  .vms__float-buttons {
    display: none !important;
  }

  .vms__vehicles-detail {
    .vms__navigate-panel-wrap {
      display: none;
    }

    /deep/ .vms__vehicles-detail_carousel {
      .agile {
        display: none !important;
      }
    }

    /deep/ .vms__carousel-first-child {
      display: block !important;
    }

    .vms__vehicles-detail_next-prev {
      display: none;
    }

    .vms__control-buttons {
      display: none;
    }

    &_card {
      background-color: #fff !important;
      -webkit-print-color-adjust: exact;
    }

    .vms__navigate-panel {
      display: none;
    }

    &_card-info {
      padding: 70px 42px 40px;
    }

    &_card-info_buttons {
      display: none;
    }

    &_contacts-buttons {
      display: none;
    }

    .vms__best-deals {
      display: none;
    }

    &-head_title {
      margin-top: 0;
    }

    &_card>.vms__container {
      min-height: 455px;
    }

    /deep/ .ps__rail-y {
      display: none !important;
    }

    &_contacts {
      margin-top: 10px;

      /deep/ .vms__dealer-info-title {
        margin-bottom: 20px;
      }
    }

    &_card-info * {
      color: #000 !important;
    }
  }
}
</style>
