export const VEHICLES_BEST_DEALS_REQUEST = 'VEHICLES_BEST_DEALS_REQUEST';
export const VEHICLES_BEST_DEALS_SUCCESS = 'VEHICLES_BEST_DEALS_SUCCESS';
export const VEHICLES_BEST_DEALS_ERROR = 'VEHICLES_BEST_DEALS_ERROR';
export const VEHICLES_FILTERED_LIST_SUCCESS = 'VEHICLES_FILTERED_LIST_SUCCESS';
export const VEHICLES_FILTERED_LIST_REQUEST = 'VEHICLES_FILTERED_LIST_REQUEST';
export const VEHICLES_FILTERED_LIST_ERROR = 'VEHICLES_FILTERED_LIST_ERROR';
export const VEHICLES_BY_IDS_SUCCESS = 'VEHICLES_BY_IDS_SUCCESS';
export const VEHICLES_BY_IDS_REQUEST = 'VEHICLES_BY_IDS_REQUEST';
export const VEHICLES_BY_IDS_ERROR = 'VEHICLES_BY_IDS_ERROR';
export const VEHICLES_ITEM_SUCCESS = 'VEHICLES_ITEM_SUCCESS';
export const VEHICLES_ITEM_REQUEST = 'VEHICLES_ITEM_REQUEST';
export const VEHICLES_ITEM_ERROR = 'VEHICLES_ITEM_ERROR';
export const VEHICLES_ITEM_CLEAR = 'VEHICLES_ITEM_CLEAR';
export const VEHICLES_VIEW_TYPE_SUCCESS = 'VEHICLES_VIEW_TYPE_SUCCESS';
