<template>
  <div
    class="vms__not-found"
    v-responsive="{ 'media-768': el => el.width < 768 }"
    :style="{
      width: MixinGetContainerWidth(listParams.width),
      height: MixinGetContainerHeight(listParams.height)
    }"
  >
    <div class="vms__not-found_title"><span>404</span></div>
    <div class="vms__not-found_text vms__BarlowSemiCondensedRegular">{{ $t('notFound.text') }}</div>
    <UIButton @click="goToVehicles" background="#2d9c90" color="#fff">
      <UIIcon name="mdi-car" left />
      {{ $t('notFound.btn') }}
    </UIButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';
import Responsive from '@/directives/Responsive';

export default {
  name: 'NotFound',
  mixins,
  directives: {
    responsive: Responsive
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  components: { UIButton, UIIcon },
  created() {
    document.title = '404';
  },
  methods: {
    goToVehicles() {
      window.location.replace('#/vehicles');
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__not-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  background-color: #fff;

  &_title {
    width: 100%;
    height: 300px;
    position: relative;
    background-color: #2d9c90;

    span {
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      background-color: #fff;
      padding: 8px;
      font-size: 6rem;
      border-radius: 100%;
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
      color: #062d29;
    }
  }

  &_text {
    margin-top: 150px;
    line-height: 1.15;
    font-size: 2rem;
    color: #000;
    padding: 0 15px;
    text-align: center;
  }

  .vms__ui-button {
    margin-top: 20px;
  }
}

@mixin media-768() {
  &_title {
    height: 200px;
    margin-bottom: 50px;
  }

  &_text {
    font-size: 1.5rem;
    margin-top: 100px;
  }
}

.media-768 .vms__not-found {
  @include media-768();
}

@media screen and (max-width: 768px) {
  .vms__not-found {
    @include media-768();
  }
}
</style>
