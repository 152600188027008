const optionsService = (() => {
  let _service;

  function _installService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  /**
   *
   * @param {object} options - showroom + widget params
   *
   */
  function _isGridvehicleCard(options) {
    const type = String(options?.ga);
    if (type) {
      if (type === '1') {
        return false;
      }
      return true;
    }
    if (!options.showGallerySwitch && !options.showListSwitch) {
      return true;
    }
    return options.showGallerySwitch;
  }

  function _resultsPerPage(options) {
    if (options && options.rpp) {
      if (Number.isNaN(Number(options.rpp))) {
        return Number(options.resultsPerPage);
      }
      return Number(options.rpp);
    }
    if (options.resultsPerPage) {
      return Number(options.resultsPerPage);
    }
    return 20;
  }

  function _getOptions(options) {
    return {
      ga: _isGridvehicleCard(options),
      rpp: _resultsPerPage(options),
      googleMapsApiKey: options.googleMapsApiKey,
      techLanguage: options.techLanguage
    };
  }

  return {
    installService: _installService,
    isGridvehicleCard: _isGridvehicleCard,
    resultsPerPage: _resultsPerPage,
    getOptions: _getOptions
  };
})();

export default optionsService;
