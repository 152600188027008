<template>
  <div
    class="vms__ui-button vms__OpenSansRegular"
    :class="{
      'vms__ui-button--disabled': disabled,
      'vms__ui-button--shadow': shadow,
      'vms__ui-button--link': link,
      'vms__ui-button--href': href,
      'vms__ui-button--blank': blank
    }"
    @click="onClick"
    :style="propsStyles"
    :title="buttonTitle"
    :href="!link ? href : null"
  >
    <slot />
  </div>
</template>

<script>
import mixins from '@/mixins';

export default {
  name: 'UIButton',
  mixins,
  props: {
    /**
     * Set it to `true` to use prop `to`
     */
    link: {
      type: Boolean,
      default: false
    },
    /**
     * Open relative link(Vue router link)
     */
    to: {
      type: String,
      default: null
    },
    /**
     * Open absolute link
     */
    href: {
      type: String,
      default: null
    },
    /**
     * Open link in the new tab
     */
    blank: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Set box shadow for button
     */
    shadow: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    propsStyles() {
      const styles = [];
      if (this.background) {
        styles.push(`background-color: ${this.background}`);
      }
      if (this.color) {
        styles.push(`color: ${this.color}`);
      }
      return styles.join('; ');
    },
    buttonTitle() {
      if (this.to) {
        return this.to;
      }
      if (this.href && this.link) {
        return this.href;
      }
      return null;
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        if (this.link) {
          if (this.href) {
            if (this.blank) {
              this.MixinOpenLinkNewTab(this.href);
            } else {
              this.MixinOpenLinkThisTab(this.href);
            }
          } else {
            this.$router.push(this.to);
          }
        } else {
          this.$emit('click');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  outline: none;
  -webkit-appearance: none;
  padding: 8px 14px;
  line-height: 1.5;
  font-size: 1rem;
  text-align: center;
  transition: all 0.2s ease;

  > * {
    pointer-events: none;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .vms__ui-icon {
    overflow: unset;
  }

  &:hover {
    text-decoration: underline;
  }

  &--shadow {
    background-color: #fff;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05),
      0 1px 5px 0 rgba(0, 0, 0, 0.05);

    &:hover {
      filter: brightness(95%);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.07), 0 8px 10px 1px rgba(0, 0, 0, 0.07),
        0 3px 14px 2px rgba(0, 0, 0, 0.07);
      text-decoration: none;
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0 1px 1px -2px rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05),
        0 1px 5px 0 rgba(0, 0, 0, 0.05);
    }
  }

  &--disabled {
    cursor: not-allowed;
    filter: brightness(90%);

    &:hover {
      filter: brightness(90%);
      box-shadow: inherit;
      text-decoration: none;
    }

    &:active {
      transform: none;
    }
  }
}
</style>
