import {
  FAVORITES_ADD_FAVORITE_REQUEST,
  FAVORITES_ADD_FAVORITE_SUCCESS,
  FAVORITES_ADD_FAVORITE_ERROR,
  FAVORITES_UPDATE_FAVORITE_REQUEST,
  FAVORITES_UPDATE_FAVORITE_SUCCESS,
  FAVORITES_UPDATE_FAVORITE_ERROR
} from '@/store/modules/favorites/types';

export default {
  [FAVORITES_ADD_FAVORITE_REQUEST]: state => {
    state.statusFavorites = false;
  },
  [FAVORITES_ADD_FAVORITE_SUCCESS]: (state, payload) => {
    state.statusFavorites = true;
    state.favoriteList = payload;
  },
  [FAVORITES_ADD_FAVORITE_ERROR]: (state, payload) => {
    state.statusFavorites = payload;
  },
  [FAVORITES_UPDATE_FAVORITE_REQUEST]: state => {
    state.statusFavorites = false;
  },
  [FAVORITES_UPDATE_FAVORITE_SUCCESS]: (state, payload) => {
    state.statusFavorites = true;
    state.favoriteList = payload;
  },
  [FAVORITES_UPDATE_FAVORITE_ERROR]: (state, payload) => {
    state.statusFavorites = payload;
  }
};
