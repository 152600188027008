<template>
  <div
    v-responsive="{
      'media-992': el => el.width > 992,
      'media-768': el => el.width < 768,
      'media-340': el => el.width < 340
    }"
    class="vms__ui-menu"
  >
    <!-- Menu -->
    <transition name="fade">
      <div v-if="menuActive" class="vms__ui-menu_content">
        <!-- Add to Favorite list -->
        <div
          class="vms__ui-menu_content-item vms__OpenSansRegular"
          :class="{ 'vms__ui-menu_content-item--active': isFavorite }"
          @click="toggleFavorite"
          data-cy="three-dots__favorite-btn"
        >
          <UIIcon name="mdi-star" left />
          {{ $t('buttons.favorite') }}
        </div>

        <!-- Add to Compare -->
        <div
          class="vms__ui-menu_content-item vms__OpenSansRegular"
          :class="{ 'vms__ui-menu_content-item--active': isCompare }"
          @click="toggleCompare"
          data-cy="three-dots__compared-btn"
        >
          <UIIcon name="mdi-car-multiple" left />
          {{ $t('buttons.compare') }}
        </div>

        <!-- Open Contact Modal -->
        <div
          v-if="!contactBtnVisible"
          class="vms__ui-menu_content-item vms__ui-menu_content-item_contact vms__OpenSansRegular"
          @click="openModalContact"
        >
          <UIIcon left name="mdi-comment-multiple" size="16px" />
          {{ $t('buttons.contact') }}
        </div>

        <!-- Open price Modal -->
        <div
          v-if="!priceBtnVisible"
          class="vms__ui-menu_content-item vms__ui-menu_content-item_price vms__OpenSansRegular"
          @click="$emit('on-price')"
        >
          <UIIcon left name="mdi-bell-outline" />
          {{ $t('buttons.priceAlarm') }}
        </div>

        <!-- Open print page -->
        <div
          @click="onPrintPage"
          class="vms__ui-menu_content-item vms__ui-menu_content_print vms__OpenSansRegular"
          data-cy="three-dots__print-btn"
        >
          <UIIcon name="mdi-printer" left />
          {{ $t('buttons.print') }}
        </div>
      </div>
    </transition>

    <UIButton shadow @click="menuActive = !menuActive" data-cy="popup-btns__three-dots">
      <UIIcon name="mdi-dots-vertical" />
    </UIButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixins from '@/mixins';
import EventBus from '@/services/eventBusService';
import printHtmlBlock from 'print-html-block';
import Responsive from '@/directives/Responsive';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'UIMenu',
  mixins,
  components: { UIButton, UIIcon },
  directives: {
    responsive: Responsive
  },
  props: {
    contactBtnVisible: {
      type: Boolean,
      default: false
    },
    priceBtnVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuActive: false
    };
  },
  mounted() {
    document.addEventListener('click', this.closeMenu);
  },
  destroyed() {
    document.removeEventListener('click', this.closeMenu);
  },
  computed: {
    ...mapState('favorites', {
      favoriteList: 'favoriteList'
    }),
    ...mapState('compares', {
      compareList: 'compareList'
    }),
    isFavorite() {
      if (this.favoriteList) {
        return JSON.stringify(this.favoriteList).includes(this.$route.params.id);
      }
      return false;
    },
    isCompare() {
      if (this.compareList) {
        return JSON.stringify(this.compareList).includes(this.$route.params.id);
      }
      return false;
    }
  },
  methods: {
    closeMenu(e) {
      if (this.menuActive && !this.$el.contains(e.target)) {
        this.menuActive = false;
      }
    },
    toggleFavorite() {
      const { id } = this.$route.params;
      if(!+id) {
        return;
      }
      this.$store.dispatch('favorites/toggleFavorite', { id: +id });
      // this.menuActive = false;
    },
    toggleCompare() {
      const { id } = this.$route.params;
      if(!+id) {
        return;
      }
      this.$store.dispatch('compares/toggleCompare', { id: +id });
      // this.menuActive = false;
    },
    openModalContact() {
      EventBus.$emit('open-modal-contact');
    },
    onPrintPage() {
      const selector = '.vms__print';
      const options = {
        importStyle: true
      };
      printHtmlBlock(selector, options);
      this.menuActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-menu {
  position: relative;

  /deep/ .vms__ui-button {
    padding: 8px;
  }

  &_content {
    min-width: 250px;
    position: absolute;
    bottom: 110%;
    left: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1),
      0px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    overflow: hidden;
    padding: 10px;
    background-color: #fff;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 8px 10px;
      white-space: nowrap;
      margin: 2px 0;
      cursor: pointer;

      &--active {
        background-color: #2d9c90;
        color: #fff;
        border-radius: 3px;
      }
    }
  }
}

@mixin media-992() {
  &_content-item_contact {
    display: none;
  }

  &_content-item_price {
    display: none;
  }
}

@mixin media-768() {
  position: relative;

  &_content {
    left: auto;
    right: 0;

    &_print {
      display: none;
    }
  }
}

@mixin media-340() {
  &_content {
    min-width: 150px;
  }
}

.media-992.vms__ui-menu {
  @include media-992();
}

.media-768 .vms__ui-menu {
  @include media-768();
}

.media-340 .vms__ui-menu {
  @include media-340();
}

@media screen and (min-width: 992px) {
  .vms__ui-menu {
    @include media-992();
  }
}

@media screen and (max-width: 768px) {
  .vms__ui-menu {
    @include media-768();
  }
}

@media screen and (max-width: 340px) {
  .vms__ui-menu {
    @include media-340();
  }
}
</style>
