var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
    'media-992': function (el) { return el.width < 992; },
    'media-768': function (el) { return el.width < 768; },
    'media-576': function (el) { return el.width < 576; },
    'media-480': function (el) { return el.width < 480; }
  }),expression:"{\n    'media-992': el => el.width < 992,\n    'media-768': el => el.width < 768,\n    'media-576': el => el.width < 576,\n    'media-480': el => el.width < 480\n  }"}]},[_c('UIBurger'),_c('PageContainer',{attrs:{"loaded":_vm.dataIsReady}},[_c('div',{staticClass:"vms__vehicles"},[_c('NavigatePanel',{attrs:{"breadcrumbs":_vm.crumbs,"isKfzWhite":true,"backspaceLabel":_vm.$t('vehicles.navigatePanel.buttonBackspace')}}),_c('div',{staticClass:"vms__vehicles-filters"},[_c('PriceMileage',{on:{"setParamForSlider":_vm.setParamForSlider}}),(
            (_vm.listParams && _vm.listParams.makeCodes) ||
            (_vm.listParams.colorCodes && _vm.listParams.colorCodes.length)
          )?_c('div',{staticClass:"vms__container"},[_c('div',{staticClass:"vms__vehicles-filters_btns"},[_c('div',{staticClass:"vms__vehicles-filters_btns-title vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.filters.showFilters'))+" ")]),_c('div',{staticClass:"vms__vehicles-filters_btns-makes"},[_c('UIFilterMakes',{attrs:{"makesFull":_vm.filters.makes},on:{"UIFilterMakes":_vm.setMakes}})],1),_c('UIFilterColors',{attrs:{"colorsFull":_vm.filters.colors},on:{"UIFilterColors":_vm.setColors}})],1)]):_vm._e()],1),(_vm.filteredList && _vm.filteredList.totalCount !== 0)?_c('div',{staticClass:"vms__container"},[_c('div',{staticClass:"vms__vehicles_list-head"},[_c('div',{staticClass:"sec-title-h1",attrs:{"data-cy":"text__vehicles-amount"}},[_vm._v(" "+_vm._s(_vm.filteredList && _vm.filteredList.totalCount)+" "+_vm._s(_vm.$t('vehicles.listTitle'))+" ")]),(_vm.showSortList)?_c('UISelect',{staticClass:"vms__vehicles-sort",attrs:{"options":_vm.selectSort,"selected":_vm.listParams && _vm.listParams.orderByColumns && _vm.listParams.orderByColumns[0].key
                ? Array(_vm.listParams.orderByColumns[0].key)
                : [],"label":_vm.$t('vehicles.filters.selectLabel'),"cy-name":"sort"},on:{"UISelect":_vm.setSortFilter}}):_vm._e(),(_vm.showroom && _vm.showroom.showListSwitch && _vm.showroom.showGallerySwitch)?_c('UICardSwitcher',{staticClass:"ml-4",attrs:{"isGrid":_vm.listOptions.ga},on:{"on-change":_vm.changeTypeCards}}):_vm._e()],1),(_vm.filteredList && _vm.filteredList.items)?_c('VehiclesList',{attrs:{"stepPagination":_vm.initOptions && _vm.initOptions.rpp,"list":_vm.filteredList && _vm.filteredList.items},on:{"VehiclesListUpdateCount":_vm.updateVehiclesCount}}):_vm._e()],1):_c('div',{staticClass:"vms__container"},[_c('div',{staticClass:"vms__vehicles-empty-title vms__BarlowSemiCondensedRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.form.title'))+" ")]),_c('div',{staticClass:"vms__vehicles-empty-desc vms__OpenSansRegular"},[_vm._v(" "+_vm._s(_vm.$t('vehicles.form.desc'))+" ")]),_c('VehiclesForm'),_c('VehiclesBestDeals',{attrs:{"title":_vm.$t('vehicle.bestDealsTitle')}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }