import {
  PARAMS_REQUEST,
  PARAMS_SUCCESS,
  PARAMS_ERROR,
  PARAMS_SHOW_EMAILS
} from '@/store/modules/params/types';

export default {
  [PARAMS_REQUEST]: state => {
    state.statusParams = false;
  },
  [PARAMS_SUCCESS]: (state, params) => {
    state.statusParams = true;
    state.listParams = params;
  },
  [PARAMS_ERROR]: (state, payload) => {
    state.statusParams = payload;
  },
  [PARAMS_SHOW_EMAILS]: (state, payload) => {
    state.showEmail = payload;
  }
};
