<template>
  <div
    v-if="breadcrumbs && breadcrumbs.length"
    class="vms__ui-breadcrumbs"
    v-responsive="{ 'media-1024': el => el.width < 1024 }"
  >
    <UIButton link to="/" class="vms__ui-breadcrumbs_item vms__ui-breadcrumbs_home" data-cy="btns__to-kfz-page">
      <UIIcon name="mdi-car-hatchback" left />
      {{ $t('navigatePanel.kfz') }}
    </UIButton>
    <UIButton
      class="vms__ui-breadcrumbs_item"
      :class="{ 'vms__ui-breadcrumbs--active': breadcrumbs.length - 1 === i }"
      v-for="(crumb, i) of breadcrumbs"
      :key="i"
      link
      :to="crumb.link"
      :disabled="breadcrumbs.length - 1 === i"
      :data-cy="`btns__breadcrumbs-${i + 1}`"
    >
      <UIIcon name="mdi-chevron-right" left />
      <div>{{ crumb.title }}</div>
    </UIButton>
  </div>
</template>

<script>
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import UIButton from '@/components/ui/UIButton';
import UIIcon from '@/components/ui/UIIcon';

export default {
  name: 'UIBreadcrumbs',
  mixins,
  directives: {
    responsive: Responsive
  },
  components: { UIButton, UIIcon },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .vms__ui-button {
    display: flex;
    align-items: center;
    line-height: 1.71;
    opacity: 0.7;
    font-size: 1rem;
    color: #000;
    margin-left: 0;
    padding-left: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &--active {
    font-weight: bold;
    opacity: 1;
  }
}

@mixin media-1024() {
  .vms__ui-button {
    font-size: 0.875rem;

    .mdi {
      font-size: 1rem;
    }
  }
}

.media-1024 .vms__ui-breadcrumbs {
  @include media-1024();
}

@media screen and (max-width: 1024px) {
  .vms__ui-breadcrumbs {
    @include media-1024();
  }
}
</style>
