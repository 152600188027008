<template>
  <div class="vms__print">
    <div class="vms__print-title vms__BarlowLight">{{ payload && payload.title }}</div>
    <div class="vms__print-subtitle vms__OpenSansRegular">
      {{ $t('vehicle.offerNumber') }} {{ payload && payload.importedId }}
    </div>
    <div class="vms__print-info">
      <div class="vms__print-info_img">
        <progressive-img
          v-if="payload && payload.images && payload.images.length"
          :src="payload.images[0].small"
        />
      </div>
      <div class="vms__print-info_vehicle">
        <div class="vms__print-info_vehicle-finance">
          <Finance :payload="payload" />
        </div>
        <div class="vms__print-info_vehicle-params">
          <UIParam
            v-if="payload && payload.mileage"
            :label="$t('vehicle.card.detail.item1')"
            :value="`${MixinFormatThousands(payload.mileage)} km`"
          >
            <div class="mdi mdi-highway" />
          </UIParam>
          <UIParam
            v-if="payload && payload.firstRegistrationDate"
            :label="$t('vehicle.card.detail.item2')"
            :value="`${MixinFormatDate(payload.firstRegistrationDate, 'MMMM YYYY')}`"
          >
            <div class="mdi mdi-progress-clock" />
          </UIParam>
          <UIParam
            v-if="payload && payload.primaryEnginePowerKw"
            :label="$t('vehicle.card.detail.item3')"
            :value="`${payload.primaryEnginePowerKw} KW`"
          >
            <div class="mdi mdi-speedometer" />
          </UIParam>
          <UIParam
            v-if="payload && payload.fuelName"
            :label="$t('vehicle.card.detail.item4')"
            :value="`${payload.fuelName}`"
          >
            <div class="mdi mdi-gas-station" />
          </UIParam>
        </div>
        <div class="vms__print-info_vehicle-qr">
          <VueQrcode :width="100" :value="pageUrl" />
          <div class="vms__print-info_vehicle-qr_text vms__OpenSansRegular">
            {{ $t('print.qrLinkText') }}
            <div>{{ pageUrl }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="vms__print-features">
      <div v-for="(feature, i) of features" :key="i">
        <UIParam
          v-if="feature.value !== null"
          :title="
            feature.icon === 'mdi-seat-recline-extra'
              ? payload && payload.upholsteryColorText
              : null
          "
          :class="{ 'vms__ui-param--green': feature.icon === 'mdi mdi-leaf' }"
          :label="feature.label"
          :value="feature.icon === 'mdi-format-color-fill' ? colors : `${feature.value}`"
        >
          <UIIcon :name="feature.icon" />
          <div
            v-if="payload && payload.colorCssAlias && feature.icon === 'mdi-format-color-fill'"
            :class="{ 'vms__vehicle-color': payload.colorCssAlias }"
            :style="{ 'background-color': payload.colorCssAlias }"
            slot="value"
          />
        </UIParam>
      </div>
    </div>
    <div class="vms__print-extras">
      <Equipments
        :equipments="payload && payload.equipments"
        :otherEquipments="payload && payload.otherEquipments"
      />
    </div>
    <div class="vms__print-contact">
      <DealerInfo :info="payload && payload.dealer" :recaptcha="false" />
      <div class="vms__print-contact_qr-google">
        <div class="vms__print-contact_qr-google-title vms__BarlowSemiCondensedRegular">
          {{ $t('print.qrGoogle.title') }}
        </div>
        <div class="vms__print-contact_qr-google_content">
          <VueQrcode :width="100" :value="googleUrl" />
          <svg
            width="414"
            height="89"
            viewBox="0 0 414 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M98.0821 27.291C98.0821 42.0873 86.0873 54.0821 71.291 54.0821C56.4948 54.0821 44.5 42.0873 44.5 27.291C44.5 12.4948 56.4948 0.5 71.291 0.5C86.0873 0.5 98.0821 12.4948 98.0821 27.291Z"
                fill="white"
                stroke="#72878B"
              />
              <path
                d="M74.8516 33.791H65.8516V19.7754H74.8516V33.791ZM70.3516 37.7754C69.9453 37.7754 69.5859 37.6348 69.2734 37.3535C68.9922 37.0723 68.8516 36.7285 68.8516 36.3223C68.8516 35.8848 68.9922 35.5254 69.2734 35.2441C69.5859 34.9316 69.9453 34.7754 70.3516 34.7754C70.7891 34.7754 71.1484 34.9316 71.4297 35.2441C71.7109 35.5254 71.8516 35.8848 71.8516 36.3223C71.8516 36.7285 71.7109 37.0723 71.4297 37.3535C71.1484 37.6348 70.7891 37.7754 70.3516 37.7754ZM74.3828 16.7754H66.3672C65.6797 16.7754 65.0859 17.0254 64.5859 17.5254C64.1172 18.0254 63.8828 18.6191 63.8828 19.3066V36.2754C63.8828 36.9629 64.1172 37.5566 64.5859 38.0566C65.0859 38.5566 65.6797 38.8066 66.3672 38.8066H74.3828C75.0703 38.8066 75.6484 38.5566 76.1172 38.0566C76.6172 37.5566 76.8672 36.9629 76.8672 36.2754V19.3066C76.8672 18.6191 76.6172 18.0254 76.1172 17.5254C75.6484 17.0254 75.0703 16.7754 74.3828 16.7754Z"
                fill="#72878B"
              />
              <path
                d="M234.082 27.291C234.082 42.0873 222.087 54.0821 207.291 54.0821C192.495 54.0821 180.5 42.0873 180.5 27.291C180.5 12.4948 192.495 0.5 207.291 0.5C222.087 0.5 234.082 12.4948 234.082 27.291Z"
                fill="white"
                stroke="#72878B"
              />
              <path
                d="M198.852 19.7754H204.852V25.7754H198.852V19.7754ZM214.883 19.7754V25.7754H208.883V19.7754H214.883ZM208.883 30.791H210.852V28.7754H208.883V26.8066H210.852V28.7754H212.867V26.8066H214.883V28.7754H212.867V30.791H214.883V33.791H212.867V35.8066H210.852V33.791H207.852V35.8066H205.883V31.7754H208.883V30.791ZM210.852 30.791V33.791H212.867V30.791H210.852ZM198.852 35.8066V29.8066H204.852V35.8066H198.852ZM200.867 21.791V23.8066H202.883V21.791H200.867ZM210.852 21.791V23.8066H212.867V21.791H210.852ZM200.867 31.7754V33.791H202.883V31.7754H200.867ZM198.852 26.8066H200.867V28.7754H198.852V26.8066ZM203.867 26.8066H207.852V30.791H205.883V28.7754H203.867V26.8066ZM205.883 21.791H207.852V25.7754H205.883V21.791ZM196.883 17.8066V21.791H194.867V17.8066C194.867 17.2441 195.055 16.7754 195.43 16.4004C195.836 15.9941 196.32 15.791 196.883 15.791H200.867V17.8066H196.883ZM216.852 15.791C217.414 15.791 217.883 15.9941 218.258 16.4004C218.664 16.7754 218.867 17.2441 218.867 17.8066V21.791H216.852V17.8066H212.867V15.791H216.852ZM196.883 33.791V37.7754H200.867V39.791H196.883C196.32 39.791 195.836 39.5879 195.43 39.1816C195.055 38.8066 194.867 38.3379 194.867 37.7754V33.791H196.883ZM216.852 37.7754V33.791H218.867V37.7754C218.867 38.3379 218.664 38.8066 218.258 39.1816C217.883 39.5879 217.414 39.791 216.852 39.791H212.867V37.7754H216.852Z"
                fill="#72878B"
              />
              <path
                d="M370.082 27.291C370.082 42.0873 358.087 54.0821 343.291 54.0821C328.495 54.0821 316.5 42.0873 316.5 27.291C316.5 12.4948 328.495 0.5 343.291 0.5C358.087 0.5 370.082 12.4948 370.082 27.291Z"
                fill="white"
                stroke="#72878B"
              />
              <path
                d="M350.851 19.25C351.476 20.5781 351.729 22.0039 351.612 23.5273C351.534 25.0117 351.144 26.418 350.44 27.7461C349.894 28.6836 348.956 29.9922 347.628 31.6719C346.964 32.5312 346.456 33.1758 346.104 33.6055C345.323 34.6992 344.757 35.6562 344.405 36.4766C344.327 36.6328 344.21 36.9062 344.054 37.2969L343.644 38.5273C343.565 38.7617 343.487 38.9375 343.409 39.0547C343.331 39.1719 343.194 39.2305 342.999 39.2305C342.804 39.2305 342.628 39.1328 342.472 38.9375C342.433 38.8203 342.374 38.625 342.296 38.3516C341.905 37.1797 341.534 36.3008 341.183 35.7148C340.792 34.9727 340.187 34.0352 339.366 32.9023L350.851 19.25ZM339.425 22.2969L335.265 27.1602C335.577 27.8242 335.987 28.5273 336.495 29.2695C336.808 29.6992 337.315 30.3438 338.019 31.2031L338.253 31.5547C338.565 31.9062 338.819 32.2188 339.015 32.4922L344.229 26.3398H344.171C343.272 26.6523 342.374 26.6133 341.476 26.2227C340.616 25.793 339.991 25.1289 339.601 24.2305C339.522 24.0352 339.464 23.8008 339.425 23.5273C339.347 23.0586 339.347 22.6484 339.425 22.2969ZM336.202 17.5508C335.226 18.8008 334.64 20.2656 334.444 21.9453C334.249 23.5859 334.444 25.168 335.03 26.6914L340.011 20.7734L336.202 17.5508ZM345.753 14.6797L341.769 19.4844L341.827 19.4258C342.647 19.1523 343.468 19.1719 344.288 19.4844C345.147 19.7969 345.792 20.3438 346.222 21.125C346.417 21.5156 346.534 21.8867 346.573 22.2383C346.651 22.707 346.651 23.1367 346.573 23.5273L350.616 18.7812C350.069 17.8047 349.386 16.9844 348.565 16.3203C347.745 15.6172 346.827 15.0898 345.811 14.7383L345.753 14.6797ZM340.362 20.3633L345.226 14.5625C344.483 14.3672 343.741 14.2695 342.999 14.2695C341.788 14.2695 340.616 14.5234 339.483 15.0312C338.351 15.5 337.374 16.2031 336.554 17.1406L340.362 20.3633Z"
                fill="#72878B"
              />
              <text
                fill="#72878B"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="14"
                letter-spacing="0em"
              >
                <tspan x="24.5312" y="82.3105" class="vms__OpenSansRegular">
                  1. {{ $t('print.qrGoogle.step1') }}
                </tspan>
              </text>
              <text
                fill="#72878B"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="14"
                letter-spacing="0em"
              >
                <tspan x="160.086" y="82.3105" class="vms__OpenSansRegular">
                  2. {{ $t('print.qrGoogle.step2') }}
                </tspan>
              </text>
              <text
                fill="#72878B"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="14"
                letter-spacing="0em"
              >
                <tspan x="306.32" y="82.3105" class="vms__OpenSansRegular">
                  3. {{ $t('print.qrGoogle.step3') }}
                </tspan>
              </text>
              <path
                d="M169.354 27.3539C169.549 27.1586 169.549 26.842 169.354 26.6467L166.172 23.4648C165.976 23.2695 165.66 23.2695 165.464 23.4648C165.269 23.66 165.269 23.9766 165.464 24.1719L168.293 27.0003L165.464 29.8287C165.269 30.024 165.269 30.3406 165.464 30.5358C165.66 30.7311 165.976 30.7311 166.172 30.5358L169.354 27.3539ZM99 27.5003H169V26.5003H99V27.5003Z"
                fill="#72878B"
              />
              <path
                d="M305.354 27.3539C305.549 27.1586 305.549 26.842 305.354 26.6467L302.172 23.4648C301.976 23.2695 301.66 23.2695 301.464 23.4648C301.269 23.66 301.269 23.9766 301.464 24.1719L304.293 27.0003L301.464 29.8287C301.269 30.024 301.269 30.3406 301.464 30.5358C301.66 30.7311 301.976 30.7311 302.172 30.5358L305.354 27.3539ZM235 27.5003H305V26.5003H235V27.5003Z"
                fill="#72878B"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="414" height="89" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import VueQrcode from 'vue-qrcode';
import UIParam from '@/components/ui/UIParam';
import UIIcon from '@/components/ui/UIIcon';
import Finance from '@/components/VehicleDetail/Finance';
import Equipments from '@/components/VehicleDetail/Equipments';
import DealerInfo from '@/components/DealerInfo';

export default {
  name: 'Print',
  mixins,
  components: {
    VueQrcode,
    UIParam,
    UIIcon,
    Finance,
    Equipments,
    DealerInfo
  },
  props: {
    payload: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    pageUrl() {
      if (window.location.href) {
        return window.location.href;
      }
      return null;
    },
    googleUrl() {
      if (this.payload && this.payload.dealer) {
        const { latitude, longitude } = this.payload.dealer;
        return `http://maps.google.com/maps?&z=10&q=${latitude},${longitude}&ll=${latitude},${longitude}`;
      }
      return 'https://www.google.com/maps';
    },
    features() {
      if (this.payload) {
        return [
          {
            label: this.$t('vehicle.featuresLabels.slide1'),
            value: this.payload.cO2Emission
              ? `${this.payload.cO2Emission} g/km`
              : null,
            icon: 'mdi-leaf'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide2'),
            value: this.payload.fuelConsumptionCombined || null,
            icon: 'mdi-water'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide3'),
            value: this.payload.gearboxName || null,
            icon: 'mdi-source-branch'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide4'),
            value: this.payload.primaryEngineCapacityCcm ? `${this.payload.primaryEngineCapacityCcm} ccm` : null,
            icon: 'mdi-piston'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide5'),
            value: this.payload.colorName || null,
            icon: 'mdi-format-color-fill'
          },
          {
            label: this.$t('vehicle.featuresLabels.slide6'),
            value: this.payload.upholsteryName || null,
            icon: 'mdi-seat-recline-extra'
          }
        ].filter(f => f.value !== null);
      }
      return [];
    },
    colors() {
      if (this.payload) {
        let result;
        const { colorName, manufacturerColorName, isMetallicColor } = this.payload;
        if (manufacturerColorName && manufacturerColorName !== '') {
          result = `${manufacturerColorName ? `${manufacturerColorName},` : ''} ${
            isMetallicColor ? 'Metallic' : ''
          }`;
        } else {
          result = `${colorName ? `${colorName},` : ''} ${isMetallicColor ? 'Metallic' : ''}`;
        }
        return result.trim().replace(/,\s*$/, ''); // remove last comma
      }
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__print {
  height: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;

  &-title {
    margin: 20px 20px 0;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.37;
    color: #000;
    letter-spacing: -1.52px;
  }

  &-subtitle {
    line-height: 1.36;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    opacity: 0.6;
    margin: 0 20px 20px;
  }

  &-info {
    display: flex;

    &_img {
      width: 55%;
    }

    &_vehicle {
      position: relative;
      width: 45%;
      padding: 0 30px;

      &-params {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;

        /deep/ .vms__ui-param {
          width: 50%;
          margin-bottom: 15px;

          .vms__ui-param_info-p2 {
            font-size: 14px;
          }

          .vms__ui-param_icon .mdi {
            font-size: 20px;
          }
        }
      }

      &-qr {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px dashed #72878b;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px;
        padding-right: 10px;

        > svg {
          width: auto;
          height: 69px;
        }

        &_text {
          font-style: normal;
          font-size: 12px;
          line-height: 20px;
          margin-left: 10px;
          word-break: break-word;
          margin-top: 4px;

          > div {
            text-decoration: underline;
            color: #4ca7db;
          }
        }
      }
    }

    /deep/ .vms__vehicle-detail_finance {
      > div {
        color: #000;
      }

      &_title {
        font-size: 36px;
      }

      &_discount {
        height: 25px;
        -webkit-print-color-adjust: exact !important;
        background-color: #df4949;
        border-radius: 20px;
        padding: 0;
        top: 92px;
        left: 200px;

        /deep/ .vms__ui-discount-span {
          color: #fff !important;
          margin-top: 5px;
        }

        svg {
          display: none;
        }
      }

      &_price-discount {
        font-weight: 100;
        line-height: 1.36;
        opacity: 1;
        font-size: 36px;
        font-family: BarlowLight, serif;
      }
    }
  }

  &-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 25px 20px 0;

    > div {
      width: 25%;
      margin-top: 25px;
    }

    /deep/ .vms__ui-param {
      .vms__ui-param_info-p2 {
        font-size: 14px;
      }

      .vms__ui-param_icon .mdi {
        font-size: 20px;
      }
    }

    .vms__vehicle-color {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 100%;
      margin-left: 6px;
      background-color: transparent;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
      -webkit-print-color-adjust: exact !important;
    }

    .vms__vehicle-upholstery-color {
      width: 16px;
      height: 16px;
      border: 3px solid;
      display: inline-block;
      border-radius: 100%;
      margin-left: 6px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
      -webkit-print-color-adjust: exact !important;
    }
  }

  &-extras {
    /deep/ .vms__vehicles-detail_equipments_wrapper {
      max-width: none;
      margin: 0;
      padding: 0;

      .vms__vehicles-detail_equipments {
        padding: 0;
        border: 0;

        &_title {
          font-size: 22px;
        }
      }

      .vms__vehicles-detail_other-equipments_p {
        font-size: 12px;
      }

      .vms__vehicles-detail_other-equipments_title {
        font-size: 22px;
      }

      .vms__vehicles-detail_equipments-list {
        > div {
          min-height: 10px;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: flex-start;
          font-size: 12px;
          line-height: 1.6;

          .mdi-check {
            margin-top: 0;
            font-size: 12px;
          }
        }
      }

      .vms__vehicles-detail_other-equipments {
        padding: 0;
        border: 0;
      }
    }
  }

  &-contact {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    /deep/ .vms__dealer-info-dealer {
      padding: 0;
      font-size: 12px;
    }

    /deep/ .vms__dealer-info-tel {
      padding: 0;
      font-size: 12px;
    }

    /deep/ .vms__dealer-info-fax {
      padding: 0;
      font-size: 12px;
    }

    /deep/ .vms__dealer-info-link {
      padding: 0;
      font-size: 12px;
    }

    /deep/ .vms__dealer-address2 {
      padding: 0;
      font-size: 12px;
    }

    &_qr-google {
      &-title {
        width: auto;
        line-height: 1.15;
        letter-spacing: -0.04px;
        font-size: 22px;
        color: #000;
        margin-bottom: 40px;
      }

      &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px dashed #72878b;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px;

        > svg {
          width: auto;
          height: 69px;
        }
      }
    }
  }

  &_title {
    margin-bottom: 30px;
    line-height: 1.15;
    letter-spacing: -0.04px;
    font-size: 22px;
    color: #000;
  }

  /deep/ .vms__dealer-info-title {
    font-size: 22px;
  }
}

@media print {
  .vms__print {
    height: auto !important;
    opacity: 1 !important;
    overflow: visible !important;
  }
}
</style>
