<template>
  <div class="vms__ui-input_wrapper">
    <input
      class="vms__ui-input vms__OpenSansRegular"
      :class="{
        'vms__ui-input--error': error,
        'vms__ui-input--error-indent': errorMessage,
        'vms__ui-input--regular': regular
      }"
      :type="showedPassword ? 'text' : type"
      :placeholder="placeholder"
      v-model="value"
    />
    <div
      v-if="typeInput === 'password'"
      class="vms__ui-input_eye mdi"
      :class="{ 'mdi-eye-outline': showedPassword, 'mdi-eye-off-outline': !showedPassword }"
      @click="showedPassword = !showedPassword"
    ></div>
    <div v-if="error" class="vms__ui-input_wrapper-span vms__OpenSansRegular">
      {{ errorMessage }}
    </div>
    <div v-if="isLoading" class="vms__ui-input-loader">
      <div class="mdi mdi-loading mdi-spin-05 mdi-24px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIInput',
  props: {
    placeholder: {
      type: String,
      default: 'Placeholder *'
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    regular: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: null,
      type: this.$props.typeInput,
      showedPassword: false
    };
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    }
  },
  methods: {
    reset() {
      this.value = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-input_wrapper {
  position: relative;

  &-span {
    position: absolute;
    top: 45px;
    left: 0;
    font-size: 0.675rem;
    color: #df4949;
  }

  .vms__ui-input {
    padding: 8px 17px !important;
    background-color: #fff !important;
    color: #000 !important;
    line-height: 1.6 !important;
    font-size: 1rem !important;
    border-radius: 3px !important;
    border: 1px solid #e2e2e2 !important;

    &::placeholder {
      font-size: 1rem !important;
      font-family: OpenSansRegular, serif !important;
      color: #8e8e8e !important;
    }

    &--error {
      border-color: #df4949 !important;

      &-indent {
        margin-bottom: 10px;
      }
    }

    &--regular {
      border: 0 !important;
      border-bottom: 1px solid #cacaca !important;
      background-color: transparent !important;
      padding: 4px 0 !important;
      border-radius: 0 !important;

      & ~ .vms__ui-input-loader {
        top: 5px !important;
      }
    }

    &_eye {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      font-size: 20px;
      color: #cacaca;
      cursor: pointer;
    }

    &-loader {
      position: absolute;
      top: 9px;
      right: 10px;

      > div {
        color: var(--accent-green--dark);
      }
    }
  }
}
</style>
