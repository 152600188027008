import {
  OPTIONS_UPDATE_RESULTS_PER_PAGE_SUCCESS,
  OPTIONS_UPDATE_VEHICLE_CARD_TYPE_SUCCESS,
  OPTIONS_INIT_REQUEST,
  OPTIONS_INIT_SUCCESS,
  OPTIONS_INIT_ERROR,
  OPTIONS_UPDATE_MAX_MILAGE_SUCCESS,
  OPTIONS_UPDATE_MIN_PRICE_SUCCESS,
  OPTIONS_UPDATE_MAX_PRICE_SUCCESS
} from '@/store/modules/options/types';

export default {
  setOptions({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit(OPTIONS_INIT_REQUEST);
      if (options) {
        commit(OPTIONS_INIT_SUCCESS, options);
        resolve(options);
      } else {
        commit(OPTIONS_INIT_ERROR);
        reject(new Error('Parameters not passed'));
      }
    });
  },
  updateVehicleCardType({ commit }, isGrid) {
    return new Promise((resolve, reject) => {
      if (typeof isGrid === 'boolean') {
        commit(OPTIONS_UPDATE_VEHICLE_CARD_TYPE_SUCCESS, isGrid);
        resolve(isGrid);
      }
      reject(new Error('The parameter type must be Boolean'));
    });
  },
  updateResultsPerPage({ commit }, count) {
    commit(OPTIONS_UPDATE_RESULTS_PER_PAGE_SUCCESS, count);
  },
  updateMaxMileage({ commit }, milage) {
    commit(OPTIONS_UPDATE_MAX_MILAGE_SUCCESS, milage);
  },
  updateMinPrice({ commit }, price) {
    commit(OPTIONS_UPDATE_MIN_PRICE_SUCCESS, price);
  },
  updateMaxPrice({ commit }, price) {
    commit(OPTIONS_UPDATE_MAX_PRICE_SUCCESS, price);
  }
};
