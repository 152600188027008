const initProps = {
  makes: {
    _type: Array,
    default: ''
  },
  chassis: {
    _type: Array,
    default: ''
  },
  vehicleTypes: {
    _type: Array,
    default: ''
  },
  transmissions: {
    _type: Array,
    default: ''
  },
  fuels: {
    _type: Array,
    default: ''
  },
  categoryGroups: {
    _type: Array,
    default: ''
  },
  categories: {
    _type: Array,
    default: ''
  },
  colors: {
    _type: Array,
    default: ''
  },
  dealers: {
    _type: Array,
    default: ''
  },
  bodyTypes: {
    _type: Array,
    default: ''
  },
  showroomUid: {
    _type: String,
    default: ''
  },
  instanceId: {
    _type: String,
    default: ''
  },
  culture: {
    _type: String,
    default: ''
  },
  ga: {
    _type: String,
    default: ''
  },
  offsetScrollTop: {
    _type: Number,
    default: 0
  },
  rpp: {
    _type: String,
    default: ''
  },
  availablePages: {
    _type: String,
    default: ''
  },
  startPage: {
    _type: String,
    default: ''
  },
  bestDeals: {
    _type: Boolean,
    default: 'false'
  },
  bestDealsLimit: {
    _type: String,
    default: ''
  },
  conditions: {
    _type: String,
    default: ''
  },
  groupCodes: {
    _type: String,
    default: ''
  },
  categoryCodes: {
    _type: String,
    default: ''
  },
  dealerLocationIds: {
    _type: String,
    default: ''
  },
  priceFrom: {
    _type: String,
    default: ''
  },
  priceTo: {
    _type: String,
    default: ''
  },
  priceInterval: {
    _type: String,
    default: ''
  },
  firstRegistrationYearFrom: {
    _type: String,
    default: ''
  },
  firstRegistrationYearTo: {
    _type: String,
    default: ''
  },
  mileageFrom: {
    _type: String,
    default: ''
  },
  mileageTo: {
    _type: String,
    default: ''
  },
  mileageInterval: {
    _type: String,
    default: ''
  },
  tc: {
    _type: String,
    default: ''
  },
  tr: {
    _type: String,
    default: ''
  },
  le: {
    _type: String,
    default: ''
  },
  av: {
    _type: String,
    default: ''
  },
  pe: {
    _type: String,
    default: ''
  },
  dl: {
    _type: String,
    default: ''
  },
  pc: {
    _type: String,
    default: ''
  },
  vi: {
    _type: String,
    default: ''
  },
  techLanguage: {
    type: Boolean,
    default: false
  }
};

export default initProps;
