<template>
  <div
    id="PageScrollbar"
    ref="pageContainer"
    class="vms__page-container"
    :class="{ 'vms__page-container--loading': !loaded }"
    :style="{
      width: getPageWidth(),
      height: getPageHeight()
    }"
  >
    <!-- Content -->
    <slot />

    <!-- Loader -->
    <transition name="main-fade" mode="out-in">
      <UIPreloader v-if="!loaded" />
    </transition>
  </div>
</template>

<script>
import mixins from '@/mixins';
import { mapState } from 'vuex';
import SimpleBar from 'simplebar';
import UIPreloader from '@/components/ui/UIPreloader';

export default {
  name: 'PageContainer',
  mixins,
  components: { UIPreloader },
  props: {
    loaded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      simpleBar: null
    };
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    })
  },
  watch: {
    loaded(status) {
      if (status === true) {
        const options = {}; // https://github.com/Grsmto/simplebar/blob/master/packages/simplebar/README.md#options
        this.simpleBar = new SimpleBar(this.$refs.pageContainer, options);
      }
    }
  },
  methods: {
    getPageWidth() {
      return this.MixinGetContainerWidth(this.listParams.width);
    },
    getPageHeight() {
      return this.MixinGetContainerHeight(this.listParams.height);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__page-container--loading {
  overflow: hidden;
  height: 100vh !important;
}
</style>
