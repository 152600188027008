<template>
  <div class="vms__ui-slider" :class="{ 'vms__ui-slider--disabled': disabled }">
    <div class="vms__ui-slider_info">
      <div class="vms__ui-slider_info_title vms__OpenSansRegular">{{ title }}</div>
      <!-- if set only min -->
      <div v-if="ifSetOnlyMin" class="vms__ui-slider_info-p3 vms__OpenSansRegular">
        <div>
          {{ $t('vehicles.filters.from') }} {{ min }}{{ endText }} -
          {{ $t('vehicles.filters.to') }} {{ MixinFormatThousands(value) }}{{ endText }}
        </div>
      </div>

      <!-- if set only max -->
      <div v-if="ifSetOnlyMax" class="vms__ui-slider_info-p3 vms__OpenSansRegular">
        <div>{{ $t('vehicles.filters.to') }} {{ MixinFormatThousands(value) }}{{ endText }}</div>
      </div>

      <!-- if set min & max -->
      <div v-if="ifSetMinMax" class="vms__ui-slider_info-p3 vms__OpenSansRegular">
        <div>
          {{ $t('vehicles.filters.from') }} {{ min }}{{ endText }} -
          {{ $t('vehicles.filters.to') }} {{ MixinFormatThousands(value) }}{{ endText }}
        </div>
      </div>

      <!-- without min & max -->
      <div v-if="withoutMinMax" class="vms__ui-slider_info-p3 vms__OpenSansRegular">
        <div>{{ $t('vehicles.filters.to') }} {{ MixinFormatThousands(value) }}{{ endText }}</div>
      </div>
    </div>
    <div ref="vms__slider" :disabled="disabled"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import noUiSlider from 'nouislider';
import mixins from '@/mixins';

export default {
  name: 'UISliderMileage',
  mixins,
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    endText: {
      type: String,
      default: null
    },
    start: {
      type: Array,
      default: null
    },
    fromKey: {
      type: String,
      default: 'from'
    },
    toKey: {
      type: String,
      default: 'to'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    interval: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldValue: null,
      value: null,
      slider: null,
      initParams: _.omitBy(this.$root.$props, _.isNil),
      sliderOptions: {
        start: 0
      }
    };
  },
  computed: {
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ifSetOnlyMin() {
      return this.initParams[this.fromKey] && !this.initParams[this.toKey];
    },
    ifSetOnlyMax() {
      return !this.initParams[this.fromKey] && this.initParams[this.toKey];
    },
    ifSetMinMax() {
      return this.initParams[this.fromKey] && this.initParams[this.toKey];
    },
    withoutMinMax() {
      return !this.initParams[this.fromKey] && !this.initParams[this.toKey];
    }
  },
  mounted() {
    this.initSlider();
    this.oldValue = this.value;
  },
  beforeUnmount() {
    this.slider.noUiSlider.destroy();
  },
  methods: {
    initSlider() {
      this.slider = this.$refs.vms__slider;
      noUiSlider.create(this.slider, {
        start: this.setUpPosition(),
        connect: [true, false],
        step: this.interval,
        format: {
          from: value => value,
          to: value => Math.round(value)
        },
        range: {
          min: this.min,
          max: this.max || 1
        }
      });
      this.slider.noUiSlider.on('update', newValue => {
        this.value = this.formatResult(newValue);
      });
      this.slider.noUiSlider.on('set', () => {
        this.changeValue();
      });
    },
    formatResult(initValue) {
      return initValue[0];
    },
    changeValue() {
      if (!this.disabled) {
        const result = {};
        const key = this.toKey;
        result[key] = this.value;
        this.$emit('onChange', result);
      }
    },
    setUpPosition() {
      if (this.start) {
        return [this.start];
      }
      if (this.listParams[this.toKey] || this.listParams[this.toKey] === 0) {
        return [this.listParams[this.toKey]];
      }
      return [this.max];
    },
    reset() {
      this.slider.noUiSlider.reset();
      this.slider.noUiSlider.set(this.setUpPosition());
    }
  }
};
</script>

<style lang="scss">
@import '~nouislider/distribute/nouislider.min.css';

.vms__ui-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--disabled {
    opacity: 0.5;

    * {
      cursor: not-allowed !important;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &_title,
    &-p1,
    &-p2,
    &-p3,
    &-p4 {
      margin-right: 10px;
      line-height: 1.33;
      font-size: 1.125rem;
      color: inherit;

      > div {
        font-family: inherit;
      }
    }

    &-p2 {
      display: flex;

      > div {
        margin-left: 6px;
      }
    }

    &-p3 {
      > div {
        display: inline-block;
      }
    }
  }

  .noUi-horizontal {
    height: 26px;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
  }

  .noUi-base {
    padding-top: 12px;
  }

  .noUi-connects {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .noUi-connect {
    background-color: #fff;
  }

  .noUi-handle {
    width: 20px;
    height: 20px;
    top: 2px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    background-color: #2d9c90;
    box-shadow: none;
    border: 2px solid #fff;

    &::before {
      content: '';
      display: none;
    }

    &::after {
      content: '';
      display: none;
    }
  }
}
</style>
