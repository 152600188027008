<template>
  <div class="vms__ui-card-switcher">
    <div
      @click="onChange(true)"
      class="vms__ui-card-switcher_button vms__ui-card-switcher_button-grid"
      :class="{ 'vms__ui-card-switcher_button--active': isGrid }"
      data-cy="btns__view-grid"
    >
      <div class="mdi mdi-grid" />
    </div>
    <div
      @click="onChange(false)"
      class="vms__ui-card-switcher_button vms__ui-card-switcher_button-list"
      :class="{ 'vms__ui-card-switcher_button--active': !isGrid }"
      data-cy="btns__view-list"
    >
      <div class="mdi mdi-format-list-bulleted" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UICardSwitcher',
  props: {
    isGrid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChange(data) {
      if (window.auWidgetEmbed) {
        window.auWidgetEmbed.init();
      }
      this.$emit('on-change', data);
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__ui-card-switcher {
  display: flex;

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    opacity: 1;
    margin-left: 12px;
    cursor: pointer;

    &--active {
      opacity: 0.3 !important;
    }

    .mdi {
      font-size: 22px;
      color: #062d29;
    }
  }
}
</style>
