import {
  OPTIONS_UPDATE_VEHICLE_CARD_TYPE_SUCCESS,
  OPTIONS_UPDATE_RESULTS_PER_PAGE_SUCCESS,
  OPTIONS_INIT_REQUEST,
  OPTIONS_INIT_SUCCESS,
  OPTIONS_INIT_ERROR,
  OPTIONS_UPDATE_MAX_MILAGE_SUCCESS,
  OPTIONS_UPDATE_MIN_PRICE_SUCCESS,
  OPTIONS_UPDATE_MAX_PRICE_SUCCESS
} from '@/store/modules/options/types';

export default {
  [OPTIONS_INIT_REQUEST]: state => {
    state.statusOptions = false;
  },
  [OPTIONS_INIT_SUCCESS]: (state, filters) => {
    state.statusOptions = true;
    state.initOptions = { ...filters };
    state.listOptions = filters;
    if (filters.googleMapsApiKey) state.googleMapsApiKey = filters.googleMapsApiKey;
    if (filters.showSortList) state.showSortList = filters.showSortList;
    if (filters.showCompare) state.showCompare = filters.showCompare;
  },
  [OPTIONS_INIT_ERROR]: (state, payload) => {
    state.statusOptions = payload;
  },
  [OPTIONS_UPDATE_VEHICLE_CARD_TYPE_SUCCESS]: (state, isGrid) => {
    state.listOptions.ga = isGrid;
  },
  [OPTIONS_UPDATE_RESULTS_PER_PAGE_SUCCESS]: (state, count) => {
    state.listOptions.rpp = count;
  },
  [OPTIONS_UPDATE_MAX_MILAGE_SUCCESS]: (state, milage) => {
    state.mileageTo = milage;
  },
  [OPTIONS_UPDATE_MIN_PRICE_SUCCESS]: (state, price) => {
    state.priceFrom = price;
  },
  [OPTIONS_UPDATE_MAX_PRICE_SUCCESS]: (state, price) => {
    state.priceTo = price;
  }
};
