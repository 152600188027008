<template>
  <div
    v-if="
      bestDealsList &&
      bestDealsList.length &&
      listParams.bestDeals !== false &&
      listParams.bestDealsLimit !== 0
    "
    class="vms__best-deals vms__container"
  >
    <div class="vms__best-deals-title vms__BarlowSemiCondensedRegular">
      {{ $t('vehicle.bestDealsTitle') }}
    </div>
    <agile
      v-if="bestDealsList && vehiclesList && vehiclesList.length > 3"
      :dots="true"
      :options="options"
      :unagile="bestDealsList.length < 2"
      class="vms__best-deals_carousel"
    >
      <VehiclesCard
        v-for="(item, i) of vehiclesList"
        :key="i"
        :id="item.id"
        :link="`/vehicles/${item && item.id}`"
        :imgUrl="item && item.thumbUrl"
        :title="item.title"
        :date="item.firstRegistrationDate"
        :km="item.mileage"
        :price="item.price"
        :discount="item"
        :payload="item"
        :cy-name="`best-deals-${i+1}`"
      />
      <template slot="prevButton"><div data-cy="best-deals__left-arrow" class="mdi mdi-arrow-left" /></template>
      <template slot="nextButton"><div data-cy="best-deals__right-arrow" class="mdi mdi-arrow-right" /></template>
    </agile>
    <simplebar v-else ref="bestDealsScrollbar">
      <div class="vms__best-deals_list">
        <VehiclesCard
          v-for="(item, i) of vehiclesList"
          :key="i"
          :id="item.id"
          :link="`/vehicles/${item && item.id}`"
          :imgUrl="item && item.thumbUrl"
          :title="item.title"
          :date="item.firstRegistrationDate"
          :km="item.mileage"
          :price="item.price"
          :discount="item"
          :payload="item"
          :cy-name="`best-deals-${i+1}`"
        />
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { VueAgile } from '@/plugins/vue-agile';
import mixins from '@/mixins';
import Responsive from '@/directives/Responsive';
import VehiclesCard from '@/components/VehiclesCard';
import simplebar from 'simplebar-vue';

export default {
  name: 'VehiclesBestDeals',
  mixins,
  components: {
    agile: VueAgile,
    VehiclesCard,
    simplebar
  },
  directives: {
    responsive: Responsive
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    scrollbar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      filterParams: {},
      timer: null,
      options: {
        slidesToShow: 3,
        spacingBetweenSlides: 20,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    };
  },
  created() {
    if (this.showroomLoaded === true) {
      this.getBestDeals();
      if (window.auWidgetEmbed) {
        window.auWidgetEmbed.init();
      }
    }
  },
  computed: {
    ...mapState('vehicles', {
      bestDealsList: 'bestDealsList',
      bestDealsLoaded: 'bestDealsLoaded',
      vehiclesByIds: 'vehiclesByIds'
    }),
    ...mapState('params', {
      listParams: 'listParams'
    }),
    ...mapState('showrooms', {
      showroomLoaded: 'showroomLoaded'
    }),
    vehiclesList() {
      if (this.bestDealsList) {
        return this.bestDealsList.slice(0, this.listParams.bestDealsLimit);
      }
      return [];
    }
  },
  watch: {
    showroomLoaded(loaded) {
      if (loaded === true) {
        this.getBestDeals();
      }
    },
    bestDealsLoaded(loaded) {
      if (loaded === true) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (window.auWidgetEmbed) {
              window.auWidgetEmbed.init();
            }
          }, 1000);
        });
      }
    }
  },
  methods: {
    getBestDeals() {
      this.filterParams = {
        ...this.listParams,
        PageSize: 3
      };
      this.$store.dispatch('vehicles/getBestDeals', this.filterParams);
    },
    listenBestDealsScrollbar() {
      if (this.timer !== null) {
        clearTimeout(this.timer);
        this.disableScroll();
      }
      this.timer = setTimeout(() => {
        this.enableScroll();
      }, 500);
    },
    enableScroll() {
      this.$props.scrollbar.create();
    },
    disableScroll() {
      this.$props.scrollbar.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.vms__best-deals {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;

  &-title {
    line-height: 1.15;
    letter-spacing: -0.04px;
    font-size: 1.625rem;
    color: #000;
    margin-bottom: 50px;
  }

  &_list {
    display: flex;
    padding-bottom: 15px;

    .vms__vehicles_item {
      margin: 0 calc(10px + 4%) 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_carousel {
    .vms__vehicles_item {
      min-width: auto;
      max-width: none;
    }
  }

  /deep/ .agile__actions {
    justify-content: center;
    margin-top: 10px;

    .agile__nav-button {
      padding: 10px;
      cursor: pointer;
    }

    .agile__dots {
      margin: 0 5px;

      .agile__dot .agile__dot-btn {
        background-color: #000;
      }
    }
  }
}
</style>
